<script setup lang="ts">
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import DatePicker from 'primevue/datepicker';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
	downloadFile,
	getFileObjectFromBase64,
	isEmptyValue
} from '@/shared/helpers';
import { ActionPopover, DownloadButton, RefreshButton } from '@/shared/ui';

import {
	faxBurstHeaders,
	faxInboxHeaders,
	faxOutboxHeaders,
	faxStatusOptions
} from '../model/constants';
import useFaxStore from '../model/store';

import AttachJobToFax from './AttachJobToFax.vue';
import FaxStatus from './FaxStatus.vue';
import FaxUpload from './FaxUpload.vue';

import type {
	Burst,
	BurstFilters,
	FaxFiltersWithMetaData,
	FaxInbox,
	FaxOutbox,
	InboxFilters,
	OutboxFilters
} from '../model/types';
import type {
	DataTablePageEvent,
	DataTableSortEvent
} from 'primevue/datatable';

const { t } = useI18n();
const toast = useToast();
const confirm = useConfirm();
const faxStore = useFaxStore();

const handlePage = (state: DataTablePageEvent) => {
	faxStore.pagination.page = state.page + 1;
};
const handleSort = (state: DataTableSortEvent) => {
	faxStore.pagination.page = 1;

	faxStore.handleSort({
		desc: state.sortOrder === -1,
		field: state.sortField as string
	});
};

const getFilterIconClass = (field: string) => {
	const filter =
		faxStore[currentFilters.value][
			field as keyof FaxFiltersWithMetaData<
				InboxFilters | OutboxFilters | BurstFilters
			>
		];
	return isEmptyValue(filter.value) || filter.value === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const currentFilters = computed(() => {
	if (faxStore.currentTab == 'inbox') {
		return 'inboxFilters';
	} else if (faxStore.currentTab == 'outbox') {
		return 'outboxFilters';
	} else {
		return 'burstFilters';
	}
});

const currentData = computed(() => {
	if (faxStore.currentTab == 'inbox') {
		return 'faxInbox';
	} else if (faxStore.currentTab == 'outbox') {
		return 'faxOutbox';
	} else {
		return 'burstTable';
	}
});

const currentHeaders = computed(() => {
	if (faxStore.currentTab == 'inbox') {
		return faxInboxHeaders;
	} else if (faxStore.currentTab == 'outbox') {
		return faxOutboxHeaders;
	} else {
		return faxBurstHeaders;
	}
});

const burstButtonIsLoading = ref({
	faxIds: [0],
	loading: false
});
const burstFaxesConfirm = (faxIds: number[]) => {
	confirm.require({
		accept: () => {
			burstFaxesHandler(faxIds);
		},
		acceptProps: {
			label: t('faxDialog.actions.burst.burst'),
			severity: 'warn'
		},
		header: t('confirmation'),
		icon: 'pi pi-info-circle',
		message:
			faxIds.length > 1
				? t('faxDialog.actions.burst.plural')
				: t('faxDialog.actions.burst.single'),
		rejectLabel: t('faxDialog.actions.cancel'),
		rejectProps: {
			label: t('faxDialog.actions.cancel'),
			outlined: true,
			severity: 'secondary'
		}
	});
};
const burstFaxesHandler = async (faxIds: number[]) => {
	burstButtonIsLoading.value = {
		faxIds: faxIds,
		loading: true
	};

	await faxStore.burstChosenFaxes(faxIds);

	selectedFaxes.value = null;
	burstButtonIsLoading.value = {
		faxIds: [0],
		loading: false
	};
};

const deleteButtonIsLoading = ref({
	faxIds: [0],
	loading: false
});
const deleteFaxesConfirm = (faxIds: number[]) => {
	confirm.require({
		accept: () => {
			deleteFaxesHandler(faxIds);
		},
		acceptProps: {
			label: t('faxDialog.actions.delete.delete'),
			severity: 'danger'
		},
		header: t('confirmation'),
		icon: 'pi pi-info-circle',
		message:
			faxIds.length > 1
				? t('faxDialog.actions.delete.plural')
				: t('faxDialog.actions.delete.single'),
		rejectLabel: t('faxDialog.actions.cancel'),
		rejectProps: {
			label: t('faxDialog.actions.cancel'),
			outlined: true,
			severity: 'secondary'
		}
	});
};
const deleteFaxesHandler = async (faxIds: number[]) => {
	deleteButtonIsLoading.value = {
		faxIds: faxIds,
		loading: true
	};

	const response = await faxStore.deleteChosenFaxes(faxIds);
	if (response.status === 'Error') {
		toast.add({
			life: 3000,
			severity: 'error',
			detail: t('faxDialog.actions.delete.error'),
			summary: t('defaultToast.errorSummary')
		});
	} else {
		toast.add({
			life: 3000,
			severity: 'success',
			detail: t('faxDialog.actions.delete.success'),
			summary: t('defaultToast.successSummary')
		});
	}

	selectedFaxes.value = null;

	deleteButtonIsLoading.value = {
		faxIds: [0],
		loading: false
	};
};

const attachJobDetails = ref<FaxInbox | FaxOutbox | null>(null);
const showJobAttachDialog = ref<boolean>(false);
const openJobAttachDialog = (item: FaxInbox | FaxOutbox) => {
	attachJobDetails.value = item;
	showJobAttachDialog.value = true;
};

const selectedFaxes = ref<FaxInbox[] | FaxOutbox[] | Burst[] | null>();
const statusList = [
	{
		title: t('faxDialog.status.unread'),
		value: 'unread'
	},
	{
		title: t('faxDialog.status.read'),
		value: 'read'
	},
	{
		title: t('faxDialog.status.burst'),
		value: 'burst'
	}
];
const statusModel = ref('');

const prepareSelectedFaxes = computed(() => {
	if (selectedFaxes.value) {
		return selectedFaxes.value.map(item => item.faxId);
	} else {
		return [];
	}
});

const changeStatusIsLoading = ref<boolean>(false);
const changeSelectedFaxesStatus = async (faxId: number[], status: string) => {
	changeStatusIsLoading.value = true;

	await faxStore.updateFaxStatusHandler({
		faxIds: faxId,
		status: status
	});

	changeStatusIsLoading.value = false;
	statusModel.value = '';
	selectedFaxes.value = null;
};
const showUploadFaxDialog = ref<boolean>(false);

const updateReferral = async (slotProps: any, status: string) => {
	if (status === 'cancel') {
		slotProps.editorCancelCallback();
	} else {
		const response = await faxStore.updateFaxReferralHandler({
			faxId: slotProps.data.faxId,
			referral: slotProps.data[slotProps.field]
		});
		if (response === 'Error' || response === 'failed') {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: t('faxDialog.actions.changeReferral.error'),
				summary: t('defaultToast.errorSummary')
			});
		} else {
			toast.add({
				life: 3000,
				severity: 'success',
				detail: t('faxDialog.actions.changeReferral.success'),
				summary: t('defaultToast.successSummary')
			});
		}
		slotProps.editorSaveCallback();
	}
};

const dataTableRef = ref();
const skeletonArray = Array(10).fill({});

const confirmationIsLoading = ref({
	faxId: 0,
	loading: false
});
const downloadOutboxTransmittal = async (jobid: number, faxId: number) => {
	confirmationIsLoading.value.faxId = faxId;
	confirmationIsLoading.value.loading = true;
	const response = await faxStore.downloadOutboxTransmittalHandler(jobid);
	if (!response) {
		return;
	} else {
		const fileData = getFileObjectFromBase64(
			response,
			`fax-confirmation-${jobid}.pdf`
		);
		downloadFile(fileData.url, fileData.name);
	}
	confirmationIsLoading.value.faxId = 0;
	confirmationIsLoading.value.loading = false;
};
</script>

<template>
	<DataTable
		ref="dataTableRef"
		v-model:filters="faxStore[currentFilters]"
		v-model:selection="selectedFaxes"
		dataKey="faxId"
		editMode="cell"
		filterDisplay="menu"
		lazy
		paginator
		:rows="faxStore.pagination.limit"
		scrollable
		scrollHeight="flex"
		:sortField="faxStore.sort.sortBy"
		:sortOrder="faxStore.sort.sortDesc === false ? 1 : -1"
		style="height: calc(100% - 57px)"
		tableClass="tw3-max-w-full tw3-h-full"
		:totalRecords="faxStore[currentData].state.totalCount"
		:value="
			faxStore[currentData].isLoading
				? skeletonArray
				: faxStore[currentData].state.data
		"
		@page="handlePage"
		@sort="handleSort"
	>
		<Column
			v-if="faxStore.currentTab !== 'outbox'"
			headerStyle="width: 3rem"
			selectionMode="multiple"
		></Column>
		<Column
			v-for="column of currentHeaders"
			:key="column.key"
			:editor="column.key === 'referral' ? 'InputText' : false"
			:field="column.key"
			:filterField="column.key"
			:header="column.title"
			:showFilterMatchModes="false"
			sortable
			:style="
				column.key === 'referral'
					? {
							width: 'auto'
						}
					: column.key === 'dateTime'
						? {
								width: '16%',
								'min-width': '16%',
								'text-align': 'center'
							}
						: column.key === 'sentFrom' || column.key === 'sentTo'
							? {
									width: '14%',
									'min-width': '14%',
									'text-align': 'center'
								}
							: column.key === 'pages'
								? {
										width: '5%',
										'min-width': '5%',
										'text-align': 'right'
									}
								: column.key === 'status'
									? {
											width: '5%',
											'min-width': '5%',
											'text-align': 'center'
										}
									: {}
			"
		>
			<template #filtericon>
				<i :class="getFilterIconClass(column.key)"></i>
			</template>
			<template #filter="{ filterModel }">
				<DatePicker
					v-if="column.key === 'date' || column.key === 'dateTime'"
					v-model="filterModel.value"
					appendTo="#vue3app"
					dateFormat="mm/dd/yy"
					mask="99/99/9999"
					placeholder="mm/dd/yyyy"
				/>
				<Select
					v-else-if="column.key === 'status'"
					v-model="filterModel.value"
					optionLabel="label"
					:options="faxStatusOptions"
					optionValue="value"
					placeholder="Select status"
				/>
				<InputText
					v-else
					v-model="filterModel.value"
					class="p-column-filter"
					:placeholder="`${t('searchBy')} ${column.title}`"
					type="text"
				/>
			</template>

			<template #body="{ data, editorInitCallback }">
				<div class="cell-container">
					<Skeleton v-if="faxStore[currentData].isLoading" />
					<FaxStatus
						v-else-if="column.key === 'status'"
						:status="data[column.key]"
					/>
					<div
						v-else-if="column.key === 'fileName' && data.url"
						v-tooltip="data[column.key]"
						class="tw3-max-w-[250px] tw3-truncate"
					>
						<a :href="data.url" target="_blank">
							{{ data[column.key] }}
						</a>
					</div>
					<div
						v-else-if="column.key === 'referral'"
						@click="editorInitCallback"
					>
						<span v-if="data[column.key]">{{ data[column.key] }}</span>
						<span v-else>
							{{ t('faxDialog.actions.changeReferral.placeholder') }}</span
						>
					</div>
					<span v-else>{{ data[column.key] }}</span>
				</div>
			</template>
			<template
				#editor="slotProps"
				v-if="column.key === 'referral' ? 'InputText' : false"
			>
				<Skeleton v-if="faxStore[currentData].isLoading" />
				<div v-else class="tw3-flex tw3-items-center tw3-gap-1">
					<InputText
						v-model="slotProps.data[slotProps.field]"
						:placeholder="t('faxDialog.actions.changeReferral.placeholder')"
					/>

					<Button
						icon="pi pi-check"
						size="small"
						text
						@click.once="
							() => {
								updateReferral(slotProps, 'update');
							}
						"
					/>
					<Button
						icon="pi pi-times"
						severity="danger"
						size="small"
						text
						@click="
							event => {
								updateReferral(slotProps, 'cancel');
							}
						"
					/>
				</div>
			</template>
		</Column>
		<Column
			bodyClass="text-center"
			dataType="boolean"
			field="actions"
			:header="t('faxDialog.actions.actions')"
			:showClearButton="false"
			style="width: 100px"
		>
			<template #body="{ data }">
				<Skeleton v-if="faxStore[currentData].isLoading" />
				<div v-else class="tw3-flex tw3-gap-1">
					<template v-if="faxStore.currentTab !== 'outbox'">
						<Button
							v-tooltip.top="t('faxDialog.actions.delete.delete')"
							icon="pi pi-trash"
							:loading="
								deleteButtonIsLoading.loading &&
								deleteButtonIsLoading.faxIds.includes(data.faxId)
							"
							severity="danger"
							size="small"
							text
							@click.once="deleteFaxesConfirm([data.faxId])"
						/>
						<ActionPopover v-tooltip.top="'Additional actions'">
							<template #content>
								<div class="tw3-flex tw3-flex-col tw3-gap-2">
									<Button
										v-if="faxStore.currentTab === 'inbox'"
										v-tooltip.top="t('faxDialog.actions.burst.burst')"
										icon="pi pi-eraser"
										:loading="
											burstButtonIsLoading.loading &&
											burstButtonIsLoading.faxIds.includes(data.faxId)
										"
										severity="warn"
										size="small"
										text
										@click.once="burstFaxesConfirm([data.faxId])"
									/>
									<DownloadButton
										v-tooltip.top="t('faxDialog.actions.download')"
										:fileName="data.fileName"
										:link="data.url"
										size="small"
										text
										@downloading="
											() => {
												if (faxStore.currentTab == 'inbox')
													changeSelectedFaxesStatus([data.faxId], 'read');
											}
										"
									/>
									<Button
										v-tooltip.top="t('faxDialog.actions.attachFax.attach')"
										icon="pi pi-paperclip"
										severity="secondary"
										size="small"
										text
										@click="openJobAttachDialog(data)"
									/>
								</div>
							</template>
						</ActionPopover>
					</template>
					<Button
						v-else
						v-tooltip.top="{
							value:
								data.status == 'SENT'
									? t('faxDialog.actions.downloadConfirmation')
									: t('faxDialog.actions.noConfirmationAvailable'),
							pt: {
								text: 'text-center'
							}
						}"
						:disabled="data.status !== 'SENT'"
						icon="pi pi-download"
						:loading="
							confirmationIsLoading.loading &&
							confirmationIsLoading.faxId == data.faxId
						"
						:severity="data.status == 'SENT' ? 'success' : 'secondary'"
						size="small"
						text
						@click="downloadOutboxTransmittal(data.jobid, data.faxId)"
					/>
				</div>
			</template>
		</Column>

		<template #header>
			<div class="tw3-flex tw3-justify-start tw3-gap-3">
				<Button
					:label="t('faxDialog.actions.upload.upload')"
					severity="info"
					@click="showUploadFaxDialog = true"
				/>

				<div
					v-if="faxStore.currentTab !== 'outbox'"
					class="tw3-flex tw3-gap-3 tw3-items-center tw3-justify-start"
				>
					<ActionPopover
						dropdownPosition="left-bottom"
						icon="pi pi-pen-to-square"
					>
						<template #button>
							<Button
								v-tooltip.top="{
									value: t('faxDialog.actions.pluralActionsTooltip'),
									pt: {
										text: '!tw3-text-center'
									}
								}"
								icon="pi pi-angle-down"
								iconPos="right"
								:label="t('faxDialog.actions.actions')"
								severity="info"
							/>
						</template>
						<template #content>
							<div
								class="tw3-flex tw3-gap-3 tw3-items-center tw3-justify-start"
							>
								<Select
									v-if="faxStore.currentTab === 'inbox'"
									v-model="statusModel"
									:disabled="prepareSelectedFaxes.length === 0"
									:loading="changeStatusIsLoading"
									optionLabel="title"
									:options="statusList"
									optionValue="value"
									:placeholder="t('faxDialog.selectStatus')"
									@change="
										changeSelectedFaxesStatus(prepareSelectedFaxes, statusModel)
									"
								/>
								<Button
									v-if="faxStore.currentTab === 'inbox'"
									v-tooltip.top="t('faxDialog.actions.burst.pluralTooltip')"
									:disabled="prepareSelectedFaxes.length === 0"
									icon="pi pi-eraser"
									:loading="burstButtonIsLoading.loading"
									severity="warn"
									size="small"
									text
									@click="burstFaxesConfirm(prepareSelectedFaxes)"
								/>
								<Button
									v-if="faxStore.currentTab !== 'outbox'"
									v-tooltip.top="t('faxDialog.actions.delete.pluralTooltip')"
									:disabled="prepareSelectedFaxes.length === 0"
									icon="pi pi-trash"
									:loading="deleteButtonIsLoading.loading"
									severity="danger"
									size="small"
									text
									@click="deleteFaxesConfirm(prepareSelectedFaxes)"
								/>
							</div>
						</template>
					</ActionPopover>
				</div>

				<RefreshButton
					class="tw3-mr-0 tw3-ml-auto"
					@click="faxStore.refreshData()"
				/>
			</div>
		</template>

		<template v-if="!faxStore[currentData].isLoading" #empty>
			<div
				class="tw3-w-full tw3-h-full tw3-flex tw3-justify-center tw3-items-center"
			>
				<p class="tw3-text-center tw3-text-3xl">{{ t('noData') }}</p>
			</div>
		</template>
	</DataTable>
	<AttachJobToFax
		v-if="showJobAttachDialog"
		v-model:show="showJobAttachDialog"
		:data="attachJobDetails"
	/>
	<FaxUpload v-if="showUploadFaxDialog" v-model:show="showUploadFaxDialog" />
</template>
