import {
	formatNumber,
	formatPrice,
	formatToOneDigit
} from '@/widgets/dashboard/lib/format-data';
import { TimeRange } from '@/widgets/dashboard/model/types';

import {
	calculatePercentageDifference,
	prettifyPercentageDifference
} from './percentage';

export const getChartOptions = (
	single: boolean,
	timeMode: TimeRange,
	labels: string[],
	isPercent?: boolean
) => {
	const documentStyle = getComputedStyle(document.documentElement);
	const textColorSecondary = documentStyle.getPropertyValue(
		'--p-text-muted-color'
	);

	return {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: function (context: any) {
						let label = context.dataset.label || '';

						if (label) {
							label += ': ';
						}
						if (context.parsed.y !== null) {
							label += isPercent
								? formatToOneDigit(context.parsed.y).toFixed(1) + '%'
								: context.dataset.yAxisID === 'yRight' || single
									? formatNumber(context.parsed.y)
									: formatPrice(formatNumber(context.parsed.y));
							if (context.parsed.x > 0 && !isPercent) {
								label +=
									' (' +
									prettifyPercentageDifference(
										calculatePercentageDifference(
											context.parsed.y,
											context.dataset.data[context.parsed.x - 1]
										)
									) +
									')';
							}
						}
						return label;
					}
				}
			}
		},
		responsive: true,
		maintainAspectRatio: false,
		hover: {
			mode: 'index'
		},
		scales: {
			y: {
				ticks: {
					color: textColorSecondary,
					callback: function (value: number) {
						return isPercent
							? formatToOneDigit(value).toFixed(1) + '%'
							: !single
								? formatPrice(formatNumber(value))
								: formatNumber(value);
					}
				},
				grid: {
					display: false
				}
			},
			...(!single
				? {
						yRight: {
							position: 'right',
							ticks: {
								color: textColorSecondary,
								callback: function (value: number) {
									return formatNumber(value);
								}
							},
							grid: {
								display: false
							}
						}
					}
				: {}),
			x: {
				ticks: {
					color: textColorSecondary,
					callback: (value: string, index: number) => {
						if (timeMode !== TimeRange.DAY && timeMode !== TimeRange.WEEK) {
							return labels[index];
						}
						const totalTicks = labels.length;
						if (
							index === 0 ||
							index === Math.floor(totalTicks / 2) ||
							index === totalTicks - 1
						) {
							return labels[index];
						}
					}
				},
				barPercentage: 0.5,
				grid: {
					display: false
				}
			}
		}
	};
};
