import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Tag from 'primevue/tag';

import {
	ClaimStatuses,
	ClaimStatusesFullInfo,
	mappedStatus
} from './lib/constants';
import { ClaimStatus } from './lib/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClaimStatus',
  props: {
    status: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_unref(Tag), {
    class: "tw3-capitalize",
    severity: _unref(mappedStatus)[_ctx.status],
    value: _unref(ClaimStatuses)[_ctx.status]
  }, null, 8, ["severity", "value"])), [
    [_directive_tooltip, _unref(ClaimStatusesFullInfo)[_ctx.status]]
  ])
}
}

})