import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createSlots as _createSlots, renderList as _renderList, vShow as _vShow, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-justify-between" }
const _hoisted_2 = { class: "tw3-flex tw3-gap-2 tw3-items-center" }
const _hoisted_3 = { class: "!tw3-text-xl !tw3-font-bold tw3-text-black" }
const _hoisted_4 = { class: "tw3-flex tw3-items-center tw3-pr-4 tw3-opacity-50" }
const _hoisted_5 = {
  class: "tw3-flex tw3-justify-end tw3-gap-2",
  style: {"padding-right":"2.5px"}
}
const _hoisted_6 = { class: "tw3-flex tw3-gap-2" }
const _hoisted_7 = { class: "tw3-text-right tw3-pr-3" }
const _hoisted_8 = { class: "tw3-text-right tw3-pr-3" }
const _hoisted_9 = {
  key: 0,
  class: "tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20"
}

import { FilterMatchMode, FilterOperator } from '@primevue/core/api';
import { useInfiniteScroll } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Message from 'primevue/message';
import Select from 'primevue/select';
import { computed, reactive, ref, watch, watchEffect } from 'vue';

import router from '@/app/router';
import { BalanceType } from '@/entities/accounting/accounts/lib/types';
import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import { TRANSACTION_TYPE_ID_LEDGER_ENTRY } from '@/entities/accounting/transactions/lib/types';
import useTransactionsStore from '@/entities/accounting/transactions/lib/typeStore';
import useOrganizationsStore from '@/entities/organizations/lib/store';
import AccountSelect from '@/features/accounting/AccountSelect.vue';
import useAccountingStore from '@/features/accounting/lib/store';
import ShopSelect from '@/features/accounting/ShopSelect.vue';
import { formatDate, formatMoney } from '@/shared/helpers/formatters';
import PageCard from '@/shared/ui/container/PageCard.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';
import LoadingIndicator from '@/shared/ui/overlay/LoadingIndicator.vue';
import LedgerEntryEdit from '@/widgets/accounting/accountTransactions/LedgerEntryEdit.vue';
import TransactionDetails from '@/widgets/accounting/journalEntries/TransactionDetails.vue';

import useStore from './lib/store';

export default /*@__PURE__*/_defineComponent({
  __name: 'AccountTransactionList',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props;

const { organizations } = storeToRefs(useOrganizationsStore());

const transactionsStore = useTransactionsStore();
const { types: transactionTypes, typesById: transactionTypesById } =
	storeToRefs(transactionsStore);

const store = useStore();
const {
	loading,
	account,
	transactions,
	transactionsCanLoadMore,
	transactionsParams
} = storeToRefs(store);

const transactionStore = useTransactionStore();

const accountingStore = useAccountingStore();
const {
	accounts,
	typesById: accountTypesbyId,
	selectedProfileId,
	shopsById
} = storeToRefs(accountingStore);

watch(
	() => props.id,
	() => {
		transactionsParams.value = {
			...transactionsParams.value,
			accountId: props.id
		};
	},
	{ immediate: true }
);

watchEffect(() => {
	selectedProfileId.value = account.value?.organizationId;
});

const accountType = computed(() => {
	if (!account.value) {
		return null;
	}
	return accountTypesbyId.value[account.value.typeId];
});

const organization = computed(() => {
	return organizations.value.find(
		organization => organization.id === account.value?.organizationId
	);
});

const refresh = () => {
	store.loadTransactions(true);
};

const transactionsNormalized = computed(() => {
	if (accountType.value == null) {
		return [];
	}
	return transactions.value.map((transaction: any) => {
		return {
			...transaction,
			increase: accountType.value!.creditAccount
				? transaction.credit
				: transaction.debit,
			decrease: accountType.value!.creditAccount
				? transaction.debit
				: transaction.credit
		};
	});
});

const filters = ref();

const initFilters = () => {
	filters.value = reactive({
		otherAccount: {
			value: null,
			matchMode: FilterMatchMode.EQUALS
		},
		type: {
			value: null,
			matchMode: FilterMatchMode.EQUALS
		},
		shop: {
			value: null,
			matchMode: FilterMatchMode.EQUALS
		},
		increase: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		},
		decrease: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		},
		transactionDt: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		}
	});
};

initFilters();

const activeFilters = computed<Record<string, boolean>>(() => ({
	shop: filters.value.shop.value,
	type: filters.value.type.value,
	otherAccount: filters.value.otherAccount.value,
	increase:
		filters.value.increase.constraints[0].value ||
		filters.value.increase.constraints[1].value,
	decrease:
		filters.value.decrease.constraints[0].value ||
		filters.value.decrease.constraints[1].value,
	transactionDt:
		filters.value.transactionDt.constraints[0].value ||
		filters.value.transactionDt.constraints[1].value
}));

const clearFilter: Record<string, CallableFunction> = {
	otherAccount: () => {
		filters.value.otherAccount.value = null;
		applyFilters();
	},
	type: () => {
		filters.value.type.value = null;
		applyFilters();
	},
	shop: () => {
		filters.value.shop.value = null;
		applyFilters();
	},
	increase: () => {
		filters.value.increase.constraints[0].value = null;
		filters.value.increase.constraints[1].value = null;
		applyFilters();
	},
	decrease: () => {
		filters.value.decrease.constraints[0].value = null;
		filters.value.decrease.constraints[1].value = null;
		applyFilters();
	},
	transactionDt: () => {
		filters.value.transactionDt.constraints[0].value = null;
		filters.value.transactionDt.constraints[1].value = null;
		applyFilters();
	}
};

const applyFilters = () => {
	let debit = {
		min: filters.value.increase.constraints[0].value,
		max: filters.value.increase.constraints[1].value
	};
	let credit = {
		min: filters.value.decrease.constraints[0].value,
		max: filters.value.decrease.constraints[1].value
	};
	if (accountType.value?.creditAccount) {
		const a = debit;
		debit = credit;
		credit = a;
	}
	transactionsParams.value = {
		...transactionsParams.value,
		shopId: filters.value.shop.value,
		typeId: filters.value.type.value,
		otherAccountId: filters.value.otherAccount.value,
		debit: debit,
		credit: credit,
		transactionDt: {
			start: filters.value.transactionDt.constraints[0].value,
			end: filters.value.transactionDt.constraints[1].value
		}
	};
};

const title = computed(() => [
	{ label: 'Chart of Accounts', disabled: true },
	{
		label: `${accountType.value?.name}:  ${account.value?.name}`,
		disabled: true
	}
]);

const details = async (id: number) => {
	transactionStore.loadSelectedTransaction(id, true);
	showTransactionDetails.value = true;
};

const edit = async (id: number) => {
	transactionStore.loadSelectedTransaction(id);
	showTransactionDetails.value = false;
	showLedgerEntryEdit.value = true;
};

const showLedgerEntryEdit = ref(false);
const showTransactionDetails = ref(false);

const goToAccounts = () => {
	router.push({ name: 'accounting-accounts' });
};

const editingRows = ref<any[]>([]);

const editMode = ref(false);

watch(
	() => editingRows.value,
	() => {
		if (editingRows.value.length > 0) {
			editMode.value = true;
		} else {
			editMode.value = false;
		}
	},
	{ deep: true }
);

const addLedgerEntry = () => {
	transactionStore.resetSelectedTransaction(account.value!.organizationId!, 1);
	showLedgerEntryEdit.value = true;
};

const table = ref<typeof DataTable | null>(null);
useInfiniteScroll(
	() => table.value?.$el.querySelector('.p-datatable-table-container'),
	async () => {
		await store.loadTransactions();
	},
	{
		distance: 10,
		interval: 1000,
		canLoadMore: () => transactionsCanLoadMore.value
	}
);

applyFilters();

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!!organization.value && !!_unref(account) && !!accountType.value)
      ? (_openBlock(), _createBlock(PageCard, { key: 0 }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _withDirectives(_createVNode(_unref(Button), {
                  "aria-label": "Filter",
                  icon: "pi pi-arrow-left",
                  serverity: "secondary",
                  text: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (goToAccounts()))
                }, null, 512), [
                  [
                    _directive_tooltip,
                    'Back to accounts',
                    void 0,
                    { top: true }
                  ]
                ]),
                _createVNode(_unref(Breadcrumb), {
                  model: title.value,
                  "pt:root:class": "!tw3-p-0"
                }, {
                  item: _withCtx(({ item }) => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(item.label), 1)
                  ]),
                  _: 1
                }, 8, ["model"]),
                _createVNode(_unref(Message), {
                  class: "!tw3-ml-4 !tw3-my-0",
                  closable: false,
                  "pt:content:class": "!tw3-py-2 !tw3-px-4",
                  severity: "secondary"
                }, {
                  default: _withCtx(() => [
                    (accountType.value.balanceType == _unref(BalanceType).BALANCE_TYPE_ON_DATE)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _cache[14] || (_cache[14] = _createElementVNode("span", { class: "tw3-mr-2" }, " Current balance:", -1)),
                          _createElementVNode("b", null, _toDisplayString(_unref(formatMoney)(_unref(account).balance)), 1)
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _cache[15] || (_cache[15] = _createElementVNode("span", { class: "tw3-mr-2" }, " Year to date balance:", -1)),
                          _createElementVNode("b", null, _toDisplayString(_unref(formatMoney)(_unref(account).balance)), 1)
                        ], 64))
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(organization.value.name), 1)
            ])
          ]),
          content: _withCtx(() => [
            _createVNode(_unref(DataTable), {
              ref_key: "table",
              ref: table,
              editingRows: editingRows.value,
              "onUpdate:editingRows": _cache[8] || (_cache[8] = ($event: any) => ((editingRows).value = $event)),
              filters: filters.value,
              "onUpdate:filters": _cache[9] || (_cache[9] = ($event: any) => ((filters).value = $event)),
              dataKey: "id",
              editMode: "row",
              filterDisplay: "menu",
              lazy: "",
              pt: { bodyRow: { style: 'cursor: pointer' } },
              rowHover: !editMode.value,
              scrollable: "",
              scrollHeight: "flex",
              size: "small",
              tableClass: "tw3-max-w-full",
              value: transactionsNormalized.value,
              onFilter: _cache[10] || (_cache[10] = event => applyFilters()),
              onRowClick: _cache[11] || (_cache[11] = event => (editMode.value ? null : details(event.data.id)))
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _withDirectives(_createVNode(_unref(Button), {
                      disabled: editMode.value,
                      icon: "pi pi-plus",
                      label: "New Ledger Entry",
                      onClick: addLedgerEntry
                    }, null, 8, ["disabled"]), [
                      [
                        _directive_tooltip,
                        'Create New Ledger Entry',
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _withDirectives(_createVNode(_unref(Button), {
                      disabled: editMode.value,
                      icon: "pi pi-refresh",
                      severity: "secondary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (refresh()))
                    }, null, 8, ["disabled"]), [
                      [
                        _directive_tooltip,
                        'Refresh',
                        void 0,
                        { top: true }
                      ]
                    ])
                  ])
                ])
              ]),
              empty: _withCtx(() => [
                (!_unref(loading))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[22] || (_cache[22] = [
                      _createElementVNode("p", { class: "tw3-text-center tw3-text-3xl" }, "Transactions not found", -1)
                    ])))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                _createVNode(_unref(Column), {
                  field: "type",
                  header: "Type",
                  showAddButton: false,
                  showFilterMatchModes: false,
                  showFilterOperator: false,
                  style: { width: '15%' }
                }, _createSlots({
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_unref(transactionTypesById)[data.typeId].name), 1)
                  ]),
                  filtericon: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(
								activeFilters.value.type == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							)
                    }, null, 2)
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_unref(Button), {
                      outlined: "",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (clearFilter.type()))
                    }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createTextVNode(" Clear ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, [
                  (!editMode.value)
                    ? {
                        name: "filter",
                        fn: _withCtx(({ filterModel }) => [
                          _createVNode(_unref(Select), {
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                            optionLabel: "name",
                            options: _unref(transactionTypes),
                            optionValue: "id",
                            placeholder: "All types"
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1024),
                _createVNode(_unref(Column), {
                  field: "transactionDt",
                  header: "Date",
                  pt: {
						filterRemove: { style: 'display: none' }
					},
                  showAddButton: false,
                  showFilterMatchModes: false,
                  showFilterOperator: false,
                  style: { width: '10%' }
                }, _createSlots({
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_unref(formatDate)(data.transactionDt)), 1)
                  ]),
                  filtericon: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(
								activeFilters.value.transactionDt == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							)
                    }, null, 2)
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_unref(Button), {
                      outlined: "",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (clearFilter.transactionDt()))
                    }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode(" Clear ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, [
                  (!editMode.value)
                    ? {
                        name: "filter",
                        fn: _withCtx(({ filterModel }) => [
                          _createVNode(DatePicker, {
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                            class: "p-column-filter",
                            manualInput: false,
                            placeholder: 
								filterModel.matchMode == 'gte'
									? `Greater or equal than`
									: `Less or equal than`
							,
                            showIcon: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1024),
                _createVNode(_unref(Column), {
                  field: "increase",
                  header: "Increase",
                  pt: {
						filterRemove: { style: 'display: none' }
					},
                  showAddButton: false,
                  showFilterMatchModes: false,
                  showFilterOperator: false,
                  style: { width: '10%' }
                }, _createSlots({
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(data.increase ? _unref(formatMoney)(data.increase) : ''), 1)
                  ]),
                  filtericon: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(
								activeFilters.value.increase == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							)
                    }, null, 2)
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_unref(Button), {
                      outlined: "",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (clearFilter.increase()))
                    }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode(" Clear ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, [
                  (!editMode.value)
                    ? {
                        name: "filter",
                        fn: _withCtx(({ filterModel }) => [
                          _createVNode(InputMoney, {
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                            align: "left",
                            class: "p-column-filter",
                            placeholder: 
								filterModel.matchMode == 'gte'
									? `Greater or equal than`
									: `Less or equal than`
							
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1024),
                _createVNode(_unref(Column), {
                  field: "decrease",
                  header: "Decrease",
                  pt: {
						filterRemove: { style: 'display: none' }
					},
                  showAddButton: false,
                  showFilterMatchModes: false,
                  showFilterOperator: false,
                  style: { width: '10%' }
                }, _createSlots({
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(data.decrease ? _unref(formatMoney)(data.decrease) : ''), 1)
                  ]),
                  filtericon: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(
								activeFilters.value.decrease == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							)
                    }, null, 2)
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_unref(Button), {
                      outlined: "",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (clearFilter.decrease()))
                    }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode(" Clear ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, [
                  (!editMode.value)
                    ? {
                        name: "filter",
                        fn: _withCtx(({ filterModel }) => [
                          _createVNode(InputMoney, {
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                            align: "left",
                            class: "p-column-filter",
                            placeholder: 
								filterModel.matchMode == 'gte'
									? `Greater or equal than`
									: `Less or equal than`
							
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1024),
                _createVNode(_unref(Column), {
                  field: "description",
                  header: "Description",
                  style: { width: '17%' }
                }),
                _createVNode(_unref(Column), {
                  field: "otherAccount",
                  header: "Other accounts",
                  showAddButton: false,
                  showFilterMatchModes: false,
                  showFilterOperator: false,
                  style: { width: '15%' }
                }, _createSlots({
                  body: _withCtx(({ data }) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.otherAccountIds, (accountId) => {
                      return (_openBlock(), _createElementBlock("div", { key: accountId }, _toDisplayString(_unref(accounts).find(account => account.id === accountId)?.name), 1))
                    }), 128))
                  ]),
                  filtericon: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(
								activeFilters.value.otherAccount == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							)
                    }, null, 2)
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_unref(Button), {
                      outlined: "",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (clearFilter.otherAccount()))
                    }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode(" Clear ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, [
                  (!editMode.value)
                    ? {
                        name: "filter",
                        fn: _withCtx(({ filterModel }) => [
                          _createVNode(AccountSelect, {
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                            placeholder: "All accounts"
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1024),
                _createVNode(_unref(Column), {
                  field: "shop",
                  header: "Shop",
                  showAddButton: false,
                  showFilterMatchModes: false,
                  showFilterOperator: false,
                  style: { width: '17%' }
                }, _createSlots({
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_unref(shopsById)[data.shopId].name), 1)
                  ]),
                  filtericon: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(
								activeFilters.value.shop == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							)
                    }, null, 2)
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_unref(Button), {
                      outlined: "",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (clearFilter.shop()))
                    }, {
                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                        _createTextVNode(" Clear ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, [
                  (!editMode.value)
                    ? {
                        name: "filter",
                        fn: _withCtx(({ filterModel }) => [
                          _createVNode(ShopSelect, {
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event)
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1024)
              ]),
              _: 1
            }, 8, ["editingRows", "filters", "rowHover", "value"]),
            _withDirectives(_createVNode(LoadingIndicator, null, null, 512), [
              [_vShow, _unref(loading)]
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(TransactionDetails, {
      visible: showTransactionDetails.value,
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((showTransactionDetails).value = $event)),
      allowEdit: [_unref(TRANSACTION_TYPE_ID_LEDGER_ENTRY)],
      onDeleted: refresh,
      onEdit: edit
    }, null, 8, ["visible", "allowEdit"]),
    _createVNode(LedgerEntryEdit, {
      visible: showLedgerEntryEdit.value,
      "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => ((showLedgerEntryEdit).value = $event)),
      account: _unref(account),
      onSaved: refresh
    }, null, 8, ["visible", "account"])
  ], 64))
}
}

})