import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-gap-[1rem] tw3-px-[0.5rem]" }
const _hoisted_2 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_3 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_4 = { class: "tw3-w-full tw3-max-w-64" }

import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import InputGroup from 'primevue/inputgroup';
import InputMask from 'primevue/inputmask';
import { useField, useForm } from 'vee-validate';
import { nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { InputTextWithError } from '@/shared/ui';

const ZOD_COMPANY_NAME = 'companyName';
const ZOD_WEBSITE = 'website';
const ZOD_FAX = 'fax';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactInfoCompany',
  props: {
    companyName: {},
    website: {},
    fax: {},
    isCommercial: {}
  },
  emits: ["update:companyName", "update:website", "update:fax", "update:isCommercial"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const props = __props;

const emit = __emit;

const store = useCustomerEditStore();

const validationSchema = toTypedSchema(
	zod.object({
		[ZOD_COMPANY_NAME]: zod.string().nullish(),
		[ZOD_WEBSITE]: zod.string().nullish(),
		[ZOD_FAX]: zod.string().max(14, t('errorNumber')).nullish()
	})
);

useForm({
	initialValues: {
		[ZOD_COMPANY_NAME]: props.companyName,
		[ZOD_WEBSITE]: props.website?.split('//').at(-1),
		[ZOD_FAX]: props.fax
	},
	validationSchema
});

const domen = ref<string>(
	props.website?.split('//').at(0)
		? props.website.split('//').at(0) + '//'
		: 'https://'
);

const {
	errorMessage: companyNameError,
	value: companyNameValue,
	setValue: setValueCompany
} = useField(ZOD_COMPANY_NAME);
const {
	errorMessage: websiteError,
	value: websiteValue,
	setValue: setValueSite
} = useField(ZOD_WEBSITE);
const { errorMessage: faxError, value: faxValue } = useField(ZOD_FAX);

const updateDomen = () => {
	domen.value = domen.value === 'https://' ? 'http://' : 'https://';
	if (!websiteValue.value) return;
	emit('update:website', domen.value + websiteValue.value);
};

const updateCompanyName = async (v: string | null) => {
	const input = document.getElementById('companyNameInput') as HTMLInputElement;
	const cursorPosition = input.selectionStart;
	emit(
		'update:companyName',
		v
			? v
					.split(' ')
					.map(word => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' ')
			: v
	);
	await nextTick();
	input.setSelectionRange(cursorPosition, cursorPosition);
};

watch(
	() => companyNameError.value,
	() =>
		store.updateErrors(
			'company_name',
			companyNameError.value ? { error: companyNameError.value } : {}
		)
);

watch(
	() => websiteError.value,
	() =>
		store.updateErrors(
			'website',
			websiteError.value ? { error: websiteError.value } : {}
		)
);

watch(
	() => faxError.value,
	() =>
		store.updateErrors('fax', faxError.value ? { error: faxError.value } : {})
);

watch(
	() => props.website,
	() => {
		setValueSite(props.website?.split('//').at(-1));
		domen.value = props.website?.split('//').at(0)
			? props.website.split('//').at(0) + '//'
			: 'https://';
	}
);

watch(
	() => props.companyName,
	() => setValueCompany(props.companyName)
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Company Name ", -1)),
      _createVNode(_unref(InputTextWithError), {
        id: "companyNameInput",
        disabled: !_ctx.isCommercial,
        errMsg: _unref(companyNameError),
        modelValue: _unref(companyNameValue),
        placeholder: "Company Name",
        "onUpdate:modelValue": updateCompanyName
      }, null, 8, ["disabled", "errMsg", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Website ", -1)),
      _createVNode(_unref(InputGroup), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Button), {
            label: domen.value,
            severity: "secondary",
            onClick: updateDomen
          }, null, 8, ["label"]),
          _createVNode(_unref(InputTextWithError), {
            modelValue: _unref(websiteValue),
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => (_isRef(websiteValue) ? (websiteValue).value = $event : null)),
              _cache[1] || (_cache[1] = 
						v => emit('update:website', v ? domen.value + v : null)
					)
            ],
            disabled: !_ctx.isCommercial,
            errMsg: _unref(websiteError),
            placeholder: "Website"
          }, null, 8, ["modelValue", "disabled", "errMsg"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Fax ", -1)),
      _createVNode(_unref(InputMask), {
        modelValue: _unref(faxValue),
        "onUpdate:modelValue": [
          _cache[2] || (_cache[2] = ($event: any) => (_isRef(faxValue) ? (faxValue).value = $event : null)),
          _cache[3] || (_cache[3] = v => emit('update:fax', v ?? null))
        ],
        class: "tw3-w-full tw3-max-w-64",
        disabled: !_ctx.isCommercial,
        invalid: !!_unref(faxError),
        mask: "(999) 999-9999",
        placeholder: "Fax",
        type: "text"
      }, null, 8, ["modelValue", "disabled", "invalid"])
    ])
  ]))
}
}

})