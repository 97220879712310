import { toTypedSchema } from '@vee-validate/zod';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { InvalidSubmissionHandler, useFieldArray, useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { z } from 'zod';

import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import {
	Transaction,
	TRANSACTION_TYPE_ID_JOURNAL_ENTRY
} from '@/entities/accounting/transactions/lib/types';
import { useMessages } from '@/shared/composables';

import useStore from './lib/store';

export const useJournalEntryForm = () => {
	const { showFieldValidationError } = useMessages();

	const transactionStore = useTransactionStore();
	const { selectedTransaction } = storeToRefs(transactionStore);

	const store = useStore();

	const saving = ref(false);

	const { defineField, handleSubmit, resetForm, errors, meta } =
		useForm<Transaction>({
			validationSchema: toTypedSchema(
				z.object({
					id: z.optional(z.number().nullable()),
					transactionDt: z.any().refine(value => value instanceof DateTime, {
						message: 'required'
					}),
					description: z.string().trim(),
					shopId: z.number().min(1, 'required'),
					items: z
						.array(
							z
								.object({
									accountId: z
										.number({ message: 'Select Account' })
										.min(1, 'Select Account'),
									debit: z.number().min(0, 'required'),
									credit: z.number().min(0, 'required'),
									description: z.string().trim()
								})
								.refine(
									item => {
										return item.debit > 0 || item.credit > 0;
									},
									{
										message: 'Debit or Credit must be greater than 0'
									}
								)
						)
						.refine(() => creditTotal.value === debitTotal.value, {
							message: 'Debit and Credit amounts must match'
						})
				})
			)
		});

	const fieldLabels: Record<string, string> = {
		typeId: 'Transaction Type',
		transactionDt: 'Transaction Date',
		description: 'Transaction Description',
		shopId: 'Shop',
		items: 'Transaction items'
	};

	const [transactionDt] = defineField('transactionDt');
	const [description] = defineField('description');
	const [shopId] = defineField('shopId');
	const {
		remove: removeItem,
		push: pushItem,
		fields: items
	} = useFieldArray('items');

	const itemsErrors = computed(() => {
		return items.value.map((item: any, index: number) => {
			const errs = errors.value as Record<string, string>;
			return {
				accountId: errs[`items[${index}].accountId`],
				debit: errs[`items[${index}]`],
				credit: errs[`items[${index}]`]
			};
		});
	});

	const onValidationError: InvalidSubmissionHandler = res => {
		let showItemsErrors = false;
		for (const [field, error] of Object.entries(res.errors)) {
			if (field.startsWith('items[')) {
				showItemsErrors = true;
				continue;
			}
			showFieldValidationError(fieldLabels[field] ?? field, error!);
		}
		if (showItemsErrors) {
			showFieldValidationError(
				'Transaction items',
				'Please correct the errors in the items'
			);
		}
	};

	const doSave = async (values: Transaction) => {
		saving.value = true;
		selectedTransaction.value = {
			...selectedTransaction.value!,
			...values
		};
		await transactionStore.saveSelectedTransaction();
		await store.loadTransactions(true);
		saving.value = false;
	};

	const init = () => {
		if (
			selectedTransaction.value?.typeId != TRANSACTION_TYPE_ID_JOURNAL_ENTRY
		) {
			throw new Error('Only Journal Entry transactions are supported');
		}
		resetForm({
			values: selectedTransaction.value!
		});
	};

	const reset = () => {
		// TODO: rework resetSelectedTransaction to accept some defaults
		const oldValues = { ...selectedTransaction.value };
		transactionStore.resetSelectedTransaction(
			selectedTransaction.value!.organizationId!,
			2
		);
		selectedTransaction.value!.shopId = oldValues.shopId!;
		resetForm({
			values: selectedTransaction.value!
		});
	};

	const save = (onSuccess: () => void) => {
		handleSubmit(async (values: Transaction) => {
			await doSave(values);
			onSuccess();
		}, onValidationError)();
	};

	const addItem = () => {
		pushItem({
			accountId: null,
			debit: 0,
			credit: 0,
			description: '',
			customerId: null,
			vendorId: null,
			partId: null,
			quantity: 0
		});
	};

	const creditTotal = computed(() =>
		items.value.reduce((acc: number, item: any) => acc + item.value.credit, 0)
	);

	const debitTotal = computed(() =>
		items.value.reduce((acc: number, item: any) => acc + item.value.debit, 0)
	);

	const amountMatch = computed(() => creditTotal.value === debitTotal.value);

	const swapDebitCredit = () => {
		items.value.forEach((item: any) => {
			const debit = item.value.debit;
			item.value.debit = item.value.credit;
			item.value.credit = debit;
		});
	};

	const onItemAmountChange = (item: any, edited: string, value: number) => {
		if (edited === 'debit') {
			if (value > 0) {
				item.credit = 0;
			}
		} else {
			if (value > 0) {
				item.debit = 0;
			}
		}
	};

	const valid = computed(() => meta.value.valid);

	return {
		valid,
		init,
		transactionDt,
		description,
		shopId,
		items,
		addItem,
		removeItem,
		swapDebitCredit,
		creditTotal,
		debitTotal,
		amountMatch,
		save,
		reset,
		itemsErrors,
		onItemAmountChange,
		fieldLabels,
		saving,
		errors
	};
};
