<script setup lang="ts">
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Skeleton from 'primevue/skeleton';
import { computed, ref, watchEffect } from 'vue';

import router from '@/app/router';
import { BalanceType } from '@/entities/accounting/accounts/lib/types';
import useTransactionsStore from '@/entities/accounting/transactions/lib/typeStore';
import useOrganizationsStore from '@/entities/organizations/lib/store';
import useAccountingStore from '@/features/accounting/lib/store';
import { formatDate, formatMoney } from '@/shared/helpers/formatters';
import PageCard from '@/shared/ui/container/PageCard.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import TransactionDetails from '@/widgets/accounting/journalEntries/TransactionDetails.vue';

import useAccountingReportsStore from './lib/store';

const cid = 'accounting-transactions-by-account';

const skeletonArray = Array(30).fill({});

const props = defineProps<{
	id: number;
}>();

const { organizations } = storeToRefs(useOrganizationsStore());

const accountingStore = useAccountingStore();
const { accounts, typesById, shopsById } = storeToRefs(accountingStore);

const account = computed(() => {
	return accounts.value.find((account: any) => account.id == props.id);
});
const accountType = computed(() =>
	account.value ? typesById.value[account.value.typeId] : null
);

const organization = computed(() => {
	return organizations.value.find(
		(organization: any) => organization.id == account.value?.organizationId
	)!;
});

const transactionsStore = useTransactionsStore();
const { typesById: transactionTypesById } = storeToRefs(transactionsStore);

const reportsStore = useAccountingReportsStore();
const { transactionsByAccount: report, transactionsByAccountLoading: loading } =
	storeToRefs(reportsStore);

const selectedTransactionDtFrom = ref(DateTime.now().startOf('year'));
const selectedTransactionDtTo = ref(DateTime.now().startOf('day'));

const refresh = async () => {
	await reportsStore.loadTransactionsByAccount({
		accountId: props.id,
		transactionDt: {
			start: selectedTransactionDtFrom.value,
			end: selectedTransactionDtTo.value.plus({ days: 1 })
		}
	});
};

watchEffect(async () => {
	await refresh();
});

const recordsNormalized = computed(() => {
	if (!accountType.value) {
		return [];
	}
	let balance = report.value.startBalance;
	const creditAccount = accountType.value.creditAccount;
	return report.value.record.map((transaction: any) => {
		const increase = creditAccount ? transaction.credit : transaction.debit;
		const decrease = creditAccount ? transaction.debit : transaction.credit;
		const balanceBefore = balance;
		balance += increase - decrease;
		return {
			...transaction,
			increase: increase,
			decrease: decrease,
			balanceBefore: balanceBefore,
			balance: balance
		};
	});
});

const totalIncrease = computed(() => {
	return recordsNormalized.value.reduce(
		(acc, transaction) => acc + transaction.increase,
		0
	);
});

const totalDecrease = computed(() => {
	return recordsNormalized.value.reduce(
		(acc, transaction) => acc + transaction.decrease,
		0
	);
});

const totalBalance = computed(() => {
	return recordsNormalized.value.reduce(
		(acc, transaction) => acc + transaction.balance,
		0
	);
});

const table = ref<typeof DataTable | null>(null);

const ytd = computed(() => {
	return accountType.value?.balanceType == BalanceType.BALANCE_TYPE_YTD;
});

const mainReport = computed(() => {
	return !ytd.value
		? {
				title: 'Balance Sheet',
				routeName: 'accounting-balance-sheet'
			}
		: {
				title: 'Income Statement',
				routeName: 'accounting-income-statement'
			};
});

const title = computed(() => [
	{
		label: mainReport.value.title,
		disabled: true
	},
	{
		label: `${accountType.value?.name}: ${account.value?.name}`,
		disabled: true
	}
]);

const showTransactionDetails = ref(false);
const selectedTransaction: any = ref(null);

const goToAccounts = () => {
	router.push({ name: mainReport.value.routeName });
};
</script>

<template>
	<PageCard v-if="!!accountType">
		<template #title>
			<div class="tw3-flex tw3-justify-between">
				<div class="tw3-flex tw3-gap-2 tw3-items-center">
					<Button
						v-tooltip.top="`Back to ${mainReport.title}`"
						aria-label="Filter"
						icon="pi pi-arrow-left"
						serverity="secondary"
						text
						@click="goToAccounts()"
					>
					</Button>

					<Breadcrumb :model="title" pt:root:class="!tw3-p-0">
						<template #item="{ item }">
							<span class="!tw3-text-xl !tw3-font-bold tw3-text-black">
								{{ item.label }}
							</span>
						</template>
					</Breadcrumb>
				</div>
				<div class="tw3-flex tw3-items-center tw3-pr-4 tw3-opacity-50">
					{{ organization.name }}
				</div>
			</div>
		</template>
		<template #content>
			<DataTable
				ref="table"
				dataKey="id"
				editMode="row"
				filterDisplay="menu"
				groupRowsBy="someColumn"
				:pt="{
					rowgroupheadercell: { colspan: '99' },
					rowgroupfootercell: { colspan: '99' }
				}"
				:rowHover="!loading"
				rowGroupMode="subheader"
				scrollable
				scrollHeight="flex"
				size="small"
				tableClass="tw3-max-w-full"
				:value="loading ? skeletonArray : recordsNormalized"
			>
				<template #header>
					<div class="tw3-flex tw3-justify-between tw3-gap-2">
						<div class="tw3-flex tw3-gap-3">
							<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow">
								<label :for="`${cid}-dt-from`">From</label>
								<DatePicker
									v-model="selectedTransactionDtFrom"
									class="tw3-w-[250px]"
									hourFormat="12"
									:inputId="`${cid}-dt-from`"
									:manualInput="false"
									showIcon
								/>
							</div>
							<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow">
								<label :for="`${cid}-dt-from`">To</label>
								<DatePicker
									:id="`${cid}-dt-to`"
									v-model="selectedTransactionDtTo"
									class="tw3-w-[250px]"
									hourFormat="12"
									:manualInput="false"
									showIcon
								/>
							</div>
						</div>
						<div class="tw3-flex tw3-gap-2 tw3-items-end">
							<Button
								v-tooltip.top="'Refresh'"
								icon="pi pi-refresh"
								severity="secondary"
								@click="refresh()"
							/>
						</div>
					</div>
				</template>

				<template v-if="!loading && !ytd" #groupheader="{ data }">
					<div class="tw3-flex tw3-justify-end tw3-gap-2 tw3-font-bold">
						<span class="tw3-mr-3"> Starting balance: </span>
						{{ formatMoney(data.balanceBefore) }}
					</div>
				</template>

				<template v-if="!loading" #groupfooter="{ data }">
					<div class="tw3-flex tw3-justify-end tw3-font-bold tw3-w-full">
						<template v-if="!ytd">
							<span class="tw3-mr-3"> Ending balance: </span>
							<span> {{ formatMoney(data.balance) }} </span>
						</template>
						<template v-else>
							<span class="tw3-mx-3">
								<span class="tw3-mr-3"> Increase: </span>
								<span> {{ formatMoney(totalIncrease) }} </span>
							</span>
							/
							<span class="tw3-mx-3">
								<span class="tw3-mr-3"> Decrease: </span>
								<span> {{ formatMoney(totalDecrease) }} </span>
							</span>
							/
							<span class="tw3-mx-3">
								<span class="tw3-mr-3"> Total Change: </span>
								<span> {{ formatMoney(totalBalance) }} </span>
							</span>
						</template>
					</div>
				</template>

				<Column field="transactionDt" header="Date" :style="{ width: '8%' }">
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<template v-else>
							{{ formatDate(data.transactionDt) }}
						</template>
					</template>
				</Column>

				<Column field="type" header="Type" :style="{ width: '17%' }">
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<template v-else>
							{{ transactionTypesById[data.typeId].name }}
						</template>
					</template>
				</Column>

				<Column
					field="description"
					header="Description"
					:style="{ width: '19%' }"
				>
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<template v-else>
							{{ data.description }}
						</template>
					</template>
				</Column>

				<Column
					field="otherAccount"
					header="Other Account"
					:style="{ width: '17%' }"
				>
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<template v-else>
							{{
								accounts.find(account => account.id === data.otherAccountId)
									?.name
							}}
							<div v-for="accountId in data.otherAccountIds" :key="accountId">
								{{ accounts.find(account => account.id === accountId)?.name }}
							</div>
						</template>
					</template>
				</Column>

				<Column field="shop" header="Shop" :style="{ width: '17%' }">
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<template v-else>
							{{ shopsById[data.shopId].name }}
						</template>
					</template>
				</Column>

				<Column field="increase" header="Increase" :style="{ width: '8%' }">
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<div v-else class="tw3-text-right">
							{{ data.increase ? formatMoney(data.increase) : '' }}
						</div>
					</template>
				</Column>

				<Column field="decrease" header="Decrease" :style="{ width: '8%' }">
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<div v-else class="tw3-text-right">
							{{ data.decrease ? formatMoney(data.decrease) : '' }}
						</div>
					</template>
				</Column>

				<Column
					v-if="!ytd"
					field="balance"
					header="Balance"
					:style="{ width: '8%' }"
				>
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<div v-else class="tw3-text-right">
							{{ formatMoney(data.balance) }}
						</div>
					</template>
				</Column>

				<template v-if="!loading" #empty>
					<div
						class="tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20"
					>
						<p class="tw3-text-center tw3-text-3xl">Transactions not found</p>
					</div>
				</template>
			</DataTable>
		</template>
	</PageCard>
	<TransactionDetails
		v-model:transaction="selectedTransaction"
		v-model:visible="showTransactionDetails"
	/>
</template>
