<script setup lang="ts">
import Chart from 'primevue/chart';
import Popover from 'primevue/popover';
import { computed, ref, StyleValue } from 'vue';

import { formatNumber, formatPrice, formatToOneDigit } from '../lib';

defineProps<{
	title: string;
	differenceNumber: number;
	value: number;
	chart: any;
	valueStyle: StyleValue;
	cardStyle?: StyleValue;
	isRevenue?: boolean;
	isPercent?: boolean;
	last90?: boolean;
	showTooltip?: {
		tooltipHeaderI18: string;
		tooltipDescI18: string;
	};
	showTooltipExplaining?: boolean;
}>();

const popup = ref<any>();

const toggle = (event: MouseEvent) => {
	popup.value.toggle(event);
};

const chartLifetimeOptions = computed(() => {
	return {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: false
			}
		},
		responsive: true,
		maintainAspectRatio: false,
		hover: {
			mode: null
		},
		scales: {
			y: {
				display: false,
				grid: {
					display: false
				}
			},
			x: {
				display: false,
				grid: {
					display: false
				}
			}
		},
		elements: {
			line: {
				borderWidth: 2,
				tension: 0.8
			},
			point: {
				radius: 0
			}
		}
	};
});
</script>

<template>
	<div class="tw3-col-span-12 md:tw3-col-span-6 xl:tw3-col-span-3">
		<div
			class="card tw3-cursor-pointer !tw3-border-0 !tw3-shadow-transparent tw3-relative tw3-h-28 tw3-rounded-xl tw3-p-4"
			:style="cardStyle"
		>
			<span class="tw3-text-sm tw3-font-medium tw3-leading-none tw3-uppercase">
				{{ title }}
			</span>
			<div
				class="tw3-flex tw3-justify-center tw3-z-10 tw3-absolute tw3-top-2 tw3-right-2"
				@click.stop
			>
				<div
					v-if="showTooltip"
					aria-controls="overlay_popover"
					class="tw3-w-9 tw3-h-9 tw3-flex tw3-items-center tw3-justify-center tw3-opacity-60 tw3-transition-all hover:tw3-opacity-100 hover:tw3-bg-gray-100 tw3-rounded-full"
					@click="toggle"
				>
					<i class="pi pi-info-circle tw3-text-lg" />
				</div>
				<Popover
					v-if="showTooltip"
					id="overlay_popover"
					ref="popup"
					class="tw3-max-w-96 !tw3-mt-0 !tw3-ml-[-5px]"
				>
					<i18n-t
						class="tw3-text-lg tw3-mb-2"
						:keypath="showTooltip.tooltipDescI18"
						tag="p"
					>
						<template #name>
							<span class="tw3-font-bold">{{
								$t(showTooltip.tooltipHeaderI18)
							}}</span>
						</template>
					</i18n-t>
					<p
						v-if="showTooltipExplaining"
						class="tw3-text-sm tw3-text-gray-600 tw3-whitespace-pre-line"
					>
						{{ $t('dashboardTooltipDescription') }}
					</p>
				</Popover>
			</div>
			<div class="tw3-relative tw3-flex tw3-justify-between tw3-h-full">
				<div class="tw3-flex tw3-justify-between tw3-items-center">
					<div
						class="tw3-flex tw3-justify-center tw3-items-center tw3-gap-2 tw3-h-8 tw3-rounded tw3-p-2 tw3-mr-4"
						:style="valueStyle"
					>
						<i
							class="pi"
							:class="differenceNumber >= 0 ? 'pi-plus' : 'pi-minus'"
						></i>
						<span class="tw3-leading-tight">
							{{
								isPercent
									? formatToOneDigit(differenceNumber).toFixed(1) + '%'
									: isRevenue
										? formatPrice(formatNumber(differenceNumber))
										: formatNumber(differenceNumber)
							}}
						</span>
					</div>
					<div class="tw3-leading-loose tw3-text-3xl">
						{{
							isPercent
								? formatToOneDigit(value).toFixed(1) + '%'
								: isRevenue
									? formatPrice(formatNumber(value))
									: formatNumber(value)
						}}
					</div>
				</div>
				<span
					class="tw3-absolute tw3-bottom-0 tw3-left-0 tw3-text-xs tw3-font-semibold tw3-leading-none tw3-text-gray-500"
				>
					{{ last90 ? $t('time.last90days') : $t('time.thisMonth') }}
				</span>
			</div>
			<div
				class="tw3-w-44 tw3-h-20 tw3-bottom-4 tw3-right-4 tw3-absolute tw3-inline-block"
				:class="{ '!tw3-h-14': showTooltip }"
			>
				<Chart
					v-if="chart"
					class="tw3-h-full"
					:data="chart"
					:options="chartLifetimeOptions"
					type="line"
				/>
			</div>
		</div>
	</div>
</template>
