import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-dialog-title" }
const _hoisted_2 = { class: "tw3-grid tw3-grid-cols-1 tw3-gap-4" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-row tw3-gap-2" }
const _hoisted_4 = { class: "tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1" }
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1" }
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "tw3-flex tw3-flex-col tw3-gap-0" }
const _hoisted_9 = ["for"]
const _hoisted_10 = { class: "tw3-flex tw3-flex-col tw3-gap-0" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "tw3-flex tw3-justify-between tw3-mt-5" }
const _hoisted_14 = { class: "tw3-flex tw3-justify-start" }
const _hoisted_15 = { class: "tw3-flex tw3-justify-end" }

import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Textarea from 'primevue/textarea';
import { useConfirm } from 'primevue/useconfirm';
import { computed } from 'vue';

import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import useTransactionTypesStore from '@/entities/accounting/transactions/lib/typeStore';
import CheatSheet from '@/features/accounting/CheatSheet.vue';
import useAccountingStore from '@/features/accounting/lib/store';
import { formatDate, formatMoney } from '@/shared/helpers/formatters';

const cid = 'accounting-transaction-details';


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionDetails',
  props: /*@__PURE__*/_mergeModels({
    allowEdit: { default: () => [] }
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["edit", "deleted"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

const { typesById } = storeToRefs(useTransactionTypesStore());
const { accounts, shopsById } = storeToRefs(useAccountingStore());

const transactionStore = useTransactionStore();
const { selectedTransaction } = storeToRefs(transactionStore);

const transaction = computed(() => selectedTransaction.value!);

const confirm = useConfirm();

const emit = __emit;



const visible = _useModel<boolean>(__props, 'visible');

const edit = () => {
	emit('edit', transaction.value.id!);
};

const remove = () => {
	confirm.require({
		message: 'Do you want to delete this record?',
		header: 'Delete Transaction',
		icon: 'pi pi-info-circle',
		rejectLabel: 'Cancel',
		rejectProps: {
			label: 'Cancel',
			severity: 'secondary',
			outlined: true
		},
		acceptProps: {
			label: 'Delete',
			severity: 'danger'
		},
		accept: () => {
			transactionStore.deleteSelectedTransaction();
			visible.value = false;
			emit('deleted');
		},
		reject: () => {}
	});
};

const cancel = () => {
	visible.value = false;
};

const loading = computed(
	() => !selectedTransaction.value || !shopsById.value || !typesById.value
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    visible: visible.value,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((visible).value = $event)),
    appendTo: "#vue3app",
    modal: "",
    style: { width: '60rem' }
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(loading.value
						? 'Loading...'
						: `${_unref(typesById)[transaction.value.typeId!]?.name} #${transaction.value?.id}`), 1),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "tw3-flex-grow" }, null, -1)),
      _createVNode(CheatSheet, { rounded: "" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", {
              class: "tw3-pl-1",
              for: `${cid}-shop-id`
            }, "Shop", 8, _hoisted_5),
            (loading.value)
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2.2rem"
                }))
              : (_openBlock(), _createBlock(_unref(InputText), {
                  key: 1,
                  id: `${cid}-shop-id`,
                  modelValue: _unref(shopsById)[transaction.value.shopId!].name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(shopsById)[transaction.value.shopId!].name) = $event)),
                  disabled: "",
                  type: "text"
                }, null, 8, ["id", "modelValue"]))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", {
              class: "tw3-pl-1",
              for: `${cid}-transaction-dt`
            }, "Date", 8, _hoisted_7),
            (loading.value)
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2.2rem"
                }))
              : (_openBlock(), _createBlock(_unref(InputText), {
                  key: 1,
                  id: `${cid}-transaction-dt`,
                  disabled: "",
                  type: "text",
                  value: _unref(formatDate)(transaction.value.transactionDt)
                }, null, 8, ["id", "value"]))
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-description`
          }, " Transaction Description ", 8, _hoisted_9),
          (loading.value)
            ? (_openBlock(), _createBlock(_unref(Skeleton), {
                key: 0,
                height: "3.4rem"
              }))
            : (_openBlock(), _createBlock(_unref(Textarea), {
                key: 1,
                id: `${cid}-description`,
                modelValue: transaction.value.description,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((transaction.value.description) = $event)),
                disabled: ""
              }, null, 8, ["id", "modelValue"]))
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { class: "tw3-pl-1" }, "Items", -1)),
          _createVNode(_unref(DataTable), {
            size: "small",
            value: loading.value ? new Array(2) : transaction.value.items
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(Column), {
                field: "account",
                header: "Account"
              }, {
                body: _withCtx(({ data }) => [
                  (loading.value)
                    ? (_openBlock(), _createBlock(_unref(Skeleton), {
                        key: 0,
                        height: "2.2rem"
                      }))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_unref(accounts).find(account => account.id === data.accountId)?.name), 1)
                      ], 64))
                ]),
                _: 1
              }),
              _createVNode(_unref(Column), {
                field: "debit",
                header: "Debit"
              }, {
                body: _withCtx(({ data }) => [
                  (loading.value)
                    ? (_openBlock(), _createBlock(_unref(Skeleton), {
                        key: 0,
                        height: "2.2rem"
                      }))
                    : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(data.debit ? _unref(formatMoney)(data.debit) : ''), 1))
                ]),
                _: 1
              }),
              _createVNode(_unref(Column), {
                field: "credit",
                header: "Credit"
              }, {
                body: _withCtx(({ data }) => [
                  (loading.value)
                    ? (_openBlock(), _createBlock(_unref(Skeleton), {
                        key: 0,
                        height: "2.2rem"
                      }))
                    : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(data.credit ? _unref(formatMoney)(data.credit) : ''), 1))
                ]),
                _: 1
              }),
              _createVNode(_unref(Column), {
                field: "description",
                header: "Description"
              }, {
                body: _withCtx(({ data }) => [
                  (loading.value)
                    ? (_openBlock(), _createBlock(_unref(Skeleton), {
                        key: 0,
                        height: "2.2rem"
                      }))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(data.description), 1)
                      ], 64))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (transaction.value && _ctx.allowEdit.includes(transaction.value.typeId!))
            ? (_openBlock(), _createBlock(_unref(Button), {
                key: 0,
                label: 'Delete',
                severity: "danger",
                onClick: remove
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_15, [
          (transaction.value && _ctx.allowEdit.includes(transaction.value.typeId!))
            ? (_openBlock(), _createBlock(_unref(Button), {
                key: 0,
                class: "tw3-ml-5",
                label: 'Edit',
                onClick: edit
              }))
            : _createCommentVNode("", true),
          _createVNode(_unref(Button), {
            class: "tw3-ml-5",
            label: 'Close',
            severity: "secondary",
            onClick: cancel
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})