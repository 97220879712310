<script setup lang="ts">
import { useEventListener as vueuseEventListener } from '@vueuse/core';
import { ref, watch } from 'vue';

import { useClaimDetailsStore } from '@/entities/claim/lib/store';
import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { useUserStore } from '@/entities/user/lib/store';
import { useEventListener } from '@/shared/composables';
import ClaimDialog from '@/widgets/claimDialog/ClaimDialog.vue';
import { newClaimFromJob } from '@/widgets/claimTable/lib/utils';
import EditCustomerDialog from '@/widgets/editCustomerDialog/editCustomerDialog.vue';
import FaxDialog from '@/widgets/faxDialog';
import { useInsuranceStore } from '@/widgets/insurance/lib/store';
import InsuranceDialog from '@/widgets/insuranceDialog/InsuranceDialog.vue';
import QuoteDialog from '@/widgets/quoteDialog/QuoteDialog.vue';
import RejectedInvoicesDialog from '@/widgets/rejectedInvoicesDialog';

const showInsuranceDialog = useEventListener<boolean>('openInsuranceDialog');
const showQuotesDialog = useEventListener<boolean>('openQuotesDialog');
const showClaimDetailsDialog = useEventListener<boolean>(
	'openClaimDetailsDialog'
);
const showFaxDialog = useEventListener<boolean>('openFaxDialog');
const showRejectedInvoicesDialog = useEventListener<boolean>(
	'openRejectedInvoicesDialog'
);
const showEditCustomerDialog = ref(false);

const customerEditStore = useCustomerEditStore();

vueuseEventListener(
	window,
	'openHydrateCustomerDialog',
	async (event: CustomEvent) => {
		customerEditStore.customerStateBeforeChanges = undefined;
		if (event.detail.field)
			customerEditStore.vue2FieldName = event.detail.field;
		if (event.detail.id) {
			await customerEditStore.customer.execute(0, { id: event.detail.id });
		} else {
			customerEditStore.customer.state = {
				...event.detail.data,
				status: 'active',
				names: [],
				phones: [],
				emails: [],
				addresses: []
			};
		}
		showEditCustomerDialog.value = true;
	}
);

vueuseEventListener(
	window,
	'showNewClaimDialog',
	async (event: CustomEvent) => {
		// Create new claim from job details
		const insuranceStore = useInsuranceStore();
		await insuranceStore.insurances.execute(0, { providerName: 'glaxis' });
		const claimsStore = useClaimDetailsStore();
		claimsStore.claim.state = newClaimFromJob(event.detail);
		showClaimDetailsDialog.value = true;
	}
);
vueuseEventListener(
	window,
	'showReviewClaimDialog',
	async (event: CustomEvent) => {
		// Show claim dialog for existing claim
		const insuranceStore = useInsuranceStore();
		await insuranceStore.insurances.execute(0, { providerName: 'glaxis' });
		const claimsStore = useClaimDetailsStore();
		claimsStore.claim.state = event.detail;
		showClaimDetailsDialog.value = true;
	}
);

useUserStore();

watch(
	() => customerEditStore.vue2FieldName,
	() => {
		if (!customerEditStore.vue2FieldName) {
			customerEditStore.onClose();
			showEditCustomerDialog.value = false;
		}
	}
);
</script>

<template>
	<InsuranceDialog
		v-if="showInsuranceDialog"
		v-model:show="showInsuranceDialog"
	/>
	<EditCustomerDialog
		v-if="showEditCustomerDialog"
		v-model:show="showEditCustomerDialog"
	/>
	<QuoteDialog v-if="showQuotesDialog" v-model:show="showQuotesDialog" />
	<ClaimDialog
		v-if="showClaimDetailsDialog"
		v-model:show="showClaimDetailsDialog"
	/>
	<FaxDialog v-if="showFaxDialog" v-model:show="showFaxDialog" />
	<RejectedInvoicesDialog
		v-if="showRejectedInvoicesDialog"
		v-model:show="showRejectedInvoicesDialog"
	/>
</template>
