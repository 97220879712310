import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { reactive, ref, watchEffect } from 'vue';

import { filterEmptyFields } from '@/shared/helpers';

import { getTechs } from '../../api';
import { techFiltersInit } from '../../config/constants';
import {
	FiltersWithMetadata,
	TechEntry,
	TechRequestParams,
	TechSortBy
} from '../types';

export const useTechnicianStore = defineStore('technician', () => {
	const toast = useToast();

	const sort = reactive({ desc: true, field: 'totalCommissions' });
	const filters = ref<FiltersWithMetadata<TechEntry>>({
		...techFiltersInit
	});
	const pagination = reactive({
		page: 1,
		pageSize: 10
	});

	const techs = useAsyncState(
		(args: TechRequestParams) => {
			const sort = args.sortField as keyof typeof TechSortBy;
			const searchFilters = filterEmptyFields(
				filters?.value as Record<string, any>,
				'value'
			);

			return getTechs({
				...args,
				filters: searchFilters,
				sortField: TechSortBy[sort]
			}).catch(error => {
				toast.add({
					detail: error,
					life: 3000,
					severity: 'error',
					summary: 'Error Message'
				});
				return { totalCount: 0, items: [] };
			});
		},
		{ totalCount: 0, items: [] },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const handleSort = (sortData: { field: string | null; desc: boolean }) => {
		if (sortData.field === null || sortData.field.trim() === '') {
			return;
		}

		sort.field = sortData.field;
		sort.desc = sortData.desc;
	};

	const handleRows = (v: number) => {
		pagination.pageSize = v;
		pagination.page = 1;
	};

	const composeGetArgs = (): TechRequestParams => {
		return {
			filters: filters.value,
			take: pagination.pageSize,
			skip: (pagination.page - 1) * pagination.pageSize,
			sortField: sort.field,
			sortOrder: sort.desc ? 'DESC' : 'ASC'
		};
	};

	watchEffect(async () => await techs.execute(0, composeGetArgs()));

	return {
		handleSort,
		handleRows,
		composeGetArgs,
		filters,
		pagination,
		techs,
		sort
	};
});
