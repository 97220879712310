import { CORE_API } from '@/shared/api';
import { Exact } from '@/shared/types';

import {
	GetPaymentSettingsTemplateResponse,
	PaymentSettingsTemplate
} from './types';

export const URL = '/unum/accounting/payment/settings/template';

export const getPaymentSettingsTemplate =
	async (): Promise<PaymentSettingsTemplate> => {
		const response =
			await CORE_API.get<GetPaymentSettingsTemplateResponse>(URL);
		return response.data.settings;
	};

export const updatePaymentSettingsTemplate = async <T>(
	request: Exact<T, PaymentSettingsTemplate>
): Promise<void> => {
	await CORE_API.patch(URL, request);
};
