import { TimeRange } from '../model/types';

export const formatDate = (dateStr: string | number, period: TimeRange) => {
	const date = new Date(dateStr);

	if (period === TimeRange.MONTH) {
		return date.toLocaleDateString('en-US', { month: 'long', timeZone: 'UTC' });
	} else if (period === TimeRange.YEAR) {
		return date.getUTCFullYear().toString();
	} else {
		return date.toLocaleString(undefined, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			timeZone: 'UTC'
		});
	}
};

export const formatNumber = (num: number | string) => {
	const formattedNumber = Number(num);
	let result;

	if (formattedNumber >= 1_000_000) {
		result = Math.round(formattedNumber / 10_000) / 100 + 'm';
	} else if (formattedNumber >= 1_000) {
		result = Math.round(formattedNumber / 100) / 10 + 'k';
	} else {
		result = formattedNumber.toFixed(0);
	}

	return result.toString();
};

export const formatToOneDigit = (num: number) => {
	const formattedNumber = Math.round(num * 10) / 10;

	return formattedNumber;
};

export const formatPrice = (num: number | string) => {
	return `$${num}`;
};
