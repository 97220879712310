import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-grid tw3-grid-cols-2 tw3-gap-2" }
const _hoisted_2 = { class: "tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end" }
const _hoisted_3 = { class: "tw3-h-10 tw3-font-bold tw3-content-center" }
const _hoisted_4 = { class: "tw3-h-8 tw3-items-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-flex tw3-gap-2" }
const _hoisted_5 = { class: "tw3-h-10 tw3-content-center tw3-font-bold" }
const _hoisted_6 = { class: "tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end" }
const _hoisted_7 = { class: "tw3-h-10 tw3-content-center" }
const _hoisted_8 = { class: "tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end" }
const _hoisted_9 = { class: "tw3-h-10 tw3-content-center" }
const _hoisted_10 = { class: "tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_11 = { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }

import Badge from 'primevue/badge';
import Fieldset from 'primevue/fieldset';
import Select from 'primevue/select';

import { useI18n } from 'vue-i18n';

import { Insurance } from '@/entities/insurance/lib/types';
import { US_STATES } from '@/shared/constants';
import { isEmptyValue } from '@/shared/helpers';

import { useInsuranceStore } from './lib/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'InsuredCustomer',
  props: /*@__PURE__*/_mergeModels({
    policyNumber: {},
    status: { type: Boolean }
  }, {
    "insuredCustomer": { required: true },
    "insuredCustomerModifiers": {},
    "insurance": {
	required: true
},
    "insuranceModifiers": {},
  }),
  emits: ["update:insuredCustomer", "update:insurance"],
  setup(__props: any) {

const { t } = useI18n();

const insuredCustomer = _useModel<{
	city: string;
	firstName: string;
	id: number;
	lastName: string;
	phone1: string;
	phone2: string;
	postalCode: string;
	state: string;
	streetAddress: string;
}>(__props, 'insuredCustomer');

const insurance = _useModel<Insurance>(__props, 'insurance');



const insuranceStore = useInsuranceStore();

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(Fieldset), {
      legend: `${_unref(t)('policy')} ${_ctx.policyNumber}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('customer')), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(insuredCustomer.value.firstName) + " " + _toDisplayString(insuredCustomer.value.lastName), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, _toDisplayString(_unref(t)('phone')), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(insuredCustomer.value.phone1), 1)
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('street')), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(insuredCustomer.value.streetAddress), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)('city')), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(insuredCustomer.value.city) + ", " + _toDisplayString(insuredCustomer.value.state) + " " + _toDisplayString(insuredCustomer.value.postalCode), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", null, _toDisplayString(_unref(t)('insurance')), 1),
              (_unref(isEmptyValue)(insurance.value.insurerId))
                ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                    key: 0,
                    class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                    severity: "danger"
                  }, null, 512)), [
                    [
                      _directive_tooltip,
                      _unref(t)('required'),
                      void 0,
                      { top: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            _createVNode(_unref(Select), {
              modelValue: insurance.value.insurerId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((insurance.value.insurerId) = $event)),
              class: "tw3-w-full tw3-max-w-64",
              disabled: _ctx.status,
              optionLabel: "name",
              options: _unref(insuranceStore).insurances.state,
              optionValue: "externalId"
            }, null, 8, ["modelValue", "disabled", "options"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(t)('statePolicty')), 1),
            _createVNode(_unref(Select), {
              modelValue: insurance.value.policyState,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((insurance.value.policyState) = $event)),
              class: "tw3-w-full tw3-max-w-64",
              disabled: _ctx.status,
              optionLabel: "title",
              options: _unref(US_STATES),
              optionValue: "value",
              placeholder: "Select State"
            }, null, 8, ["modelValue", "disabled", "options"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["legend"])
  ]))
}
}

})