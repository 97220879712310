import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Button from 'primevue/button';
import Popover from 'primevue/popover';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionPopover',
  props: {
    icon: { default: 'pi pi-ellipsis-h' },
    severity: { default: 'secondary' },
    dropdownPosition: { default: 'right' }
  },
  setup(__props: any) {



const popover = ref();
const toggle = (event: Event) => {
	popover.value.toggle(event);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tw3-flex tw3-justify-end custom-class", {
			'left-bottom': _ctx.dropdownPosition === 'left-bottom'
		}])
  }, [
    _createElementVNode("div", { onClick: toggle }, [
      _renderSlot(_ctx.$slots, "button", {}, () => [
        _createVNode(_unref(Button), {
          class: "p-button-text",
          icon: _ctx.icon ? _ctx.icon : 'pi pi-ellipsis-h',
          severity: _ctx.severity ? _ctx.severity : 'secondary',
          text: ""
        }, null, 8, ["icon", "severity"])
      ])
    ]),
    _createVNode(_unref(Popover), {
      ref_key: "popover",
      ref: popover,
      "pt:root": { class: 'arrow-hidder' }
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content")
      ]),
      _: 3
    }, 512)
  ], 2))
}
}

})