import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col tw3-gap-4 tw3-w-[25rem]" }

import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Popover from 'primevue/popover';
import { computed, ref } from 'vue';

import useAccountingStore from '@/features/accounting/lib/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheatSheet',
  props: {
    rounded: { type: Boolean }
  },
  setup(__props: any) {



const accountingStore = useAccountingStore();
const { types } = storeToRefs(accountingStore);
const values = computed(() => {
	return types.value.map(type => {
		return {
			name: type.name,
			type: type.creditAccount ? 'credit' : 'debit',
			increase: type.creditAccount ? 'Credit' : 'Debit',
			decrease: type.creditAccount ? 'Debit' : 'Credit'
		};
	});
});

const op = ref();
const toggle = (event: Event) => {
	op.value.toggle(event);
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_unref(Button), {
      icon: "pi pi-question-circle",
      rounded: _ctx.rounded,
      text: "",
      onClick: toggle
    }, null, 8, ["rounded"]), [
      [
        _directive_tooltip,
        'Debits And Credits Cheat Sheet',
        void 0,
        { top: true }
      ]
    ]),
    _createVNode(_unref(Popover), {
      ref_key: "op",
      ref: op
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "tw3-font-bold tw3-pl-2" }, "Debits And Credits Cheat Sheet", -1)),
          _createVNode(_unref(DataTable), {
            showGridlines: "",
            value: values.value
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(Column), {
                field: "name",
                header: "Name"
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`${data.type}-type`)
                  }, _toDisplayString(data.name), 3)
                ]),
                _: 1
              }),
              _createVNode(_unref(Column), {
                field: "increase",
                header: "Increase"
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`tw3-italic ${data.type}-type`)
                  }, _toDisplayString(data.increase), 3)
                ]),
                _: 1
              }),
              _createVNode(_unref(Column), {
                field: "decrease",
                header: "Decrease"
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`tw3-italic ${data.type}-type`)
                  }, _toDisplayString(data.decrease), 3)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"])
        ])
      ]),
      _: 1
    }, 512)
  ], 64))
}
}

})