import { CORE_API } from '@/shared/api';
import { convertToDateTime } from '@/shared/helpers/converters';
import { Exact } from '@/shared/types';

import {
	CreateTransactionRequest,
	CreateTransactionResponse,
	GetTransactionResponse,
	ListTransactionRequest,
	ListTransactionResponse,
	ListTransactionTypeResponse,
	Transaction,
	TransactionShort,
	TransactionType,
	UpdateTransactionRequest
} from './types';

export const URL = '/unum/accounting';

export const listTransactionType = async (): Promise<TransactionType[]> => {
	const response = await CORE_API.get<ListTransactionTypeResponse>(
		URL + '/transaction/type'
	);
	return response.data.transactionType;
};

export const listTransaction = async <T>(
	request: Exact<T, ListTransactionRequest>
): Promise<TransactionShort[]> => {
	// * this is "GET with BODY" request
	const response = await CORE_API.post<ListTransactionResponse>(
		URL + '/transaction/search',
		request
	);
	return convertToDateTime(response.data.transaction);
};

export const getTransaction = async (id: number): Promise<Transaction> => {
	// * this is "GET with BODY" request
	const response = await CORE_API.get<GetTransactionResponse>(
		URL + '/transaction/' + id
	);
	return convertToDateTime(response.data.transaction);
};

export const createTransaction = async <T>(
	request: Exact<T, CreateTransactionRequest>
): Promise<number> => {
	const response = await CORE_API.post<CreateTransactionResponse>(
		URL + '/transaction',
		request
	);
	return response.data.id;
};

export const updateTransaction = async <T>(
	request: Exact<T, UpdateTransactionRequest>
): Promise<void> => {
	await CORE_API.patch(URL + '/transaction', request);
};

export const deleteTransaction = async (id: number): Promise<void> => {
	await CORE_API.delete(`${URL}/transaction/${id}`);
};
