<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Skeleton from 'primevue/skeleton';
import { InvalidSubmissionHandler, useForm } from 'vee-validate';
import { ref } from 'vue';
import { z } from 'zod';

import { Profile } from '@/entities/accounting/profiles/lib/types';
import { useMessages } from '@/shared/composables';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useProfilesStore from './lib/store';

const cid = 'accounting-profile-create-dialog';

const profilesStore = useProfilesStore();

const { organizations } = storeToRefs(profilesStore);

const { showFieldValidationError } = useMessages();

const value = defineModel<boolean>();

const saving = ref(false);

const { defineField, handleSubmit, meta, resetForm } = useForm<Profile>({
	validationSchema: toTypedSchema(
		z.object({
			organizationId: z.number().int().positive()
		})
	),
	initialValues: {
		organizationId: undefined
	}
});

const fieldLabels: Record<string, string> = {
	organizationId: 'Company'
};

const [organizationId] = defineField('organizationId');

const close = () => {
	value.value = false;
};

const onValidationError: InvalidSubmissionHandler = ({ errors }) => {
	for (const [field, error] of Object.entries(errors)) {
		showFieldValidationError(fieldLabels[field], error!);
	}
};

const doSave = async (values: Profile) => {
	saving.value = true;
	await profilesStore.createProfile(values);
	resetForm();
	saving.value = false;
};

const save = handleSubmit(async (values: Profile) => {
	await doSave(values);
	close();
}, onValidationError);

const loading = ref(false);
</script>

<template>
	<Dialog
		v-model:visible="value"
		appendTo="#vue3app"
		:header="'New Profile'"
		modal
		:style="{ width: '35rem' }"
	>
		<div class="tw3-grid tw3-grid-cols-1 tw3-gap-4">
			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-organization-id`">
					{{ fieldLabels.organizationId }}
				</label>
				<Skeleton v-if="loading" height="2.2rem" />
				<AutoCompleteSelect
					v-else
					:id="`${cid}-organization-id`"
					v-model="organizationId"
					fluid
					:options="organizations"
					optionValue="id"
					@search="profilesStore.searchOrganizations"
				/>
			</div>
		</div>
		<div class="tw3-flex tw3-justify-end tw3-mt-5">
			<Button
				class="tw3-ml-5"
				:disabled="saving"
				:label="'Cancel'"
				severity="secondary"
				@click="close()"
			/>
			<Button
				class="tw3-ml-5"
				:disabled="saving || !meta.valid"
				:label="'Create'"
				@click="save()"
			/>
		</div>
	</Dialog>
</template>

<style scoped></style>
