import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  class: "tw3-flex tw3-justify-between tw3-gap-2",
  style: {"padding":"12px 16px"}
}
const _hoisted_2 = { class: "tw3-flex tw3-gap-4" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-gap-1 tw3-grow" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "tw3-flex tw3-gap-4 tw3-items-end" }
const _hoisted_6 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-font-bold" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 1,
  class: "action-buttons tw3-flex tw3-flex-row tw3-items-center tw3-justify-end tw3-gap-3"
}

import { FilterMatchMode } from '@primevue/core/api';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import { useConfirm } from 'primevue/useconfirm';
import { computed, ref } from 'vue';

import { Template } from '@/entities/accounting/accountTemplates/lib/types';
import useAccountingStore from '@/features/accounting/lib/store';
import { buildTree, flattenTree } from '@/shared/helpers/tree';

import useTemplatesStore from './lib/store';
import TemplateEdit from './TemplateEdit.vue';

const cid = 'accounting-account-templates-list';


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateList',
  setup(__props) {

const skeletonArray = Array(30).fill({});

const accountingStore = useAccountingStore();
const { typesById, subtypesById } = storeToRefs(accountingStore);

const templatesStore = useTemplatesStore();
const { loading, templates } = storeToRefs(templatesStore);

const defaultTemplate: Template = {
	id: 0,
	name: '',
	code: '',
	typeId: 0,
	subtypeId: 0,
	description: '',
	parentTemplateId: undefined,
	system: false
};

const accountsTree = computed(() => {
	if (!templates.value) {
		return [];
	}
	const sorted = templates.value
		.toSorted((a, b) => {
			return (
				a.typeId - b.typeId ||
				a.subtypeId - b.subtypeId ||
				a.name.localeCompare(b.name)
			);
		})
		.map(item => {
			const subtype = subtypesById.value[item.subtypeId] || { name: '' };
			const type = typesById.value[subtype.accountTypeId] || { name: '' };
			return {
				...item,
				type: type.name,
				subtype: subtype.name,
				balanceType: type.balanceType
			};
		});

	return flattenTree(buildTree(sorted, 'parentTemplateId'));
});

const filters = ref();

const filtersWrapper = computed({
	get: () => {
		return loading.value ? {} : filters.value;
	},
	set: value => {
		filters.value = value;
	}
});

const initFilters = () => {
	filters.value = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS }
	};
};

initFilters();

const showEditTemplate = ref(false);
const selectedTemplate = ref({ ...defaultTemplate });

const create = () => {
	showEditTemplate.value = true;
	selectedTemplate.value = { ...defaultTemplate };
};

const edit = (id: number) => {
	selectedTemplate.value = {
		...templates.value.find(item => item.id == id)!
	};
	showEditTemplate.value = true;
};

const refresh = () => {
	templatesStore.loadTemplates();
};

const confirm = useConfirm();

const remove = (id: number) => {
	const name = templates.value.find(item => item.id == id)!.name;
	confirm.require({
		message: `Do you want to delete Account "${name}"?`,
		header: 'Delete Account',
		icon: 'pi pi-trash',
		rejectLabel: 'Cancel',
		rejectProps: {
			label: 'Cancel',
			severity: 'secondary',
			outlined: true
		},
		acceptProps: {
			label: 'Delete',
			severity: 'danger'
		},
		accept: () => {
			templatesStore.deleteTemplate(id);
		},
		reject: () => {}
	});
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-search`
          }, "Search", 8, _hoisted_4),
          _createVNode(_unref(IconField), null, {
            default: _withCtx(() => [
              _createVNode(_unref(InputIcon), { class: "pi pi-search" }),
              _withDirectives(_createVNode(_unref(InputText), {
                id: `${cid}-search`,
                modelValue: filters.value['global'].value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value['global'].value) = $event)),
                class: "tw3-w-full",
                placeholder: "",
                style: {"width":"250px","padding-left":"calc(\n\t\t\t\t\t\t\t\t(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)\n\t\t\t\t\t\t\t)","padding-right":"calc(\n\t\t\t\t\t\t\t\t(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)\n\t\t\t\t\t\t\t)"},
                type: "search"
              }, null, 8, ["id", "modelValue"]), [
                [_directive_tooltip, 'Search by Name, Number, Type or Description']
              ]),
              _withDirectives(_createVNode(_unref(InputIcon), {
                class: "pi pi-times",
                style: {"cursor":"pointer"},
                onClick: _cache[1] || (_cache[1] = ($event: any) => (filters.value['global'].value = ''))
              }, null, 512), [
                [_vShow, filters.value['global'].value]
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createVNode(_unref(Button), {
          icon: "pi pi-plus",
          label: "New account",
          onClick: create
        }, null, 512), [
          [
            _directive_tooltip,
            'Create new account',
            void 0,
            { top: true }
          ]
        ]),
        _withDirectives(_createVNode(_unref(Button), {
          icon: "pi pi-refresh",
          severity: "secondary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (refresh()))
        }, null, 512), [
          [
            _directive_tooltip,
            'Refresh',
            void 0,
            { top: true }
          ]
        ])
      ])
    ]),
    _createVNode(_unref(DataTable), {
      filters: filtersWrapper.value,
      "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => ((filtersWrapper).value = $event)),
      dataKey: "id",
      filterDisplay: "menu",
      globalFilterFields: ['name', 'code', 'type', 'subtype', 'description'],
      groupRowsBy: "type",
      pt: {
			rowgroupheadercell: { colspan: '99' }
		},
      rowHover: !_unref(loading),
      rowGroupMode: "subheader",
      scrollable: "",
      scrollHeight: "flex",
      size: "small",
      value: _unref(loading) ? _unref(skeletonArray) : accountsTree.value
    }, _createSlots({
      groupheader: _withCtx(({ data }) => [
        _createElementVNode("div", _hoisted_6, _toDisplayString(data.type), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_unref(Column), {
          field: "name",
          header: "Account name"
        }, {
          body: _withCtx(({ data }) => [
            (_unref(loading))
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2rem"
                }))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "tw3-flex tw3-flex-row tw3-items-center tw3-gap-1",
                  style: _normalizeStyle({
							'padding-left': `${0.875 * (data.depth + 1)}rem`
						})
                }, [
                  (data.parentTemplateId)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _cache[6] || (_cache[6] = _createElementVNode("i", { class: "material-icons md-arrow_right tw3-opacity-40" }, null, -1)),
                        _createElementVNode("div", null, _toDisplayString(data.name), 1)
                      ], 64))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(data.name), 1)),
                  (data.system)
                    ? (_openBlock(), _createBlock(_unref(Tag), {
                        key: 2,
                        pt: {
								label: { class: 'tw3-font-normal' },
								root: { class: 'tw3-ml-3' }
							},
                        severity: "info",
                        value: "System"
                      }))
                    : _createCommentVNode("", true)
                ], 4))
          ]),
          _: 1
        }),
        _createVNode(_unref(Column), {
          field: "code",
          header: "Account number"
        }, {
          body: _withCtx(({ data }) => [
            (_unref(loading))
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2rem"
                }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(data.code), 1)
                ], 64))
          ]),
          _: 1
        }),
        _createVNode(_unref(Column), {
          field: "subtype",
          header: "Subtype"
        }, {
          body: _withCtx(({ data }) => [
            (_unref(loading))
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2rem"
                }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(data.subtype), 1)
                ], 64))
          ]),
          _: 1
        }),
        _createVNode(_unref(Column), {
          field: "description",
          header: "Description"
        }, {
          body: _withCtx(({ data }) => [
            (_unref(loading))
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2rem"
                }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(data.description), 1)
                ], 64))
          ]),
          _: 1
        }),
        _createVNode(_unref(Column), { pt: { bodyCell: { class: '!tw3-py-0' } } }, {
          body: _withCtx(({ data }) => [
            (_unref(loading))
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2rem"
                }))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _withDirectives((_openBlock(), _createBlock(_unref(Button), {
                    "aria-label": "Edit",
                    rounded: "",
                    severity: "secondary",
                    text: "",
                    onClick: ($event: any) => (edit(data.id))
                  }, {
                    icon: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createElementVNode("i", { class: "material-icons md-edit" }, null, -1)
                    ])),
                    _: 2
                  }, 1032, ["onClick"])), [
                    [
                      _directive_tooltip,
                      { value: 'Edit account', showDelay: 400 },
                      void 0,
                      { top: true }
                    ]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_unref(Button), {
                    "aria-label": "Delete",
                    rounded: "",
                    severity: "secondary",
                    text: "",
                    onClick: ($event: any) => (remove(data.id))
                  }, {
                    icon: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("i", { class: "material-icons md-delete" }, null, -1)
                    ])),
                    _: 2
                  }, 1032, ["onClick"])), [
                    [
                      _directive_tooltip,
                      { value: 'Delete account', showDelay: 400 },
                      void 0,
                      { top: true }
                    ]
                  ])
                ]))
          ]),
          _: 1
        })
      ]),
      _: 2
    }, [
      (!_unref(loading))
        ? {
            name: "empty",
            fn: _withCtx(() => [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20" }, [
                _createElementVNode("p", { class: "tw3-text-center tw3-text-3xl" }, "Accounts not found")
              ], -1))
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["filters", "rowHover", "value"]),
    _createVNode(TemplateEdit, {
      account: selectedTemplate.value,
      "onUpdate:account": _cache[4] || (_cache[4] = ($event: any) => ((selectedTemplate).value = $event)),
      visible: showEditTemplate.value,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((showEditTemplate).value = $event))
    }, null, 8, ["account", "visible"])
  ], 64))
}
}

})