<script setup lang="ts">
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Select from 'primevue/select';
import { useToast } from 'primevue/usetoast';
import { computed, defineModel, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useFaxStore from '../model/store';

import type { FaxInbox, FaxOutbox } from '../model/types';

const { t } = useI18n();
const toast = useToast();
const selectedType = ref<string>('');
const jobSearchValue = ref<
	| string
	| {
			id: number;
			shop_id: number;
	  }
>('');
const showModal = defineModel<boolean>('show');
const props = defineProps<{
	data: FaxInbox | FaxOutbox | null;
}>();

const faxStore = useFaxStore();
const documentTypes = [
	{
		code: 'swo',
		name: 'SWO'
	},
	{
		code: 'dis',
		name: 'DIS'
	},
	{
		code: 'pop',
		name: 'PoP'
	},
	{
		code: 'fiv',
		name: 'FIV'
	},
	{
		code: 'quo',
		name: 'QUO'
	},
	{
		code: 'oth',
		name: 'OTH'
	}
];

const jobList = computed(() => faxStore.faxJobsList.state);
const search = (params: { event: Event; query: string }) => {
	faxStore.faxJobsList.execute(0, params.query);
};

const attachIsLoading = ref<boolean>(false);
const attachFaxHandler = async () => {
	attachIsLoading.value = true;

	if (typeof jobSearchValue.value !== 'string' && props.data?.fileName) {
		const response = await faxStore.attachFaxHandler({
			created: -1,
			document_name: props.data?.fileName,
			document_type: selectedType.value,
			id: -1,
			jobId: jobSearchValue.value.id,
			modified: -1,
			shopId: jobSearchValue.value.shop_id,
			url: props.data?.url
		});

		if (response === 'Error') {
			toast.add({
				life: 3000,
				severity: 'error',
				summary: t('faxDialog.actions.attachFax.error')
			});
		} else {
			toast.add({
				life: 3000,
				severity: 'success',
				summary: t('faxDialog.actions.attachFax.success')
			});
		}
		showModal.value = false;
		attachIsLoading.value = false;
	}
};
</script>

<template>
	<Dialog
		id="FaxAttachJobDialog"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-w-full tw3-max-w-[1400px]"
		:draggable="false"
		:header="t('faxDialog.attachFaxJob')"
		modal
	>
		<div
			class="tw3-flex tw3-justify-between tw3-gap-3 tw3-w-full tw3-items-end tw3-pb-5"
		>
			<div class="tw3-flex tw3-items-end tw3-gap-3">
				<div class="tw3-flex tw3-flex-col tw3-gap-1 tw3-min-w-96">
					<label class="tw3-leading-tight">{{ t('job') }}</label>
					<AutoComplete
						v-model="jobSearchValue"
						class="tw3-w-full"
						:inputStyle="{
							'padding-right': '40px'
						}"
						inputClass="tw3-w-full"
						:loading="faxStore.faxJobsList.isLoading"
						optionLabel="name"
						:placeholder="t('faxDialog.searchForJob')"
						:suggestions="jobList"
						@complete="search"
					>
					</AutoComplete>
				</div>
				<div class="tw3-flex tw3-flex-col tw3-gap-1 tw3-min-w-96">
					<label class="tw3-leading-tight">{{ t('faxDialog.type') }}</label>
					<Select
						v-model="selectedType"
						class="w-full md:w-56"
						optionLabel="name"
						:options="documentTypes"
						optionValue="code"
						:placeholder="t('faxDialog.selectType')"
					/>
				</div>
				<Button
					class="tw3-w-full"
					:disabled="selectedType === '' || typeof jobSearchValue === 'string'"
					icon="pi pi-plus-circle"
					:label="t('faxDialog.attachJob')"
					:loading="attachIsLoading"
					size="small"
					@click.once="attachFaxHandler()"
				/>
			</div>
			<div class="tw3-col">
				<p>
					<strong>{{ t('faxDialog.uploadFax.fileName') }} </strong>
					{{ data?.fileName }}
				</p>
				<p>
					<strong>{{ t('faxDialog.uploadFax.dateTime') }} </strong>
					{{ data?.dateTime }}
				</p>
			</div>
		</div>

		<iframe
			:src="data !== null ? data.url : ''"
			style="width: 100%; height: 100%; min-height: 550px"
			type="application/pdf"
		></iframe>
	</Dialog>
</template>
