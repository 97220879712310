<script setup lang="ts">
// Account selector that uses fearures/accounting/lib/store store
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useAccountingStore from './lib/store';

const value = defineModel<number | undefined>();

const props = withDefaults(
	defineProps<{
		invalid?: boolean;
		placeholder?: string;
		types?: number[];
	}>(),
	{
		invalid: false,
		placeholder: 'Select Account',
		types: () => []
	}
);

const accountingStore = useAccountingStore();
const { typesById, subtypesById, accounts } = storeToRefs(accountingStore);

const filteredAccounts = computed(() => {
	if (props.types.length) {
		return accounts.value.filter(account =>
			props.types.includes(account.typeId)
		);
	}
	return accounts.value;
});
</script>

<template>
	<AutoCompleteSelect
		v-model="value"
		:filterBy="['name', 'code']"
		fluid
		:invalid="props.invalid"
		optionGroup="typeId"
		optionPrefix="code"
		:options="filteredAccounts"
		optionValue="id"
		:placeholder="placeholder"
		:pt="{
			option: {
				style: 'padding-top: 0.2rem; padding-bottom: 0.2rem'
			},
			optionGroup: {
				style: 'color: inherit'
			}
		}"
	>
		<template #option="slotProps">
			<slot name="option" :option="slotProps.option">
				<div class="tw3-flex tw3-flex-col">
					<div class="tw3-flex tw3-items-end">
						<span> {{ slotProps.option.name }} </span>
						<span
							v-if="slotProps.option.code"
							class="tw3-ml-1 gb-label"
							style="
								color: var(--p-surface-600);
								background-color: var(--p-surface-100);
								padding-left: 0.3rem;
								padding-right: 0.3rem;
								border-radius: 0.2rem;
							"
						>
							{{ slotProps.option.code }}
						</span>
					</div>
					<div class="gb-label">
						{{ subtypesById[slotProps.option.subtypeId].name }}
					</div>
				</div>
			</slot>
		</template>
		<template #optiongroup="slotProps">
			{{ typesById[slotProps.option.label].name }}
		</template>
	</AutoCompleteSelect>
</template>
