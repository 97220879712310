<script setup lang="ts">
import { round } from 'lodash';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import { computed, ref, watchEffect } from 'vue';

import router from '@/app/router';
import useAccountingStore from '@/features/accounting/lib/store';
import ProfileSelect from '@/features/accounting/ProfileSelect.vue';
import { formatMoney } from '@/shared/helpers/formatters';
import { buildTree, flattenTree } from '@/shared/helpers/tree';
import PageCard from '@/shared/ui/container/PageCard.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';

import useAccountingReportsStore from './lib/store';

const cid = 'accounting-balance-sheet';

const skeletonArray = Array(30).fill({});

const { typesById, selectedProfileId } = storeToRefs(useAccountingStore());

const reportsStore = useAccountingReportsStore();
const { balanceSheetLoading: loading, balanceSheet: records } =
	storeToRefs(reportsStore);

const accountsTree = computed(() => {
	if (!records.value) {
		return [];
	}
	const sorted = records.value.toSorted((a, b) => {
		return (
			a.typeId - b.typeId ||
			a.subtypeId - b.subtypeId ||
			a.name.localeCompare(b.name)
		);
	});

	return flattenTree(buildTree(sorted));
});

// start of the current day
const selectedTransactionDtTo = ref(DateTime.now().startOf('day'));

const refresh = () => {
	reportsStore.loadBalanceSheet({
		organizationId: selectedProfileId.value!,
		transactionDtTo: selectedTransactionDtTo.value.plus({ days: 1 })
	});
};

watchEffect(() => {
	refresh();
});

const title = ref([{ label: 'Balance Sheet', disabled: true }]);

const calculateTotal = (typeId: number) => {
	return records.value
		.filter(account => account.typeId === typeId)
		.reduce((acc, account) => acc + account.balance, 0);
};

const showTransactions = (id: number) => {
	router.push({
		name: 'accounting-transactions-by-account',
		params: { id: id.toString() }
	});
};

const balanced = computed(() => {
	if (!records.value) {
		return false;
	}
	const totalAssets = round(calculateTotal(1));
	const totalLiabilities = round(calculateTotal(2));
	const totalEquity = round(calculateTotal(3));
	return totalAssets === totalLiabilities + totalEquity;
});
</script>

<template>
	<PageCard>
		<template #title>
			<div class="tw3-flex tw3-justify-between">
				<div class="tw3-flex tw3-gap-2 tw3-items-center">
					<Breadcrumb :model="title" pt:root:class="!tw3-p-0 !tw3-ml-3">
						<template #item="{ item }">
							<span class="!tw3-text-xl !tw3-font-bold tw3-text-black">{{
								item.label
							}}</span>
						</template>
					</Breadcrumb>
				</div>
				<div class="tw3-flex tw3-gap-2 tw3-items-center">
					<Tag
						:severity="balanced ? 'success' : 'danger'"
						:value="balanced ? 'Balanced' : 'Not in balance'"
					></Tag>
				</div>
			</div>
		</template>
		<template #content>
			<DataTable
				dataKey="id"
				filterDisplay="menu"
				groupRowsBy="typeId"
				:pt="{
					root: { class: 'tw3-mb-3' },
					tableContainer: {
						class: 'tw3-self-center',
						style: 'width: 60rem; max-width: 100%;'
					},
					thead: { style: 'display: none' },
					rowgroupheadercell: { colspan: '99' },
					rowgroupfootercell: { colspan: '99' },
					bodyRow: { style: 'cursor: pointer' }
				}"
				:rowHover="!loading"
				rowGroupMode="subheader"
				scrollable
				scrollHeight="flex"
				size="small"
				tableClass="tw3-max-w-full"
				:value="loading ? skeletonArray : accountsTree"
				@row-click="event => showTransactions(event.data.id)"
			>
				<template #header>
					<div class="tw3-flex tw3-justify-between tw3-gap-2">
						<div class="tw3-flex tw3-gap-3">
							<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow">
								<label :for="`${cid}-profile-id`">Organization</label>
								<ProfileSelect :id="`${cid}-profile-id`" />
							</div>

							<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow">
								<label for="name">As of</label>
								<DatePicker
									v-model="selectedTransactionDtTo"
									class="tw3-w-[250px]"
									:manualInput="false"
									showIcon
								/>
							</div>
						</div>
						<div class="tw3-flex tw3-gap-2 tw3-items-end">
							<Button
								v-tooltip.top="'Refresh'"
								icon="pi pi-refresh"
								severity="secondary"
								@click="refresh()"
							/>
						</div>
					</div>
				</template>

				<Column
					key="code"
					field="code"
					header="Account number"
					:pt="{ bodyCell: { class: 'tw3-opacity-40' } }"
					style="width: 7%"
				>
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<template v-else>{{ data.code }}</template>
					</template>
				</Column>

				<Column key="name" field="name" header="Account name">
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<div
							v-else
							class="tw3-flex tw3-flex-row tw3-items-center tw3-gap-1"
							:style="{
								'padding-left': `${0.875 * (data.depth + 1)}rem`
							}"
						>
							<template v-if="data.parentAccountId">
								<i class="material-icons md-arrow_right tw3-opacity-40" />
								<div>{{ data.name }}</div>
							</template>
							<template v-else>
								<div>
									{{ data.name }}
								</div>
							</template>
						</div>
					</template>
				</Column>

				<Column key="balance" bodyStyle="text-align: right" field="balance">
					<template #header>
						<span class="tw3-flex-1 tw3-text-right tw3-font-bold">
							Balance
						</span>
					</template>
					<template #body="{ data }">
						<Skeleton v-if="loading" />
						<span v-else>{{ formatMoney(data.balance) }}</span>
					</template>
				</Column>

				<template v-if="!loading" #groupheader="{ data }">
					<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-font-bold">
						{{ typesById[data.typeId].name }}
					</div>
				</template>
				<template v-if="!loading" #groupfooter="{ data }">
					<div class="tw3-flex tw3-justify-end tw3-font-bold tw3-w-full">
						<span class="tw3-mr-3">
							{{ typesById[data.typeId].name }} Total:
						</span>
						<span> {{ formatMoney(calculateTotal(data.typeId)) }} </span>
					</div>
				</template>

				<template v-if="!loading" #empty>
					<div
						class="tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20"
					>
						<p class="tw3-text-center tw3-text-3xl">Balance sheet is empty</p>
					</div>
				</template>
			</DataTable>
		</template>
	</PageCard>
</template>
