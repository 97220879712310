import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tw3-h-10 tw3-w-10 tw3-flex tw3-absolute tw3--top-10 tw3-right-6 tw3-bg-surface-50 tw3-border-surface-200 tw3-rounded-md tw3-border" }
const _hoisted_2 = { class: "tw3-grid tw3-grid-cols-2 tw3-gap-4" }
const _hoisted_3 = { class: "tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end" }
const _hoisted_4 = { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_5 = { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_6 = { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_7 = { class: "tw3-grid tw3-grid-cols-2 tw3-basis-2/3 tw3-gap-2" }
const _hoisted_8 = { class: "tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end" }
const _hoisted_9 = { class: "tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_10 = { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_11 = { class: "tw3-text-slate-400 tw3-text-xs" }
const _hoisted_12 = { class: "tw3-h-8 tw3-relative tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end" }
const _hoisted_13 = { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_14 = { class: "tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }

import dayjs from 'dayjs';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import Popover from 'primevue/popover';
import Select from 'primevue/select';
import ToggleButton from 'primevue/togglebutton';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { AdditionalInfo, Agent } from '@/entities/claim/lib/types';
import { isEmptyValue } from '@/shared/helpers';

import { insuranceClaimCodes } from './lib/constants';
import { calculateSubrogationData } from './lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdditionalInfo',
  props: /*@__PURE__*/_mergeModels({
    dateOfLoss: {},
    status: { type: Boolean }
  }, {
    "additionalInfo": {
	required: true
},
    "additionalInfoModifiers": {},
    "agent": {
	required: true
},
    "agentModifiers": {},
  }),
  emits: ["update:additionalInfo", "update:agent"],
  setup(__props: any) {

const { t } = useI18n();

const additionalInfo = _useModel<AdditionalInfo>(__props, 'additionalInfo');

const agent = _useModel<Agent>(__props, 'agent');



const popoverRef = ref();
const formattedDateOfLoss = dayjs(__props.dateOfLoss).format('YYYY-MM-DD');

const computedCauseOfLossFields = computed(() =>
	calculateSubrogationData(additionalInfo.value.causeOfLossCode)
);

const toggleAdditionalInfoSettings = event => {
	popoverRef.value.toggle(event);
};

watch(computedCauseOfLossFields, newCauseOfLossFields => {
	if (newCauseOfLossFields.SubrogationContactName === null) {
		additionalInfo.value.subrogationContactName = '';
	}
	if (newCauseOfLossFields.SubrogationData === null) {
		additionalInfo.value.subrogationData = '';
	}
});

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(Fieldset), {
    class: "tw3-relative",
    legend: `${_unref(t)('incident')} ${_unref(formattedDateOfLoss)}`,
    toggleable: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Button), {
          "aria-label": "Settings",
          class: "tw3-border-surface-200",
          icon: "pi pi-cog",
          outlined: "",
          severity: "secondary",
          text: "",
          type: "button",
          onClick: toggleAdditionalInfoSettings
        }),
        _createVNode(_unref(Popover), {
          ref_key: "popoverRef",
          ref: popoverRef
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('agencyName')), 1),
                  _createVNode(_unref(InputText), {
                    modelValue: agent.value.agencyName,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((agent.value.agencyName) = $event)),
                    disabled: _ctx.status,
                    type: "text"
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('contactPhone')), 1),
                  _createVNode(_unref(InputMask), {
                    id: "phone",
                    modelValue: agent.value.phone,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((agent.value.phone) = $event)),
                    disabled: _ctx.status,
                    mask: "(999) 999-9999",
                    placeholder: "(999) 999-9999"
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('agentFirstName')), 1),
                  _createVNode(_unref(InputText), {
                    modelValue: agent.value.firstName,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((agent.value.firstName) = $event)),
                    disabled: _ctx.status,
                    type: "text"
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('agentFirstName')), 1),
                  _createVNode(_unref(InputText), {
                    modelValue: agent.value.lastName,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((agent.value.lastName) = $event)),
                    disabled: _ctx.status,
                    type: "text"
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ])
            ])
          ]),
          _: 1
        }, 512)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_8, [
            _withDirectives((_openBlock(), _createElementBlock("span", null, [
              _createTextVNode(_toDisplayString(_unref(t)('causeOfLoss')), 1)
            ])), [
              [
                _directive_tooltip,
                _unref(t)('causeOfLossTooltip'),
                void 0,
                { top: true }
              ]
            ])
          ]),
          _createVNode(_unref(Select), {
            modelValue: additionalInfo.value.causeOfLossCode,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((additionalInfo.value.causeOfLossCode) = $event)),
            class: "tw3-w-full tw3-max-w-64",
            disabled: _ctx.status,
            optionLabel: "title",
            options: _unref(insuranceClaimCodes),
            optionValue: "value"
          }, null, 8, ["modelValue", "disabled", "options"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_9, [
            _withDirectives((_openBlock(), _createElementBlock("span", null, [
              _createTextVNode(_toDisplayString(_unref(t)('shopContactPerson')), 1)
            ])), [
              [
                _directive_tooltip,
                _unref(t)('shopContactPersonTooltip'),
                void 0,
                { top: true }
              ]
            ]),
            (_unref(isEmptyValue)(additionalInfo.value.originationContactName))
              ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                  key: 0,
                  class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                  severity: "danger"
                }, null, 512)), [
                  [
                    _directive_tooltip,
                    _unref(t)('required'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _createVNode(_unref(InputText), {
            modelValue: additionalInfo.value.originationContactName,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((additionalInfo.value.originationContactName) = $event)),
            class: "tw3-w-full tw3-max-w-64",
            disabled: _ctx.status,
            type: "text"
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_10, [
            _createTextVNode(_toDisplayString(_unref(t)('subrogationData')) + " ", 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString(computedCauseOfLossFields.value.SubrogationData), 1)
          ]),
          _createVNode(_unref(InputText), {
            modelValue: additionalInfo.value.subrogationData,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((additionalInfo.value.subrogationData) = $event)),
            class: "tw3-w-full tw3-max-w-64",
            disabled: 
						_unref(isEmptyValue)(computedCauseOfLossFields.value.SubrogationData) || _ctx.status
					,
            type: "text"
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_12, [
            _withDirectives((_openBlock(), _createElementBlock("span", null, [
              _createTextVNode(_toDisplayString(_unref(t)('glassOnlyLoss')), 1)
            ])), [
              [
                _directive_tooltip,
                _unref(t)('glassOnlyLossTooltip'),
                void 0,
                { top: true }
              ]
            ]),
            (_unref(isEmptyValue)(additionalInfo.value.glassOnlyDamage))
              ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                  key: 0,
                  class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                  severity: "danger"
                }, null, 512)), [
                  [
                    _directive_tooltip,
                    _unref(t)('required'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _createVNode(_unref(ToggleButton), {
            modelValue: additionalInfo.value.glassOnlyDamage,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((additionalInfo.value.glassOnlyDamage) = $event)),
            disabled: _ctx.status,
            offLabel: _unref(t)('no'),
            onLabel: _unref(t)('yes')
          }, null, 8, ["modelValue", "disabled", "offLabel", "onLabel"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(computedCauseOfLossFields.value.SubrogationContactName ?? _unref(t)('details')), 1),
          _createVNode(_unref(InputText), {
            modelValue: additionalInfo.value.subrogationContactName,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((additionalInfo.value.subrogationContactName) = $event)),
            class: "tw3-w-full tw3-max-w-64",
            disabled: 
						_unref(isEmptyValue)(computedCauseOfLossFields.value.SubrogationContactName) ||
						_ctx.status
					,
            type: "text"
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_14, [
            _withDirectives((_openBlock(), _createElementBlock("span", null, [
              _createTextVNode(_toDisplayString(_unref(t)('bodyInjury')), 1)
            ])), [
              [
                _directive_tooltip,
                _unref(t)('bodyInjuryTooltip'),
                void 0,
                { top: true }
              ]
            ]),
            (_unref(isEmptyValue)(additionalInfo.value.bodilyInjury))
              ? _withDirectives((_openBlock(), _createBlock(_unref(Badge), {
                  key: 0,
                  class: "tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]",
                  severity: "danger"
                }, null, 512)), [
                  [
                    _directive_tooltip,
                    _unref(t)('required'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _createVNode(_unref(ToggleButton), {
            modelValue: additionalInfo.value.bodilyInjury,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((additionalInfo.value.bodilyInjury) = $event)),
            disabled: _ctx.status,
            offLabel: _unref(t)('no'),
            onLabel: _unref(t)('yes')
          }, null, 8, ["modelValue", "disabled", "offLabel", "onLabel"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["legend"]))
}
}

})