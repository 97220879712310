import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col tw3-gap-3" }
const _hoisted_2 = {
  key: 0,
  class: "tw3-flex tw3-items-center tw3-gap-4"
}
const _hoisted_3 = { class: "tw3-font-semibold tw3-leading-none" }
const _hoisted_4 = { class: "tw3-flex tw3-items-center tw3-gap-3" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "tw3-flex tw3-gap-3" }

import * as filestack from 'filestack-js';
import { PickerFileMetadata, PickerResponse } from 'filestack-js';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputMask from 'primevue/inputmask';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useAppStore from '@/entities/app/lib/store';
import { formatFileSize } from '@/shared/helpers/formatter';

import useFaxStore from '../model/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'FaxUpload',
  props: {
    "show": { type: Boolean },
    "showModifiers": {},
  },
  emits: ["update:show"],
  setup(__props) {

// import * as filepicker from 'filepicker-js';
const { t } = useI18n();
const toast = useToast();
const fileName = ref<string>('');
const file = ref<PickerFileMetadata>();
const faxNumber = ref<string>();
const showModal = _useModel<boolean>(__props, 'show');

const faxStore = useFaxStore();
const appStore = useAppStore();

const sendingIsInProcess = ref<boolean>(false);
const sendFax = async () => {
	if (file.value && faxNumber.value) {
		const PreparedFaxNumber = faxNumber.value.replace(/\D/g, '');
		if (PreparedFaxNumber.length !== 10) {
			return;
		}
		sendingIsInProcess.value = true;

		const outgoingFaxNumber = (
			await faxStore.outgoingFaxNumber
		).state.value.toString();
		const sendFaxInfo = new FormData();
		sendFaxInfo.set('filename', window.btoa(file?.value.filename));
		sendFaxInfo.set('url', window.btoa(file?.value.url));
		sendFaxInfo.set('sender', outgoingFaxNumber);
		sendFaxInfo.set('receiver', PreparedFaxNumber);

		await faxStore.sendNewFaxHandler(sendFaxInfo);

		showModal.value = false;
		sendingIsInProcess.value = false;
	}
};

const burstIsInProcess = ref<boolean>(false);
const burstFaxHandler = async () => {
	if (file.value) {
		burstIsInProcess.value = true;
		const response = await faxStore.burstNewFax({
			burstName: file.value.filename,
			burstUrl: file.value.url
		});
		if (response === 'Error') {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: t('faxDialog.uploadFax.burstNewFax.error'),
				summary: t('defaultToast.errorSummary')
			});
		} else {
			toast.add({
				life: 3000,
				severity: 'success',
				detail: t('faxDialog.uploadFax.burstNewFax.success'),
				summary: t('defaultToast.successSummary')
			});
		}
		showModal.value = false;
		burstIsInProcess.value = false;
	}
};

const openFilePicker = () => {
	const client = filestack.init(appStore.appConfig.state.filePickerKey);
	const options = {
		accept: ['application/pdf'],
		maxFiles: 1,
		onUploadDone: handleUploadDone
	};

	client.picker(options).open();
};

const handleUploadDone = (result: PickerResponse) => {
	const uploadedFile = result.filesUploaded[0];

	file.value = uploadedFile;
	fileName.value = file.value.filename;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "UploadFaxDialog",
    visible: showModal.value,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-max-w-[450px]",
    dragable: false,
    header: _unref(t)('faxDialog.uploadFax.uploadFaxHeader'),
    modal: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Button), {
          label: _unref(t)('faxDialog.uploadFax.chooseFile'),
          severity: "secondary",
          size: "small",
          type: "button",
          onClick: openFilePicker
        }, null, 8, ["label"]),
        (file.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)('faxDialog.uploadFax.yourFile')), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("a", {
                  class: "tw3-font-semibold tw3-text-ellipsis tw3-max-w-60 tw3-whitespace-nowrap tw3-overflow-hidden",
                  href: file.value.url,
                  target: "_blank"
                }, _toDisplayString(file.value.filename), 9, _hoisted_5),
                _createElementVNode("div", null, _toDisplayString(_unref(formatFileSize)(file.value.size)), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_unref(InputMask), {
          modelValue: faxNumber.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((faxNumber).value = $event)),
          mask: "(999) 999-9999",
          placeholder: _unref(t)('faxDialog.uploadFax.enterFaxNumber')
        }, null, 8, ["modelValue", "placeholder"]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(Button), {
            class: "tw3-w-full",
            disabled: !file.value || !faxNumber.value,
            label: _unref(t)('faxDialog.uploadFax.sendFax.sendFax'),
            loading: sendingIsInProcess.value,
            size: "small",
            onClickOnce: sendFax
          }, null, 8, ["disabled", "label", "loading"]),
          _createVNode(_unref(Button), {
            class: "tw3-w-full",
            disabled: !file.value,
            label: _unref(t)('faxDialog.uploadFax.burstNewFax.burstFax'),
            loading: burstIsInProcess.value,
            severity: "warn",
            size: "small",
            onClickOnce: _cache[1] || (_cache[1] = ($event: any) => (burstFaxHandler()))
          }, null, 8, ["disabled", "label", "loading"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})