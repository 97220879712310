import { toTypedSchema } from '@vee-validate/zod';
import { useAsyncState } from '@vueuse/core';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { InvalidSubmissionHandler, useFieldArray, useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { z } from 'zod';

import {
	createExpense as createExpenseApi,
	deleteExpense as deleteExpenseApi,
	getExpense,
	updateExpense as updateExpenseApi
} from '@/entities/accounting/expense/api';
import {
	CreateExpenseRequest,
	Expense,
	UpdateExpenseRequest
} from '@/entities/accounting/expense/model/types';
import { useUserStore } from '@/entities/user/lib/store';
import { useMessages } from '@/shared/composables';

import { useVendorStore } from './vendorStore';

export const useExpenseForm = () => {
	const { showFieldValidationError, showError, showInfo } = useMessages();

	const userStore = useUserStore();
	const { defaultOrganization } = storeToRefs(userStore);

	const vendorStore = useVendorStore();

	const loading = ref(false);

	const expense = useAsyncState(
		(id: number) => {
			return getExpense(id).catch(error => {
				showError(error);
				return null;
			});
		},
		null,
		{
			immediate: false,
			resetOnExecute: true
		}
	);

	const load = async (id: number) => {
		await expense.execute(0, id);
		if (expense.state.value?.vendorId != null) {
			vendorStore.load([expense.state.value.vendorId]);
		}
		resetForm({
			values: { vendorId: undefined, ...expense.state.value! }
		});
	};

	const reset = () => {
		const itemsCount = 1;

		const items = [];
		for (let i = 0; i < itemsCount; i++) {
			items.push({
				accountId: 0,
				amount: 0
			});
		}

		const profileId = defaultOrganization.value!.id!;

		expense.state.value = {
			id: undefined,
			organizationId: profileId,
			shopId: userStore.getDefaultOrganizationShop(profileId).id,
			transactionDt: DateTime.now().startOf('day'),
			amount: 0,
			paidFromAccountId: 0,
			jobId: 0,
			vendorId: undefined,
			description: '',
			referenceNumber: '',
			items: items
		};
		// selectedExpense.value!.shopId = oldValues.shopId!;
		resetForm({
			values: expense.state.value!
		});
	};

	const save = (onSuccess: () => void) => {
		handleSubmit(async (values: Expense) => {
			await saveExpense(values);
			onSuccess();
		}, onValidationError)();
	};

	const saveExpense = async (values: Expense) => {
		if (expense.state.value == null) {
			return;
		}
		loading.value = true;
		try {
			if (expense.state.value.id) {
				await updateExpense(values);
			} else {
				await createExpense(values);
			}
			showInfo('Expense saved');
		} catch (error) {
			showError(error);
		} finally {
			loading.value = false;
		}
	};

	const createExpense = async (expense: Expense) => {
		const params: CreateExpenseRequest = {
			organizationId: expense.organizationId!,
			shopId: expense.shopId!,
			paidFromAccountId: expense.paidFromAccountId!,
			jobId: expense.jobId!,
			vendorId: expense.vendorId!,
			transactionDt: expense.transactionDt,
			description: expense.description,
			referenceNumber: expense.referenceNumber,
			items: expense.items
		};
		console.log('createExpense', params);

		await createExpenseApi(params);
	};

	const updateExpense = async (expense: Expense) => {
		const params: UpdateExpenseRequest = {
			id: expense.id!,
			paidFromAccountId: expense.paidFromAccountId!,
			jobId: expense.jobId!,
			vendorId: expense.vendorId!,
			transactionDt: expense.transactionDt,
			description: expense.description,
			referenceNumber: expense.referenceNumber,
			items: expense.items
		};
		await updateExpenseApi(params);
	};

	const remove = async () => {
		if (expense.state.value == null) {
			return;
		}
		loading.value = true;
		try {
			if (expense.state.value.id) {
				await deleteExpenseApi(expense.state.value.id);
				showInfo('Expense deleted');
			}
		} catch (error) {
			showError(error);
		} finally {
			loading.value = false;
		}
	};

	const { defineField, handleSubmit, resetForm, errors, meta } =
		useForm<Expense>({
			validationSchema: toTypedSchema(
				z.object({
					id: z.optional(z.number().nullable()),
					transactionDt: z.any().refine(value => value instanceof DateTime, {
						message: 'required'
					}),
					description: z.string().trim(),
					referenceNumber: z.string().trim(),
					organizationId: z.number().min(1, 'required'),
					shopId: z.number().min(1, 'required'),
					paidFromAccountId: z
						.number({ message: 'Select Paid from' })
						.min(1, 'Select Paid from'),
					vendorId: z.optional(z.number().nullable()),
					items: z.array(
						z
							.object({
								accountId: z
									.number({ message: 'Select Account' })
									.min(1, 'Select Account'),
								amount: z.number().min(0, 'required')
							})
							.refine(
								item => {
									return item.amount > 0;
								},
								{
									message: 'Amount must be greater than 0'
								}
							)
					)
				})
			)
		});

	const fieldLabels: Record<string, string> = {
		transactionDt: 'Transaction Date',
		description: 'Description',
		referenceNumber: 'Reference Number',
		shopId: 'Shop',
		paidFromAccountId: 'Paid from',
		vendorId: 'Paid to',
		items: 'Items'
	};

	const [transactionDt] = defineField('transactionDt');
	const [description] = defineField('description');
	const [referenceNumber] = defineField('referenceNumber');
	const [shopId] = defineField('shopId');
	const [vendorId] = defineField('vendorId');
	const [paidFromAccountId] = defineField('paidFromAccountId');
	const {
		remove: removeItem,
		push: pushItem,
		fields: items
	} = useFieldArray('items');

	const itemsErrors = computed(() => {
		return items.value.map((item: any, index: number) => {
			const errs = errors.value as Record<string, string>;
			return {
				accountId: errs[`items[${index}].accountId`],
				amount: errs[`items[${index}]`]
			};
		});
	});

	const onValidationError: InvalidSubmissionHandler = res => {
		let showItemsErrors = false;
		for (const [field, error] of Object.entries(res.errors)) {
			if (field.startsWith('items[')) {
				showItemsErrors = true;
				continue;
			}
			showFieldValidationError(fieldLabels[field] ?? field, error!);
		}
		if (showItemsErrors) {
			showFieldValidationError(
				'Items',
				'Please correct the errors in the items'
			);
		}
	};

	const addItem = () => {
		pushItem({
			accountId: null,
			amount: 0
		});
	};

	const amountTotal = computed(() => {
		return items.value.reduce(
			(acc: number, item: any) => acc + item.value.amount,
			0
		);
	});

	const valid = computed(() => meta.value.valid);

	const editMode = computed(
		() =>
			expense.state.value?.id != null && expense.state.value?.id != undefined
	);

	return {
		valid,
		transactionDt,
		description,
		referenceNumber,
		shopId,
		vendorId,
		paidFromAccountId,
		items,
		addItem,
		removeItem,
		amountTotal,
		save,
		reset,
		itemsErrors,
		fieldLabels,
		loading,
		errors,
		load,
		remove,
		editMode
	};
};
