import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tw3-grid tw3-grid-cols-1 tw3-gap-4" }
const _hoisted_2 = { class: "tw3-flex tw3-flex-col tw3-gap-1" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "tw3-flex tw3-justify-end tw3-mt-5" }

import { toTypedSchema } from '@vee-validate/zod';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Skeleton from 'primevue/skeleton';
import { InvalidSubmissionHandler, useForm } from 'vee-validate';
import { ref } from 'vue';
import { z } from 'zod';

import { Profile } from '@/entities/accounting/profiles/lib/types';
import { useMessages } from '@/shared/composables';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useProfilesStore from './lib/store';

const cid = 'accounting-profile-create-dialog';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileCreateDialog',
  props: {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const profilesStore = useProfilesStore();

const { organizations } = storeToRefs(profilesStore);

const { showFieldValidationError } = useMessages();

const value = _useModel<boolean>(__props, "modelValue");

const saving = ref(false);

const { defineField, handleSubmit, meta, resetForm } = useForm<Profile>({
	validationSchema: toTypedSchema(
		z.object({
			organizationId: z.number().int().positive()
		})
	),
	initialValues: {
		organizationId: undefined
	}
});

const fieldLabels: Record<string, string> = {
	organizationId: 'Company'
};

const [organizationId] = defineField('organizationId');

const close = () => {
	value.value = false;
};

const onValidationError: InvalidSubmissionHandler = ({ errors }) => {
	for (const [field, error] of Object.entries(errors)) {
		showFieldValidationError(fieldLabels[field], error!);
	}
};

const doSave = async (values: Profile) => {
	saving.value = true;
	await profilesStore.createProfile(values);
	resetForm();
	saving.value = false;
};

const save = handleSubmit(async (values: Profile) => {
	await doSave(values);
	close();
}, onValidationError);

const loading = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    visible: value.value,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((value).value = $event)),
    appendTo: "#vue3app",
    header: 'New Profile',
    modal: "",
    style: { width: '35rem' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-organization-id`
          }, _toDisplayString(fieldLabels.organizationId), 9, _hoisted_3),
          (loading.value)
            ? (_openBlock(), _createBlock(_unref(Skeleton), {
                key: 0,
                height: "2.2rem"
              }))
            : (_openBlock(), _createBlock(AutoCompleteSelect, {
                key: 1,
                id: `${cid}-organization-id`,
                modelValue: _unref(organizationId),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(organizationId) ? (organizationId).value = $event : null)),
                fluid: "",
                options: _unref(organizations),
                optionValue: "id",
                onSearch: _unref(profilesStore).searchOrganizations
              }, null, 8, ["id", "modelValue", "options", "onSearch"]))
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(Button), {
          class: "tw3-ml-5",
          disabled: saving.value,
          label: 'Cancel',
          severity: "secondary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (close()))
        }, null, 8, ["disabled"]),
        _createVNode(_unref(Button), {
          class: "tw3-ml-5",
          disabled: saving.value || !_unref(meta).valid,
          label: 'Create',
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(save)()))
        }, null, 8, ["disabled"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})