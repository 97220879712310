import { capitalize } from 'lodash-es';

const ES = {
	agencyName: 'Nombre de la Agencia',
	agentFirstName: 'Nombre del Agente',
	agentLastName: 'Apellido del Agente',
	all: 'Todo',
	appointmentDate: 'Fecha de la Cita',
	archive: 'Archivar',
	archiveDialog: {
		archiveTitle: '¿Está seguro de que desea desarchivar esta reclamación?',
		unarchiveTitle: '¿Está seguro de que desea archivar esta reclamación?'
	},
	autoAccept: 'Aceptación Automática',
	bodyInjury: 'Lesión Corporal',
	bodyInjuryTooltip: '¿Hubo una lesión relacionada con esta reclamación?',
	//@ts-ignore
	capitalize: function (ctx) {
		//@ts-ignore
		return capitalize(ctx.named('key'));
	},
	causeOfLoss: 'Causa de Pérdida',
	causeOfLossTooltip: 'Determine cómo ocurrió la pérdida',
	city: 'Ciudad',
	claimForJob: 'Reclamación para Trabajo',
	claims: 'Reclamaciones',
	contactPhone: 'Teléfono de Contacto',
	coverage: 'Cobertura',
	customer: 'Cliente',
	damage: 'Daño',
	damageTitles: {
		location: 'Capturar qué vidrio será reparado o reemplazado',
		position: 'Código de Posición Nags',
		qty: 'Capturar las piezas de vidrio que se usarán para reparar el vehículo',
		relLocation: 'Código de Ubicación Relativa Nags',
		repairable:
			'Si la apertura del daño en el vidrio es el parabrisas,\nel minorista debe certificar que el parabrisas\nno era reparable',
		side: 'Código de Lado Nags'
	},
	date: 'Fecha',
	deductable: 'Deducible',
	details: 'Detalles',
	driverName: 'Nombre del Conductor (si está disponible)',
	error: 'Error',
	faxDialog: {
		fax: 'Fax',
		actions: {
			actions: 'Acciones',
			attachFax: {
				attach: 'Adjuntar al trabajo',
				error: 'No se ha podido adjuntar este fax',
				success: 'El documento se ha adjuntado correctamente'
			},
			burst: {
				burst: 'Ráfaga',
				plural: '¿Quieres reventar estos faxes?',
				pluralTooltip: 'Ráfaga de faxes seleccionados',
				single: '¿Quieres reventar este fax?'
			},
			cancel: 'Cancelar',
			changeReferral: {
				error:
					'Se ha producido un error durante la actualización Referencia/Notas',
				placeholder: 'Introducir remisión/Notas',
				success: '¡Refferal/Notes successfullyffully updated!'
			},
			changeStatus: {
				error: 'Se ha producido un error al cambiar el estado',
				success: '¡Estado cambiado con éxito!'
			},
			delete: {
				delete: 'Borrar',
				error: 'Se ha producido un error al eliminar un fax.',
				plural: '¿Desea eliminar estos faxes?',
				pluralTooltip: 'Borrar faxes seleccionados',
				single: '¿Desea eliminar este fax?',
				success: 'Faxes eliminados con éxito'
			},
			download: 'Descargar fax',
			downloadConfirmation: 'Descargar la página de transmisión',
			noConfirmationAvailable: 'No hay página de transmisión disponible',
			confirmationError:
				'Algo ha ido mal al recuperar la copia de su fax anterior. Por favor, inténtelo de nuevo.',
			confirmationSuccess: 'Página de transmisión descargada correctamente',
			pluralActions: 'Acciones en plural:',
			pluralActionsTooltip: 'Acciones con los faxes seleccionados',
			upload: {
				upload: 'Cargar fax'
			}
		},
		inbox: {
			dateTime: 'Fecha/Hora',
			pages: 'Páginas',
			referral: 'Remisión/Notas',
			sentFrom: 'Enviado Desde',
			sentTo: 'Enviado A',
			status: 'Estado',
			title: 'Buzón De Fax'
		},
		outbox: {
			dateTime: 'Fecha/Hora',
			file: 'File Name',
			pages: 'Páginas',
			sentFrom: 'Enviado Desde',
			sentTo: 'Enviado A',
			status: 'Estado',
			title: 'Buzón De Salida De Fax'
		},
		status: {
			burst: 'Ráfaga',
			error: 'Error',
			queued: 'En cola',
			read: 'Leer',
			sending: 'Envío de',
			sent: 'Enviado',
			unread: 'Sin leer'
		},
		statusResponses: {
			error: 'Error al enviar un fax',
			queued: 'El fax está en la cola',
			read: 'Se lee el fax',
			sending: 'El fax está enviando',
			sent: 'Fax enviado',
			unread: 'Fax no leído'
		},
		searchForJob: 'Buscar trabajo...',
		type: 'Tipo',
		attachFaxJob: 'Adjuntar fax al trabajo',
		selectType: 'Seleccione un tipo',
		selectStatus: 'Seleccionar estado',
		attachJob: 'Adjuntar trabajo',
		uploadFax: {
			fileName: 'Nombre del archivo:',
			dateTime: 'Fecha/Hora:',
			enterFaxNumber: 'Introduzca el número de fax',
			yourFile: 'Tu expediente:',
			uploadFaxHeader: 'Enviar/Borrar nuevo fax',
			burstNewFax: {
				burstFax: 'Fax de ráfaga',
				error: 'Este fax no ha podido ser reventado.',
				success: 'El fax se ha enviado correctamente.'
			},
			sendFax: {
				sendFax: 'Enviar fax',
				error: 'Se ha producido un error al enviar un fax',
				success: 'Fax enviado correctamente'
			},
			chooseFile: 'Elegir archivo'
		}
	},
	job: 'Empleo',
	confirmation: 'Confirmación',
	defaultToast: {
		successSummary: 'Mensaje de éxito',
		errorSummary: 'Mensaje de error',
		errorDetails: 'Algo salió mal'
	},
	searchBy: 'Buscar por ',
	glassOnlyLoss: 'Pérdida Solo de Vidrio',
	glassOnlyLossTooltip: 'Determine si esta es una reclamación solo de vidrio',
	inbox: 'Bandeja de entrada',
	incident: 'Incidente en',
	insurance: 'Seguro',
	jobId: 'ID de Trabajo',
	location: 'Ubicación',
	mobileIndicator: 'Indicador Móvil',
	new: 'Nuevo',
	no: 'No',
	noData: 'Datos No Encontrados',
	openClaim: 'Abrir Reclamación',
	pending: 'Pendiente',
	phone: 'Teléfono',
	plate: 'Placa',
	policy: 'Póliza',
	position: 'Posición',
	qty: 'Cantidad',
	refNumber: 'Número de Referencia',
	reference: 'Referencia',
	rejectedInvoices: {
		tabs: {
			rejected: 'Rechazado',
			queued: 'En cola',
			preparing: 'Preparación',
			pending: 'Pendiente',
			failed: 'Fallido',
			unsubmitted: 'No enviado'
		},
		columnNames: {
			amount: 'Importe',
			customer: 'Cliente',
			invoice: 'Factura',
			referral: 'Remisión',
			tradingPartner: 'Socio comercial'
		},
		addTagsTitle: 'Añadir etiquetas',
		title: 'Facturas rechazadas',
		applyTags: 'Aplicar etiquetas',
		tagDeletedSuccess: 'Etiqueta eliminada correctamente',
		tagDeletedFailed: 'No se ha podido eliminar la etiqueta.',
		tagAddedSuccess: 'Nuevas etiquetas añadidas',
		tags: 'Etiquetas',
		showDetails: 'Mostrar detalles',
		voidedJobs: 'Incluir trabajos anulados'
	},
	addNewTag: 'Añadir nueva etiqueta',
	relLocation: 'Ubicación Relativa',
	removeDamageInfoHeader: 'Eliminar Información de Daños',
	removeDamageInfoMsg:
		'¿Está seguro de que desea eliminar esta información de daños?',
	repairable: 'Reparable',
	required: 'Requerido',
	result: 'Resultado',
	selectShop: 'Seleccionar Tienda',
	shopContactPerson: 'Persona de Contacto del Taller',
	shopContactPersonTooltip:
		'Permite a la compañía de seguros o TPA contactar al originador.',
	showActive: 'Mostrar Activos',
	showArchive: 'Mostrar Archivados',
	side: 'Lado',
	statePolicty: 'Política Estatal donde fue Escrita',
	status: 'Estado',
	statusResponses: {
		coverage: 'Respuesta de cobertura recibida',
		error: 'Ocurrió un error',
		new: 'Reclamación creada',
		pending: 'Aviso de pérdida enviado',
		success: 'Acuse de recibo enviado'
	},
	street: 'Calle',
	submitClaim: 'Enviar Reclamación',
	submitClaimMsg: '¿Está seguro de que desea enviar esta reclamación?',
	subrogationContactName: {
		CAUSE_OF_LOSS_105: 'Nombre del Conductor (si está disponible)',
		CAUSE_OF_LOSS_111: null,
		CAUSE_OF_LOSS_199: 'Nombre de la Persona (si está disponible/aplicable)',
		CAUSE_OF_LOSS_201: 'Nombre del Conductor (si está disponible)',
		CAUSE_OF_LOSS_301: 'Nombre de la Persona (si está disponible)',
		CAUSE_OF_LOSS_341: 'Nombre de la Persona (si está disponible)',
		CAUSE_OF_LOSS_499: null
	},
	subrogationData: 'Datos de subrogación',
	subrogationDataKeys: {
		CAUSE_OF_LOSS_105: 'Número de Placa',
		CAUSE_OF_LOSS_111: 'Tipo de Animal',
		CAUSE_OF_LOSS_199: 'Descripción del Objeto',
		CAUSE_OF_LOSS_201:
			'Número de Reclamación (si está disponible) e Información de Contacto',
		CAUSE_OF_LOSS_301: 'Información de Contacto',
		CAUSE_OF_LOSS_341:
			'Información de Contacto (por ejemplo, informe de la policía)',
		CAUSE_OF_LOSS_499:
			'Tipo de Tormenta (por ejemplo, relámpago, viento, arena, etc.)'
	},
	success: 'Éxito',
	type: 'Tipo',
	unarchive: 'Desarchivar',
	vehicle: 'Vehículo',
	vehicleNumber: 'Número de Vehículo',
	vehicleOwnership: 'Propiedad del Vehículo',
	vehicleType: 'Tipo de Vehículo',
	vin: 'VIN',
	vinTooltip:
		'Un titular de póliza a menudo tiene más de un vehículo con la misma marca/modelo/año en una póliza, por lo tanto, si está disponible, el VIN puede ser enviado para filtrar la información de confirmación de cobertura.',
	workLocation: 'Código Postal de Ubicación de Trabajo',
	yes: 'Sí'
};

export default ES;
