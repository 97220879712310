import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw3-flex tw3-flex-col"
}
const _hoisted_2 = { class: "tw3-text-xs" }
const _hoisted_3 = {
  key: 1,
  class: "tw3-tracking-widest"
}
const _hoisted_4 = { class: "tw3-flex tw3-flex-col tw3-items-center tw3-justify-center tw3-border-b-0" }

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { Customer } from '@/entities/customer/lib/types';
import { UPDATE_VEHICLE_MUTATION } from '@/entities/vehicle/lib/graphql/mutation/vehicle.mutation';
import useApolloClient from '@/shared/apollo';

import { vehiclesHeader } from '../../lib/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerVehicles',
  setup(__props) {

const client = useApolloClient;
const toast = useToast();
const store = useCustomerEditStore();

const loading = ref(false);

const updateValue = (field: keyof Customer, value: any) => {
	store.customer.state = { ...store.customer.state, [field]: value };
};

const removeVehicle = async (id: number) => {
	loading.value = true;
	try {
		await client.mutate({
			mutation: UPDATE_VEHICLE_MUTATION,
			variables: { id, vehicle: { consumer_id: null } }
		});
		updateValue(
			'vehicles',
			store.customer.state.vehicles.filter(v => v.id !== id)
		);
	} catch (e) {
		toast.add({
			detail: 'Something went wrong',
			life: 3000,
			severity: 'error',
			summary: 'Error Message'
		});
	} finally {
		loading.value = false;
	}
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DataTable), {
    class: "tw3-w-full",
    loading: _unref(store).customer.isLoading,
    value: _unref(store).customer.state.vehicles
  }, {
    empty: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "tw3-text-center" }, "No Data", -1)
    ])),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(vehiclesHeader), (column) => {
        return (_openBlock(), _createBlock(_unref(Column), {
          key: column.key,
          field: column.key,
          header: column.title
        }, {
          body: _withCtx(({ data }) => [
            (column.key === 'vehicle')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", null, _toDisplayString(data.year + ' ' + data.make + ' ' + data.model), 1),
                  _createElementVNode("div", _hoisted_2, _toDisplayString(data.style), 1)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(data[column.key]), 1))
          ]),
          _: 2
        }, 1032, ["field", "header"]))
      }), 128)),
      _createVNode(_unref(Column), null, {
        body: _withCtx(({ data }) => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(Button), {
              "aria-label": "Delete",
              class: "tw3-w-8 tw3-h-8",
              icon: "pi pi-trash",
              loading: loading.value,
              rounded: "",
              severity: "danger",
              text: "",
              onClick: ($event: any) => (removeVehicle(data.id))
            }, null, 8, ["loading", "onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "value"]))
}
}

})