import { DateTime } from 'luxon';

// recursively convert all keys that ends with "Dt" and has string value to DateTime
export const convertToDateTime = (value: any): any => {
	if (Array.isArray(value)) {
		return value.map(convertToDateTime);
	}
	if (value instanceof Object) {
		Object.keys(value).forEach(key => {
			if (key.endsWith('Dt') && typeof value[key] === 'string') {
				value[key] = DateTime.fromISO(value[key]);
				return;
			}
			value[key] = convertToDateTime(value[key]);
		});
	}
	return value;
};
