import { formatDate } from '@/widgets/dashboard/lib/format-data';
import {
	DashboardAvgWeightData,
	DashboardLifetimeData,
	TimeRange
} from '@/widgets/dashboard/model/types';

export const toLabelsAndData = <
	T extends DashboardLifetimeData | DashboardAvgWeightData
>(
	apiData: T[],
	code: TimeRange
) => {
	const data: number[] = [];
	const labels = apiData.map((_, index) => {
		const val = apiData[apiData.length - 1 - index];
		if ('weight' in val) {
			data.push(val.weight !== 0 ? (val.amount / val.weight) * 100 : 0);
		} else {
			data.push(val.amount);
		}
		return formatDate(val.date, code);
	});
	return { labels, data };
};
