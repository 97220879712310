<template>
	<div class="tw3-text-center tw3-py-20">
		<h2 class="tw3-text-4xl tw3-font-bold tw3-text-gray-400">
			{{ $t('permission') }}
		</h2>
		<p class="tw3-text-xl tw3-text-gray-600 tw3-max-w-xl tw3-mt-4 tw3-mx-auto">
			{{ $t('dashboard.permissionMsg') }}
		</p>
	</div>
</template>
