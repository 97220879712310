<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Fieldset from 'primevue/fieldset';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Textarea from 'primevue/textarea';
import { computed, nextTick, ref, toRefs, watch } from 'vue';

import { Account } from '@/entities/accounting/accounts/lib/types';
import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import useTransactionTypesStore from '@/entities/accounting/transactions/lib/typeStore';
import AccountSelect from '@/features/accounting/AccountSelect.vue';
import ShopSelect from '@/features/accounting/ShopSelect.vue';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';

import { LedgerEntryAction, useLedgerEntryForm } from './useLedgerEntryForm';

const cid = 'accounting-ledger-entry-edit';

const emit = defineEmits<{
	saved: [];
}>();

const transactionStore = useTransactionStore();
const { selectedTransaction } = storeToRefs(transactionStore);
const { typesById } = storeToRefs(useTransactionTypesStore());

const transaction = computed(() => selectedTransaction.value!);
const editMode = computed(() => transaction.value?.id !== 0);
const visible = defineModel<boolean>('visible');
const props = defineProps<{
	account: Account | undefined;
}>();

const { account } = toRefs(props);

const {
	init,
	valid,
	mainAccountId,
	transactionDt,
	description,
	mainItemDescription,
	shopId,
	items,
	itemsErrors,
	addItem,
	removeItem,
	totalAmount,
	action,
	save: doSave,
	reset,
	fieldLabels
} = useLedgerEntryForm(account);

const shopSelect = ref<typeof InputText>();

watch(
	visible,
	() => {
		if (visible.value) {
			init();
			nextTick(() => {
				// Focus the first input
				shopSelect.value?.$el.focus();
			});
		}
	},
	{ immediate: true }
);
defineExpose({ init });

const close = () => {
	visible.value = false;
};

const toggleAction = () => {
	action.value =
		action.value === LedgerEntryAction.INCREASE
			? LedgerEntryAction.DECREASE
			: LedgerEntryAction.INCREASE;
};

const errorTooltipPT = {
	text: {
		style: { backgroundColor: 'rgb(248 113 113)' }
	},
	arrow: {
		style: { borderTopColor: 'rgb(248 113 113)' }
	}
};

const save = () => {
	doSave(async () => {
		close();
		emit('saved');
	});
};

const saveAndNew = () => {
	doSave(async () => {
		reset();
		shopSelect.value?.$el.focus();
		emit('saved');
	});
};

const loading = computed(() => !selectedTransaction.value || !typesById.value);

const title = computed(() => {
	if (loading.value) {
		return 'Loading...';
	}
	if (editMode.value) {
		return `Ledger entry #${transaction.value?.id}`;
	}
	return 'New Ledger Entry';
});
</script>

<template>
	<Dialog
		v-model:visible="visible"
		appendTo="#vue3app"
		:header="title"
		modal
		:style="{ width: '60rem' }"
	>
		<div class="tw3-grid tw3-grid-cols-1 tw3-gap-4">
			<div class="tw3-flex tw3-flex-row tw3-gap-4">
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-shop-id`">
						{{ fieldLabels.shopId }}
					</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<ShopSelect
						v-else
						:id="`${cid}-shop-id`"
						ref="shopSelect"
						v-model="shopId"
						:loading="loading"
					/>
				</div>
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-transaction-dt`">
						{{ fieldLabels.transactionDt }}
					</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<DatePicker
						v-else
						:id="`${cid}-transaction-dt`"
						v-model="transactionDt"
					/>
				</div>
			</div>

			<div class="tw3-flex tw3-flex-col tw3-gap-0">
				<label class="tw3-pl-1" :for="`${cid}-description`">{{
					fieldLabels.description
				}}</label>
				<Skeleton v-if="loading" height="3.4rem" />
				<Textarea v-else :id="`${cid}-description`" v-model="description" />
			</div>
			<Fieldset
				class="relative"
				:legend="fieldLabels.items"
				:pt="{
					legendLabel: { class: 'gb-label ' },
					legend: { class: '!tw3-pb-1' },
					contentContainer: { class: 'tw3-pt-3' },
					root: { class: '!tw3-pb-2' }
				}"
			>
				<DataTable
					:pt="{
						column: {
							headerCell: {
								style: 'padding: 0px 0.8rem 0.4rem 0px;'
							},
							bodyCell: {
								style: 'padding: 0.2rem 0.8rem 0rem 0px; border: none'
							},
							footerCell: {
								style: 'padding: 0px 0.8rem 0px 0px; border: none'
							}
						}
					}"
					scrollable
					scrollHeight="flex"
					size="small"
					tableClass="tw3-max-w-full"
					:value="loading ? new Array(1) : items"
				>
					<Column field="account" style="width: 27%">
						<template #header>
							<div class="tw3-flex tw3-flex-col tw3-grow">
								<div class="gb-label tw3-pl-1">Account</div>
								<Skeleton v-if="loading" height="2.2rem" />
								<AutoCompleteSelect
									v-else
									v-model="mainAccountId"
									disabled
									fluid
									:options="[account]"
									optionValue="id"
								/>
							</div>
						</template>
						<template #body="{ data, index }">
							<Skeleton v-if="loading" height="2.2rem" />
							<div
								v-else
								v-tooltip.top="{
									value: itemsErrors[index].accountId,
									pt: errorTooltipPT
								}"
							>
								<AccountSelect
									v-model="data.value.accountId"
									:invalid="!!itemsErrors[index].accountId"
								/>
							</div>
						</template>
					</Column>
					<Column field="amount" style="width: 18%">
						<template #header>
							<div class="tw3-flex tw3-flex-col tw3-grow">
								<div class="gb-label tw3-pl-1">Amount $</div>
								<Skeleton v-if="loading" height="2.2rem" />
								<InputGroup v-else>
									<Button
										v-tooltip.top="
											action == LedgerEntryAction.INCREASE
												? 'Increase'
												: 'Decrease'
										"
										:icon="
											action == LedgerEntryAction.INCREASE
												? 'pi pi-arrow-circle-up'
												: 'pi pi-arrow-circle-down'
										"
										severity="primary"
										@click="toggleAction"
									/>
									<InputMoney
										v-model="totalAmount"
										disabled
										:pt="{
											pcInput: {
												root: {
													class: 'tw3-font-bold'
												}
											}
										}"
									/>
								</InputGroup>
							</div>
						</template>
						<template #body="{ data, index }">
							<Skeleton v-if="loading" height="2.2rem" />
							<InputGroup v-else>
								<InputGroupAddon
									><i
										v-tooltip.top="
											action == LedgerEntryAction.INCREASE
												? 'Decrease'
												: 'Increase'
										"
										:class="
											action == LedgerEntryAction.INCREASE
												? 'pi pi-arrow-circle-down'
												: 'pi pi-arrow-circle-up'
										"
									></i
								></InputGroupAddon>
								<InputMoney
									v-model="data.value.amount"
									v-tooltip.top="{
										value: itemsErrors[index].amount,
										pt: errorTooltipPT
									}"
									:invalid="!!itemsErrors[index].amount"
								/>
							</InputGroup>
						</template>
					</Column>
					<Column field="description" style="width: 32%">
						<template #header>
							<div class="tw3-flex tw3-flex-col tw3-grow">
								<div class="gb-label tw3-pl-1">Item Description</div>
								<Skeleton v-if="loading" height="2.2rem" />
								<InputText
									v-else
									v-model="mainItemDescription"
									fluid
									type="text"
								/>
							</div>
						</template>
						<template #body="{ data }">
							<Skeleton v-if="loading" height="2.2rem" />
							<InputText
								v-else
								v-model="data.value.description"
								fluid
								type="text"
							/>
						</template>
					</Column>
					<Column
						field="actions"
						:pt="{
							headerCell: { class: '!tw3-py-0' }
						}"
						style="width: 4%; max-width: 48px"
					>
						<template #footer>
							<Button
								v-tooltip.top="'Add item'"
								class="tw3-mr-2"
								icon="pi pi-plus"
								rounded
								text
								@click="addItem"
							/>
						</template>
						<template #body="{ index }">
							<Button
								v-if="!loading"
								:disabled="items.length <= 1"
								icon="pi pi-trash"
								rounded
								text
								@click="removeItem(index)"
							/>
						</template>
					</Column>
				</DataTable>
			</Fieldset>
		</div>
		<div class="tw3-flex tw3-justify-between tw3-mt-5">
			<div class="tw3-flex tw3-justify-start"></div>
			<div class="tw3-flex tw3-justify-end">
				<Button
					class="tw3-ml-5"
					:label="'Close'"
					severity="secondary"
					@click="close"
				/>
				<Button
					class="tw3-ml-5"
					:disabled="loading || !valid"
					:label="'Save'"
					@click="save"
				/>
				<Button
					class="tw3-ml-5"
					:disabled="loading || !valid"
					:label="'Save and New'"
					@click="saveAndNew"
				/>
			</div>
		</div>
	</Dialog>
</template>

<style scoped></style>
