<script setup lang="ts">
import { FilterMatchMode, FilterOperator } from '@primevue/core/api';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import { computed, ref } from 'vue';

import router from '@/app/router';
import {
	Account,
	AccountStatus,
	BalanceType
} from '@/entities/accounting/accounts/lib/types';
import CheatSheet from '@/features/accounting/CheatSheet.vue';
import useAccountingStore from '@/features/accounting/lib/store';
import ProfileSelect from '@/features/accounting/ProfileSelect.vue';
import { formatMoney } from '@/shared/helpers/formatters';
import { buildTree, flattenTree } from '@/shared/helpers/tree';
import PageCard from '@/shared/ui/container/PageCard.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';

import AccountEdit from './AccountEdit.vue';

const cid = 'accounting-account-list';

const skeletonArray = Array(30).fill({});

const accountingStore = useAccountingStore();
const {
	loading,
	statuses,
	accounts,
	typesById,
	subtypesById,
	selectedProfileId
} = storeToRefs(accountingStore);

const statusOptions = [{ name: 'All statuses', id: null }, ...statuses.value];

const defaultAccount: Account = {
	id: 0,
	name: '',
	code: '',
	typeId: 0,
	subtypeId: 0,
	description: '',
	parentAccountId: undefined,
	status: AccountStatus.ACCOUNT_STATUS_ACTIVE,
	balance: 0,
	system: false,
	organizationId: 0
};

const title = ref([{ label: 'Chart of Accounts', disabled: true }]);

const accountsTree = computed(() => {
	if (!accounts.value || !typesById.value || !subtypesById.value) {
		return [];
	}
	const sorted = accounts.value
		.toSorted((a, b) => {
			return (
				a.typeId - b.typeId ||
				a.subtypeId - b.subtypeId ||
				a.name.localeCompare(b.name)
			);
		})
		.map(account => {
			return {
				...account,
				type: typesById.value[account.typeId].name,
				subtype: subtypesById.value[account.subtypeId].name,
				balanceType: typesById.value[account.typeId].balanceType
			};
		});

	return flattenTree(buildTree(sorted));
});

const filters = ref();

const filtersWrapper = computed({
	get: () => {
		return loading.value ? {} : filters.value;
	},
	set: value => {
		filters.value = value;
	}
});

const initFilters = () => {
	filters.value = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		status: {
			value: AccountStatus.ACCOUNT_STATUS_ACTIVE,
			matchMode: FilterMatchMode.EQUALS
		},
		balance: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		}
	};
};

initFilters();

const isEmptyFilterStatus = computed(() => {
	return filters.value.status.value == null;
});

const isEmptyFilterBalance = computed(() => {
	return (
		filters.value.balance.constraints[0].value == null &&
		filters.value.balance.constraints[1].value == null
	);
});

const clearFilterBalance = () => {
	filters.value.balance.constraints[0].value = null;
	filters.value.balance.constraints[1].value = null;
};

const showEditAccount = ref(false);
const selectedAccount = ref({ ...defaultAccount });

const create = () => {
	showEditAccount.value = true;
	selectedAccount.value = {
		...defaultAccount,
		organizationId: selectedProfileId.value!
	};
};

const edit = (id: number) => {
	selectedAccount.value = {
		...accounts.value.find(account => account.id == id)!
	};
	showEditAccount.value = true;
};

const showTransactions = (id: number) => {
	router.push({
		name: 'accounting-account-transactions',
		params: { id: id.toString() }
	});
};

const refresh = () => {
	accountingStore.loadAccounts();
};
</script>

<template>
	<PageCard>
		<template #title>
			<div class="tw3-flex tw3-justify-between">
				<div class="tw3-flex tw3-gap-2 tw3-items-center">
					<Breadcrumb :model="title" pt:root:class="!tw3-p-0 !tw3-ml-3">
						<template #item="{ item }">
							<span class="!tw3-text-xl !tw3-font-bold tw3-text-black">{{
								item.label
							}}</span>
						</template>
					</Breadcrumb>
				</div>
				<div class="tw3-flex tw3-gap-2 tw3-items-center">
					<CheatSheet></CheatSheet>
				</div>
			</div>
		</template>
		<template #content>
			<div
				class="tw3-flex tw3-justify-between tw3-gap-2"
				style="padding: 12px 16px"
			>
				<div class="tw3-flex tw3-gap-4">
					<div class="tw3-flex tw3-flex-col tw3-gap-1 tw3-grow">
						<label class="tw3-pl-1" :for="`${cid}-organization`"
							>Organization
						</label>
						<ProfileSelect :id="`${cid}-organization`" />
					</div>
					<div class="tw3-flex tw3-flex-col tw3-gap-1 tw3-grow">
						<label class="tw3-pl-1" :for="`${cid}-search`">Search</label>
						<IconField>
							<InputIcon class="pi pi-search" />
							<InputText
								:id="`${cid}-search`"
								v-model="filters['global'].value"
								v-tooltip="
									'Search by Account Name, Number, Type or Description'
								"
								class="tw3-w-full"
								placeholder=""
								style="
									width: 250px;
									padding-left: calc(
										(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)
									);
									padding-right: calc(
										(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)
									);
								"
								type="search"
							/>
							<InputIcon
								v-show="filters['global'].value"
								class="pi pi-times"
								style="cursor: pointer"
								@click="filters['global'].value = ''"
							/>
						</IconField>
					</div>
				</div>
				<div class="tw3-flex tw3-gap-4 tw3-items-end">
					<Button
						v-tooltip.top="'Create new account'"
						icon="pi pi-plus"
						label="New account"
						@click="create"
					/>
					<Button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						severity="secondary"
						@click="refresh()"
					/>
				</div>
			</div>

			<DataTable
				v-model:filters="filtersWrapper"
				dataKey="id"
				filterDisplay="menu"
				:globalFilterFields="['name', 'code', 'type', 'subtype', 'description']"
				groupRowsBy="type"
				:pt="{
					rowgroupheadercell: { colspan: '99' },
					bodyRow: { style: 'cursor: pointer' }
				}"
				:rowHover="!loading"
				rowGroupMode="subheader"
				scrollable
				scrollHeight="flex"
				size="small"
				:value="loading ? skeletonArray : accountsTree"
				@row-click="event => showTransactions(event.data.id)"
			>
				<template #groupheader="{ data }">
					<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-font-bold">
						{{ data.type }}
					</div>
				</template>

				<Column field="name" header="Account name">
					<template #body="{ data }">
						<Skeleton v-if="loading" height="2rem" />
						<template v-else>
							<div
								class="tw3-flex tw3-flex-row tw3-items-center tw3-gap-1"
								:style="{
									'padding-left': `${0.875 * (data.depth + 1)}rem`
								}"
							>
								<template v-if="data.parentAccountId">
									<i class="material-icons md-arrow_right tw3-opacity-40" />
									<div>{{ data.name }}</div>
								</template>
								<template v-else>
									<div>
										{{ data.name }}
									</div>
								</template>
								<Tag
									v-if="data.system"
									:pt="{
										label: { class: 'tw3-font-normal' },
										root: { class: 'tw3-ml-3' }
									}"
									severity="info"
									value="System"
								></Tag>
							</div>
						</template>
					</template>
				</Column>

				<Column field="code" header="Account number">
					<template #body="{ data }">
						<Skeleton v-if="loading" height="2rem" />
						<template v-else>{{ data.code }}</template>
					</template>
				</Column>

				<Column field="subtype" header="Subtype">
					<template #body="{ data }">
						<Skeleton v-if="loading" height="2rem" />
						<template v-else>{{ data.subtype }}</template>
					</template>
				</Column>

				<Column
					field="balance"
					header="Balance"
					:pt="{
						filterRemove: { style: 'display: none' }
					}"
					:showAddButton="false"
					:showFilterMatchModes="false"
					:showFilterOperator="false"
				>
					<template #body="{ data }">
						<Skeleton v-if="loading" height="2rem" />
						<div v-else class="tw3-text-right">
							{{ data.balance ? formatMoney(data.balance) : '' }}
							<span
								:class="
									data.balance &&
									data.balanceType == BalanceType.BALANCE_TYPE_YTD
										? 'tw3-opacity-40'
										: 'tw3-opacity-0'
								"
							>
								YTD
							</span>
						</div>
					</template>
					<template #filter="{ filterModel }">
						<div class="tw3-flex tw3-flex-col tw3-gap-0">
							<label for="gte">
								{{
									filterModel.matchMode == 'gte'
										? 'Greater or equal than'
										: 'Less or equal than'
								}}
							</label>
							<InputMoney v-model="filterModel.value" name="gte" />
						</div>
					</template>
					<template #filterclear>
						<Button outlined @click="clearFilterBalance()"> Clear </Button>
					</template>
					<template #filtericon>
						<i
							:class="
								isEmptyFilterBalance ? 'pi pi-filter' : 'pi pi-filter-fill'
							"
						></i>
					</template>
				</Column>

				<Column
					field="status"
					header="Status"
					:showAddButton="false"
					:showClearButton="false"
					:showFilterMatchModes="false"
					:showFilterOperator="false"
				>
					<template #body="{ data }">
						<Skeleton v-if="loading" height="2rem" />
						<div v-else class="tw3-flex tw3-items-center tw3-justify-center">
							<Tag
								pt:label:class="tw3-font-normal"
								:severity="
									data.status == AccountStatus.ACCOUNT_STATUS_ACTIVE
										? 'success'
										: 'danger'
								"
								:value="
									data.status == AccountStatus.ACCOUNT_STATUS_ACTIVE
										? 'Active'
										: 'Inactive'
								"
							></Tag>
						</div>
					</template>

					<template #filter="{ filterModel }">
						<Select
							v-model="filterModel.value"
							optionLabel="name"
							:options="statusOptions"
							optionValue="id"
							placeholder="All statuses"
						>
						</Select>
					</template>
					<template #filtericon>
						<i
							:class="
								isEmptyFilterStatus ? 'pi pi-filter' : 'pi pi-filter-fill'
							"
						></i>
					</template>
				</Column>

				<Column field="description" header="Description">
					<template #body="{ data }">
						<Skeleton v-if="loading" height="2rem" />
						<template v-else>{{ data.description }}</template>
					</template>
				</Column>

				<Column :pt="{ bodyCell: { class: '!tw3-py-0' } }">
					<template #body="{ data }">
						<Skeleton v-if="loading" height="2rem" />
						<div
							v-else
							class="action-buttons tw3-flex tw3-flex-row tw3-items-center tw3-justify-end tw3-gap-3"
						>
							<Button
								v-tooltip.top="{ value: 'Edit account', showDelay: 400 }"
								aria-label="Edit"
								rounded
								severity="secondary"
								text
								@click="edit(data.id)"
							>
								<template #icon>
									<i class="material-icons md-edit" />
								</template>
							</Button>
						</div>
					</template>
				</Column>

				<template v-if="!loading" #empty>
					<div
						class="tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20"
					>
						<p class="tw3-text-center tw3-text-3xl">Accounts not found</p>
					</div>
				</template>
			</DataTable>
		</template>
	</PageCard>
	<AccountEdit
		v-model:account="selectedAccount"
		v-model:visible="showEditAccount"
	/>
</template>
