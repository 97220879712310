import { useAsyncState } from '@vueuse/core';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { suggestVendor } from '@/entities/vendor/api';
import { VendorSuggestData } from '@/entities/vendor/model/types';
import useAccountingStore from '@/features/accounting/lib/store';
import { useMessages } from '@/shared/composables';

export const useVendorStore = defineStore('pagesAccountingVendorStore', () => {
	const { showError } = useMessages();

	const loadingCounter = ref(0);
	const loading = computed(() => {
		return loadingCounter.value > 0;
	});

	const { selectedProfileId } = storeToRefs(useAccountingStore());

	const vendorsState = useAsyncState(
		(name: string, ids: number[]) => {
			return suggestVendor(selectedProfileId.value!, name, ids, 30).catch(
				error => {
					showError(error);
					return [] as VendorSuggestData[];
				}
			);
		},
		[] as VendorSuggestData[],
		{
			immediate: true,
			resetOnExecute: false
		}
	);

	const search = async (term: string) => {
		if (term === '') {
			return;
		}
		await vendorsState.execute(0, term, []);
	};

	const load = async (id: number[]) => {
		await vendorsState.execute(0, '', id);
	};

	return {
		loading,
		vendors: vendorsState.state,
		search,
		load
	};
});
