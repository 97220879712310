<script setup lang="ts">
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { useRoute } from 'vue-router';

import DialogContainer from '@/widgets/dialogContainer/DialogContainer.vue';
import MainContainer from '@/widgets/mainContainer/MainContainer.vue';

import { routes } from './router';

const route = useRoute();
</script>

<template>
	<div id="vue3app">
		<MainContainer
			v-if="
				routes
					.filter(v => v.path !== '/')
					.some(v => route.path.startsWith(v.path)) || route.path === '/'
			"
		/>
		<Toast position="top-center" :pt="{ root: { style: 'top: 62px' } }" />
		<ConfirmDialog />
		<DialogContainer />
	</div>
</template>
