<script setup lang="ts">
import Breadcrumb from 'primevue/breadcrumb';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import { ref } from 'vue';

import ProfileSelect from '@/features/accounting/ProfileSelect.vue';
import PageCard from '@/shared/ui/container/PageCard.vue';

import PaymentSettings from './PaymentSettings.vue';

const cid = 'accounting-settings-panel';

const title = ref([{ label: 'Accounting Settings', disabled: true }]);
</script>

<template>
	<PageCard>
		<template #title>
			<div class="tw3-flex tw3-gap-2 tw3-items-center">
				<Breadcrumb :model="title" pt:root:class="!tw3-p-0 !tw3-ml-3">
					<template #item="{ item }">
						<span class="!tw3-text-xl !tw3-font-bold tw3-text-black">{{
							item.label
						}}</span>
					</template>
				</Breadcrumb>
			</div>
		</template>
		<template #content>
			<div
				class="tw3-flex tw3-justify-between tw3-gap-2"
				style="padding: 12px 16px"
			>
				<div class="tw3-flex tw3-gap-4">
					<div class="tw3-flex tw3-flex-col tw3-gap-1 tw3-grow">
						<label class="tw3-pl-1" :for="`${cid}-profile-id`"
							>Organization</label
						>
						<ProfileSelect :id="`${cid}-profile-id`" />
					</div>
				</div>
				<div class="tw3-flex tw3-gap-4 tw3-items-end"></div>
			</div>

			<Tabs value="0">
				<TabList>
					<Tab value="0"> Customer Payments </Tab>
				</TabList>
				<TabPanels>
					<TabPanel class="tw3-overflow-y-auto" value="0">
						<PaymentSettings></PaymentSettings>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</template>
	</PageCard>
</template>
