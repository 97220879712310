import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-gap-2 tw3-items-center" }
const _hoisted_2 = { class: "!tw3-text-xl !tw3-font-bold tw3-text-black" }
const _hoisted_3 = {
  class: "tw3-flex tw3-justify-between tw3-gap-2",
  style: {"padding":"12px 16px"}
}
const _hoisted_4 = { class: "tw3-flex tw3-gap-4" }
const _hoisted_5 = { class: "tw3-flex tw3-flex-col tw3-gap-1 tw3-grow" }
const _hoisted_6 = ["for"]

import Breadcrumb from 'primevue/breadcrumb';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import { ref } from 'vue';

import ProfileSelect from '@/features/accounting/ProfileSelect.vue';
import PageCard from '@/shared/ui/container/PageCard.vue';

import PaymentSettings from './PaymentSettings.vue';

const cid = 'accounting-settings-panel';


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsPanel',
  setup(__props) {

const title = ref([{ label: 'Accounting Settings', disabled: true }]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageCard, null, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Breadcrumb), {
          model: title.value,
          "pt:root:class": "!tw3-p-0 !tw3-ml-3"
        }, {
          item: _withCtx(({ item }) => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(item.label), 1)
          ]),
          _: 1
        }, 8, ["model"])
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", {
              class: "tw3-pl-1",
              for: `${cid}-profile-id`
            }, "Organization", 8, _hoisted_6),
            _createVNode(ProfileSelect, {
              id: `${cid}-profile-id`
            }, null, 8, ["id"])
          ])
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "tw3-flex tw3-gap-4 tw3-items-end" }, null, -1))
      ]),
      _createVNode(_unref(Tabs), { value: "0" }, {
        default: _withCtx(() => [
          _createVNode(_unref(TabList), null, {
            default: _withCtx(() => [
              _createVNode(_unref(Tab), { value: "0" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Customer Payments ")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(TabPanels), null, {
            default: _withCtx(() => [
              _createVNode(_unref(TabPanel), {
                class: "tw3-overflow-y-auto",
                value: "0"
              }, {
                default: _withCtx(() => [
                  _createVNode(PaymentSettings)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})