import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import {
	attachFax,
	burstFaxes,
	burstLocalFax,
	deleteBurstsFaxes,
	deleteFaxes,
	getBurstCount,
	getBurstTable,
	getFaxInbox,
	getFaxOutbox,
	getFaxSentPdf,
	getOutgoingFaxNumber,
	// getUnreadFaxCount,
	quickJobSearch,
	sendNewFax,
	updateFaxReferral,
	updateFaxStatus
} from '../api';

import {
	faxBurstFilters,
	faxInboxFilters,
	faxOutboxFilters
} from './constants';

import type {
	AttachFaxRequest,
	BurstFilters,
	BurstLocalFaxRequest,
	FaxFiltersWithMetaData,
	InboxFilters,
	OutboxFilters
} from './types';

const useFaxStore = defineStore('fax', () => {
	const toast = useToast();
	const { t } = useI18n();
	const sort = reactive({ sortBy: '', sortDesc: true });
	const pagination = reactive({
		limit: 10,
		page: 1
	});
	const inboxFilters = ref<FaxFiltersWithMetaData<InboxFilters>>({
		...faxInboxFilters
	});
	const outboxFilters = ref<FaxFiltersWithMetaData<OutboxFilters>>({
		...faxOutboxFilters
	});
	const burstFilters = ref<FaxFiltersWithMetaData<BurstFilters>>({
		...faxBurstFilters
	});

	// const unreadFaxCount = useAsyncState(
	// 	() => {
	// 		return getUnreadFaxCount();
	// 	},
	// 	0,
	// 	{}
	// );

	const burstCount = useAsyncState(
		() => {
			return getBurstCount();
		},
		0,
		{}
	);

	const faxInbox = useAsyncState(
		() => {
			return getFaxInbox({
				...sort,
				...pagination,
				...removeEmptyFilters({
					inboxDateTimeFilter: inboxFilters.value.dateTime.value,
					inboxPagesFilter: inboxFilters.value.pages.value,
					inboxReferralNotesFilter: inboxFilters.value.referral.value,
					inboxSentFromFilter: inboxFilters.value.sentFrom.value,
					inboxSentToFilter: inboxFilters.value.sentTo.value,
					inboxStatusFilter: inboxFilters.value.status.value
				})
			});
		},
		{ data: [], totalCount: 0 },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const faxOutbox = useAsyncState(
		() => {
			return getFaxOutbox({
				...sort,
				...pagination,
				...removeEmptyFilters({
					outboxDateTimeFilter: outboxFilters.value.dateTime.value,
					outboxFileFilter: outboxFilters.value.fileName.value,
					outboxPagesFilter: outboxFilters.value.pages.value,
					outboxReceiverFilter: outboxFilters.value.sentTo.value,
					outboxSenderFilter: outboxFilters.value.sentFrom.value,
					outboxStatusFilter: outboxFilters.value.status.value
				})
			});
		},
		{ data: [], totalCount: 0 },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const burstTable = useAsyncState(
		() => {
			return getBurstTable({
				...sort,
				...pagination,
				...removeEmptyFilters({
					burstDateTimeFilter: burstFilters.value.dateTime.value,
					burstReferralNotesFilter: '',
					burstStatusFilter: ''
				})
			});
		},
		{ data: [], totalCount: 0 },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const outgoingFaxNumber = useAsyncState(
		() => {
			return getOutgoingFaxNumber();
		},
		0,
		{}
	);

	const currentTab = ref<string>('inbox');

	const changeCurrentTab = (tabKey: string) => {
		setDefaultRequestProperties();
		currentTab.value = tabKey;
	};

	const handleSort = (sortData: { field: string | null; desc: boolean }) => {
		if (sortData.field === null || sortData.field.trim() === '') return;

		sort.sortBy = sortData.field;
		sort.sortDesc = sortData.desc;
	};

	function resetFilters<T>(filters: FaxFiltersWithMetaData<T>) {
		Object.keys(filters).forEach(key => {
			filters[key as keyof T].value = '';
		});
	}

	const setDefaultRequestProperties = () => {
		resetFilters(inboxFilters.value);
		resetFilters(outboxFilters.value);
		pagination.page = 1;
		pagination.limit = 10;
		sort.sortBy = '';
		sort.sortDesc = true;
	};

	async function refreshData() {
		if (currentTab.value == 'inbox') {
			await faxInbox.execute();
		} else if (currentTab.value == 'outbox') {
			await faxOutbox.execute();
		} else {
			await burstTable.execute();
		}
	}

	function removeEmptyFilters(filters: {
		[key: string]: string | boolean | null;
	}) {
		const result: {
			[key: string]: string | boolean | null;
		} = {};
		for (const key in filters) {
			if (filters[key] !== null && filters[key] !== undefined) {
				result[key] = filters[key];
			} else {
				result[key] = '';
			}
		}
		return result;
	}

	const burstChosenFaxes = async (faxIds: number[]) => {
		const faxIDsString = faxIds.join();

		const response = await burstFaxes(faxIDsString);

		if (!response || response.status == 'Error') {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: t('faxDialog.actions.burst.error'),
				summary: t('defaultToast.errorSummary')
			});
		} else {
			toast.add({
				life: 3000,
				severity: 'success',
				detail: t('faxDialog.actions.burst.success'),
				summary: t('defaultToast.successSummary')
			});
			refreshData();
			burstCount.execute();
		}
		return response;
	};

	const deleteChosenFaxes = async (faxIds: number[]) => {
		const faxIDsString = faxIds.join();
		let response;
		if (currentTab.value == 'burst') {
			response = await deleteBurstsFaxes(faxIDsString);
		} else {
			response = await deleteFaxes(faxIDsString);
		}
		if (response.status !== 'Error') {
			refreshData();
		}
		return response;
	};

	const faxJobsList = useAsyncState(
		(searchValue: string) => {
			return quickJobSearch(searchValue);
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const attachFaxHandler = async (params: AttachFaxRequest) => {
		return await attachFax(params);
	};

	const updateFaxStatusHandler = async (params: {
		faxIds: number[];
		status: string;
	}) => {
		const response = await updateFaxStatus({
			faxIds: params.faxIds.join(),
			status: params.status
		});

		if (response === 'Error') {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: t('faxDialog.actions.changeStatus.error'),
				summary: t('defaultToast.errorSummary')
			});
		} else {
			toast.add({
				life: 3000,
				severity: 'success',
				detail: t('faxDialog.actions.changeStatus.success'),
				summary: t('defaultToast.successSummary')
			});
			await refreshData();
		}

		return response;
	};

	const updateFaxReferralHandler = async (params: {
		faxId: number;
		referral: string | null;
	}) => {
		const response = await updateFaxReferral(params);
		if (response !== 'Error' && response !== 'failed') {
			await refreshData();
		}
		return response;
	};

	const sendNewFaxHandler = async (params: FormData) => {
		const response = await sendNewFax(params);
		if (response === 'Error') {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: t('faxDialog.uploadFax.sendFax.error'),
				summary: t('defaultToast.errorSummary')
			});
		} else {
			toast.add({
				life: 3000,
				severity: 'success',
				detail: t('faxDialog.uploadFax.sendFax.success'),
				summary: t('defaultToast.successSummary')
			});
			changeCurrentTab('outbox');
			refreshData();
		}
		return response;
	};

	const burstNewFax = async (params: BurstLocalFaxRequest) => {
		const response = await burstLocalFax(params);
		if (response !== 'Error') {
			changeCurrentTab('burst');
			burstCount.execute(0);
		}
		return response;
	};

	const downloadOutboxTransmittalHandler = async (
		jobid: number
	): Promise<string | null> => {
		const response = await getFaxSentPdf(jobid);
		if (!response) {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: t('faxDialog.actions.confirmationError'),
				summary: t('defaultToast.errorSummary')
			});
			return null;
		} else {
			toast.add({
				life: 3000,
				severity: 'success',
				detail: t('faxDialog.actions.confirmationSuccess'),
				summary: t('defaultToast.successSummary')
			});
			return response.data;
		}
	};

	onMounted(async () => {
		await faxOutbox.execute(0);
		await faxInbox.execute(0);
	});

	watch(
		[currentTab, pagination, sort, inboxFilters, outboxFilters, burstFilters],
		async () => {
			await refreshData();
		}
	);

	onUnmounted(() => {
		setDefaultRequestProperties();
		currentTab.value = 'inbox';
	});

	return {
		attachFaxHandler,
		burstChosenFaxes,
		burstCount,
		burstFilters,
		burstNewFax,
		burstTable,
		changeCurrentTab,
		currentTab,
		deleteChosenFaxes,
		faxInbox,
		faxJobsList,
		faxOutbox,
		handleSort,
		inboxFilters,
		outboxFilters,
		outgoingFaxNumber,
		pagination,
		refreshData,
		sendNewFaxHandler,
		sort,
		// unreadFaxCount,
		updateFaxReferralHandler,
		updateFaxStatusHandler,
		downloadOutboxTransmittalHandler
	};
});

export default useFaxStore;
