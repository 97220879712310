<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import SelectButton from 'primevue/selectbutton';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import { InvalidSubmissionHandler, useForm } from 'vee-validate';
import { computed, nextTick, ref, watch } from 'vue';
import { z } from 'zod';

import {
	Account,
	AccountStatus
} from '@/entities/accounting/accounts/lib/types';
import useAccountingStore from '@/features/accounting/lib/store';
import { useMessages } from '@/shared/composables';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useAccountsStore from './lib/store';

const cid = 'accounting-account-edit';

const { showFieldValidationError } = useMessages();

const store = useAccountsStore();

const accountingStore = useAccountingStore();

const {
	statuses,
	accounts,
	typesById,
	subtypesById,
	groupedSubtypes,
	selectedProfileId
} = storeToRefs(accountingStore);

const visible = defineModel<boolean>('visible');

const saving = ref(false);

const account = defineModel<Account>('account', { required: true });

// Returns true if the account has a parent with the given id
const hasParent = (account: Account, parentAccountId: number | undefined) => {
	if (account.id === parentAccountId) {
		return true;
	}
	if (account.parentAccountId == null) {
		return false;
	}
	const parent = accounts.value.find(a => a.id === account.parentAccountId);
	if (parent == null) {
		return false;
	}
	return hasParent(parent, parentAccountId);
};

const parentAccounts = computed(() => {
	if (subtypeId.value == null) {
		return [];
	}
	return [
		{
			id: undefined,
			name: 'No parent account'
		},
		...accounts.value.filter(
			a =>
				// Parent account must have the same subtype
				a.subtypeId == subtypeId.value &&
				// If we editing existings account - filter out the account itself and its children
				(!editMode.value || !hasParent(a, account.value.id))
		)
	];
});

const editMode = computed(() => !!account.value.id); //TODO: use null instead of 0 for id ???

const { defineField, handleSubmit, resetForm, errors, meta } = useForm<Account>(
	{
		validationSchema: toTypedSchema(
			z.object({
				id: z.optional(z.number().nullable()),
				organizationId: z.number().min(1, 'required'),
				name: z
					.string()
					.trim()
					.min(1, 'required')
					.refine(value => {
						return !accounts.value.some(
							a =>
								a.name.toLowerCase() === value.toLowerCase() &&
								a.id !== account.value.id
						);
					}, 'Account name must be unique'),
				subtypeId: z.number().min(1, 'required'),
				parentAccountId: z.optional(z.number()),
				description: z.string().trim(),
				code: z
					.string()
					.trim()
					.refine(value => {
						return (
							value == '' ||
							!accounts.value.some(
								a =>
									a.code.toLowerCase() === value.toLowerCase() &&
									a.id !== account.value.id
							)
						);
					}, 'Account number must be unique'),
				status: z.string()
			})
		),
		initialValues: {
			organizationId: 0,
			name: '',
			code: '',
			typeId: 0,
			subtypeId: 0,
			description: '',
			parentAccountId: undefined,
			status: AccountStatus.ACCOUNT_STATUS_ACTIVE,
			balance: 0
		}
	}
);

const fieldLabels: Record<string, string> = {
	name: 'Account Name',
	subtypeId: 'Account Type',
	parentAccountId: 'Parent Account',
	description: 'Account Description',
	code: 'Account Number',
	status: 'Status'
};

const [name] = defineField('name');
const [subtypeId] = defineField('subtypeId');
const [parentAccountId] = defineField('parentAccountId');
const [description] = defineField('description');
const [code] = defineField('code');
const [status] = defineField('status');

const nameInput = ref<typeof InputText>();

watch(visible, () => {
	if (visible.value) {
		resetForm({
			values: {
				...account.value,
				organizationId: selectedProfileId.value,
				parentAccountId: undefined
			}
		});
		nextTick(() => {
			// Focus the first input
			nameInput.value?.$el.focus();
		});
	}
});

const close = () => {
	visible.value = false;
};

const onValidationError: InvalidSubmissionHandler = ({ errors }) => {
	for (const [field, error] of Object.entries(errors)) {
		showFieldValidationError(fieldLabels[field], error!);
	}
};

const doSave = async (values: Account) => {
	saving.value = true;
	await store.saveAccount(values);
	await accountingStore.loadAccounts();
	saving.value = false;
};

const save = handleSubmit(async (values: Account) => {
	await doSave(values);
	close();
}, onValidationError);

const getSubtypeFullName = (subtypeId: string) => {
	const subtype = subtypesById.value[subtypeId];
	const type = typesById.value[subtype.accountTypeId];
	return `${type.name}: ${subtype.name}`;
};

const errorTooltipPT = {
	text: {
		style: { backgroundColor: 'rgb(248 113 113)' }
	},
	arrow: {
		style: { borderTopColor: 'rgb(248 113 113)' }
	}
};
</script>

<template>
	<Dialog
		v-model:visible="visible"
		appendTo="#vue3app"
		modal
		:style="{ width: '35rem' }"
	>
		<template #header>
			<div class="tw3-flex tw3-items-center">
				{{ editMode ? 'Edit Account' : 'New Account' }}
				<Tag
					v-if="account.system"
					:pt="{
						label: { class: 'tw3-font-normal' },
						root: { class: 'tw3-ml-3' }
					}"
					severity="info"
					value="System"
				></Tag>
			</div>
		</template>
		<div class="tw3-grid tw3-grid-cols-1 tw3-gap-4">
			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-name`">
					{{ fieldLabels.name }}
				</label>
				<InputText
					:id="`${cid}-name`"
					ref="nameInput"
					v-model="name"
					v-tooltip.top="{
						value: errors.name,
						pt: errorTooltipPT
					}"
					:disabled="account.system"
					:invalid="!!errors.name"
					type="text"
				/>
			</div>
			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-subtype`">
					{{ fieldLabels.subtypeId }}
				</label>
				<Select
					:id="`${cid}-subtype`"
					v-model="subtypeId"
					class="w-full md:w-56"
					:disabled="editMode || account.system"
					optionGroupChildren="items"
					optionGroupLabel="name"
					optionLabel="name"
					:options="groupedSubtypes"
					optionValue="id"
					placeholder="Account Type"
					:pt="{
						optionGroup: {
							style: {
								color: 'black'
							}
						},
						option: {
							style: {
								paddingLeft: '24px'
							}
						}
					}"
				>
					<template #value="{ placeholder, value }">
						<template v-if="value">
							{{ getSubtypeFullName(value) }}
						</template>
						<template v-else>
							{{ placeholder }}
						</template>
					</template>
				</Select>
			</div>

			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-parent-account-id`">
					{{ fieldLabels.parentAccountId }}
				</label>
				<AutoCompleteSelect
					:id="`${cid}-parent-account-id`"
					v-model="parentAccountId"
					class="w-full md:w-56"
					:disabled="account.system"
					:options="parentAccounts"
					optionValue="id"
					placeholder="No parent account"
				/>
			</div>

			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-description`">
					{{ fieldLabels.description }}
				</label>
				<Textarea :id="`${cid}-description`" v-model="description" />
			</div>
			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-code`">
					{{ fieldLabels.code }}
				</label>
				<InputText
					:id="`${cid}-code`"
					v-model="code"
					v-tooltip.top="{
						value: errors.code,
						pt: errorTooltipPT
					}"
					:invalid="!!errors.code"
					type="text"
				/>
			</div>
			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-status`">Status</label>
				<SelectButton
					v-model="status"
					:allowEmpty="false"
					:disabled="account.system"
					optionLabel="name"
					:options="statuses"
					optionValue="id"
				/>
			</div>
		</div>
		<div class="tw3-flex tw3-justify-end tw3-mt-5">
			<Button
				class="tw3-ml-5"
				:disabled="saving"
				:label="'Cancel'"
				severity="secondary"
				@click="close()"
			/>
			<Button
				class="tw3-ml-5"
				:disabled="saving || !meta.valid"
				:label="'Save'"
				@click="save()"
			/>
		</div>
	</Dialog>
</template>

<style scoped></style>
