import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { listTransactionType } from '@/entities/accounting/transactions/lib/api';
import { TransactionType } from '@/entities/accounting/transactions/lib/types';
import { useMessages } from '@/shared/composables';

const useTransactionTypesStore = defineStore(
	'entitiesAccountingTransactionTypes',
	() => {
		const { showError } = useMessages();

		const loadingCounter = ref(0);
		const loading = computed(() => {
			return loadingCounter.value > 0;
		});

		const typesState = useAsyncState(
			() => {
				loadingCounter.value++;
				return listTransactionType()
					.catch(error => {
						showError(error);
						return [];
					})
					.finally(() => {
						loadingCounter.value--;
					});
			},
			[],
			{
				immediate: true,
				resetOnExecute: false
			}
		);

		const typesById = computed(() => {
			const res: Record<number, TransactionType> = {};
			typesState.state.value.forEach(type => {
				res[type.id] = type;
			});
			return res;
		});

		return {
			loading,
			types: typesState.state,
			typesById
		};
	}
);

export default useTransactionTypesStore;
