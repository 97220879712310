<template>
	<div
		class="tw3-flex tw3-justify-end custom-class"
		:class="{
			'left-bottom': dropdownPosition === 'left-bottom'
		}"
	>
		<div @click="toggle">
			<slot name="button">
				<Button
					class="p-button-text"
					:icon="icon ? icon : 'pi pi-ellipsis-h'"
					:severity="severity ? severity : 'secondary'"
					text
				/>
			</slot>
		</div>
		<Popover ref="popover" :pt:root="{ class: 'arrow-hidder' }">
			<slot name="content"></slot>
		</Popover>
	</div>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import Popover from 'primevue/popover';
import { ref } from 'vue';

withDefaults(
	defineProps<{
		icon?: string;
		severity?: string;
		dropdownPosition?: 'left' | 'right' | 'left-bottom';
	}>(),
	{
		icon: 'pi pi-ellipsis-h',
		severity: 'secondary',
		dropdownPosition: 'right'
	}
);

const popover = ref();
const toggle = (event: Event) => {
	popover.value.toggle(event);
};
</script>

<style scoped>
.custom-class {
	position: relative;
	display: flex;
	align-items: center;
}

:deep(.arrow-hidder) {
	top: 100% !important;
	margin-top: 0 !important;
	left: auto !important;
	right: 0 !important;
}

.left-bottom :deep(.arrow-hidder) {
	left: 0 !important;
	right: auto !important;
}

:deep(.arrow-hidder:before),
:deep(.arrow-hidder:after) {
	display: none !important;
}
</style>
