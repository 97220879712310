import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tw3-grid tw3-grid-cols-2 tw3-gap-4 tw3-min-h-0" }
const _hoisted_2 = { class: "tw3-grid tw3-grid-cols-3 tw3-gap-2 tw3-items-center tw3-p-2" }
const _hoisted_3 = { class: "tw3-col-span-2" }
const _hoisted_4 = { class: "tw3-grid tw3-grid-cols-3 tw3-gap-2 tw3-items-center tw3-p-2" }
const _hoisted_5 = { class: "tw3-col-span-2" }
const _hoisted_6 = { class: "tw3-text-right" }
const _hoisted_7 = { class: "tw3-col-span-2" }

import { storeToRefs } from 'pinia';
import Divider from 'primevue/divider';
import Fieldset from 'primevue/fieldset';
import Skeleton from 'primevue/skeleton';
import { ref, watchEffect } from 'vue';

import { PaymentCategoryId } from '@/entities/accounting/settings/lib/types';
import AccountSelect from '@/features/accounting/AccountSelect.vue';

import useSettingsStore from './lib/store';

type PaymentCategoryAccount = {
	categoryId: PaymentCategoryId;
	name: string;
	accountId: number | undefined;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentSettings',
  setup(__props) {

const settingsStore = useSettingsStore();
const { paymentSettings, loading } = storeToRefs(settingsStore);

const paymentCategories = [
	{ categoryId: PaymentCategoryId.CATEGORY_CASH, name: 'Cash' },
	{ categoryId: PaymentCategoryId.CATEGORY_CHECK, name: 'Check' },
	{
		categoryId: PaymentCategoryId.CATEGORY_BANK_TRANSFER,
		name: 'Bank Transfer'
	},
	{ categoryId: PaymentCategoryId.CATEGORY_MANUAL_CARD, name: 'Manual Card' },
	{ categoryId: PaymentCategoryId.CATEGORY_OTHER, name: 'Other' },
	{ categoryId: PaymentCategoryId.CATEGORY_CARD, name: 'Card' }
];

const paymentCategoryAccount = ref<PaymentCategoryAccount[]>(
	paymentCategories.map(paymentCategory => ({
		categoryId: paymentCategory.categoryId,
		name: paymentCategory.name,
		accountId: undefined
	}))
);

const accountReceivableId = ref<number | undefined>();
const unappliedCustomerPaymentsId = ref<number | undefined>();

// Update form values from store
watchEffect(() => {
	accountReceivableId.value = paymentSettings.value.accountReceivableId;
	unappliedCustomerPaymentsId.value =
		paymentSettings.value.unappliedCustomerPaymentsId;
	paymentCategoryAccount.value = paymentCategories.map(paymentCategory => {
		const settingsCategory = paymentSettings.value.category.find(
			category => category.categoryId === paymentCategory.categoryId
		);
		return {
			categoryId: paymentCategory.categoryId,
			name: paymentCategory.name,
			accountId: settingsCategory?.accountId
		};
	});
});

const saveCategoryAccount = async (
	categoryId: PaymentCategoryId,
	accountId?: number
) => {
	if (accountId === undefined) {
		return;
	}
	await settingsStore.savePaymentSettings({
		category: [
			{
				categoryId: categoryId,
				accountId: accountId
			}
		]
	});
};

const saveAccountReceivableId = async (accountId?: number) => {
	if (accountId === undefined) {
		return;
	}
	await settingsStore.savePaymentSettings({
		accountReceivableId: accountId,
		category: []
	});
};

const saveUnappliedCustomerPaymentsId = async (accountId?: number) => {
	if (accountId === undefined) {
		return;
	}
	await settingsStore.savePaymentSettings({
		unappliedCustomerPaymentsId: accountId,
		category: []
	});
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Fieldset), {
      legend: "Default Accounts",
      pt: { root: { class: '!tw3-mt-3' } }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "tw3-text-right" }, "Unapplied Customer Payments", -1)),
          _createElementVNode("div", _hoisted_3, [
            (_unref(loading))
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2.2rem"
                }))
              : (_openBlock(), _createBlock(AccountSelect, {
                  key: 1,
                  modelValue: unappliedCustomerPaymentsId.value,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((unappliedCustomerPaymentsId).value = $event)),
                    saveUnappliedCustomerPaymentsId
                  ]
                }, null, 8, ["modelValue"]))
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "tw3-text-right" }, "Account Receivable", -1)),
          _createElementVNode("div", _hoisted_5, [
            (_unref(loading))
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2.2rem"
                }))
              : (_openBlock(), _createBlock(AccountSelect, {
                  key: 1,
                  modelValue: accountReceivableId.value,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => ((accountReceivableId).value = $event)),
                    saveAccountReceivableId
                  ]
                }, null, 8, ["modelValue"]))
          ])
        ]),
        _createVNode(_unref(Divider)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paymentCategoryAccount.value, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.categoryId,
            class: "tw3-grid tw3-grid-cols-3 tw3-gap-2 tw3-items-center tw3-p-2"
          }, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(item.name), 1),
            _createElementVNode("div", _hoisted_7, [
              (_unref(loading))
                ? (_openBlock(), _createBlock(_unref(Skeleton), {
                    key: 0,
                    height: "2.2rem"
                  }))
                : (_openBlock(), _createBlock(AccountSelect, {
                    key: 1,
                    modelValue: item.accountId,
                    "onUpdate:modelValue": [($event: any) => ((item.accountId) = $event), ($event: any) => (saveCategoryAccount(item.categoryId, $event))]
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
            ])
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
}

})