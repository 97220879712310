<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Chart from 'primevue/chart';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import TreeSelect from 'primevue/treeselect';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUserStore } from '@/entities/user/lib/store';
import { timeRangeModes } from '@/widgets/dashboard/config/constants';
import {
	calculateTooltipTotals,
	formatNumber,
	formatPrice,
	getChartLifetimeData,
	getChartOptions,
	getLastUpdatedDate,
	getMainChartData,
	getTimeRangePrefix,
	getTimeRangeSuffix,
	toLabelsAndData
} from '@/widgets/dashboard/lib';
import { DashboardHeaderTooltip } from '@/widgets/dashboard/model/types';
import DashboardLifetime from '@/widgets/dashboard/ui/DashboardLifetime.vue';

import {
	calculateAvgWeightWithoutLast,
	getChartTextByModeTech,
	getPieBackgroundColors,
	getPieOptions
} from '../lib';
import { useTechnicianDashboardStore } from '../model/store';
import { FollowUpJobs } from '../model/types';

import TechnicianFollowUpInfo from './technician/TechnicianFollowUpInfo.vue';
import TechnicianTable from './technician/TechnicianTable.vue';

const { t } = useI18n();

const store = useTechnicianDashboardStore();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const shopOptions = computed(() => [
	{
		key: 'allShops',
		label: 'All shops'
	},
	...user.value.organizations.map(v => ({
		key: 'organizations_' + v.id,
		label: v.name,
		children: user.value.shops
			.filter(s => s.organization_id === v.id)
			.map(s => ({ key: 'shops_' + s.id, label: s.name }))
	}))
]);

const selectedShop = ref({ allShops: true });
const apiSelectedShop = computed(() => {
	let shopIds: number[] = [];
	Object.keys(selectedShop.value).forEach(v => {
		if (v === 'allShops' || v === 'organizations' || v === 'shops') return;
		else {
			const data = v.split('_');
			if (data[0] === 'shops') {
				shopIds.push(Number(data[1]));
			}
			if (data[0] === 'organizations') {
				shopIds = user.value.shops
					.filter(v => v.organization_id === Number(data[1]))
					.map(v => v.id);
			}
		}
	});
	return shopIds.length ? shopIds : undefined;
});

const mainBlockTitle = ref(t('tech.titles.commissionGrow'));
const chartTitle = ref(t('commissionAmount'));
const chartHeaderTooltips = ref<DashboardHeaderTooltip[]>([]);

const selectedMainChart = ref(1);
const dataActualDate = ref<string>();
const mainChart = ref();
const chartOptions = ref();
const numberOfCompletedChart = ref();
const rateOfWarrantyChart = ref();
const amountOfCommissionChart = ref();
const averageCostRatioChart = ref();

const pieFollowUpJob = ref();
const pieFollowUpJobOptions = ref();

const timeMode = ref(timeRangeModes[2]);

const mainChartDescSuffix = computed(() =>
	getTimeRangeSuffix(timeMode.value.code)
);

const onUpdate = async () => {
	await nextTick();
	await getAndSetMainChartData();
};

const updateChartMode = async (v: number) => {
	selectedMainChart.value = v;
	selectedShop.value = { allShops: true };
	timeMode.value = timeRangeModes[2];
	await onUpdate();
};

const getAndSetMainChartData = async () => {
	const params = {
		time_range: timeMode.value.code,
		shop_ids: apiSelectedShop.value
	};
	const headPrefix = getTimeRangePrefix(timeMode.value.code);
	let currentLabels;

	if (selectedMainChart.value === 1) {
		await store.filteredCommission.execute(0, params);

		const totalWithoutLast = calculateTooltipTotals(
			store.filteredCommission.state
		);

		chartHeaderTooltips.value = [
			{
				data: formatPrice(formatNumber(totalWithoutLast)),
				title: headPrefix + ' ' + t('commissionAmount'),
				desc: t('tech.titles.amountOfCommission')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredCommission.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(
			data,
			labels,
			t('commission'),
			'#86efac'
		);
	} else if (selectedMainChart.value === 2) {
		await store.filteredCostRatio.execute(0, params);

		const tooltipData = calculateAvgWeightWithoutLast(
			store.filteredCostRatio.state
		);

		chartHeaderTooltips.value = [
			{
				data: tooltipData,
				title: t('averageCostRatio'),
				desc: t('averageCostRatio')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredCostRatio.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(data, labels, t('costRatio'), '#34d399');
	} else if (selectedMainChart.value === 3) {
		await store.filteredWarranty.execute(0, params);

		const tooltipData = calculateAvgWeightWithoutLast(
			store.filteredWarranty.state
		);

		chartHeaderTooltips.value = [
			{
				data: tooltipData,
				title: headPrefix + ' ' + t('averageWarrantyRate'),
				desc: t('averageWarrantyRate')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredWarranty.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(
			data,
			labels,
			t('warrantyRate'),
			'#5eead4'
		);
	} else {
		await store.filteredCompleted.execute(0, params);

		const totalWithoutLast = calculateTooltipTotals(
			store.filteredCompleted.state
		);

		chartHeaderTooltips.value = [
			{
				data: formatNumber(totalWithoutLast),
				title: headPrefix + ' ' + t('completedJobsCount'),
				desc: t('tech.titles.totalNumberOfCJobs')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredCompleted.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(
			data,
			labels,
			t('completedJobs'),
			'#22d3ee'
		);
	}

	const { chartTitle: chart, mainBlockTitle: main } = getChartTextByModeTech(
		selectedMainChart.value
	);
	mainBlockTitle.value = main;
	chartTitle.value = chart;

	chartOptions.value = getChartOptions(
		true,
		timeMode.value.code,
		currentLabels,
		selectedMainChart.value === 3 || selectedMainChart.value === 2
	);
};

onMounted(async () => {
	const promises: Promise<any>[] = [
		getAndSetMainChartData(),
		store.followUpJobs.execute(0),
		store.purchaseMethodJobs.execute(0)
	];

	if (store.totalAndCommissionAmount.state.data.length === 0) {
		promises.push(store.totalAndCommissionAmount.execute(0));
	}
	if (store.averageAndCostRatio.state.data.length === 0) {
		promises.push(store.averageAndCostRatio.execute(0));
	}
	if (store.averageAndWarrantyRate.state.data.length === 0) {
		promises.push(store.averageAndWarrantyRate.execute(0));
	}
	if (store.totalAndCompletedNumber.state.data.length === 0) {
		promises.push(store.totalAndCompletedNumber.execute(0));
	}

	await Promise.all(promises);

	dataActualDate.value = getLastUpdatedDate();

	amountOfCommissionChart.value = getChartLifetimeData(
		store.totalAndCommissionAmount.state.data,
		'134, 239, 172'
	);
	averageCostRatioChart.value = getChartLifetimeData(
		store.averageAndCostRatio.state.data,
		'52, 211, 153'
	);
	rateOfWarrantyChart.value = getChartLifetimeData(
		store.averageAndWarrantyRate.state.data,
		'94, 234, 212'
	);
	numberOfCompletedChart.value = getChartLifetimeData(
		store.totalAndCompletedNumber.state.data,
		'34, 211, 238'
	);

	pieFollowUpJobOptions.value = getPieOptions();
	const { backgroundColor, hoverBackgroundColor } = getPieBackgroundColors();
	const { callBack, dropOff, pickUp, rAndI, recal, tint } =
		store.followUpJobs.state;
	pieFollowUpJob.value = {
		labels: [
			t('callBack'),
			t('dropOff'),
			t('pickUp'),
			t('rAndI'),
			t('recal'),
			t('tint')
		],
		datasets: [
			{
				data: [callBack, dropOff, pickUp, rAndI, recal, tint],
				backgroundColor,
				hoverBackgroundColor
			}
		]
	};
});
</script>

<template>
	<div
		v-if="
			store.totalAndCommissionAmount.isLoading ||
			store.averageAndCostRatio.isLoading ||
			store.averageAndWarrantyRate.isLoading ||
			store.totalAndCompletedNumber.isLoading
		"
		class="tw3-text-center tw3-py-20"
	>
		<ProgressSpinner />
	</div>
	<div
		v-else
		class="tw3-max-w-[1440px] tw3-m-auto tw3-grid tw3-grid-cols-12 tw3-gap-4 md:tw3-gap-8"
	>
		<DashboardLifetime
			:cardStyle="
				selectedMainChart === 1
					? {
							boxShadow: '0px 6px 20px rgba(134, 239, 172, 0.5) !important'
						}
					: undefined
			"
			:chart="amountOfCommissionChart"
			:differenceNumber="store.totalAndCommissionAmount.state.difference"
			isRevenue
			:title="$t('lifetimeCommission')"
			:value="store.totalAndCommissionAmount.state.count"
			:valueStyle="{
				backgroundColor: '#86efac',
				boxShadow: '0px 6px 20px rgba(134, 239, 172, 0.3)'
			}"
			@click="updateChartMode(1)"
		/>

		<DashboardLifetime
			:cardStyle="
				selectedMainChart === 4
					? {
							boxShadow: '0px 6px 20px rgba(34, 211, 238, 0.5) !important'
						}
					: undefined
			"
			:chart="numberOfCompletedChart"
			:differenceNumber="store.totalAndCompletedNumber.state.difference"
			:title="$t('lifetimeCompletedJobs')"
			:value="store.totalAndCompletedNumber.state.count"
			:valueStyle="{
				backgroundColor: '#22d3ee',
				boxShadow: '0px 6px 20px rgba(34, 211, 238, 0.3)'
			}"
			@click="updateChartMode(4)"
		/>

		<DashboardLifetime
			:cardStyle="
				selectedMainChart === 2
					? {
							boxShadow: '0px 6px 20px rgba(52, 211, 153, 0.5) !important'
						}
					: undefined
			"
			:chart="averageCostRatioChart"
			:differenceNumber="store.averageAndCostRatio.state.difference"
			isPercent
			:showTooltip="{
				tooltipHeaderI18: 'tech.costRatioLifetime',
				tooltipDescI18: 'costRatioExplaining'
			}"
			:title="$t('lifetimeCostRatio')"
			:value="store.averageAndCostRatio.state.count"
			:valueStyle="{
				backgroundColor: '#34d399',
				boxShadow: '0px 6px 20px rgba(52, 211, 153, 0.3)'
			}"
			@click="updateChartMode(2)"
		/>

		<DashboardLifetime
			:cardStyle="
				selectedMainChart === 3
					? {
							boxShadow: '0px 6px 20px rgba(94, 234, 212, 0.5) !important'
						}
					: undefined
			"
			:chart="rateOfWarrantyChart"
			:differenceNumber="store.averageAndWarrantyRate.state.difference"
			isPercent
			last90
			:title="$t('lifetimeWarrantyRate')"
			:value="store.averageAndWarrantyRate.state.count"
			:valueStyle="{
				backgroundColor: '#5eead4',
				boxShadow: '0px 6px 20px rgba(94, 234, 212, 0.3)'
			}"
			@click="updateChartMode(3)"
		/>

		<div class="tw3-col-span-12 lg:tw3-col-span-8">
			<div
				class="card widget-visitor-graph !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-4"
			>
				<div
					class="tw3-font-medium tw3-flex tw3-flex-col sm:tw3-flex-row sm:tw3-justify-between sm:tw3-items-center tw3-leading-loose"
				>
					<span>{{ mainBlockTitle }}</span>
					<div
						class="tw3-flex tw3-flex-col sm:tw3-flex-row tw3-items-center tw3-gap-2"
					>
						<TreeSelect
							v-model="selectedShop"
							class="tw3-w-full sm:tw3-w-auto"
							:loading="
								store.filteredCommission.isLoading ||
								store.filteredWarranty.isLoading ||
								store.filteredCompleted.isLoading ||
								store.filteredCostRatio.isLoading
							"
							:options="shopOptions"
							placeholder="Select an Item"
							@update:model-value="onUpdate"
						/>
						<Select
							v-model="timeMode"
							class="tw3-w-full sm:tw3-w-auto"
							:loading="
								store.filteredCommission.isLoading ||
								store.filteredWarranty.isLoading ||
								store.filteredCompleted.isLoading ||
								store.filteredCostRatio.isLoading
							"
							optionLabel="name"
							:options="timeRangeModes"
							@update:model-value="onUpdate"
						/>
					</div>
				</div>
				<div class="graph-content tw3-grid tw3-grid-cols-12 tw3-gap-4 tw3-mt-6">
					<div
						v-if="
							!mainChart ||
							store.filteredCommission.isLoading ||
							store.filteredWarranty.isLoading ||
							store.filteredCompleted.isLoading ||
							store.filteredCostRatio.isLoading
						"
						class="tw3-col-span-12 md:tw3-col-span-6"
					>
						<Skeleton height="31px" width="5.5rem" />
						<Skeleton class="tw3-my-4" height="18px" width="7rem" />
						<Skeleton height="18px" width="12rem" />
					</div>
					<template v-else>
						<div
							v-for="(item, index) in chartHeaderTooltips"
							:key="index"
							class="tw3-col-span-12 md:tw3-col-span-6"
						>
							<div class="tw3-text-3xl tw3-font-semibold">
								{{ item.data }}
							</div>
							<div class="tw3-font-semibold tw3-my-4">{{ item.title }}</div>
							<p class="tw3-text-surface-500">
								{{ item.desc + mainChartDescSuffix }}
							</p>
						</div>
					</template>
				</div>

				<div class="graph">
					<div class="tw3-text-xl tw3-font-semibold tw3-my-6">
						{{ chartTitle }}
					</div>

					<div
						v-if="
							!mainChart ||
							store.filteredCommission.isLoading ||
							store.filteredWarranty.isLoading ||
							store.filteredCompleted.isLoading ||
							store.filteredCostRatio.isLoading
						"
						class="tw3-h-[590px] tw3-w-full tw3-flex tw3-justify-center tw3-items-center"
					>
						<ProgressSpinner />
					</div>
					<Chart
						v-else
						:data="mainChart"
						:height="590"
						:options="chartOptions"
						type="bar"
					/>
				</div>

				<div class="tw3-self-end tw3-mt-2 tw3-text-sm tw3-text-gray-300">
					{{ $t('dataUpdatedOn', { date: dataActualDate }) }}
				</div>
			</div>
		</div>

		<div class="tw3-col-span-12 lg:tw3-col-span-4">
			<div
				class="card !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-6 tw3-flex tw3-flex-col tw3-items-center"
			>
				<div class="tw3-w-full tw3-flex tw3-flex-col tw3-gap-2">
					<div class="tw3-font-medium tw3-text-lg">
						{{ $t('mobileVsInShop') }}
					</div>
					<div class="tw3-text-base tw3-text-gray-500">
						{{
							$t('mobileAbout', {
								n: store.purchaseMethodJobs.state.mobile
							})
						}}
					</div>
					<ProgressBar
						:mode="
							store.purchaseMethodJobs.isLoading
								? 'indeterminate'
								: 'determinate'
						"
						:value="store.purchaseMethodJobs.state.mobile"
					/>
				</div>
				<div class="tw3-w-full tw3-flex tw3-flex-col tw3-gap-2 tw3-mt-4">
					<div class="tw3-font-medium tw3-text-lg">
						{{ $t('techside') }}
					</div>
					<div class="tw3-text-base tw3-text-gray-500">
						{{
							$t('techsideJobsCompleted', {
								n: store.purchaseMethodJobs.state.techside
							})
						}}
					</div>
					<ProgressBar
						:mode="
							store.purchaseMethodJobs.isLoading
								? 'indeterminate'
								: 'determinate'
						"
						:pt:value:style="{ backgroundColor: 'rgba(52, 205, 235, 0.75)' }"
						:value="store.purchaseMethodJobs.state.techside"
					/>
				</div>
				<div class="tw3-w-full tw3-flex tw3-flex-col tw3-gap-2 tw3-mt-4">
					<div class="tw3-font-medium tw3-text-lg">
						{{ $t('lifetimeCompletedJobs') }}
					</div>
					<div class="tw3-text-base tw3-text-gray-500">
						{{
							$t('lifetimeJobsCompleted', {
								n: store.purchaseMethodJobs.state.todayJob
							})
						}}
					</div>
					<ProgressBar
						:mode="
							store.purchaseMethodJobs.isLoading
								? 'indeterminate'
								: 'determinate'
						"
						:pt:value:style="{ backgroundColor: '#fde047' }"
						:value="store.purchaseMethodJobs.state.todayJob"
					/>
				</div>
			</div>
			<div
				class="card !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-6 tw3-flex tw3-flex-col tw3-items-center tw3-mt-6"
			>
				<div class="tw3-font-semibold tw3-text-xl tw3-mb-6">
					{{ $t('followUpJobs') }}
				</div>
				<Chart
					v-if="pieFollowUpJob"
					class="tw3-w-60 tw3-h-60"
					:data="pieFollowUpJob"
					:options="pieFollowUpJobOptions"
					type="doughnut"
				/>
				<div
					v-else
					class="tw3-w-60 tw3-h-60 tw3-flex tw3-justify-center tw3-items-center"
				>
					<ProgressSpinner />
				</div>
				<TechnicianFollowUpInfo
					:data="store.followUpJobs.state as FollowUpJobs"
				/>
			</div>
		</div>

		<div class="tw3-col-span-12 tw3-mb-[1.25rem]">
			<div
				class="card !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-4"
			>
				<TechnicianTable />
			</div>
		</div>
	</div>
</template>
