import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import PageCard from '@/shared/ui/container/PageCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerInvoicesPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageCard, null, {
    title: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("h1", null, "Customer Invoices", -1)
    ])),
    content: _withCtx(() => _cache[1] || (_cache[1] = [
      _createTextVNode(" Under construction ")
    ])),
    _: 1
  }))
}
}

})