import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import Card from 'primevue/card';
import { computed, onMounted, ref } from 'vue';

import { Address } from '@/entities/address/lib/types';
import { getAddress } from '@/entities/customer/lib/api';
import { customersHeader } from '@/entities/customer/lib/contants';
import {
	useCustomerEditStore,
	useCustomersStore
} from '@/entities/customer/lib/store';
import useStorageStore from '@/features/customer/lib/storageStore';
import UpdateColumns from '@/features/customer/UpdateColumns.vue';
import AddressDialog from '@/widgets/addressDialog/AddressDialog.vue';
import CustomersList from '@/widgets/customers/CustomersList.vue';
import EditCustomerDialog from '@/widgets/editCustomerDialog/editCustomerDialog.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomersPage',
  setup(__props) {

const store = useCustomersStore();
const editStore = useCustomerEditStore();
const storage = useStorageStore();

const address = ref<Address>();
const showAddress = ref(false);

const showColumnSettings = ref(false);
const showEditDialog = ref(false);

const selectedColumns = computed(() =>
	storage.customerColumns.length ? storage.customerColumns : customersHeader
);

const toggleAddress = async (id: number, addressId: number) => {
	if (address.value && address.value.id === addressId) return;
	address.value = await getAddress(id, addressId);
	showAddress.value = true;
};

const toggleEdit = async (id: string) => {
	await editStore.customer.execute(0, { id });
	showEditDialog.value = true;
};

onMounted(() => store.customers.execute(0, store.composeGetCustomersArgs(0)));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Card), {
    "pt:body:class": "tw3-overflow-auto",
    "pt:content:class": "tw3-overflow-auto",
    "pt:root:class": "tw3-grow tw3-overflow-auto"
  }, {
    title: _withCtx(() => _cache[3] || (_cache[3] = [
      _createElementVNode("div", null, "My Customers", -1)
    ])),
    content: _withCtx(() => [
      _createVNode(EditCustomerDialog, {
        show: showEditDialog.value,
        "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showEditDialog).value = $event))
      }, null, 8, ["show"]),
      _createVNode(UpdateColumns, {
        show: showColumnSettings.value,
        "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((showColumnSettings).value = $event)),
        modelValue: selectedColumns.value,
        onSave: _unref(storage).updateCustomerColumns
      }, null, 8, ["show", "modelValue", "onSave"]),
      (address.value)
        ? (_openBlock(), _createBlock(AddressDialog, {
            key: 0,
            show: showAddress.value,
            "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((showAddress).value = $event)),
            fullAddress: address.value.full_address,
            lat: address.value.lat,
            lng: address.value.lng
          }, null, 8, ["show", "fullAddress", "lat", "lng"]))
        : _createCommentVNode("", true),
      _createVNode(CustomersList, {
        onOnEdit: toggleEdit,
        onOnOpenAddress: toggleAddress
      })
    ]),
    _: 1
  }))
}
}

})