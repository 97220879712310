import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useMessages } from '@/shared/composables';

import {
	createTemplate as createTemplateApi,
	deleteTemplate as deleteTemplateApi,
	listTemplate,
	updateTemplate as updateTemplateApi
} from '../../../../entities/accounting/accountTemplates/lib/api';
import {
	CreateTemplateRequest,
	Template,
	UpdateTemplateRequest
} from '../../../../entities/accounting/accountTemplates/lib/types';

const useTemplatesStore = defineStore(
	'widgetsAccountingAccountTemplates',
	() => {
		const { showError, showInfo } = useMessages();

		const loadingCounter = ref(0);
		const loading = computed(() => {
			return loadingCounter.value > 0;
		});

		const templatesState = useAsyncState(
			() => {
				loadingCounter.value++;
				return listTemplate()
					.catch(error => {
						showError(error);
						return [];
					})
					.finally(() => {
						loadingCounter.value--;
					});
			},
			[] as Template[],
			{
				immediate: true,
				resetOnExecute: false
			}
		);

		const loadTemplates = async () => {
			await templatesState.execute(0);
		};

		const saveTemplate = async (params: Template) => {
			loadingCounter.value++;
			try {
				if (params.id) {
					const request: UpdateTemplateRequest = {
						id: params.id,
						name: params.name,
						description: params.description,
						code: params.code,
						parentTemplateId: params.parentTemplateId,
						system: params.system
					};
					await updateTemplateApi(request);
				} else {
					const request: CreateTemplateRequest = {
						subtypeId: params.subtypeId,
						name: params.name,
						description: params.description,
						code: params.code,
						parentTemplateId: params.parentTemplateId,
						system: params.system
					};
					await createTemplateApi(request);
				}
				showInfo('Template saved');
				loadTemplates();
			} catch (error) {
				showError(error);
			} finally {
				loadingCounter.value--;
			}
		};

		const deleteTemplate = async (id: number) => {
			loadingCounter.value++;
			try {
				await deleteTemplateApi(id);
				showInfo('Template deleted');
				loadTemplates();
			} catch (error) {
				showError(error);
			} finally {
				loadingCounter.value--;
			}
		};

		return {
			loading,
			templates: templatesState.state,
			loadTemplates,
			saveTemplate,
			deleteTemplate
		};
	}
);

export default useTemplatesStore;
