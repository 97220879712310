<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Chart from 'primevue/chart';
import ProgressSpinner from 'primevue/progressspinner';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import TreeSelect from 'primevue/treeselect';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUserStore } from '@/entities/user/lib/store';
import { permissionCheck, permissions } from '@/entities/user/lib/util';
import { timeRangeModes } from '@/widgets/dashboard/config/constants';
import {
	calculateTooltipTotals,
	formatNumber,
	formatPrice,
	getChartLifetimeData,
	getChartOptions,
	getDualMainChartData,
	getLastUpdatedDate,
	getMainChartData,
	getTimeRangePrefix,
	getTimeRangeSuffix,
	toLabelsAndData
} from '@/widgets/dashboard/lib';
import { DashboardHeaderTooltip } from '@/widgets/dashboard/model/types';
import DashboardLifetime from '@/widgets/dashboard/ui/DashboardLifetime.vue';
import NewestInvoices from '@/widgets/dashboard/ui/NewestInvoices.vue';
import PermissionRequired from '@/widgets/dashboard/ui/PermissionRequired.vue';

import { getChartTextByModeRevenue } from '../lib';
import { useDashboardStore } from '../model/store';

const { t } = useI18n();

const store = useDashboardStore();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const shopOptions = computed(() => [
	{
		key: 'allShops',
		label: t('allShops')
	},
	...user.value.organizations.map(v => ({
		key: 'organizations_' + v.id,
		label: v.name,
		children: user.value.shops
			.filter(s => s.organization_id === v.id)
			.map(s => ({ key: 'shops_' + s.id, label: s.name }))
	}))
]);

const selectedShop = ref({ allShops: true });
const apiSelectedShop = computed(() => {
	let shopIds: number[] = [];
	Object.keys(selectedShop.value).forEach(v => {
		if (v === 'allShops' || v === 'organizations' || v === 'shops') return;
		else {
			const data = v.split('_');
			if (data[0] === 'shops') {
				shopIds.push(Number(data[1]));
			}
			if (selectedMainChart.value !== 4) {
				shopIds = user.value.shops
					.filter(v => v.organization_id === Number(data[1]))
					.map(v => v.id);
			}
		}
	});
	return shopIds.length ? shopIds : undefined;
});
const apiSelectedOrganization = computed(() => {
	const organizationIds: number[] = [];
	Object.keys(selectedShop.value).forEach(v => {
		if (v === 'allShops' || v === 'organizations' || v === 'shops') return;
		else {
			const data = v.split('_');
			if (data[0] === 'organizations') {
				organizationIds.push(Number(data[1]));
			}
		}
	});
	return organizationIds.length ? organizationIds : undefined;
});

const mainBlockTitle = ref(t('dashboard.titles.compareRevenue'));
const chartTitle = ref(t('dashboard.titles.revenueAndNumberOfInvoices'));
const chartHeaderTooltips = ref<DashboardHeaderTooltip[]>([]);

const selectedMainChart = ref(1);

const dataActualDate = ref<string>();
const mainChart = ref();
const chartOptions = ref();
const numberOfInvoicesChart = ref();
const averageOfInvoicesChart = ref();
const revenueOfInvoicesChart = ref();
const totalCustomerCountChart = ref();

const timeMode = ref(timeRangeModes[2]);

const mainChartDescSuffix = computed(() =>
	getTimeRangeSuffix(timeMode.value.code)
);

const onUpdate = async () => {
	await nextTick();
	await getAndSetMainChartData();
};

const updateChartMode = async (v: number) => {
	selectedMainChart.value = v;
	selectedShop.value = { allShops: true };
	timeMode.value = timeRangeModes[2];
	await onUpdate();
};

const getAndSetMainChartData = async () => {
	const params = {
		time_range: timeMode.value.code,
		shop_ids: apiSelectedShop.value
	};
	const headPrefix = getTimeRangePrefix(timeMode.value.code);
	let currentLabels;

	if (selectedMainChart.value === 1) {
		await store.revenueAndInvoicesAmount.execute(0, params);

		const totalWithoutLastI = calculateTooltipTotals(
			store.revenueAndInvoicesAmount.state.invoices
		);
		const totalWithoutLastR = calculateTooltipTotals(
			store.revenueAndInvoicesAmount.state.revenue
		);

		chartHeaderTooltips.value = [
			{
				data: formatPrice(formatNumber(totalWithoutLastR)),
				title: headPrefix + ' ' + t('revenue'),
				desc: t('dashboard.titles.totalAmountOfInvoices')
			},
			{
				data: formatNumber(totalWithoutLastI),
				title: headPrefix + ' ' + t('invoiceCount'),
				desc: t('dashboard.titles.totalNumberOfInvoices')
			}
		];
		const { labels, data: revenueData } = toLabelsAndData(
			store.revenueAndInvoicesAmount.state.revenue,
			timeMode.value.code
		);
		const { data: invoiceData } = toLabelsAndData(
			store.revenueAndInvoicesAmount.state.invoices,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getDualMainChartData(invoiceData, revenueData, labels);
	} else if (selectedMainChart.value === 4) {
		await store.filteredNumberOfCustomers.execute(0, {
			...params,
			organization_ids: apiSelectedOrganization.value
		});

		const totalWithoutLast = calculateTooltipTotals(
			store.filteredNumberOfCustomers.state
		);

		chartHeaderTooltips.value = [
			{
				data: formatNumber(totalWithoutLast),
				title: headPrefix + ' ' + t('customerCount'),
				desc: t('dashboard.titles.totalNumberOfCustomers')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredNumberOfCustomers.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(data, labels, t('customers'), '#3B82F6');
	} else if (selectedMainChart.value === 3) {
		await store.filteredNumberOfInvoices.execute(0, params);

		const totalWithoutLast = calculateTooltipTotals(
			store.filteredNumberOfInvoices.state
		);

		chartHeaderTooltips.value = [
			{
				data: formatNumber(totalWithoutLast),
				title: headPrefix + ' ' + t('invoiceCount'),
				desc: t('dashboard.titles.totalNumberOfInvoices')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredNumberOfInvoices.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(data, labels, t('invoices'), '#fc6161');
	} else {
		await store.filteredAverageOfInvoices.execute(0, params);

		const totalWithoutLast = calculateTooltipTotals(
			store.filteredAverageOfInvoices.state
		);
		const yearLength = store.filteredAverageOfInvoices.state.filter(
			(v, index) =>
				v.amount !== 0 &&
				index !== store.filteredAverageOfInvoices.state.length - 1
		).length;

		chartHeaderTooltips.value = [
			{
				data: formatPrice(formatNumber(totalWithoutLast / yearLength)),
				title: t('averageInvoice'),
				desc: t('averageInvoice')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredAverageOfInvoices.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(data, labels, t('invoices'), '#0bd18a');
	}

	const { chartTitle: chart, mainBlockTitle: main } = getChartTextByModeRevenue(
		selectedMainChart.value
	);
	mainBlockTitle.value = main;
	chartTitle.value = chart;

	chartOptions.value = getChartOptions(
		selectedMainChart.value !== 1,
		timeMode.value.code,
		currentLabels
	);
};

onMounted(async () => {
	if (!permissionCheck(permissions.GRAPH, user.value)) return;
	const promises: Promise<any>[] = [getAndSetMainChartData()];

	if (store.amountAndRevenueOfInvoices.state.data.length === 0) {
		promises.push(store.amountAndRevenueOfInvoices.execute(0));
	}
	if (store.averageAndNumberOfInvoices.state.data.length === 0) {
		promises.push(store.averageAndNumberOfInvoices.execute(0));
	}
	if (store.totalAndNumberOfCustomers.state.data.length === 0) {
		promises.push(store.totalAndNumberOfCustomers.execute(0));
	}
	if (store.totalAndNumberOfInvoices.state.data.length === 0) {
		promises.push(store.totalAndNumberOfInvoices.execute(0));
	}
	if (store.newestInvoices.state.length === 0) {
		promises.push(store.newestInvoices.execute(0));
	}

	await Promise.all(promises);

	dataActualDate.value = getLastUpdatedDate();

	averageOfInvoicesChart.value = getChartLifetimeData(
		store.averageAndNumberOfInvoices.state.data,
		'11, 209, 138'
	);
	numberOfInvoicesChart.value = getChartLifetimeData(
		store.totalAndNumberOfInvoices.state.data,
		'252, 97, 97'
	);
	revenueOfInvoicesChart.value = getChartLifetimeData(
		store.amountAndRevenueOfInvoices.state.data,
		'0, 208, 222'
	);
	totalCustomerCountChart.value = getChartLifetimeData(
		store.totalAndNumberOfCustomers.state.data,
		'59, 130, 246'
	);
});
</script>

<template>
	<div
		v-if="
			store.amountAndRevenueOfInvoices.isLoading ||
			store.averageAndNumberOfInvoices.isLoading ||
			store.totalAndNumberOfCustomers.isLoading ||
			store.totalAndNumberOfInvoices.isLoading ||
			store.newestInvoices.isLoading
		"
		class="tw3-text-center tw3-py-20"
	>
		<ProgressSpinner />
	</div>
	<PermissionRequired v-else-if="!permissionCheck(permissions.GRAPH, user)" />
	<div
		v-else
		class="tw3-max-w-[1440px] tw3-m-auto tw3-grid tw3-grid-cols-12 tw3-gap-4 md:tw3-gap-8"
	>
		<DashboardLifetime
			:cardStyle="
				selectedMainChart === 1
					? {
							boxShadow: '0px 6px 20px rgba(0, 208, 222, 0.5) !important'
						}
					: undefined
			"
			:chart="revenueOfInvoicesChart"
			:differenceNumber="store.amountAndRevenueOfInvoices.state.difference"
			isRevenue
			:showTooltip="{
				tooltipHeaderI18: 'revenueData',
				tooltipDescI18: 'dashboardTooltipTitle'
			}"
			showTooltipExplaining
			:title="$t('lifetimeRevenue')"
			:value="store.amountAndRevenueOfInvoices.state.count"
			:valueStyle="{
				backgroundColor: '#00d0de',
				boxShadow: '0px 6px 20px rgba(0, 208, 222, 0.3)'
			}"
			@click="updateChartMode(1)"
		/>

		<DashboardLifetime
			:cardStyle="
				selectedMainChart === 2
					? {
							boxShadow: '0px 6px 20px rgba(11, 209, 138, 0.5) !important'
						}
					: undefined
			"
			:chart="averageOfInvoicesChart"
			:differenceNumber="store.averageAndNumberOfInvoices.state.difference"
			isRevenue
			:title="$t('averageInvoiceAmount')"
			:value="store.averageAndNumberOfInvoices.state.count"
			:valueStyle="{
				backgroundColor: '#0bd18a',
				boxShadow: '0px 6px 20px rgba(11, 209, 138, 0.3)'
			}"
			@click="updateChartMode(2)"
		/>

		<DashboardLifetime
			:cardStyle="
				selectedMainChart === 3
					? {
							boxShadow: '0px 6px 20px rgba(252, 97, 97, 0.5) !important'
						}
					: undefined
			"
			:chart="numberOfInvoicesChart"
			:differenceNumber="store.totalAndNumberOfInvoices.state.difference"
			:title="$t('lifetimeInvoices')"
			:value="store.totalAndNumberOfInvoices.state.count"
			:valueStyle="{
				backgroundColor: '#fc6161',
				boxShadow: '0px 6px 20px rgba(252, 97, 97, 0.3)'
			}"
			@click="updateChartMode(3)"
		/>

		<DashboardLifetime
			:cardStyle="
				selectedMainChart === 4
					? {
							boxShadow: '0px 6px 20px rgba(59, 130, 246, 0.5) !important'
						}
					: undefined
			"
			:chart="totalCustomerCountChart"
			:differenceNumber="store.totalAndNumberOfCustomers.state.difference"
			:title="$t('lifetimeCustomers')"
			:value="store.totalAndNumberOfCustomers.state.count"
			:valueStyle="{
				backgroundColor: '#3B82F6',
				boxShadow: '0px 6px 20px rgba(59, 130, 246, 0.3)'
			}"
			@click="updateChartMode(4)"
		/>

		<div class="tw3-col-span-12 lg:tw3-col-span-8">
			<div
				class="card widget-visitor-graph !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-4"
			>
				<div
					class="tw3-font-medium tw3-flex tw3-flex-col sm:tw3-flex-row sm:tw3-justify-between sm:tw3-items-center tw3-leading-loose"
				>
					<span>{{ mainBlockTitle }}</span>
					<div
						class="tw3-flex tw3-flex-col sm:tw3-flex-row tw3-items-center tw3-gap-2"
					>
						<TreeSelect
							v-model="selectedShop"
							class="tw3-w-full sm:tw3-w-auto"
							:loading="
								store.revenueAndInvoicesAmount.isLoading ||
								store.filteredNumberOfCustomers.isLoading ||
								store.filteredNumberOfInvoices.isLoading ||
								store.filteredAverageOfInvoices.isLoading
							"
							:options="shopOptions"
							placeholder="Select an Item"
							@update:model-value="onUpdate"
						/>
						<Select
							v-model="timeMode"
							class="tw3-w-full sm:tw3-w-auto"
							:loading="
								store.revenueAndInvoicesAmount.isLoading ||
								store.filteredNumberOfCustomers.isLoading ||
								store.filteredNumberOfInvoices.isLoading ||
								store.filteredAverageOfInvoices.isLoading
							"
							optionLabel="name"
							:options="timeRangeModes"
							@update:model-value="onUpdate"
						/>
					</div>
				</div>
				<div class="graph-content tw3-grid tw3-grid-cols-12 tw3-gap-4 tw3-mt-6">
					<div
						v-if="
							!mainChart ||
							store.revenueAndInvoicesAmount.isLoading ||
							store.filteredNumberOfCustomers.isLoading ||
							store.filteredNumberOfInvoices.isLoading ||
							store.filteredAverageOfInvoices.isLoading
						"
						class="tw3-col-span-12 md:tw3-col-span-6"
					>
						<Skeleton height="31px" width="5.5rem" />
						<Skeleton class="tw3-my-4" height="18px" width="7rem" />
						<Skeleton height="18px" width="12rem" />
					</div>
					<template v-else>
						<div
							v-for="(item, index) in chartHeaderTooltips"
							:key="index"
							class="tw3-col-span-12 md:tw3-col-span-6"
						>
							<div class="tw3-text-3xl tw3-font-semibold">
								{{ item.data }}
							</div>
							<div class="tw3-font-semibold tw3-my-4">{{ item.title }}</div>
							<p class="tw3-text-surface-500">
								{{ item.desc + mainChartDescSuffix }}
							</p>
						</div>
					</template>
				</div>

				<div class="graph">
					<div class="tw3-text-xl tw3-font-semibold tw3-my-6">
						{{ chartTitle }}
					</div>

					<div
						v-if="
							!mainChart ||
							store.revenueAndInvoicesAmount.isLoading ||
							store.filteredNumberOfCustomers.isLoading ||
							store.filteredNumberOfInvoices.isLoading ||
							store.filteredAverageOfInvoices.isLoading
						"
						class="tw3-h-[590px] tw3-w-full tw3-flex tw3-justify-center tw3-items-center"
					>
						<ProgressSpinner />
					</div>
					<Chart
						v-else
						:data="mainChart"
						:height="590"
						:options="chartOptions"
						type="bar"
					/>
				</div>

				<div class="tw3-self-end tw3-mt-2 tw3-text-sm tw3-text-gray-300">
					{{ $t('dataUpdatedOn', { date: dataActualDate }) }}
				</div>
			</div>
		</div>

		<div class="tw3-col-span-12 lg:tw3-col-span-4 tw3-mb-[1.25rem]">
			<NewestInvoices
				:items="store.newestInvoices.state"
				:loading="store.newestInvoices.isLoading"
			/>
		</div>
	</div>
</template>
