import { i18n } from '@/shared/i18n';

export const getChartTextByModeTech = (mode: number) => {
	let mainBlockTitle = '';
	let chartTitle = '';

	if (mode === 1) {
		mainBlockTitle = i18n.global.t('tech.titles.commissionGrow');
		chartTitle = i18n.global.t('commissionAmount');
	} else if (mode === 4) {
		mainBlockTitle = i18n.global.t('tech.titles.completedGrow');
		chartTitle = i18n.global.t('tech.titles.numberOfCJobs');
	} else if (mode === 3) {
		mainBlockTitle = i18n.global.t('tech.titles.warrantyGrow');
		chartTitle = i18n.global.t('tech.titles.numberOfWJobs');
	} else {
		mainBlockTitle = i18n.global.t('tech.titles.averageCostRatio');
		chartTitle = i18n.global.t('costRatio');
	}

	return { mainBlockTitle, chartTitle };
};
