// Build a tree from a flat list of items
export const buildTree = (
	data: any[],
	parentKey = 'parentAccountId',
	parent: any = undefined
) => {
	const tree = data.filter(item => item[parentKey] === parent);

	tree.forEach(item => {
		const children = buildTree(data, parentKey, item.id);
		if (children.length) {
			item.children = children;
		}
	});

	return tree;
};

// Flatten a tree to a flat list of items
export const flattenTree = (tree: any[], depthKey = 'depth', depth = 0) => {
	const flat: any[] = [];
	tree.forEach(item => {
		flat.push({ ...item, [depthKey]: depth });
		if (item.children) {
			flat.push(...flattenTree(item.children, depthKey, depth + 1));
		}
	});
	return flat;
};
