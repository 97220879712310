<script setup lang="ts">
// Shop selector that uses fearures/accounting/lib/store store
import { storeToRefs } from 'pinia';

import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useAccountingStore from './lib/store';

const value = defineModel<number | undefined>();

const { shops } = storeToRefs(useAccountingStore());
</script>

<template>
	<AutoCompleteSelect v-model="value" fluid :options="shops" optionValue="id" />
</template>
