import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';

import {
	getBalanceSheet,
	getIncomeStatement,
	getTransactionsByAccount
} from '@/entities/accounting/reports/lib/api';
import {
	BalanceSheetRequest,
	IncomeStatementRequest,
	TransactionsByAccountRequest
} from '@/entities/accounting/reports/lib/types';
import { useMessages } from '@/shared/composables';

const useAccountingReportsStore = defineStore(
	'widgetsAccountingReports',
	() => {
		const { showError } = useMessages();

		const balanceSheetState = useAsyncState(
			async (params: BalanceSheetRequest) => {
				try {
					const records = await getBalanceSheet(params);
					return records.toSorted(
						(a, b) =>
							a.typeId - b.typeId ||
							a.subtypeId - b.subtypeId ||
							a.name.localeCompare(b.name)
					);
				} catch (error) {
					showError(error);
					return [];
				}
			},
			[],
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		const loadBalanceSheet = async (params: BalanceSheetRequest) => {
			balanceSheetState.execute(0, params);
		};

		const incomeStatementState = useAsyncState(
			async (params: IncomeStatementRequest) => {
				try {
					const records = await getIncomeStatement(params);
					return records.toSorted(
						(a, b) =>
							a.typeId - b.typeId ||
							a.subtypeId - b.subtypeId ||
							a.name.localeCompare(b.name)
					);
				} catch (error) {
					showError(error);
					return [];
				}
			},
			[],
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		const loadIncomeStatement = async (params: IncomeStatementRequest) => {
			incomeStatementState.execute(0, params);
		};

		const transactionsByAccountState = useAsyncState(
			(params: TransactionsByAccountRequest) => {
				return getTransactionsByAccount(params).catch(error => {
					showError(error);
					return { startBalance: 0, record: [] };
				});
			},
			{
				startBalance: 0,
				record: []
			},
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		const loadTransactionsByAccount = async (
			params: TransactionsByAccountRequest
		) => {
			transactionsByAccountState.execute(0, params);
		};

		return {
			balanceSheet: balanceSheetState.state,
			balanceSheetLoading: balanceSheetState.isLoading,
			loadBalanceSheet,
			incomeStatement: incomeStatementState.state,
			incomeStatementLoading: incomeStatementState.isLoading,
			loadIncomeStatement,
			transactionsByAccount: transactionsByAccountState.state,
			transactionsByAccountLoading: transactionsByAccountState.isLoading,
			loadTransactionsByAccount
		};
	}
);

export default useAccountingReportsStore;
