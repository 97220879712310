import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import InputText from 'primevue/inputtext';
import { InputTypeHTMLAttribute } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputTextWithError',
  props: {
    type: { default: 'text' },
    modelValue: { default: undefined },
    placeholder: { default: undefined },
    errMsg: { default: undefined },
    disabled: { type: Boolean },
    textInputStyle: { default: undefined }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_unref(InputText), {
    class: "tw3-w-full",
    disabled: _ctx.disabled,
    invalid: !!_ctx.errMsg,
    modelValue: _ctx.modelValue,
    placeholder: _ctx.placeholder,
    style: _normalizeStyle(_ctx.textInputStyle),
    type: _ctx.type,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = v => emit('update:modelValue', v ?? null))
  }, null, 8, ["disabled", "invalid", "modelValue", "placeholder", "style", "type"])), [
    [
      _directive_tooltip,
      {
			value: _ctx.errMsg,
			pt: {
				text: {
					style: { backgroundColor: 'rgb(248 113 113)' }
				},
				arrow: {
					style: { borderTopColor: 'rgb(248 113 113)' }
				}
			}
		},
      void 0,
      { top: true }
    ]
  ])
}
}

})