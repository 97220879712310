<template>
	<Button
		v-tooltip.top="tooltip"
		icon="pi pi-download"
		severity="contrast"
		size="small"
		@click="downloadFileHandler"
	/>
</template>

<script setup lang="ts">
import Button from 'primevue/button';

import { downloadFile } from '../../helpers';

const props = defineProps({
	fileName: {
		default: '',
		type: String
	},
	link: {
		default: '#',
		type: String
	},
	tooltip: {
		default: 'Download',
		type: String
	}
});

const emit = defineEmits<{
	(e: 'downloading'): void;
}>();

const downloadFileHandler = () => {
	emit('downloading');
	downloadFile(props.link, props.fileName);
};
</script>
