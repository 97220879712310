import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-gap-4" }
const _hoisted_2 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_3 = {
  key: 0,
  class: "tw3-flex tw3-items-center tw3-gap-4"
}
const _hoisted_4 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_5 = { class: "tw3-flex tw3-justify-between" }
const _hoisted_6 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_7 = {
  key: 1,
  class: "tw3-self-end"
}

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import * as R from 'ramda';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';

import { getConsumerName } from '@/entities/customer/lib/helpers';
import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { Customer, CustomerRates } from '@/entities/customer/lib/types';

import { taxesHeader } from '../../lib/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerShopSettings',
  setup(__props) {

const store = useCustomerEditStore();

const nagsSelector = ref<any>();
const shopId = ref(0);
const shopIndex = computed(() =>
	store.customer.state.consumer_shop_settings.findIndex(
		v => v.shop_id === shopId.value
	)
);

const shopRatesToSelect = computed(() => {
	const shopRatesToCompare = store.shopRates.state ? store.shopRates.state : [];

	// consumers may have custom individual rates already, this maintains those
	if (
		!store.customer.state.consumer_shop_settings[shopIndex.value].rates?.id ||
		(
			store.customer.state.consumer_shop_settings[shopIndex.value]
				.rates as CustomerRates
		).id <= 0
	) {
		return shopRatesToCompare;
	}
	let foundMatch = false;
	for (let i = 0; i < shopRatesToCompare.length; i++) {
		if (
			shopRatesToCompare[i].id ===
			store.customer.state.consumer_shop_settings[shopIndex.value].rates?.id
		) {
			foundMatch = true;
		}
	}
	if (foundMatch) {
		return shopRatesToCompare;
	} else {
		const ratesToReturn = R.clone(shopRatesToCompare);
		const rateToAdd = R.clone(
			store.customer.state.consumer_shop_settings[shopIndex.value].rates
		) as CustomerRates;
		rateToAdd.id = store.customer.state.consumer_shop_settings[shopIndex.value]
			.rates?.id as number;
		rateToAdd.name = 'Custom Consumer Rate';
		ratesToReturn.push(rateToAdd);
		return ratesToReturn;
	}
});

const updateValue = (field: keyof Customer, value: any) => {
	store.customer.state = { ...store.customer.state, [field]: value };
};

const updateRates = (val: any) => {
	updateValue(
		'consumer_shop_settings',
		store.customer.state.consumer_shop_settings.map(v => {
			if (v.shop_id === shopId.value) {
				const rates = store.shopRates.state.find(
					(v: CustomerRates) => v.id === val
				) as unknown as CustomerRates;
				return Object.assign(v, { rates: rates ?? null });
			}
			return v;
		})
	);
};

const updatePriceLevel = (val: any) => {
	updateValue(
		'consumer_shop_settings',
		store.customer.state.consumer_shop_settings.map(v => {
			if (v.shop_id === shopId.value) {
				return Object.assign(v, { pricelevel_id: val });
			}
			return v;
		})
	);
};

const addShopToConsumerSettings = (shopId: number) => {
	const consumerShopSettingToAdd = {
		override_allowed_shop: 0,
		pricelevel_id: null,
		rates: null,
		shop_id: shopId
	};
	updateValue('consumer_shop_settings', [
		...store.customer.state.consumer_shop_settings,
		consumerShopSettingToAdd
	]);
};

const hydrateNags = (isCreate?: boolean) => {
	nagsSelector.value.hide();
	const newEvent = new CustomEvent('hydrateNags', {
		detail: {
			isCreate,
			rate: isCreate
				? {}
				: store.customer.state.consumer_shop_settings[shopIndex.value].rates,
			shopRates: store.shopRates.state,
			shopId:
				store.customer.state.consumer_shop_settings[shopIndex.value].shop_id,
			defaultName:
				getConsumerName({
					company_name: store.customer.state.company_name,
					name: store.name
				}) ?? '-'
		}
	});
	window.dispatchEvent(newEvent);
};

const hydrateNagsFromVue2 = (event: any) => {
	if (event.detail.isCreate) {
		store.shopRates.state = [...store.shopRates.state, event.detail.newRate];
	} else {
		store.shopRates.state = store.shopRates.state.map(v => {
			if (v.id === event.detail.newRate.id) return event.detail.newRate;
			else return v;
		});
	}
	store.customer.state.consumer_shop_settings[shopIndex.value].rates =
		event.detail.newRate;
};

onMounted(async () => {
	window.addEventListener('returnNagsToVue3', hydrateNagsFromVue2);
	if (!store.shopsByOrganization.state.length) {
		await store.shopsByOrganization.execute(0);
	}
	shopId.value = store.customer.state.consumer_shop_settings[0]?.shop_id
		? store.customer.state.consumer_shop_settings[0].shop_id
		: store.shopsByOrganization.state[0].id;
	if (shopIndex.value === -1) {
		addShopToConsumerSettings(shopId.value);
	}
	const promises = [];
	if (!store.shopPricelevels.state.length) {
		promises.push(store.shopPricelevels.execute(0, { id: shopId.value }));
	}
	if (!store.shopRates.state.length) {
		promises.push(store.shopRates.execute(0, { id: shopId.value }));
	}
	if (!store.shopTaxes.state.length) {
		promises.push(store.shopTaxes.execute(0, { id: shopId.value }));
	}
	await Promise.all(promises);
});

watch(
	() => shopId.value,
	async () => {
		await nextTick();
		if (shopIndex.value === -1) {
			addShopToConsumerSettings(shopId.value);
		}
	}
);

onUnmounted(() =>
	window.removeEventListener('returnNagsToVue3', hydrateNagsFromVue2)
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Shop ", -1)),
      _createVNode(_unref(Select), {
        modelValue: shopId.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((shopId).value = $event)),
        class: "tw3-w-full",
        loading: _unref(store).shopsByOrganization.isLoading,
        optionLabel: "name",
        options: _unref(store).shopsByOrganization.state,
        optionValue: "id",
        placeholder: "Select item"
      }, null, 8, ["modelValue", "loading", "options"])
    ]),
    (shopIndex.value !== -1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Default Nags Pricing ", -1)),
              (_unref(store).customer.state.consumer_shop_settings[shopIndex.value].rates)
                ? (_openBlock(), _createBlock(_unref(Button), {
                    key: 0,
                    label: "Edit",
                    rounded: "",
                    severity: "secondary",
                    size: "small",
                    text: "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (hydrateNags()))
                  }))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_unref(Select), {
              ref_key: "nagsSelector",
              ref: nagsSelector,
              class: "tw3-w-full",
              filter: "",
              loading: _unref(store).shopRates.isLoading,
              modelValue: 
						_unref(store).customer.state.consumer_shop_settings[shopIndex.value].rates?.id
					,
              optionLabel: "name",
              options: shopRatesToSelect.value,
              optionValue: "id",
              placeholder: "Select item",
              showClear: "",
              "onUpdate:modelValue": updateRates
            }, {
              footer: _withCtx(() => [
                _createVNode(_unref(Button), {
                  class: "tw3-w-full tw3-my-2",
                  icon: "pi pi-plus",
                  label: "Add New",
                  severity: "secondary",
                  size: "small",
                  text: "",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (hydrateNags(true)))
                })
              ]),
              _: 1
            }, 8, ["loading", "modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Default Price Level ", -1)),
            _createVNode(_unref(Select), {
              class: "tw3-w-full",
              filter: "",
              loading: _unref(store).shopPricelevels.isLoading,
              modelValue: 
						_unref(store).customer.state.consumer_shop_settings[shopIndex.value].pricelevel_id
					,
              optionLabel: "name",
              options: _unref(store).shopPricelevels.state,
              optionValue: "id",
              placeholder: "Select item",
              showClear: "",
              "onUpdate:modelValue": updatePriceLevel
            }, null, 8, ["loading", "modelValue", "options"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(DataTable), {
      class: "tw3-w-full",
      dataKey: "id",
      selection: _unref(store).customer.state.taxes,
      value: _unref(store).shopTaxes.isLoading ? new Array(4) : _unref(store).shopTaxes.state,
      "onUpdate:selection": _cache[3] || (_cache[3] = v => updateValue('taxes', [...v]))
    }, {
      default: _withCtx(() => [
        (!_unref(store).shopTaxes.isLoading)
          ? (_openBlock(), _createBlock(_unref(Column), {
              key: 0,
              selectionMode: "multiple"
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(taxesHeader), (column) => {
          return (_openBlock(), _createBlock(_unref(Column), {
            key: column.key,
            field: column.key,
            header: column.title
          }, {
            body: _withCtx(({ data }) => [
              (_unref(store).shopTaxes.isLoading)
                ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["tw3-flex", {
							'tw3-justify-center':
								column.key === 'for_materials' || column.key === 'for_labor',
							'tw3-justify-end': column.key === 'percentage'
						}])
                  }, [
                    (
								column.key === 'for_materials' || column.key === 'for_labor'
							)
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: _normalizeClass(!!data[column.key] ? 'pi pi-check' : 'pi pi-times')
                        }, null, 2))
                      : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(data[column.key]), 1))
                  ], 2))
            ]),
            _: 2
          }, 1032, ["field", "header"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["selection", "value"])
  ]))
}
}

})