export const ACCOUNT_TYPE_ASSETS_ID = 1;
export const ACCOUNT_TYPE_LIABILITIES_ID = 2;
export const ACCOUNT_TYPE_EQUITIES_ID = 3;
export const ACCOUNT_TYPE_REVENUE_ID = 4;
export const ACCOUNT_TYPE_EXPENSE_ID = 5;

export enum BalanceType {
	BALANCE_TYPE_ON_DATE = 'BALANCE_TYPE_ON_DATE',
	BALANCE_TYPE_YTD = 'BALANCE_TYPE_YTD'
}

export type AccountType = {
	id: number;
	name: string;
	creditAccount: boolean;
	balanceType: BalanceType;
};

export type ListAccountTypeResponse = {
	accountType: AccountType[];
};

export type AccountSubtype = {
	id: number;
	name: string;
	accountTypeId: number;
	accountTypeName: string;
	creditAccount: boolean;
	balanceType: BalanceType;
};

export type ListAccountSubtypeResponse = {
	accountSubtype: AccountSubtype[];
};

export enum AccountStatus {
	ACCOUNT_STATUS_DISABLED = 'ACCOUNT_STATUS_DISABLED',
	ACCOUNT_STATUS_ACTIVE = 'ACCOUNT_STATUS_ACTIVE'
}

export type Account = {
	id?: number;
	parentAccountId?: number;
	typeId: number;
	subtypeId: number;
	name: string;
	description: string;
	code: string;
	status: AccountStatus;
	balance: number;
	system: boolean;
	organizationId: number;
};

export interface GetAccountResponse {
	account: Account;
}

export interface ListAccountResponse {
	account: Account[];
}

export type CreateAccountRequest = {
	organizationId: number;
	subtypeId: number;
	name: string;
	description: string;
	code: string;
	status: AccountStatus;
	parentAccountId?: number;
};

export type CreateAccountResponse = {
	id: number;
};

export type UpdateAccountRequest = {
	id: number;
	name: string;
	description: string;
	code: string;
	status: AccountStatus;
	parentAccountId?: number;
};
