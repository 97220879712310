import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-gap-6" }
const _hoisted_2 = { class: "tw3-flex tw3-flex-col tw3-gap-3 tw3-h-[452px] tw3-overflow-y-auto" }
const _hoisted_3 = {
  key: 0,
  class: "tw3-flex tw3-flex-1 tw3-justify-center tw3-items-center"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "tw3-text-slate-600" }
const _hoisted_6 = { class: "tw3-text-xs tw3-text-slate-400" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

import { vInfiniteScroll } from '@vueuse/components';
import { debounce } from 'lodash-es';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import { onMounted, ref } from 'vue';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { Option } from '@/entities/customer/lib/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateBillTo',
  props: {
    "show": { type: Boolean },
    "showModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["update-bill"], ["update:show"]),
  setup(__props, { emit: __emit }) {

const emit = __emit;

const store = useCustomerEditStore();
const search = ref('');

const reFetch = debounce(() => {
	store.billsTo.execute(0, { first: 0, search: search.value });
}, 300);

const showModal = _useModel<boolean>(__props, 'show');

const selectItem = (c: Option) => {
	emit('update-bill', { full_name: c.full_name, id: c.id });
	showModal.value = false;
};

onMounted(() => store.billsTo.execute(0, { first: 0, search: search.value }));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3UpdateBillTo",
    visible: showModal.value,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-max-w-lg",
    contentStyle: { padding: '1rem' },
    contentClass: "beautiful-scroll",
    header: "Select Bill To",
    modal: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(InputText), {
          modelValue: search.value,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
            _unref(reFetch)
          ],
          class: "tw3-w-full",
          placeholder: "Bill To",
          type: "text"
        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
        _createElementVNode("div", _hoisted_2, [
          (_unref(store).billsTo.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_unref(ProgressSpinner), {
                  "aria-label": "Loading",
                  class: "tw3-fill-sky-500",
                  fill: "transparent",
                  style: {"width":"50px","height":"50px"}
                })
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).billsTo.state.rows, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "tw3-p-2 tw3-rounded-lg tw3-cursor-pointer hover:tw3-bg-gray-200",
                    onClick: () => selectItem(item)
                  }, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(item.full_name), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(item.trading_partner), 1)
                  ], 8, _hoisted_4))
                }), 128)),
                (_unref(store).billsTo.state.rows.length !== _unref(store).billsTo.state.count)
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, _cache[2] || (_cache[2] = [
                      _createTextVNode(" Loading more... ")
                    ]))), [
                      [_unref(vInfiniteScroll), 
							() => _unref(store).fetchBillMore(_unref(store).billsTo.state.rows.length, search.value)
						]
                    ])
                  : _createCommentVNode("", true),
                (_unref(store).billsTo.state.count === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, "No data"))
                  : _createCommentVNode("", true)
              ], 64))
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})