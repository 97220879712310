import { DashboardLifetimeData } from '@/widgets/dashboard/model/types';

export const calculateTooltipTotals = (arr: DashboardLifetimeData[]) => {
	if (!arr.length) return 0;

	const total = arr.reduce((acc, v) => acc + v.amount, 0);
	const totalWithoutLast = total - arr[0].amount;

	return totalWithoutLast;
};
