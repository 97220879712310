import { defineStore, storeToRefs } from 'pinia';
import { computed, reactive, watchEffect } from 'vue';

import { Shop } from '@/entities/shop/lib/types';
import { useUserStore } from '@/entities/user/lib/store';

const useShopsStore = defineStore('entitiesShops', () => {
	const { user } = storeToRefs(useUserStore());

	const shops = reactive<Shop[]>(user.value.shops || []);

	watchEffect(() => {
		shops.splice(0, shops.length, ...user.value.shops);
	});

	const shopsById = computed(() => {
		const res: Record<string, Shop> = {};
		shops.forEach(shop => {
			res[shop.id] = shop;
		});
		return res;
	});

	return {
		shops,
		shopsById
	};
});

export default useShopsStore;
