export function formatAsPhoneNumber(value: string): string {
	const digits = value.replace(/\D/g, '');
	let preparedValue = '';
	if (digits.length <= 3) {
		preparedValue = `(${digits})`;
	} else if (digits.length <= 6) {
		preparedValue = `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
	} else {
		preparedValue = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
	}
	return preparedValue;
}

export function formatFileSize(bytes: number): string {
	const k = 1024;
	const dm = 3;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

	if (bytes === 0) {
		return `0 ${sizes[0]}`;
	}

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

	return `${formattedSize} ${sizes[i]}`;
}
