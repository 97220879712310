<script setup lang="ts">
// import * as filepicker from 'filepicker-js';
import * as filestack from 'filestack-js';
import { PickerFileMetadata, PickerResponse } from 'filestack-js';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputMask from 'primevue/inputmask';
import { useToast } from 'primevue/usetoast';
import { defineModel, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useAppStore from '@/entities/app/lib/store';
import { formatFileSize } from '@/shared/helpers/formatter';

import useFaxStore from '../model/store';

const { t } = useI18n();
const toast = useToast();
const fileName = ref<string>('');
const file = ref<PickerFileMetadata>();
const faxNumber = ref<string>();
const showModal = defineModel<boolean>('show');

const faxStore = useFaxStore();
const appStore = useAppStore();

const sendingIsInProcess = ref<boolean>(false);
const sendFax = async () => {
	if (file.value && faxNumber.value) {
		const PreparedFaxNumber = faxNumber.value.replace(/\D/g, '');
		if (PreparedFaxNumber.length !== 10) {
			return;
		}
		sendingIsInProcess.value = true;

		const outgoingFaxNumber = (
			await faxStore.outgoingFaxNumber
		).state.value.toString();
		const sendFaxInfo = new FormData();
		sendFaxInfo.set('filename', window.btoa(file?.value.filename));
		sendFaxInfo.set('url', window.btoa(file?.value.url));
		sendFaxInfo.set('sender', outgoingFaxNumber);
		sendFaxInfo.set('receiver', PreparedFaxNumber);

		await faxStore.sendNewFaxHandler(sendFaxInfo);

		showModal.value = false;
		sendingIsInProcess.value = false;
	}
};

const burstIsInProcess = ref<boolean>(false);
const burstFaxHandler = async () => {
	if (file.value) {
		burstIsInProcess.value = true;
		const response = await faxStore.burstNewFax({
			burstName: file.value.filename,
			burstUrl: file.value.url
		});
		if (response === 'Error') {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: t('faxDialog.uploadFax.burstNewFax.error'),
				summary: t('defaultToast.errorSummary')
			});
		} else {
			toast.add({
				life: 3000,
				severity: 'success',
				detail: t('faxDialog.uploadFax.burstNewFax.success'),
				summary: t('defaultToast.successSummary')
			});
		}
		showModal.value = false;
		burstIsInProcess.value = false;
	}
};

const openFilePicker = () => {
	const client = filestack.init(appStore.appConfig.state.filePickerKey);
	const options = {
		accept: ['application/pdf'],
		maxFiles: 1,
		onUploadDone: handleUploadDone
	};

	client.picker(options).open();
};

const handleUploadDone = (result: PickerResponse) => {
	const uploadedFile = result.filesUploaded[0];

	file.value = uploadedFile;
	fileName.value = file.value.filename;
};
</script>

<template>
	<Dialog
		id="UploadFaxDialog"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-w-full tw3-max-w-[450px]"
		:dragable="false"
		:header="t('faxDialog.uploadFax.uploadFaxHeader')"
		modal
	>
		<div class="tw3-flex tw3-flex-col tw3-gap-3">
			<Button
				:label="t('faxDialog.uploadFax.chooseFile')"
				severity="secondary"
				size="small"
				type="button"
				@click="openFilePicker"
			/>
			<div v-if="file" class="tw3-flex tw3-items-center tw3-gap-4">
				<span class="tw3-font-semibold tw3-leading-none">{{
					t('faxDialog.uploadFax.yourFile')
				}}</span>
				<div class="tw3-flex tw3-items-center tw3-gap-3">
					<a
						class="tw3-font-semibold tw3-text-ellipsis tw3-max-w-60 tw3-whitespace-nowrap tw3-overflow-hidden"
						:href="file.url"
						target="_blank"
						>{{ file.filename }}</a
					>
					<div>{{ formatFileSize(file.size) }}</div>
				</div>
			</div>
			<InputMask
				v-model="faxNumber"
				mask="(999) 999-9999"
				:placeholder="t('faxDialog.uploadFax.enterFaxNumber')"
			/>

			<div class="tw3-flex tw3-gap-3">
				<Button
					class="tw3-w-full"
					:disabled="!file || !faxNumber"
					:label="t('faxDialog.uploadFax.sendFax.sendFax')"
					:loading="sendingIsInProcess"
					size="small"
					@click.once="sendFax"
				/>
				<Button
					class="tw3-w-full"
					:disabled="!file"
					:label="t('faxDialog.uploadFax.burstNewFax.burstFax')"
					:loading="burstIsInProcess"
					severity="warn"
					size="small"
					@click.once="burstFaxHandler()"
				/>
			</div>
		</div>
	</Dialog>
</template>
