import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Dialog from 'primevue/dialog';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import Tabs from 'primevue/tabs';

import { useI18n } from 'vue-i18n';

import ClaimsTable from '@/widgets/claimTable/ClaimsTable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InsuranceDialog',
  props: {
    "show": { type: Boolean },
    "showModifiers": {},
  },
  emits: ["update:show"],
  setup(__props) {

const { t } = useI18n();

const showModal = _useModel<boolean>(__props, 'show');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3InsuranceDialog",
    visible: showModal.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-max-w-[1400px]",
    header: `Glaxis ${_unref(t)('inbox')}`,
    modal: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Tabs), { value: "0" }, {
        default: _withCtx(() => [
          _createVNode(_unref(TabList), null, {
            default: _withCtx(() => [
              _createVNode(_unref(Tab), { value: "0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('claims')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(TabPanel), { value: "0" }, {
            default: _withCtx(() => [
              _createVNode(ClaimsTable)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})