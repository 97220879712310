<script setup lang="ts">
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Image from 'primevue/image';
import InputText from 'primevue/inputtext';
import { useI18n } from 'vue-i18n';

import { isEmptyValue } from '@/shared/helpers';
import {
	formatNumber,
	formatPrice,
	formatToOneDigit
} from '@/widgets/dashboard/lib';

import { techHeader } from '../../config/constants';
import { formatTableDate } from '../../lib';
import { useTechnicianStore } from '../../model/store';

import type {
	DataTablePageEvent,
	DataTableSortEvent
} from 'primevue/datatable';

const { t } = useI18n();

const store = useTechnicianStore();

const handlePage = (state: DataTablePageEvent) => {
	store.pagination.page = state.page + 1;
};
const handleSort = (state: DataTableSortEvent) => {
	store.pagination.page = 1;

	store.handleSort({
		desc: state.sortOrder === -1,
		field: state.sortField as string
	});
};
const openTech = (id: number) => {
	const newEvent = new CustomEvent('openVendor', { detail: { id } });
	window.dispatchEvent(newEvent);
};

const getFilterIconClass = (field: string) => {
	const filter = store.filters[field];

	return isEmptyValue(filter.value) || filter.value === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};
</script>

<template>
	<DataTable
		v-model:filters="store.filters"
		dataKey="id"
		filterDisplay="menu"
		lazy
		:loading="store.techs.isLoading"
		paginator
		:rows="store.pagination.pageSize"
		:rowsPerPageOptions="[10, 20, 50, 100]"
		scrollable
		:sortField="store.sort.field"
		:sortOrder="store.sort.desc === false ? 1 : -1"
		tableClass="tw3-max-w-full"
		:totalRecords="store.techs.state.totalCount"
		:value="store.techs.state.items"
		@page="handlePage"
		@sort="handleSort"
		@update:rows="store.handleRows"
	>
		<template #header>
			<div class="tw3-font-medium">{{ $t('technicians') }}</div>
		</template>

		<Column
			v-for="column of techHeader"
			:key="column.key"
			:field="column.key"
			:filterField="column.key"
			:header="column.title"
			headerClass="tw3-relative tw3-whitespace-nowrap"
			:showFilterMatchModes="false"
			:showFilterMenu="column.key === 'name'"
			:sortable="column.key !== 'avatar'"
		>
			<template #body="{ data }">
				<div
					class="cell-container"
					:class="{
						'tw3-justify-end':
							column.key === 'lifetimeRevenue' ||
							column.key === 'lifetimeJobs' ||
							column.key === 'warrantyJobs' ||
							column.key === 'totalCommissions' ||
							column.key === 'averageCommissionPerJob' ||
							column.key === 'costRatio' ||
							column.key === 'warrantyRate' ||
							column.key === 'jobCompletionPercentage',
						'tw3-justify-center':
							column.key === 'isDisabled' ||
							column.key === 'avatar' ||
							column.key === 'averageRating'
					}"
				>
					<div
						v-if="column.key === 'averageRating'"
						class="tw3-flex tw3-items-center tw3-gap-2"
					>
						<i class="pi pi-star-fill tw3-text-green-500"></i>
						<div class="tw3-text-lg tw3-font-medium">
							{{ formatToOneDigit(data[column.key]) }}
						</div>
					</div>
					<div
						v-else-if="column.key === 'avatar'"
						class="tw3-w-10 tw3-h-10 tw3-rounded-full tw3-flex tw3-items-center tw3-justify-center"
						:class="{ 'tw3-bg-gray-100': !data.color }"
						:style="{ background: data.color }"
					>
						<div
							class="tw3-w-8 tw3-h-8 tw3-rounded-full tw3-overflow-hidden tw3-flex tw3-items-center tw3-justify-center tw3-bg-gray-200"
						>
							<Image v-if="data.avatar" alt="Image" :src="data.avatar" />
							<span v-else class="tw3-text-base tw3-text-gray-600">
								{{ !data.name ? '' : data.name.slice(0, 2).toUpperCase() }}
							</span>
						</div>
					</div>
					<div
						v-else-if="column.key === 'isDisabled'"
						class="tw3-rounded tw3-text-white tw3-text-sm tw3-leading-3 tw3-capitalize tw3-shadow-md tw3-p-2"
						:class="data[column.key] ? 'tw3-bg-gray-500' : 'tw3-bg-green-500'"
					>
						{{ data[column.key] ? $t('inactive') : $t('active') }}
					</div>
					<a
						v-else-if="column.key === 'name'"
						href="#"
						@click="openTech(data.id)"
					>
						{{ data[column.key] }}
					</a>
					<template
						v-else-if="
							column.key === 'warrantyRate' ||
							column.key === 'costRatio' ||
							column.key === 'jobCompletionPercentage'
						"
					>
						{{ formatToOneDigit(data[column.key] * 100).toFixed(1) + '%' }}
					</template>
					<template v-else-if="column.key === 'firstJobDate'">
						{{ data[column.key] ? formatTableDate(data[column.key]) : '-' }}
					</template>
					<template v-else>
						{{
							column.key === 'lifetimeRevenue' ||
							column.key === 'totalCommissions' ||
							column.key === 'averageCommissionPerJob'
								? formatPrice(formatNumber(data[column.key]))
								: formatNumber(data[column.key])
						}}
					</template>
				</div>
			</template>
			<template #filtericon>
				<i :class="getFilterIconClass(column.key)"></i>
			</template>
			<template #filter="{ filterModel }">
				<InputText
					v-if="column.key === 'name'"
					v-model="filterModel.value"
					allowEmpty
					class="p-column-filter"
					:placeholder="`Search by ${column.title}`"
					type="text"
				/>
			</template>
		</Column>

		<template v-if="!store.techs.isLoading" #empty>
			<div
				class="tw3-w-full tw3-h-[600px] tw3-flex tw3-justify-center tw3-items-center"
			>
				<p class="tw3-text-center tw3-text-3xl">{{ t('noData') }}</p>
			</div>
		</template>
	</DataTable>
</template>
