<script setup lang="ts">
import Breadcrumb from 'primevue/breadcrumb';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import { ref } from 'vue';

import PageCard from '@/shared/ui/container/PageCard.vue';
import TemplateList from '@/widgets/accounting/accountTemplates/TemplateList.vue';
import PaymentSettings from '@/widgets/accounting/settingsTemplates/PaymentSettings.vue';

const title = ref([{ label: 'Starting Accounting Settings', disabled: true }]);
</script>

<template>
	<PageCard>
		<template #title>
			<div class="tw3-flex tw3-justify-between">
				<div class="tw3-flex tw3-gap-2 tw3-items-center">
					<Breadcrumb :model="title" pt:root:class="!tw3-p-0 !tw3-ml-3">
						<template #item="{ item }">
							<span class="!tw3-text-xl !tw3-font-bold tw3-text-black">{{
								item.label
							}}</span>
						</template>
					</Breadcrumb>
				</div>
			</div>
		</template>
		<template #content>
			<Tabs value="0">
				<TabList>
					<Tab value="0"> Chart of Accounts </Tab>
					<Tab value="1"> Payment Settings </Tab>
				</TabList>
				<TabPanels>
					<TabPanel value="0">
						<TemplateList></TemplateList>
					</TabPanel>
					<TabPanel value="1">
						<PaymentSettings></PaymentSettings>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</template>
	</PageCard>
	<!-- <CreateProfileDialog v-model="showCreateProfileDialog"></CreateProfileDialog> -->
</template>
