import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Button from 'primevue/button';


export default /*@__PURE__*/_defineComponent({
  __name: 'RefreshButton',
  props: {
	tooltip: {
		type: String,
		default: 'Refresh'
	}
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_unref(Button), {
    icon: "pi pi-refresh",
    severity: "secondary",
    size: "small"
  }, null, 512)), [
    [
      _directive_tooltip,
      __props.tooltip,
      void 0,
      { top: true }
    ]
  ])
}
}

})