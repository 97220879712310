import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, isRef as _isRef, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-items-center" }
const _hoisted_2 = { class: "tw3-grid tw3-grid-cols-1 tw3-gap-4" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-gap-1" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "tw3-flex tw3-flex-col tw3-gap-1" }
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "tw3-flex tw3-flex-col tw3-gap-1" }
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "tw3-flex tw3-flex-col tw3-gap-1" }
const _hoisted_10 = ["for"]
const _hoisted_11 = { class: "tw3-flex tw3-flex-col tw3-gap-1" }
const _hoisted_12 = ["for"]
const _hoisted_13 = { class: "tw3-flex tw3-flex-col tw3-gap-1" }
const _hoisted_14 = ["for"]
const _hoisted_15 = { class: "tw3-flex tw3-justify-end tw3-mt-5" }

import { toTypedSchema } from '@vee-validate/zod';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import SelectButton from 'primevue/selectbutton';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import { InvalidSubmissionHandler, useForm } from 'vee-validate';
import { computed, nextTick, ref, watch } from 'vue';
import { z } from 'zod';

import {
	Account,
	AccountStatus
} from '@/entities/accounting/accounts/lib/types';
import useAccountingStore from '@/features/accounting/lib/store';
import { useMessages } from '@/shared/composables';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useAccountsStore from './lib/store';

const cid = 'accounting-account-edit';


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountEdit',
  props: {
    "visible": { type: Boolean },
    "visibleModifiers": {},
    "account": { required: true },
    "accountModifiers": {},
  },
  emits: ["update:visible", "update:account"],
  setup(__props) {

const { showFieldValidationError } = useMessages();

const store = useAccountsStore();

const accountingStore = useAccountingStore();

const {
	statuses,
	accounts,
	typesById,
	subtypesById,
	groupedSubtypes,
	selectedProfileId
} = storeToRefs(accountingStore);

const visible = _useModel<boolean>(__props, 'visible');

const saving = ref(false);

const account = _useModel<Account>(__props, 'account');

// Returns true if the account has a parent with the given id
const hasParent = (account: Account, parentAccountId: number | undefined) => {
	if (account.id === parentAccountId) {
		return true;
	}
	if (account.parentAccountId == null) {
		return false;
	}
	const parent = accounts.value.find(a => a.id === account.parentAccountId);
	if (parent == null) {
		return false;
	}
	return hasParent(parent, parentAccountId);
};

const parentAccounts = computed(() => {
	if (subtypeId.value == null) {
		return [];
	}
	return [
		{
			id: undefined,
			name: 'No parent account'
		},
		...accounts.value.filter(
			a =>
				// Parent account must have the same subtype
				a.subtypeId == subtypeId.value &&
				// If we editing existings account - filter out the account itself and its children
				(!editMode.value || !hasParent(a, account.value.id))
		)
	];
});

const editMode = computed(() => !!account.value.id); //TODO: use null instead of 0 for id ???

const { defineField, handleSubmit, resetForm, errors, meta } = useForm<Account>(
	{
		validationSchema: toTypedSchema(
			z.object({
				id: z.optional(z.number().nullable()),
				organizationId: z.number().min(1, 'required'),
				name: z
					.string()
					.trim()
					.min(1, 'required')
					.refine(value => {
						return !accounts.value.some(
							a =>
								a.name.toLowerCase() === value.toLowerCase() &&
								a.id !== account.value.id
						);
					}, 'Account name must be unique'),
				subtypeId: z.number().min(1, 'required'),
				parentAccountId: z.optional(z.number()),
				description: z.string().trim(),
				code: z
					.string()
					.trim()
					.refine(value => {
						return (
							value == '' ||
							!accounts.value.some(
								a =>
									a.code.toLowerCase() === value.toLowerCase() &&
									a.id !== account.value.id
							)
						);
					}, 'Account number must be unique'),
				status: z.string()
			})
		),
		initialValues: {
			organizationId: 0,
			name: '',
			code: '',
			typeId: 0,
			subtypeId: 0,
			description: '',
			parentAccountId: undefined,
			status: AccountStatus.ACCOUNT_STATUS_ACTIVE,
			balance: 0
		}
	}
);

const fieldLabels: Record<string, string> = {
	name: 'Account Name',
	subtypeId: 'Account Type',
	parentAccountId: 'Parent Account',
	description: 'Account Description',
	code: 'Account Number',
	status: 'Status'
};

const [name] = defineField('name');
const [subtypeId] = defineField('subtypeId');
const [parentAccountId] = defineField('parentAccountId');
const [description] = defineField('description');
const [code] = defineField('code');
const [status] = defineField('status');

const nameInput = ref<typeof InputText>();

watch(visible, () => {
	if (visible.value) {
		resetForm({
			values: {
				...account.value,
				organizationId: selectedProfileId.value,
				parentAccountId: undefined
			}
		});
		nextTick(() => {
			// Focus the first input
			nameInput.value?.$el.focus();
		});
	}
});

const close = () => {
	visible.value = false;
};

const onValidationError: InvalidSubmissionHandler = ({ errors }) => {
	for (const [field, error] of Object.entries(errors)) {
		showFieldValidationError(fieldLabels[field], error!);
	}
};

const doSave = async (values: Account) => {
	saving.value = true;
	await store.saveAccount(values);
	await accountingStore.loadAccounts();
	saving.value = false;
};

const save = handleSubmit(async (values: Account) => {
	await doSave(values);
	close();
}, onValidationError);

const getSubtypeFullName = (subtypeId: string) => {
	const subtype = subtypesById.value[subtypeId];
	const type = typesById.value[subtype.accountTypeId];
	return `${type.name}: ${subtype.name}`;
};

const errorTooltipPT = {
	text: {
		style: { backgroundColor: 'rgb(248 113 113)' }
	},
	arrow: {
		style: { borderTopColor: 'rgb(248 113 113)' }
	}
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(Dialog), {
    visible: visible.value,
    "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((visible).value = $event)),
    appendTo: "#vue3app",
    modal: "",
    style: { width: '35rem' }
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(editMode.value ? 'Edit Account' : 'New Account') + " ", 1),
        (account.value.system)
          ? (_openBlock(), _createBlock(_unref(Tag), {
              key: 0,
              pt: {
						label: { class: 'tw3-font-normal' },
						root: { class: 'tw3-ml-3' }
					},
              severity: "info",
              value: "System"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-name`
          }, _toDisplayString(fieldLabels.name), 9, _hoisted_4),
          _withDirectives(_createVNode(_unref(InputText), {
            id: `${cid}-name`,
            ref_key: "nameInput",
            ref: nameInput,
            modelValue: _unref(name),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(name) ? (name).value = $event : null)),
            disabled: account.value.system,
            invalid: !!_unref(errors).name,
            type: "text"
          }, null, 8, ["id", "modelValue", "disabled", "invalid"]), [
            [
              _directive_tooltip,
              {
						value: _unref(errors).name,
						pt: errorTooltipPT
					},
              void 0,
              { top: true }
            ]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-subtype`
          }, _toDisplayString(fieldLabels.subtypeId), 9, _hoisted_6),
          _createVNode(_unref(Select), {
            id: `${cid}-subtype`,
            modelValue: _unref(subtypeId),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(subtypeId) ? (subtypeId).value = $event : null)),
            class: "w-full md:w-56",
            disabled: editMode.value || account.value.system,
            optionGroupChildren: "items",
            optionGroupLabel: "name",
            optionLabel: "name",
            options: _unref(groupedSubtypes),
            optionValue: "id",
            placeholder: "Account Type",
            pt: {
						optionGroup: {
							style: {
								color: 'black'
							}
						},
						option: {
							style: {
								paddingLeft: '24px'
							}
						}
					}
          }, {
            value: _withCtx(({ placeholder, value }) => [
              value
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(getSubtypeFullName(value)), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(placeholder), 1)
                  ], 64))
            ]),
            _: 1
          }, 8, ["id", "modelValue", "disabled", "options"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-parent-account-id`
          }, _toDisplayString(fieldLabels.parentAccountId), 9, _hoisted_8),
          _createVNode(AutoCompleteSelect, {
            id: `${cid}-parent-account-id`,
            modelValue: _unref(parentAccountId),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(parentAccountId) ? (parentAccountId).value = $event : null)),
            class: "w-full md:w-56",
            disabled: account.value.system,
            options: parentAccounts.value,
            optionValue: "id",
            placeholder: "No parent account"
          }, null, 8, ["id", "modelValue", "disabled", "options"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-description`
          }, _toDisplayString(fieldLabels.description), 9, _hoisted_10),
          _createVNode(_unref(Textarea), {
            id: `${cid}-description`,
            modelValue: _unref(description),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(description) ? (description).value = $event : null))
          }, null, 8, ["id", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-code`
          }, _toDisplayString(fieldLabels.code), 9, _hoisted_12),
          _withDirectives(_createVNode(_unref(InputText), {
            id: `${cid}-code`,
            modelValue: _unref(code),
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(code) ? (code).value = $event : null)),
            invalid: !!_unref(errors).code,
            type: "text"
          }, null, 8, ["id", "modelValue", "invalid"]), [
            [
              _directive_tooltip,
              {
						value: _unref(errors).code,
						pt: errorTooltipPT
					},
              void 0,
              { top: true }
            ]
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-status`
          }, "Status", 8, _hoisted_14),
          _createVNode(_unref(SelectButton), {
            modelValue: _unref(status),
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(status) ? (status).value = $event : null)),
            allowEmpty: false,
            disabled: account.value.system,
            optionLabel: "name",
            options: _unref(statuses),
            optionValue: "id"
          }, null, 8, ["modelValue", "disabled", "options"])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_unref(Button), {
          class: "tw3-ml-5",
          disabled: saving.value,
          label: 'Cancel',
          severity: "secondary",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (close()))
        }, null, 8, ["disabled"]),
        _createVNode(_unref(Button), {
          class: "tw3-ml-5",
          disabled: saving.value || !_unref(meta).valid,
          label: 'Save',
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(save)()))
        }, null, 8, ["disabled"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})