<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Card from 'primevue/card';
import { computed } from 'vue';

import { useUserStore } from '@/entities/user/lib/store';

const { user } = storeToRefs(useUserStore());

const blocked = computed(() => {
	return !user.value.user.admin;
});
</script>

<template>
	<div class="gb-flex-container tw3-grow">
		<router-view v-if="!blocked" />
		<Card v-else>
			<template #title>Accounting</template>
			<template #content>
				<p class="m-0">Accounting disabled</p>
			</template>
		</Card>
	</div>
</template>
