import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cell-container" }
const _hoisted_2 = { class: "tw3-flex" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-items-center tw3-justify-center tw3-border-b-0" }

import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import ToggleSwitch from 'primevue/toggleswitch';
import { useFieldArray, useForm } from 'vee-validate';
import { nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { CustomerName } from '@/entities/customer/lib/types';
import { InputTextWithError } from '@/shared/ui';

const ZOD_NAMES = 'names';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactInfoNames',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { t } = useI18n();

const store = useCustomerEditStore();

const validationSchema = toTypedSchema(
	zod.object({
		[ZOD_NAMES]: zod.array(
			zod.object({
				first: zod.string({ message: t('required') }).min(1, t('required')),
				last: zod.string().nullable(),
				middle: zod.string().nullable()
			})
		)
	})
);

const { errors, setValues } = useForm({
	initialValues: { [ZOD_NAMES]: props.modelValue },
	validationSchema
});

const { fields, update } = useFieldArray<Omit<CustomerName, 'id'>>(ZOD_NAMES);

const addName = () => {
	emit('update:modelValue', [
		...props.modelValue,
		{ first: null, last: null, middle: null } as CustomerName
	]);
	if (props.modelValue.length) return;
	store.primaryNameIndex = 0;
};

const updateVal = async (
	index: number,
	field: keyof CustomerName,
	val: string | null
) => {
	const input = document.getElementById(
		`names${field}${index}`
	) as HTMLInputElement;
	const cursorPosition = input.selectionStart;
	const newVal = val
		? val
				.split(' ')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ')
		: val;
	emit(
		'update:modelValue',
		props.modelValue.map((v, vIndex) => {
			if (vIndex === index) v[field] = newVal as never;
			return v;
		})
	);
	update(index, { ...fields.value[index].value, [field]: newVal });
	await nextTick();
	input.setSelectionRange(cursorPosition, cursorPosition);
};

const removeVal = (index: number) => {
	emit(
		'update:modelValue',
		props.modelValue.filter((_, i) => i !== index)
	);
	if (store.primaryNameIndex < index) return;
	if (store.primaryNameIndex === index) {
		store.primaryNameIndex = 0;
	} else if (store.primaryNameIndex > index) {
		store.primaryNameIndex -= 1;
	}
};

watch(
	() => props.modelValue,
	() => {
		setValues({ [ZOD_NAMES]: props.modelValue });
	}
);

watch(
	() => errors.value,
	() => store.updateErrors('names', errors.value)
);

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(DataTable), {
    class: "ui-data-table",
    size: "small",
    stripedRows: "",
    tableStyle: "tw3-w-full",
    value: _unref(fields)
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Column), {
        field: "first",
        header: "First name",
        pt: {
				headerCell: {
					style: {
						width: '33.3%'
					}
				}
			}
      }, {
        body: _withCtx(({ index }) => [
          _createVNode(_unref(InputTextWithError), {
            id: `namesfirst${index}`,
            class: "tw3-w-full tw3-max-w-64",
            errMsg: _unref(errors)[`names[${index}].first` as any],
            modelValue: _unref(fields)[index].value.first,
            placeholder: "First Name",
            "onUpdate:modelValue": v => updateVal(index, 'first', v)
          }, null, 8, ["id", "errMsg", "modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), {
        field: "last",
        header: "Last name",
        pt: {
				headerCell: {
					style: {
						width: '33.3%'
					}
				}
			}
      }, {
        body: _withCtx(({ index }) => [
          _createVNode(_unref(InputTextWithError), {
            id: `nameslast${index}`,
            class: "tw3-w-full tw3-max-w-64",
            errMsg: _unref(errors)[`names[${index}].last` as any],
            modelValue: _unref(fields)[index].value.last,
            placeholder: "Last Name",
            "onUpdate:modelValue": v => updateVal(index, 'last', v)
          }, null, 8, ["id", "errMsg", "modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), {
        field: "primary",
        header: "Primary",
        style: {"width":"2.25rem"}
      }, {
        body: _withCtx(({ index }) => [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createVNode(_unref(ToggleSwitch), {
              modelValue: _unref(store).primaryNameIndex === index,
              name: "dynamic",
              style: _normalizeStyle(_unref(fields).length === 1 && 'opacity: 0.6'),
              "onUpdate:modelValue": 
							val =>
								val &&
								index !== _unref(store).primaryNameIndex &&
								(_unref(store).primaryNameIndex = index)
						
            }, null, 8, ["modelValue", "style", "onUpdate:modelValue"]), [
              [
                _directive_tooltip,
                'Set to primary',
                void 0,
                { top: true }
              ]
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), {
        field: "actions",
        header: "",
        style: {"width":"5%"}
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(Button), {
              "aria-label": "Settings",
              icon: "pi pi-plus",
              rounded: "",
              size: "small",
              text: "",
              type: "button",
              onClick: addName
            })
          ])
        ]),
        body: _withCtx(({ index }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(Button), {
              "aria-label": "Delete",
              class: "tw3-w-8 tw3-h-8",
              icon: "pi pi-trash",
              rounded: "",
              severity: "danger",
              text: "",
              onClick: ($event: any) => (removeVal(index))
            }, null, 8, ["onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value"]))
}
}

})