import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "gb-flex-container tw3-grow" }
const _hoisted_2 = {
  key: 1,
  class: "tw3-flex tw3-flex-grow tw3-items-center"
}

import { storeToRefs } from 'pinia';
import Card from 'primevue/card';
import ProgressSpinner from 'primevue/progressspinner';

import useAccountingStore from '@/features/accounting/lib/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'WrapperPage',
  setup(__props) {

const accountingStore = useAccountingStore();

const { profiles, profilesLoaded } = storeToRefs(accountingStore);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(profilesLoaded))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_unref(profiles).length)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
            : (_openBlock(), _createBlock(_unref(Card), { key: 1 }, {
                title: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Accounting")
                ])),
                content: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("p", { class: "m-0" }, "Accounting disabled", -1)
                ])),
                _: 1
              }))
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(ProgressSpinner))
        ]))
  ]))
}
}

})