import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';

import { getConfigDetails } from './api';

const useAppStore = defineStore('app', () => {
	const appConfig = useAsyncState(
		() => {
			return getConfigDetails();
		},
		{ filePickerKey: '' },
		{
			immediate: true,
			shallow: false
		}
	);
	return { appConfig };
});

export default useAppStore;
