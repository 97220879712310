export type FaxTabPanels = {
	key: string;
	title: string;
	count?: number;
};

export type FaxTableHeaders = {
	key: string;
	title: string;
	sortable?: boolean;
};

export type InboxFilters = {
	dateTime: string;
	sentFrom: string;
	sentTo: string;
	pages: string;
	status: string;
	referral: string;
};

export type OutboxFilters = {
	dateTime: string;
	fileName: string;
	sentFrom: string;
	sentTo: string;
	pages: string;
	status: string;
};

export type BurstFilters = {
	fileName: string;
	dateTime: string;
};

export type FaxFiltersWithMetaData<T> = {
	[K in keyof T]: {
		value: string | boolean | null;
		matchMode: string;
	};
};

export type FaxInboxAPI = {
	faxId: number;
	dateTime: string;
	sentFrom: string;
	sentTo: string;
	pages: number;
	referralNotes: string | null;
	status: string;
	url: string;
	filename: string;
};

export type FaxInbox = {
	faxId: number;
	dateTime: string;
	sentFrom: string;
	sentTo: string;
	pages: number;
	referral: string | null;
	status: string;
	url: string;
	fileName: string;
};

export type FaxOutboxAPI = {
	faxsentId: number;
	date: string;
	file: string;
	fileurl: string;
	sender: number;
	receiver: string;
	pages: number;
	status: string;
	jobid: number;
};

export type FaxOutbox = {
	faxId: number;
	dateTime: string;
	sentFrom: string;
	sentTo: string;
	pages: number;
	status: string;
	url: string;
	fileName: string;
	jobid: number;
};

export type BurstAPI = {
	burstId: number;
	fileName: string;
	page: number;
	displayName: string;
	dateTime: string;
	url: string;
};

export type Burst = {
	faxId: number;
	fileName: string;
	url: string;
	page: number;
	dateTime: string;
};

export type FaxInboxRequestParams = {
	page: number;
	limit: number;
	sortBy: string;
	sortDesc: boolean;
	inboxDateTimeFilter?: string | boolean;
	inboxSentFromFilter?: string | boolean;
	inboxSentToFilter?: string | boolean;
	inboxPagesFilter?: string | boolean;
	inboxReferralNotesFilter?: string | boolean;
	inboxStatusFilter?: string | boolean;
};

export type FaxOutboxRequestParams = {
	page: number;
	limit: number;
	sortBy: string;
	sortDesc: boolean;
	outboxDateTimeFilter?: string | boolean;
	outboxFileFilter?: string | boolean;
	outboxSenderFilter?: string | boolean;
	outboxReceiverFilter?: string | boolean;
	outboxPagesFilter?: string | boolean;
	outboxStatusFilter?: string | boolean;
};

export type updateFaxReferralRequestParams = {
	faxId: number;
	referral: number;
};

export type BurstTableRequestParams = {
	page: number;
	limit: number;
	sortBy: string;
	sortDesc: boolean;
	burstDateTimeFilter?: string | boolean;
	burstReferralNotesFilter?: string | boolean;
	burstStatusFilter?: string | boolean;
};

export type FaxTableResponseAPI<T> = {
	rows: T[];
	numberOfRowsFound: number;
};

export type FaxTableResponse<T> = {
	data: T[];
	totalCount: number;
};

export enum FaxStatus {
	UNREAD = 'UNREAD',
	READ = 'READ',
	SENT = 'SENT',
	ERROR = 'ERROR',
	SENDING = 'SENDING',
	QUEUED = 'QUEUED',
	BURST = 'BURST'
}

export type BurstFaxesResponse = {
	status: string;
};

export type QuickJobSearchResponse = {
	id: number;
	customerFullName: string;
	internal_number: string;
};

export type AttachFaxRequest = {
	created: number;
	document_name: string;
	document_type: string;
	id: number;
	modified: number;
	jobId: number;
	shopId: number;
	url: string;
};

export type AttachFaxResponse = {
	id: number;
	created: number;
	document_name: string;
	document_type: string;
	modified: number;
	url: string;
	job_id: number;
};

export type BurstLocalFaxRequest = {
	burstName: string;
	burstUrl: string;
};
