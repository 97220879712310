<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Textarea from 'primevue/textarea';
import { useConfirm } from 'primevue/useconfirm';
import { computed, nextTick, ref, watch } from 'vue';

import {
	ACCOUNT_TYPE_ASSETS_ID,
	ACCOUNT_TYPE_EQUITIES_ID,
	ACCOUNT_TYPE_EXPENSE_ID,
	ACCOUNT_TYPE_LIABILITIES_ID
} from '@/entities/accounting/accounts/lib/types';
import AccountSelect from '@/features/accounting/AccountSelect.vue';
import CheatSheet from '@/features/accounting/CheatSheet.vue';
import ShopSelect from '@/features/accounting/ShopSelect.vue';
import { formatMoney } from '@/shared/helpers/formatters';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';

import { useExpenseForm } from '../model/expenseEdit';
import { useVendorStore } from '../model/vendorStore';

const vendorStore = useVendorStore();
const { vendors } = storeToRefs(vendorStore);

const cid = 'accounting-expense-edit';

const props = withDefaults(
	defineProps<{
		expenseId: number | null;
	}>(),
	{
		expenseId: undefined
	}
);

const emit = defineEmits<{
	changed: [];
}>();

const visible = defineModel<boolean>('visible');

const editMode = computed(() => props.expenseId != null);

const {
	load,
	valid,
	transactionDt,
	description,
	referenceNumber,
	shopId,
	vendorId,
	paidFromAccountId,
	items,
	itemsErrors,
	addItem,
	removeItem,
	amountTotal,
	save: doSave,
	reset,
	remove,
	fieldLabels,
	loading,
	errors
} = useExpenseForm();

const shopSelect = ref<typeof InputText>();

watch(
	visible,
	() => {
		if (visible.value) {
			if (props.expenseId != null) {
				load(props.expenseId);
			} else {
				reset();
			}
			nextTick(() => {
				// Focus the first input
				shopSelect.value?.$el.focus();
			});
		}
	},
	{ immediate: true }
);

const close = () => {
	visible.value = false;
};

const errorTooltipPT = {
	text: {
		style: { backgroundColor: 'rgb(248 113 113)' }
	},
	arrow: {
		style: { borderTopColor: 'rgb(248 113 113)' }
	}
};

const save = () => {
	doSave(async () => {
		emit('changed');
		close();
	});
};

const saveAndNew = () => {
	// TODO: rework callback to promise
	doSave(async () => {
		reset();
		shopSelect.value?.$el.focus();
		emit('changed');
	});
};

const title = computed(() => {
	if (loading.value) {
		return 'Loading...';
	}
	if (editMode.value) {
		return `Expense #${props.expenseId}`;
	}
	return 'New Expense';
});

const confirm = useConfirm();

const removeExpense = () => {
	confirm.require({
		message: 'Do you want to delete this transaction?',
		header: 'Delete Transaction',
		icon: 'pi pi-info-circle',
		rejectLabel: 'Cancel',
		rejectProps: {
			label: 'Cancel',
			severity: 'secondary',
			outlined: true
		},
		acceptProps: {
			label: 'Delete',
			severity: 'danger'
		},
		accept: async () => {
			await remove();
			emit('changed');
			close();
		},
		reject: () => {}
	});
};
</script>

<template>
	<Dialog
		v-model:visible="visible"
		appendTo="#vue3app"
		modal
		:style="{ width: '60rem' }"
	>
		<template #header>
			<span class="p-dialog-title">
				{{ title }}
			</span>
			<div class="tw3-flex-grow"></div>
			<CheatSheet rounded></CheatSheet>
		</template>
		<div class="tw3-grid tw3-grid-cols-1 tw3-gap-4">
			<div class="tw3-flex tw3-flex-row tw3-gap-4">
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-shop-id`">
						{{ fieldLabels.shopId }}
					</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<ShopSelect
						v-else
						:id="`${cid}-shop-id`"
						ref="shopSelect"
						v-model="shopId"
						:loading="loading"
					/>
				</div>
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-transaction-dt`">
						{{ fieldLabels.transactionDt }}
					</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<DatePicker
						v-else
						:id="`${cid}-transaction-dt`"
						v-model="transactionDt"
					/>
				</div>
			</div>

			<div class="tw3-flex tw3-flex-col tw3-gap-0">
				<label class="tw3-pl-1" :for="`${cid}-description`">{{
					fieldLabels.description
				}}</label>
				<Skeleton v-if="loading" height="3.4rem" />
				<Textarea v-else :id="`${cid}-description`" v-model="description" />
			</div>

			<div class="tw3-flex tw3-flex-row tw3-gap-4">
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-account-id`">
						{{ fieldLabels.paidFromAccountId }}
					</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<AccountSelect
						v-else
						:id="`${cid}-account-id`"
						v-model="paidFromAccountId"
						:invalid="!!errors.paidFromAccountId"
						:loading="loading"
						:types="[
							ACCOUNT_TYPE_ASSETS_ID,
							ACCOUNT_TYPE_LIABILITIES_ID,
							ACCOUNT_TYPE_EQUITIES_ID
						]"
					/>
				</div>
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-organization-id`">
						{{ fieldLabels.vendorId }}
					</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<AutoCompleteSelect
						v-else
						:id="`${cid}-organization-id`"
						v-model="vendorId"
						clearable
						fluid
						:options="vendors"
						optionValue="id"
						@search="vendorStore.search"
					/>
				</div>
			</div>

			<div class="tw3-flex tw3-flex-row tw3-gap-4">
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-reference-number-id`">
						{{ fieldLabels.referenceNumber }}
					</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<InputText
						v-else
						:id="`${cid}-reference-number-id`"
						v-model="referenceNumber"
					/>
				</div>
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1"></div>
			</div>

			<div class="tw3-flex tw3-flex-row tw3-gap-4">
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<DataTable
						:pt="{
							column: {
								headerCell: {
									style: 'padding: 0px 0.8rem 0px 0px; border: none'
								},
								bodyCell: {
									style: 'padding: 0px 0.8rem 0.2rem 0px; border: none'
								},
								footerCell: {
									style: 'padding: 0px 0.8rem 0px 0px; border: none'
								}
							}
						}"
						scrollable
						scrollHeight="flex"
						size="small"
						tableClass="tw3-max-w-full"
						:value="loading ? new Array(2) : items"
					>
						<Column field="account" style="width: 60%">
							<template #header>
								<div class="gb-label tw3-pl-1">Account</div>
							</template>
							<template #body="{ data, index }">
								<Skeleton v-if="loading" height="2.2rem" />
								<div
									v-else
									v-tooltip.top="{
										value: itemsErrors[index].accountId,
										pt: errorTooltipPT
									}"
								>
									<AccountSelect
										v-model="data.value.accountId"
										:invalid="!!itemsErrors[index].accountId"
										:types="[ACCOUNT_TYPE_EXPENSE_ID]"
									/>
								</div>
							</template>
						</Column>
						<Column field="amount" style="width: 40%">
							<template #header>
								<div class="gb-label tw3-pl-1">Amount $</div>
							</template>
							<template #body="{ data, index }">
								<Skeleton v-if="loading" height="2.2rem" />
								<InputMoney
									v-else
									v-model="data.value.amount"
									v-tooltip.top="{
										value: itemsErrors[index].amount,
										pt: errorTooltipPT
									}"
									:invalid="!!itemsErrors[index].amount"
								/>
							</template>
						</Column>
						<Column
							field="actions"
							:pt="{
								headerCell: { class: '!tw3-p-0' },
								bodyCell: { class: '!tw3-p-0' }
							}"
							style="max-width: 48px"
						>
							<template #body="{ index }">
								<Button
									:disabled="loading || items.length <= 1"
									icon="pi pi-trash"
									rounded
									text
									@click="removeItem(index)"
								/>
							</template>
							<template #footer>
								<Button
									v-tooltip.top="'Add split account'"
									class="tw3-mr-2"
									icon="pi pi-plus"
									rounded
									text
									@click="addItem"
								/>
							</template>
						</Column>
					</DataTable>
				</div>
				<div
					class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1 tw3-py-4"
				>
					<Skeleton v-if="loading" height="2.2rem" />
					<div
						v-else
						class="tw3-font-bold tw3-text-right"
						style="padding-right: var(--p-inputtext-padding-x)"
					>
						<span class="tw3-mr-3">Total:</span> {{ formatMoney(amountTotal) }}
					</div>
				</div>
			</div>
		</div>
		<div class="tw3-flex tw3-justify-between tw3-mt-5">
			<div class="tw3-flex tw3-justify-start">
				<Button
					v-if="editMode"
					:label="'Delete'"
					severity="danger"
					@click="removeExpense"
				/>
			</div>
			<div class="tw3-flex tw3-justify-end">
				<Button
					class="tw3-ml-5"
					:label="'Cancel'"
					severity="secondary"
					@click="close()"
				/>
				<Button
					class="tw3-ml-5"
					:disabled="loading || !valid"
					:label="'Save'"
					@click="save"
				/>
				<Button
					v-if="!editMode"
					class="tw3-ml-5"
					:disabled="loading || !valid"
					:label="'Save and New'"
					@click="saveAndNew"
				/>
			</div>
		</div>
	</Dialog>
</template>

<style scoped></style>
