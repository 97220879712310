import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import {
	createAccount as createAccountApi,
	updateAccount as updateAccountApi
} from '@/entities/accounting/accounts/lib/api';
import {
	Account,
	CreateAccountRequest,
	UpdateAccountRequest
} from '@/entities/accounting/accounts/lib/types';
import { useMessages } from '@/shared/composables';

const useAccountsStore = defineStore('widgetsAccountingAccounts', () => {
	const { showError, showInfo } = useMessages();

	const loadingCounter = ref(0);
	const loading = computed(() => {
		return loadingCounter.value > 0;
	});

	const saveAccount = async (params: Account) => {
		loadingCounter.value++;
		try {
			if (params.id) {
				const request: UpdateAccountRequest = {
					id: params.id,
					name: params.name,
					description: params.description,
					code: params.code,
					status: params.status,
					parentAccountId: params.parentAccountId
				};
				await updateAccountApi(request);
			} else {
				const request: CreateAccountRequest = {
					organizationId: params.organizationId,
					subtypeId: params.subtypeId,
					name: params.name,
					description: params.description,
					code: params.code,
					status: params.status,
					parentAccountId: params.parentAccountId
				};
				await createAccountApi(request);
			}
			showInfo('Account saved');
		} catch (error) {
			showError(error);
		} finally {
			loadingCounter.value--;
		}
	};

	return {
		loading,
		saveAccount
	};
});

export default useAccountsStore;
