import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import TransactionsByAccount from '@/widgets/accounting/reports/TransactionsByAccount.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionsByAccountPage',
  props: {
    id: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TransactionsByAccount, { id: _ctx.id }, null, 8, ["id"]))
}
}

})