<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Textarea from 'primevue/textarea';
import { useConfirm } from 'primevue/useconfirm';
import { computed } from 'vue';

import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import useTransactionTypesStore from '@/entities/accounting/transactions/lib/typeStore';
import CheatSheet from '@/features/accounting/CheatSheet.vue';
import useAccountingStore from '@/features/accounting/lib/store';
import { formatDate, formatMoney } from '@/shared/helpers/formatters';

const cid = 'accounting-transaction-details';

const { typesById } = storeToRefs(useTransactionTypesStore());
const { accounts, shopsById } = storeToRefs(useAccountingStore());

const transactionStore = useTransactionStore();
const { selectedTransaction } = storeToRefs(transactionStore);

const transaction = computed(() => selectedTransaction.value!);

const confirm = useConfirm();

const emit = defineEmits<{
	edit: [id: number];
	deleted: [];
}>();

withDefaults(
	defineProps<{
		allowEdit?: number[];
	}>(),
	{
		allowEdit: () => []
	}
);

const visible = defineModel<boolean>('visible');

const edit = () => {
	emit('edit', transaction.value.id!);
};

const remove = () => {
	confirm.require({
		message: 'Do you want to delete this record?',
		header: 'Delete Transaction',
		icon: 'pi pi-info-circle',
		rejectLabel: 'Cancel',
		rejectProps: {
			label: 'Cancel',
			severity: 'secondary',
			outlined: true
		},
		acceptProps: {
			label: 'Delete',
			severity: 'danger'
		},
		accept: () => {
			transactionStore.deleteSelectedTransaction();
			visible.value = false;
			emit('deleted');
		},
		reject: () => {}
	});
};

const cancel = () => {
	visible.value = false;
};

const loading = computed(
	() => !selectedTransaction.value || !shopsById.value || !typesById.value
);
</script>

<template>
	<Dialog
		v-model:visible="visible"
		appendTo="#vue3app"
		modal
		:style="{ width: '60rem' }"
	>
		<template #header>
			<span class="p-dialog-title">
				{{
					loading
						? 'Loading...'
						: `${typesById[transaction.typeId!]?.name} #${transaction?.id}`
				}}
			</span>
			<div class="tw3-flex-grow"></div>
			<CheatSheet rounded></CheatSheet>
		</template>
		<div class="tw3-grid tw3-grid-cols-1 tw3-gap-4">
			<div class="tw3-flex tw3-flex-row tw3-gap-2">
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-shop-id`">Shop</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<InputText
						v-else
						:id="`${cid}-shop-id`"
						v-model="shopsById[transaction.shopId!].name"
						disabled
						type="text"
					/>
				</div>
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-transaction-dt`">Date</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<InputText
						v-else
						:id="`${cid}-transaction-dt`"
						disabled
						type="text"
						:value="formatDate(transaction.transactionDt)"
					/>
				</div>
			</div>

			<div class="tw3-flex tw3-flex-col tw3-gap-0">
				<label class="tw3-pl-1" :for="`${cid}-description`">
					Transaction Description
				</label>
				<Skeleton v-if="loading" height="3.4rem" />
				<Textarea
					v-else
					:id="`${cid}-description`"
					v-model="transaction.description"
					disabled
				/>
			</div>

			<div class="tw3-flex tw3-flex-col tw3-gap-0">
				<label class="tw3-pl-1">Items</label>
				<DataTable
					size="small"
					:value="loading ? new Array(2) : transaction.items"
				>
					<Column field="account" header="Account">
						<template #body="{ data }">
							<Skeleton v-if="loading" height="2.2rem" />
							<template v-else>
								{{
									accounts.find(account => account.id === data.accountId)?.name
								}}
							</template>
						</template>
					</Column>
					<Column field="debit" header="Debit">
						<template #body="{ data }">
							<Skeleton v-if="loading" height="2.2rem" />
							<span v-else>
								{{ data.debit ? formatMoney(data.debit) : '' }}
							</span>
						</template>
					</Column>
					<Column field="credit" header="Credit">
						<template #body="{ data }">
							<Skeleton v-if="loading" height="2.2rem" />
							<span v-else>
								{{ data.credit ? formatMoney(data.credit) : '' }}
							</span>
						</template>
					</Column>
					<Column field="description" header="Description">
						<template #body="{ data }">
							<Skeleton v-if="loading" height="2.2rem" />
							<template v-else>
								{{ data.description }}
							</template>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<div class="tw3-flex tw3-justify-between tw3-mt-5">
			<div class="tw3-flex tw3-justify-start">
				<Button
					v-if="transaction && allowEdit.includes(transaction.typeId!)"
					:label="'Delete'"
					severity="danger"
					@click="remove"
				/>
			</div>
			<div class="tw3-flex tw3-justify-end">
				<Button
					v-if="transaction && allowEdit.includes(transaction.typeId!)"
					class="tw3-ml-5"
					:label="'Edit'"
					@click="edit"
				/>
				<Button
					class="tw3-ml-5"
					:label="'Close'"
					severity="secondary"
					@click="cancel"
				/>
			</div>
		</div>
	</Dialog>
</template>

<style scoped></style>
