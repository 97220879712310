/* eslint-disable @typescript-eslint/no-unused-vars */

import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, Ref, ref } from 'vue';

import { OrganizationShortInfo } from '@/entities/organizations/lib/types';
import { useMessages } from '@/shared/composables';

import { getOrganization } from './api';

const useOrganizationsStore = defineStore('entitiesAdminOrganizations', () => {
	const { showError } = useMessages();

	const loadingCounter = ref(0);
	const loading = computed(() => {
		return loadingCounter.value > 0;
	});

	const organizations: Record<string, any> = {};

	const organizationsHandler: ProxyHandler<Record<number, any>> = {
		get: (
			target: Record<string, any>,
			prop: string
		): Ref<OrganizationShortInfo> => {
			if (typeof prop !== 'string' || isNaN(parseInt(prop))) {
				return Reflect.get(target, prop);
			}
			if (!Object.prototype.hasOwnProperty.call(target, prop)) {
				target[prop] = useAsyncState(
					() => {
						return getOrganization(parseInt(prop)).catch(error => {
							showError(error);
							return {} as OrganizationShortInfo;
						});
					},
					{
						id: parseInt(prop),
						name: ''
					} as OrganizationShortInfo,
					{
						immediate: true,
						resetOnExecute: false
					}
				);
			}
			return target[prop].state;
		}
	};

	const organizationsProxy = ref(
		new Proxy(organizations, organizationsHandler)
	);

	// const organizationsState = useAsyncState(
	// 	() => {
	// 		loadingCounter.value++;
	// 		return listOrganizations()
	// 			.catch(error => {
	// 				showError(error);
	// 				return [];
	// 			})
	// 			.finally(() => {
	// 				loadingCounter.value--;
	// 			});
	// 	},
	// 	[] as OrganizationShortInfo[],
	// 	{
	// 		immediate: true,
	// 		resetOnExecute: false
	// 	}
	// );

	// const loadOrganizations = async () => {
	// 	await organizationsState.execute(0);
	// };

	return {
		organizations: organizationsProxy,
		// loadOrganizations,
		loading
	};
});

export default useOrganizationsStore;
