import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col tw3-gap-5" }

import Divider from 'primevue/divider';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { Customer } from '@/entities/customer/lib/types';

import ContactInfoAddresses from './ContactInfoAddresses.vue';
import ContactInfoCompany from './ContactInfoCompany.vue';
import ContactInfoEmails from './ContactInfoEmails.vue';
import ContactInfoNames from './ContactInfoNames.vue';
import ContactInfoPhones from './ContactInfoPhones.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactInfo',
  setup(__props) {

const store = useCustomerEditStore();

const updateValue = (field: keyof Customer, value: any) => {
	store.customer.state = { ...store.customer.state, [field]: value };
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
				_unref(store).customer.state.is_commercial || _unref(store).customer.state.is_insurance
			)
      ? (_openBlock(), _createBlock(ContactInfoCompany, {
          key: 0,
          companyName: _unref(store).customer.state.company_name,
          fax: _unref(store).customer.state.fax,
          isCommercial: 
				_unref(store).customer.state.is_commercial || _unref(store).customer.state.is_insurance
			,
          website: _unref(store).customer.state.website,
          "onUpdate:companyName": _cache[0] || (_cache[0] = v => updateValue('company_name', v)),
          "onUpdate:fax": _cache[1] || (_cache[1] = v => updateValue('fax', v)),
          "onUpdate:isCommercial": _cache[2] || (_cache[2] = v => updateValue('is_commercial', v)),
          "onUpdate:website": _cache[3] || (_cache[3] = v => updateValue('website', v))
        }, null, 8, ["companyName", "fax", "isCommercial", "website"]))
      : _createCommentVNode("", true),
    (
				_unref(store).customer.state.is_commercial || _unref(store).customer.state.is_insurance
			)
      ? (_openBlock(), _createBlock(_unref(Divider), {
          key: 1,
          style: {"margin":"0"}
        }))
      : _createCommentVNode("", true),
    _createVNode(ContactInfoNames, {
      modelValue: _unref(store).customer.state.names,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = v => updateValue('names', v))
    }, null, 8, ["modelValue"]),
    _createVNode(_unref(Divider), { style: {"margin":"0"} }),
    _createVNode(ContactInfoPhones, {
      class: "tw3-w-full",
      modelValue: _unref(store).customer.state.phones,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = v => updateValue('phones', v))
    }, null, 8, ["modelValue"]),
    _createVNode(_unref(Divider), { style: {"margin":"0"} }),
    _createVNode(ContactInfoEmails, {
      class: "tw3-w-full",
      modelValue: _unref(store).customer.state.emails,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = v => updateValue('emails', v))
    }, null, 8, ["modelValue"]),
    _createVNode(_unref(Divider), { style: {"margin":"0"} }),
    _createVNode(ContactInfoAddresses, {
      modelValue: _unref(store).customer.state.addresses,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = v => updateValue('addresses', v))
    }, null, 8, ["modelValue"])
  ]))
}
}

})