<script setup lang="ts">
import { FilterMatchMode, FilterOperator } from '@primevue/core/api';
import { useInfiniteScroll } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Message from 'primevue/message';
import Select from 'primevue/select';
import { computed, reactive, ref, watch, watchEffect } from 'vue';

import router from '@/app/router';
import { BalanceType } from '@/entities/accounting/accounts/lib/types';
import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import { TRANSACTION_TYPE_ID_LEDGER_ENTRY } from '@/entities/accounting/transactions/lib/types';
import useTransactionsStore from '@/entities/accounting/transactions/lib/typeStore';
import useOrganizationsStore from '@/entities/organizations/lib/store';
import AccountSelect from '@/features/accounting/AccountSelect.vue';
import useAccountingStore from '@/features/accounting/lib/store';
import ShopSelect from '@/features/accounting/ShopSelect.vue';
import { formatDate, formatMoney } from '@/shared/helpers/formatters';
import PageCard from '@/shared/ui/container/PageCard.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';
import LoadingIndicator from '@/shared/ui/overlay/LoadingIndicator.vue';
import LedgerEntryEdit from '@/widgets/accounting/accountTransactions/LedgerEntryEdit.vue';
import TransactionDetails from '@/widgets/accounting/journalEntries/TransactionDetails.vue';

import useStore from './lib/store';
const props = defineProps<{
	id: number;
}>();

const { organizations } = storeToRefs(useOrganizationsStore());

const transactionsStore = useTransactionsStore();
const { types: transactionTypes, typesById: transactionTypesById } =
	storeToRefs(transactionsStore);

const store = useStore();
const {
	loading,
	account,
	transactions,
	transactionsCanLoadMore,
	transactionsParams
} = storeToRefs(store);

const transactionStore = useTransactionStore();

const accountingStore = useAccountingStore();
const {
	accounts,
	typesById: accountTypesbyId,
	selectedProfileId,
	shopsById
} = storeToRefs(accountingStore);

watch(
	() => props.id,
	() => {
		transactionsParams.value = {
			...transactionsParams.value,
			accountId: props.id
		};
	},
	{ immediate: true }
);

watchEffect(() => {
	selectedProfileId.value = account.value?.organizationId;
});

const accountType = computed(() => {
	if (!account.value) {
		return null;
	}
	return accountTypesbyId.value[account.value.typeId];
});

const organization = computed(() => {
	return organizations.value.find(
		organization => organization.id === account.value?.organizationId
	);
});

const refresh = () => {
	store.loadTransactions(true);
};

const transactionsNormalized = computed(() => {
	if (accountType.value == null) {
		return [];
	}
	return transactions.value.map((transaction: any) => {
		return {
			...transaction,
			increase: accountType.value!.creditAccount
				? transaction.credit
				: transaction.debit,
			decrease: accountType.value!.creditAccount
				? transaction.debit
				: transaction.credit
		};
	});
});

const filters = ref();

const initFilters = () => {
	filters.value = reactive({
		otherAccount: {
			value: null,
			matchMode: FilterMatchMode.EQUALS
		},
		type: {
			value: null,
			matchMode: FilterMatchMode.EQUALS
		},
		shop: {
			value: null,
			matchMode: FilterMatchMode.EQUALS
		},
		increase: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		},
		decrease: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		},
		transactionDt: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		}
	});
};

initFilters();

const activeFilters = computed<Record<string, boolean>>(() => ({
	shop: filters.value.shop.value,
	type: filters.value.type.value,
	otherAccount: filters.value.otherAccount.value,
	increase:
		filters.value.increase.constraints[0].value ||
		filters.value.increase.constraints[1].value,
	decrease:
		filters.value.decrease.constraints[0].value ||
		filters.value.decrease.constraints[1].value,
	transactionDt:
		filters.value.transactionDt.constraints[0].value ||
		filters.value.transactionDt.constraints[1].value
}));

const clearFilter: Record<string, CallableFunction> = {
	otherAccount: () => {
		filters.value.otherAccount.value = null;
		applyFilters();
	},
	type: () => {
		filters.value.type.value = null;
		applyFilters();
	},
	shop: () => {
		filters.value.shop.value = null;
		applyFilters();
	},
	increase: () => {
		filters.value.increase.constraints[0].value = null;
		filters.value.increase.constraints[1].value = null;
		applyFilters();
	},
	decrease: () => {
		filters.value.decrease.constraints[0].value = null;
		filters.value.decrease.constraints[1].value = null;
		applyFilters();
	},
	transactionDt: () => {
		filters.value.transactionDt.constraints[0].value = null;
		filters.value.transactionDt.constraints[1].value = null;
		applyFilters();
	}
};

const applyFilters = () => {
	let debit = {
		min: filters.value.increase.constraints[0].value,
		max: filters.value.increase.constraints[1].value
	};
	let credit = {
		min: filters.value.decrease.constraints[0].value,
		max: filters.value.decrease.constraints[1].value
	};
	if (accountType.value?.creditAccount) {
		const a = debit;
		debit = credit;
		credit = a;
	}
	transactionsParams.value = {
		...transactionsParams.value,
		shopId: filters.value.shop.value,
		typeId: filters.value.type.value,
		otherAccountId: filters.value.otherAccount.value,
		debit: debit,
		credit: credit,
		transactionDt: {
			start: filters.value.transactionDt.constraints[0].value,
			end: filters.value.transactionDt.constraints[1].value
		}
	};
};

const title = computed(() => [
	{ label: 'Chart of Accounts', disabled: true },
	{
		label: `${accountType.value?.name}:  ${account.value?.name}`,
		disabled: true
	}
]);

const details = async (id: number) => {
	transactionStore.loadSelectedTransaction(id, true);
	showTransactionDetails.value = true;
};

const edit = async (id: number) => {
	transactionStore.loadSelectedTransaction(id);
	showTransactionDetails.value = false;
	showLedgerEntryEdit.value = true;
};

const showLedgerEntryEdit = ref(false);
const showTransactionDetails = ref(false);

const goToAccounts = () => {
	router.push({ name: 'accounting-accounts' });
};

const editingRows = ref<any[]>([]);

const editMode = ref(false);

watch(
	() => editingRows.value,
	() => {
		if (editingRows.value.length > 0) {
			editMode.value = true;
		} else {
			editMode.value = false;
		}
	},
	{ deep: true }
);

const addLedgerEntry = () => {
	transactionStore.resetSelectedTransaction(account.value!.organizationId!, 1);
	showLedgerEntryEdit.value = true;
};

const table = ref<typeof DataTable | null>(null);
useInfiniteScroll(
	() => table.value?.$el.querySelector('.p-datatable-table-container'),
	async () => {
		await store.loadTransactions();
	},
	{
		distance: 10,
		interval: 1000,
		canLoadMore: () => transactionsCanLoadMore.value
	}
);

applyFilters();
</script>

<template>
	<PageCard v-if="!!organization && !!account && !!accountType">
		<template #title>
			<div class="tw3-flex tw3-justify-between">
				<div class="tw3-flex tw3-gap-2 tw3-items-center">
					<Button
						v-tooltip.top="'Back to accounts'"
						aria-label="Filter"
						icon="pi pi-arrow-left"
						serverity="secondary"
						text
						@click="goToAccounts()"
					>
					</Button>

					<Breadcrumb :model="title" pt:root:class="!tw3-p-0">
						<template #item="{ item }">
							<span class="!tw3-text-xl !tw3-font-bold tw3-text-black">{{
								item.label
							}}</span>
						</template>
					</Breadcrumb>
					<Message
						class="!tw3-ml-4 !tw3-my-0"
						:closable="false"
						pt:content:class="!tw3-py-2 !tw3-px-4"
						severity="secondary"
					>
						<template
							v-if="accountType.balanceType == BalanceType.BALANCE_TYPE_ON_DATE"
						>
							<span class="tw3-mr-2"> Current balance:</span>
							<b> {{ formatMoney(account.balance) }} </b>
						</template>
						<template v-else>
							<span class="tw3-mr-2"> Year to date balance:</span>
							<b> {{ formatMoney(account.balance) }} </b>
						</template>
					</Message>
				</div>
				<div class="tw3-flex tw3-items-center tw3-pr-4 tw3-opacity-50">
					{{ organization.name }}
				</div>
			</div>
		</template>
		<template #content>
			<DataTable
				ref="table"
				v-model:editingRows="editingRows"
				v-model:filters="filters"
				dataKey="id"
				editMode="row"
				filterDisplay="menu"
				lazy
				:pt="{ bodyRow: { style: 'cursor: pointer' } }"
				:rowHover="!editMode"
				scrollable
				scrollHeight="flex"
				size="small"
				tableClass="tw3-max-w-full"
				:value="transactionsNormalized"
				@filter="event => applyFilters()"
				@row-click="event => (editMode ? null : details(event.data.id))"
			>
				<template #header>
					<div
						class="tw3-flex tw3-justify-end tw3-gap-2"
						style="padding-right: 2.5px"
					>
						<div class="tw3-flex tw3-gap-2">
							<Button
								v-tooltip.top="'Create New Ledger Entry'"
								:disabled="editMode"
								icon="pi pi-plus"
								label="New Ledger Entry"
								@click="addLedgerEntry"
							>
							</Button>
							<Button
								v-tooltip.top="'Refresh'"
								:disabled="editMode"
								icon="pi pi-refresh"
								severity="secondary"
								@click="refresh()"
							/>
						</div>
					</div>
				</template>

				<Column
					field="type"
					header="Type"
					:showAddButton="false"
					:showFilterMatchModes="false"
					:showFilterOperator="false"
					:style="{ width: '15%' }"
				>
					<template #body="{ data }">
						{{ transactionTypesById[data.typeId].name }}
					</template>

					<template v-if="!editMode" #filter="{ filterModel }">
						<Select
							v-model="filterModel.value"
							optionLabel="name"
							:options="transactionTypes"
							optionValue="id"
							placeholder="All types"
						>
						</Select>
					</template>
					<template #filtericon>
						<i
							:class="
								activeFilters.type == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							"
						></i>
					</template>
					<template #filterclear>
						<Button outlined @click="clearFilter.type()"> Clear </Button>
					</template>
				</Column>

				<Column
					field="transactionDt"
					header="Date"
					:pt="{
						filterRemove: { style: 'display: none' }
					}"
					:showAddButton="false"
					:showFilterMatchModes="false"
					:showFilterOperator="false"
					:style="{ width: '10%' }"
				>
					<template #body="{ data }">
						{{ formatDate(data.transactionDt) }}
					</template>

					<template v-if="!editMode" #filter="{ filterModel }">
						<DatePicker
							v-model="filterModel.value"
							class="p-column-filter"
							:manualInput="false"
							:placeholder="
								filterModel.matchMode == 'gte'
									? `Greater or equal than`
									: `Less or equal than`
							"
							showIcon
						/>
					</template>
					<template #filtericon>
						<i
							:class="
								activeFilters.transactionDt == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							"
						></i>
					</template>
					<template #filterclear>
						<Button outlined @click="clearFilter.transactionDt()">
							Clear
						</Button>
					</template>
				</Column>

				<Column
					field="increase"
					header="Increase"
					:pt="{
						filterRemove: { style: 'display: none' }
					}"
					:showAddButton="false"
					:showFilterMatchModes="false"
					:showFilterOperator="false"
					:style="{ width: '10%' }"
				>
					<template #body="{ data }">
						<div class="tw3-text-right tw3-pr-3">
							{{ data.increase ? formatMoney(data.increase) : '' }}
						</div>
					</template>

					<template v-if="!editMode" #filter="{ filterModel }">
						<InputMoney
							v-model="filterModel.value"
							align="left"
							class="p-column-filter"
							:placeholder="
								filterModel.matchMode == 'gte'
									? `Greater or equal than`
									: `Less or equal than`
							"
						/>
					</template>
					<template #filtericon>
						<i
							:class="
								activeFilters.increase == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							"
						></i>
					</template>
					<template #filterclear>
						<Button outlined @click="clearFilter.increase()"> Clear </Button>
					</template>
				</Column>

				<Column
					field="decrease"
					header="Decrease"
					:pt="{
						filterRemove: { style: 'display: none' }
					}"
					:showAddButton="false"
					:showFilterMatchModes="false"
					:showFilterOperator="false"
					:style="{ width: '10%' }"
				>
					<template #body="{ data }">
						<div class="tw3-text-right tw3-pr-3">
							{{ data.decrease ? formatMoney(data.decrease) : '' }}
						</div>
					</template>

					<template v-if="!editMode" #filter="{ filterModel }">
						<InputMoney
							v-model="filterModel.value"
							align="left"
							class="p-column-filter"
							:placeholder="
								filterModel.matchMode == 'gte'
									? `Greater or equal than`
									: `Less or equal than`
							"
						/>
					</template>
					<template #filtericon>
						<i
							:class="
								activeFilters.decrease == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							"
						></i>
					</template>
					<template #filterclear>
						<Button outlined @click="clearFilter.decrease()"> Clear </Button>
					</template>
				</Column>

				<Column
					field="description"
					header="Description"
					:style="{ width: '17%' }"
				>
				</Column>

				<Column
					field="otherAccount"
					header="Other accounts"
					:showAddButton="false"
					:showFilterMatchModes="false"
					:showFilterOperator="false"
					:style="{ width: '15%' }"
				>
					<template #body="{ data }">
						<div v-for="accountId in data.otherAccountIds" :key="accountId">
							{{ accounts.find(account => account.id === accountId)?.name }}
						</div>
					</template>

					<template v-if="!editMode" #filter="{ filterModel }">
						<AccountSelect
							v-model="filterModel.value"
							placeholder="All accounts"
						/>
					</template>
					<template #filtericon>
						<i
							:class="
								activeFilters.otherAccount == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							"
						></i>
					</template>
					<template #filterclear>
						<Button outlined @click="clearFilter.otherAccount()">
							Clear
						</Button>
					</template>
				</Column>

				<Column
					field="shop"
					header="Shop"
					:showAddButton="false"
					:showFilterMatchModes="false"
					:showFilterOperator="false"
					:style="{ width: '17%' }"
				>
					<template #body="{ data }">
						{{ shopsById[data.shopId].name }}
					</template>

					<template v-if="!editMode" #filter="{ filterModel }">
						<ShopSelect v-model="filterModel.value" />
					</template>
					<template #filtericon>
						<i
							:class="
								activeFilters.shop == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							"
						></i>
					</template>
					<template #filterclear>
						<Button outlined @click="clearFilter.shop()"> Clear </Button>
					</template>
				</Column>

				<template #empty>
					<div
						v-if="!loading"
						class="tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20"
					>
						<p class="tw3-text-center tw3-text-3xl">Transactions not found</p>
					</div>
				</template>
			</DataTable>
			<LoadingIndicator v-show="loading" />
		</template>
	</PageCard>
	<TransactionDetails
		v-model:visible="showTransactionDetails"
		:allowEdit="[TRANSACTION_TYPE_ID_LEDGER_ENTRY]"
		@deleted="refresh"
		@edit="edit"
	/>
	<LedgerEntryEdit
		v-model:visible="showLedgerEntryEdit"
		:account="account"
		@saved="refresh"
	/>
</template>
