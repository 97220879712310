import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cell-container" }
const _hoisted_2 = { class: "tw3-flex" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-items-center tw3-justify-center" }

import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import ToggleSwitch from 'primevue/toggleswitch';
import { useFieldArray, useForm } from 'vee-validate';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { CustomerEmail } from '@/entities/customer/lib/types';
import { InputTextWithError } from '@/shared/ui';

const ZOD_EMAILS = 'emails';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactInfoEmails',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const { t } = useI18n();

const emit = __emit;

const store = useCustomerEditStore();

const validationSchema = toTypedSchema(
	zod.object({
		[ZOD_EMAILS]: zod.array(
			zod.object({
				email: zod.string({ message: t('required') }).email()
			})
		)
	})
);

const { errors, setValues } = useForm({
	initialValues: { [ZOD_EMAILS]: props.modelValue },
	validationSchema
});

const { fields } = useFieldArray<Omit<CustomerEmail, 'id'>>(ZOD_EMAILS);

const add = () => {
	emit('update:modelValue', [
		...props.modelValue,
		{ email: '' } as CustomerEmail
	]);
	if (props.modelValue.length) return;
	store.primaryEmailIndex = 0;
};

const updateVal = (
	index: number,
	field: keyof CustomerEmail,
	val: string | null
) => {
	emit(
		'update:modelValue',
		props.modelValue.map((v, vIndex) => {
			if (vIndex === index) v[field] = val as never;
			return v;
		})
	);
};

const removeVal = (index: number) => {
	emit(
		'update:modelValue',
		props.modelValue.filter((_, i) => i !== index)
	);
	if (store.primaryEmailIndex < index) return;
	if (store.primaryEmailIndex === index) {
		store.primaryEmailIndex = 0;
	} else if (store.primaryEmailIndex > index) {
		store.primaryEmailIndex -= 1;
	}
};

watch(
	() => props.modelValue,
	() => {
		setValues({ [ZOD_EMAILS]: props.modelValue });
	}
);

watch(
	() => errors.value,
	() => store.updateErrors('emails', errors.value)
);

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(DataTable), {
    class: "ui-data-table",
    size: "small",
    stripedRows: "",
    tableStyle: "tw3-w-full",
    value: _unref(fields)
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Column), {
        field: "email",
        header: "Email",
        pt: {
				headerCell: {
					style: {
						width: '66.6%'
					}
				}
			}
      }, {
        body: _withCtx(({ index }) => [
          _createVNode(_unref(InputTextWithError), {
            modelValue: _unref(fields)[index].value.email,
            "onUpdate:modelValue": [($event: any) => ((_unref(fields)[index].value.email) = $event), v => updateVal(index, 'email', v)],
            class: "tw3-w-full tw3-max-w-64",
            errMsg: _unref(errors)[`emails[${index}].email` as any],
            placeholder: "Email",
            type: "email"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "errMsg"])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), {
        field: "primary",
        header: "Primary",
        style: {"width":"2.25rem"}
      }, {
        body: _withCtx(({ index }) => [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createVNode(_unref(ToggleSwitch), {
              modelValue: _unref(store).primaryEmailIndex === index,
              name: "dynamic",
              style: _normalizeStyle(_unref(fields).length === 1 && 'opacity: 0.6'),
              "onUpdate:modelValue": 
							val =>
								val &&
								index !== _unref(store).primaryEmailIndex &&
								(_unref(store).primaryEmailIndex = index)
						
            }, null, 8, ["modelValue", "style", "onUpdate:modelValue"]), [
              [
                _directive_tooltip,
                'Set to primary',
                void 0,
                { top: true }
              ]
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), {
        field: "actions",
        header: "",
        style: {"width":"5%"}
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(Button), {
              "aria-label": "Settings",
              icon: "pi pi-plus",
              rounded: "",
              size: "small",
              text: "",
              type: "button",
              onClick: add
            })
          ])
        ]),
        body: _withCtx(({ index }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(Button), {
              "aria-label": "Delete",
              class: "tw3-w-8 tw3-h-8",
              icon: "pi pi-trash",
              rounded: "",
              severity: "danger",
              text: "",
              onClick: ($event: any) => (removeVal(index))
            }, null, 8, ["onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value"]))
}
}

})