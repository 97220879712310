import { DateTime } from 'luxon';

// Format a number to a currency string
export const formatMoney = (value: number) => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	}).format(value);
};

export const formatDate = (date: DateTime) => {
	return date.toFormat('LLL dd, yyyy');
};
