import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-items-center tw3-justify-between" }
const _hoisted_2 = {
  key: 1,
  class: "cell-container"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 2,
  class: "tw3-flex-1 tw3-flex tw3-justify-center tw3-items-center"
}
const _hoisted_5 = {
  key: 2,
  class: "tw3-rounded tw3-text-white tw3-bg-gray-400 tw3-text-sm tw3-leading-3 tw3-shadow-md tw3-p-2"
}
const _hoisted_6 = {
  key: 3,
  class: "tw3-rounded tw3-text-white tw3-bg-sky-700 tw3-text-sm tw3-leading-3 tw3-shadow-md tw3-p-2"
}
const _hoisted_7 = {
  key: 3,
  class: "tw3-whitespace-nowrap tw3-max-w-64 tw3-truncate"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 8 }
const _hoisted_12 = { class: "tw3-w-full tw3-h-full tw3-flex tw3-justify-center tw3-items-center" }
const _hoisted_13 = { class: "tw3-text-center tw3-text-3xl" }

import { vInfiniteScroll } from '@vueuse/components';
import { startCase } from 'lodash-es';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable, { DataTableSortEvent } from 'primevue/datatable';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
	customersHeader,
	exemptOptions,
	flaggedOptions,
	requiredOptions,
	TABLE_ID
} from '@/entities/customer/lib/contants';
import { useCustomersStore } from '@/entities/customer/lib/store';
import { CustomerStatus, CustomerType } from '@/entities/customer/lib/types';
import useStorageStore from '@/features/customer/lib/storageStore';
import { isEmptyValue } from '@/shared/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomersList',
  emits: ["on-edit", "on-open-address"],
  setup(__props, { emit: __emit }) {

const statusOptions = Object.values(CustomerStatus).map(v => ({
	id: v,
	name: startCase(v)
}));
const typeOptions = Object.values(CustomerType).map(v => ({
	id: v,
	name: v === CustomerType.Cash ? 'Retail' : v
}));

const emits = __emit;

const { t } = useI18n();
const store = useCustomersStore();
const storage = useStorageStore();

const showColumnSettings = ref(false);

const selectedColumns = computed(() =>
	storage.customerColumns.length ? storage.customerColumns : customersHeader
);

const getFilterIconClass = (field: string) => {
	const filter = store.filters[field];

	return isEmptyValue(filter.val) || filter.val === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const handleSort = (state: DataTableSortEvent) => {
	store.handleSort({
		desc: state.sortOrder === 1,
		field: state.sortField as string
	});
};

onMounted(() => store.customers.execute(0, store.composeGetCustomersArgs(0)));

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(DataTable), {
    filters: _unref(store).filters,
    "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_unref(store).filters) = $event)),
    dataKey: "id",
    filterDisplay: "menu",
    lazy: "",
    "pt:tableContainer:id": _unref(TABLE_ID),
    "pt:thead:class": "tw3-border-b",
    scrollable: "",
    scrollHeight: "flex",
    size: "small",
    sortField: _unref(store).sort.field,
    sortOrder: _unref(store).sort.desc === 'desc' ? 1 : -1,
    tableClass: "tw3-max-w-full",
    totalRecords: _unref(store).customers.state.count,
    value: 
			_unref(store).customers.isLoading ||
			(!_unref(store).customers.state.customers.length && _unref(store).loadingMore)
				? new Array(40)
				: _unref(store).customers.state.customers
		,
    onSort: handleSort
  }, _createSlots({
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Button), {
          label: "Export",
          loading: _unref(store).exportingLoading,
          raised: "",
          size: "small",
          text: "",
          onClick: _unref(store).exportData
        }, null, 8, ["loading", "onClick"]),
        _createVNode(_unref(Button), {
          icon: "pi pi-objects-column",
          loading: _unref(store).exportingLoading,
          severity: "secondary",
          size: "small",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showColumnSettings.value = true))
        }, null, 8, ["loading"])
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedColumns.value, (column) => {
        return (_openBlock(), _createBlock(_unref(Column), {
          key: column.key,
          field: column.key,
          filterField: column.key,
          header: column.key === 'edit' ? undefined : column.title,
          headerClass: "tw3-whitespace-nowrap tw3-border-b",
          "pt:headerCell:class": "tw3-border-b",
          showFilterMatchModes: false,
          showFilterMenu: column.key !== 'edit',
          sortable: column.key !== 'edit'
        }, {
          filtericon: _withCtx(() => [
            _createElementVNode("i", {
              class: _normalizeClass(getFilterIconClass(column.key))
            }, null, 2)
          ]),
          filter: _withCtx(({ filterModel }) => [
            (column.key === 'id')
              ? (_openBlock(), _createBlock(_unref(InputNumber), {
                  key: 0,
                  allowEmpty: "",
                  class: "p-column-filter",
                  modelValue: filterModel.val,
                  placeholder: `Search by ${column.title}`,
                  type: "number",
                  useGrouping: false,
                  "onUpdate:modelValue": v => (filterModel.val = v ? String(v) : v)
                }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"]))
              : (
						column.key === 'fax' ||
						column.key === 'phone' ||
						column.key === 'secondary_phone'
					)
                ? (_openBlock(), _createBlock(_unref(InputMask), {
                    key: 1,
                    allowEmpty: "",
                    class: "p-column-filter",
                    mask: "(999) 999-9999",
                    modelValue: filterModel.val,
                    placeholder: `Search by ${column.title}`,
                    type: "text",
                    useGrouping: false,
                    "onUpdate:modelValue": v => (filterModel.val = v ? String(v) : v)
                  }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"]))
                : (column.key === 'customer_type')
                  ? (_openBlock(), _createBlock(_unref(MultiSelect), {
                      key: 2,
                      modelValue: filterModel.val,
                      "onUpdate:modelValue": ($event: any) => ((filterModel.val) = $event),
                      class: "p-column-filter",
                      maxSelectedLabels: 2,
                      optionLabel: "name",
                      options: _unref(typeOptions),
                      optionValue: "id",
                      placeholder: `Search by ${column.title}`,
                      showToggleAll: false
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder"]))
                  : (column.key === 'status')
                    ? (_openBlock(), _createBlock(_unref(Select), {
                        key: 3,
                        class: "p-column-filter",
                        modelValue: filterModel.val,
                        optionLabel: "name",
                        options: _unref(statusOptions),
                        optionValue: "id",
                        placeholder: `Search by ${column.title}`,
                        "onUpdate:modelValue": 
						v => (filterModel.val = v === _unref(CustomerStatus).all ? null : v)
					
                      }, null, 8, ["modelValue", "options", "placeholder", "onUpdate:modelValue"]))
                    : (
						column.key === 'flagged' ||
						column.key === 'tax_exempt' ||
						column.key === 'po_required'
					)
                      ? (_openBlock(), _createBlock(_unref(Select), {
                          key: 4,
                          modelValue: filterModel.val,
                          "onUpdate:modelValue": ($event: any) => ((filterModel.val) = $event),
                          class: "p-column-filter",
                          optionLabel: "name",
                          options: 
						column.key === 'flagged'
							? _unref(flaggedOptions)
							: column.key === 'tax_exempt'
								? _unref(exemptOptions)
								: _unref(requiredOptions)
					,
                          optionValue: "id",
                          placeholder: `Search by ${column.title}`
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder"]))
                      : (_openBlock(), _createBlock(_unref(InputText), {
                          key: 5,
                          modelValue: filterModel.val,
                          "onUpdate:modelValue": ($event: any) => ((filterModel.val) = $event),
                          class: "p-column-filter",
                          placeholder: `Search by ${column.title}`,
                          type: "text"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
          ]),
          filterclear: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_unref(Button), {
              label: "Clear",
              outlined: "",
              size: "small",
              onClick: 
						() => {
							filterModel.val = null;
							filterCallback();
						}
					
            }, null, 8, ["onClick"])
          ]),
          body: _withCtx(({ data, index }) => [
            (_unref(store).customers.isLoading || _unref(store).loadingMore)
              ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (column.key === 'edit')
                    ? (_openBlock(), _createBlock(_unref(Button), {
                        key: 0,
                        "aria-label": "Edit",
                        severity: "secondary",
                        size: "small",
                        text: "",
                        type: "button",
                        onClick: ($event: any) => (emits('on-edit', data['id']))
                      }, {
                        icon: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createElementVNode("i", { class: "material-icons md-edit" }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["onClick"]))
                    : (column.key === 'id')
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          href: "#",
                          onClick: ($event: any) => (emits('on-edit', data[column.key]))
                        }, _toDisplayString(data[column.key]), 9, _hoisted_3))
                      : (
							column.key === 'status' ||
							column.key === 'customer_type' ||
							column.key === 'flagged' ||
							column.key === 'tax_exempt' ||
							column.key === 'po_required'
						)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            (column.key === 'status')
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: _normalizeClass(["tw3-rounded tw3-text-white tw3-text-sm tw3-leading-3 tw3-capitalize tw3-shadow-md tw3-p-2", 
								!data[column.key] ||
								data[column.key] === _unref(CustomerStatus).inactive
									? 'tw3-bg-gray-500'
									: 'tw3-bg-green-500'
							])
                                }, _toDisplayString(data[column.key]), 3))
                              : (column.key === 'customer_type')
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: _normalizeClass(["tw3-rounded tw3-text-white tw3-text-sm tw3-leading-3 tw3-capitalize tw3-shadow-md tw3-p-2", {
								'tw3-bg-yellow-500':
									data[column.key] === _unref(CustomerType).Commercial,
								'tw3-bg-cyan-500': data[column.key] === _unref(CustomerType).Cash,
								'tw3-bg-indigo-500': data[column.key] === _unref(CustomerType).Insurance
							}])
                                  }, _toDisplayString(data[column.key] === _unref(CustomerType).Cash
									? 'Retail'
									: data[column.key]), 3))
                                : (
								column.key === 'tax_exempt' && data[column.key] === 'yes'
							)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Exempt "))
                                  : (
								column.key === 'po_required' && data[column.key] === 'yes'
							)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, " PO Required "))
                                    : _createCommentVNode("", true)
                          ]))
                        : (column.key === 'notes')
                          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createTextVNode(_toDisplayString(data[column.key]), 1)
                            ])), [
                              [
                                _directive_tooltip,
                                data[column.key],
                                void 0,
                                { top: true }
                              ]
                            ])
                          : (column.key === 'email')
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 4,
                                class: "tw3-whitespace-nowrap",
                                href: 'mailto:' + data[column.key]
                              }, _toDisplayString(data[column.key]), 9, _hoisted_8))
                            : (
							column.key === 'phone' || column.key === 'secondary_phone'
						)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 5,
                                  class: "tw3-whitespace-nowrap",
                                  href: 'tel:' + data[column.key]
                                }, _toDisplayString(data[column.key]), 9, _hoisted_9))
                              : (column.key === 'full_address')
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 6,
                                    class: "tw3-whitespace-nowrap",
                                    href: "#",
                                    onClick: ($event: any) => (emits('on-open-address', data['id'], data['g_address_id']))
                                  }, _toDisplayString(data[column.key]), 9, _hoisted_10))
                                : (_openBlock(), _createElementBlock("div", {
                                    key: 7,
                                    class: _normalizeClass(["tw3-whitespace-nowrap", {
							'tw3-font-bold':
								column.key === 'name_first' || column.key === 'name_last'
						}])
                                  }, _toDisplayString(data[column.key]), 3)),
                  (
							!_unref(store).loadingMore &&
							index === _unref(store).customers.state.customers.length - 1 &&
							!_unref(store).preventFetchMore
						)
                    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_11, null, 512)), [
                        [_unref(vInfiniteScroll), _unref(store).fetchCustomersMore]
                      ])
                    : _createCommentVNode("", true)
                ]))
          ]),
          _: 2
        }, 1032, ["field", "filterField", "header", "showFilterMenu", "sortable"]))
      }), 128))
    ]),
    _: 2
  }, [
    (!_unref(store).customers.isLoading)
      ? {
          name: "empty",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)('noData')), 1)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["filters", "pt:tableContainer:id", "sortField", "sortOrder", "totalRecords", "value"]))
}
}

})