import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cell-container" }
const _hoisted_2 = { class: "cell-container" }
const _hoisted_3 = { class: "tw3-flex" }
const _hoisted_4 = { class: "tw3-flex tw3-flex-col tw3-items-center tw3-justify-center" }

import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputMask from 'primevue/inputmask';
import ToggleSwitch from 'primevue/toggleswitch';
import { useFieldArray, useForm } from 'vee-validate';
import { nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { CustomerPhone } from '@/entities/customer/lib/types';

const ZOD_PHONES = 'phones';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactInfoPhones',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { t } = useI18n();

const store = useCustomerEditStore();

const validationSchema = toTypedSchema(
	zod.object({
		[ZOD_PHONES]: zod.array(
			zod.object({
				number: zod
					.string({ message: t('required') })
					.min(14, t('errorNumber'))
					.max(14, t('errorNumber'))
			})
		)
	})
);

const { errors, setValues } = useForm({
	initialValues: { [ZOD_PHONES]: props.modelValue },
	validationSchema
});

const { fields } = useFieldArray<Omit<CustomerPhone, 'id'>>(ZOD_PHONES);

const add = () => {
	if (!props.modelValue.length) store.primaryPhoneIndex = 0;
	if (props.modelValue.length === 1) store.secondaryPhoneIndex = 1;
	emit('update:modelValue', [
		...props.modelValue,
		{ number: '' } as CustomerPhone
	]);
};

const updatePrimary = (index: number) => {
	store.primaryPhoneIndex = index;
	if (store.secondaryPhoneIndex !== index) return;
	if (fields.value.length > 1) {
		store.secondaryPhoneIndex = index === 0 ? 1 : 0;
	} else {
		store.secondaryPhoneIndex = -1;
	}
};

const updateSecondary = (index: number) => {
	if (fields.value.length <= 1) return;
	store.secondaryPhoneIndex = index;
	if (store.primaryPhoneIndex !== index) return;
	store.primaryPhoneIndex = index === 0 ? 1 : 0;
};

const updateVal = (
	index: number,
	field: keyof CustomerPhone,
	val: string | null
) => {
	emit(
		'update:modelValue',
		props.modelValue.map((v, vIndex) => {
			if (vIndex === index) v[field] = val as never;
			return v;
		})
	);
};

const removeVal = async (index: number) => {
	emit(
		'update:modelValue',
		props.modelValue.filter((_, i) => i !== index)
	);
	await nextTick();

	if (props.modelValue.length === 1) {
		store.primaryPhoneIndex = 0;
		store.secondaryPhoneIndex = -1;
		return;
	}

	if (props.modelValue.length > 1) {
		store.primaryPhoneIndex =
			store.primaryPhoneIndex === index
				? 0
				: store.primaryPhoneIndex > index
					? store.primaryPhoneIndex - 1
					: store.primaryPhoneIndex;

		store.secondaryPhoneIndex =
			store.secondaryPhoneIndex === index
				? store.primaryPhoneIndex === 0
					? 1
					: 0
				: store.secondaryPhoneIndex > index
					? store.secondaryPhoneIndex - 1
					: store.secondaryPhoneIndex;

		if (store.secondaryPhoneIndex === store.primaryPhoneIndex) {
			store.secondaryPhoneIndex = store.primaryPhoneIndex === 0 ? 1 : 0;
		}
	}
};

watch(
	() => props.modelValue,
	() => {
		setValues({ [ZOD_PHONES]: props.modelValue });
	}
);

watch(
	() => errors.value,
	() => store.updateErrors('phones', errors.value)
);

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(DataTable), {
    class: "ui-data-table",
    size: "small",
    stripedRows: "",
    tableStyle: "tw3-w-full",
    value: _unref(fields)
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Column), {
        field: "number",
        header: "Phone number",
        pt: {
				headerCell: {
					style: {
						width: '66.6%'
					}
				}
			}
      }, {
        body: _withCtx(({ index }) => [
          _withDirectives(_createVNode(_unref(InputMask), {
            modelValue: _unref(fields)[index].value.number,
            "onUpdate:modelValue": [($event: any) => ((_unref(fields)[index].value.number) = $event), v => updateVal(index, 'number', v)],
            class: "tw3-w-full tw3-max-w-64",
            invalid: !!_unref(errors)[`phones[${index}].number` as any],
            mask: "(999) 999-9999",
            placeholder: "Phone",
            type: "text"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "invalid"]), [
            [
              _directive_tooltip,
              {
						value: _unref(errors)[`phones[${index}].number` as any],
						pt: {
							text: {
								style: { backgroundColor: 'rgb(248 113 113)' }
							},
							arrow: {
								style: { borderTopColor: 'rgb(248 113 113)' }
							}
						}
					},
              void 0,
              { top: true }
            ]
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), {
        field: "primary",
        header: "Primary",
        style: {"width":"2.25rem"}
      }, {
        body: _withCtx(({ index }) => [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createVNode(_unref(ToggleSwitch), {
              modelValue: _unref(store).primaryPhoneIndex === index,
              name: "dynamic",
              style: _normalizeStyle(_unref(fields).length === 1 && 'opacity: 0.6'),
              "onUpdate:modelValue": val => val && updatePrimary(index)
            }, null, 8, ["modelValue", "style", "onUpdate:modelValue"]), [
              [
                _directive_tooltip,
                'Set to primary',
                void 0,
                { top: true }
              ]
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), {
        field: "primary",
        header: "Secondary",
        style: {"width":"2.25rem"}
      }, {
        body: _withCtx(({ index }) => [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createVNode(_unref(ToggleSwitch), {
              modelValue: _unref(store).secondaryPhoneIndex === index,
              name: "dynamic",
              style: _normalizeStyle(_unref(fields).length === 1 && 'opacity: 0.6'),
              "onUpdate:modelValue": val => val && updateSecondary(index)
            }, null, 8, ["modelValue", "style", "onUpdate:modelValue"]), [
              [
                _directive_tooltip,
                'Set to secondary',
                void 0,
                { top: true }
              ]
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), {
        field: "actions",
        header: "",
        style: {"width":"5%"}
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(Button), {
              "aria-label": "Settings",
              icon: "pi pi-plus",
              rounded: "",
              size: "small",
              text: "",
              type: "button",
              onClick: add
            })
          ])
        ]),
        body: _withCtx(({ index }) => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(Button), {
              "aria-label": "Delete",
              class: "tw3-w-8 tw3-h-8",
              icon: "pi pi-trash",
              rounded: "",
              severity: "danger",
              text: "",
              onClick: ($event: any) => (removeVal(index))
            }, null, 8, ["onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value"]))
}
}

})