import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["innerHTML"]

import Dialog from 'primevue/dialog';
import OverlayBadge from 'primevue/overlaybadge';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import Tabs from 'primevue/tabs';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useRejectedInvoicesStore from '../model/store';

import RejectedInvoicesTable from './table/RejectedInvoicesTable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RejectedInvoicesDialog',
  props: {
    "show": { type: Boolean },
    "showModifiers": {},
  },
  emits: ["update:show"],
  setup(__props) {

const rejectedInvoicesStore = useRejectedInvoicesStore();

const tabs = computed(() => {
	const invoicesCount = rejectedInvoicesStore.ediQueueStatusCounts;
	const tabs: {
		label: string;
		key: string;
		count?: number;
		customColor?: string;
	}[] = [
		{
			customColor: '#dc3545',
			key: 'rejected',
			label: t('rejectedInvoices.tabs.rejected')
		},
		{
			customColor: '#26af5d',
			key: 'queued',
			label: t('rejectedInvoices.tabs.queued')
		},
		{
			customColor: '#4d26af',
			key: 'unsubmitted',
			label: t('rejectedInvoices.tabs.unsubmitted')
		}
	];
	if (rejectedInvoicesStore.isUserAdmin) {
		tabs.push(
			{
				count: invoicesCount?.PreparingCount,
				key: 'preparing',
				label: t('rejectedInvoices.tabs.preparing')
			},
			{
				count: invoicesCount?.PendingCount,
				key: 'pending',
				label: t('rejectedInvoices.tabs.pending')
			},
			{
				count: invoicesCount?.FailedCount,
				customColor: '#e66926',
				key: 'failed',
				label: t('rejectedInvoices.tabs.failed')
			}
		);
	}

	return tabs;
});

function onTabChange(tabName: string) {
	rejectedInvoicesStore.changeCurrentTab(tabName);
}

const { t } = useI18n();

const showModal = _useModel<boolean>(__props, 'show');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Dialog), {
      id: "vue3RejectedInvoicesDialog",
      visible: showModal.value,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
      appendTo: "#vue3app",
      class: "tw3-w-full tw3-h-full tw3-max-w-[1400px]",
      draggable: false,
      header: `${_unref(t)('rejectedInvoices.title')}`,
      modal: "",
      "pt:content": 'tw3-h-full tw3-flex tw3-flex-col',
      "pt:root:class": '!tw3-border-0 tw3-overflow-hidden'
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Tabs), {
          pt: {
				panelcontainer: { style: { padding: '0' } }
			},
          value: _unref(rejectedInvoicesStore).currentTab,
          "onUpdate:value": onTabChange
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(TabList), null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value, (tab) => {
                  return (_openBlock(), _createBlock(_unref(Tab), {
                    key: tab.key,
                    "pt:root:class": '!tw3-pt-[16px] !tw3-overflow-visible',
                    value: tab.key
                  }, {
                    default: _withCtx(() => [
                      (tab?.count && tab?.count > 0)
                        ? (_openBlock(), _createBlock(_unref(OverlayBadge), {
                            key: 0,
                            "pt:pcbadge:root": {
							style: {
								'background-color': tab?.customColor || '#e66926',
								top: '-6px'
							},
							class: '!tw3-text-xs'
						},
                            size: "small",
                            value: tab.count
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(tab.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["pt:pcbadge:root", "value"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(tab.label), 1))
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"]),
        _createVNode(RejectedInvoicesTable)
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_unref(Dialog), {
      id: "vue3RejectedInvoiceDetailsDialog",
      visible: _unref(rejectedInvoicesStore).showInvoiceDetail,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_unref(rejectedInvoicesStore).showInvoiceDetail) = $event)),
      appendTo: "#vue3app",
      class: "tw3-max-w-[600px]",
      header: _unref(rejectedInvoicesStore).invoiceDetailsData.status,
      modal: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", {
          innerHTML: _unref(rejectedInvoicesStore).invoiceDetailsData.message
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})