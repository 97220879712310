import { FilterMatchMode } from '@primevue/core/api';

import { i18n } from '@/shared/i18n';

import { FiltersWithMetadata, TableHeader, TechEntry } from '../model/types';

export const DEFAULT_DASHBOARD_KEY = 'default-dashboard';

export const techHeader: TableHeader[] = [
	{ key: 'avatar', title: i18n.global.t('avatar') },
	{ key: 'name', title: i18n.global.t('name') },
	{ key: 'firstJobDate', title: i18n.global.t('since') },
	{ key: 'isDisabled', title: i18n.global.t('status') },
	{ key: 'lifetimeJobs', title: i18n.global.t('ltlJobs') },
	{
		key: 'jobCompletionPercentage',
		title: i18n.global.t('completionPercentage')
	},
	{ key: 'lifetimeRevenue', title: i18n.global.t('ltlRevenue') },
	{ key: 'totalCommissions', title: i18n.global.t('ltlCommission') },
	{ key: 'costRatio', title: i18n.global.t('costRatio') },
	{ key: 'warrantyJobs', title: i18n.global.t('warrCount') },
	{ key: 'warrantyRate', title: i18n.global.t('warrRate') },
	{ key: 'averageRating', title: i18n.global.t('rating') }
];

export const techFiltersInit: FiltersWithMetadata<TechEntry> = {
	lifetimeRevenue: { matchMode: FilterMatchMode.CONTAINS, value: null },
	lifetimeJobs: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	warrantyJobs: { matchMode: FilterMatchMode.CONTAINS, value: null },
	averageRating: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	totalCommissions: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	id: { matchMode: FilterMatchMode.CONTAINS, value: null },
	name: { matchMode: FilterMatchMode.CONTAINS, value: null },
	averageCommissionPerJob: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	costRatio: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	warrantyRate: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	jobCompletionPercentage: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	firstJobDate: { matchMode: FilterMatchMode.CONTAINS, value: '' }
};
