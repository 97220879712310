import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import { getAccount } from '@/entities/accounting/accounts/lib/api';
import { listAccountTransaction } from '@/entities/accounting/accountTransactions/lib/api';
import {
	AccountTransaction,
	ListAccountTransactionRequest
} from '@/entities/accounting/accountTransactions/lib/types';
import { useMessages } from '@/shared/composables';
import { NumberRange, OrderBy, Period } from '@/shared/types';

const useAccountTransactionsStore = defineStore(
	'widgetsAccountingAccountTransactions',
	() => {
		const { showError } = useMessages();

		const loadingCounter = ref(0);
		const loading = computed(() => {
			return loadingCounter.value > 0;
		}); //TODO:  it doesn't look ok - rework it - check data availability in the components

		const accountState = useAsyncState(
			(id: number) => {
				loadingCounter.value++;
				return getAccount(id)
					.catch(error => {
						showError(error);
						return undefined;
					})
					.finally(() => {
						loadingCounter.value--;
					});
			},
			undefined,
			{
				immediate: false
			}
		);

		const transactions = ref<AccountTransaction[]>([]);

		const transactionsParams = ref<{
			orderBy: OrderBy;
			accountId?: number;
			typeId?: number;
			shopId?: number;
			otherAccountId?: number;
			debit?: NumberRange;
			credit?: NumberRange;
			transactionDt?: Period;
		}>({
			orderBy: {
				field: 'transactionDt',
				desc: true
			} as OrderBy
		});

		const transactionsLoadLimit = ref(20);
		const transactionsCanLoadMore = ref(true);

		const loadTransactions = async (reset = false) => {
			if (!transactionsParams.value.accountId) {
				throw new Error('Account is not selected');
			}
			if (reset) {
				transactions.value = [];
			}

			loadingCounter.value++;
			try {
				const offset = transactions.value.length;
				const limit = transactionsLoadLimit.value;
				const params: ListAccountTransactionRequest = {
					...transactionsParams.value,
					offset: offset,
					limit: limit,
					accountId: transactionsParams.value.accountId!
				};
				const result = await listAccountTransaction(params);
				transactions.value.splice(offset, limit, ...result);
				transactionsCanLoadMore.value = result.length >= limit;
			} catch (error) {
				transactionsCanLoadMore.value = false;
				showError(error);
			} finally {
				loadingCounter.value--;
			}
		};

		watch(transactionsParams, () => {
			transactions.value = [];
			accountState.execute(0, transactionsParams.value.accountId!);
			loadTransactions(true);
		});

		return {
			loading,
			account: accountState.state,
			transactions: transactions,
			transactionsParams,
			loadTransactions,
			transactionsCanLoadMore
		};
	}
);

export default useAccountTransactionsStore;
