<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Textarea from 'primevue/textarea';
import ToggleSwitch from 'primevue/toggleswitch';
import { InvalidSubmissionHandler, useForm } from 'vee-validate';
import { computed, nextTick, ref, watch } from 'vue';
import { z } from 'zod';

import { Template } from '@/entities/accounting/accountTemplates/lib/types';
import useAccountingStore from '@/features/accounting/lib/store';
import { useMessages } from '@/shared/composables';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useTemplatesStore from './lib/store';

const cid = 'accounting-account-tempalte-edit';

const { showFieldValidationError } = useMessages();

const accountingStore = useAccountingStore();

const { typesById, subtypesById, groupedSubtypes } =
	storeToRefs(accountingStore);

const templatesStore = useTemplatesStore();

const { templates } = storeToRefs(templatesStore);

const visible = defineModel<boolean>('visible');

const saving = ref(false);

const account = defineModel<Template>('account', { required: true });

// Returns true if the account has a parent with the given id
const hasParent = (account: Template, parentTemplateId: number | undefined) => {
	if (account.id === parentTemplateId) {
		return true;
	}
	if (account.parentTemplateId == null) {
		return false;
	}
	const parent = templates.value.find(a => a.id === account.parentTemplateId);
	if (parent == null) {
		return false;
	}
	return hasParent(parent, parentTemplateId);
};

const parentTemplates = computed(() => {
	if (subtypeId.value == null) {
		return [];
	}
	return [
		{
			id: undefined,
			name: 'No parent account'
		},
		...templates.value.filter(
			a =>
				// Parent account must have the same subtype
				a.subtypeId == subtypeId.value &&
				// If we editing existings account - filter out the account itself and its children
				(!editMode.value || !hasParent(a, account.value.id))
		)
	];
});

const editMode = computed(() => !!account.value.id); //TODO: use null instead of 0 for id ???

const { defineField, handleSubmit, resetForm, errors, meta } =
	useForm<Template>({
		validationSchema: toTypedSchema(
			z.object({
				id: z.optional(z.number().nullable()),
				name: z
					.string()
					.trim()
					.min(1, 'required')
					.refine(value => {
						return !templates.value.some(
							a =>
								a.name.toLowerCase() === value.toLowerCase() &&
								a.id !== account.value.id
						);
					}, 'Account name must be unique'),
				subtypeId: z.number().min(1, 'required'),
				parentTemplateId: z.optional(z.number()),
				description: z.string().trim(),
				code: z
					.string()
					.trim()
					.refine(value => {
						return (
							value == '' ||
							!templates.value.some(
								a =>
									a.code.toLowerCase() === value.toLowerCase() &&
									a.id !== account.value.id
							)
						);
					}, 'Account number must be unique'),
				system: z.boolean()
			})
		),
		initialValues: {
			name: '',
			code: '',
			typeId: 0,
			subtypeId: 0,
			description: '',
			parentTemplateId: undefined,
			system: false
		}
	});

const fieldLabels: Record<string, string> = {
	name: 'Account Name',
	subtypeId: 'Account Type',
	parentTemplateId: 'Parent Account',
	description: 'Account Description',
	code: 'Account Number',
	system: 'System Account'
};

const [name] = defineField('name');
const [subtypeId] = defineField('subtypeId');
const [parentTemplateId] = defineField('parentTemplateId');
const [description] = defineField('description');
const [code] = defineField('code');
const [system] = defineField('system');

const nameInput = ref<typeof InputText>();

watch(visible, () => {
	if (visible.value) {
		resetForm({ values: { parentTemplateId: undefined, ...account.value } });
		nextTick(() => {
			// Focus the first input
			nameInput.value?.$el.focus();
		});
	}
});

const close = () => {
	visible.value = false;
};

const onValidationError: InvalidSubmissionHandler = ({ errors }) => {
	for (const [field, error] of Object.entries(errors)) {
		showFieldValidationError(fieldLabels[field], error!);
	}
};

const doSave = async (values: Template) => {
	saving.value = true;
	await templatesStore.saveTemplate(values);
	saving.value = false;
};

const save = handleSubmit(async (values: Template) => {
	await doSave(values);
	close();
}, onValidationError);

const getSubtypeFullName = (subtypeId: string) => {
	const subtype = subtypesById.value[subtypeId];
	const type = typesById.value[subtype.accountTypeId];
	return `${type.name}: ${subtype.name}`;
};
</script>

<template>
	<Dialog
		v-model:visible="visible"
		appendTo="#vue3app"
		:header="editMode ? 'Edit Account' : 'New Account'"
		modal
		:style="{ width: '35rem' }"
	>
		<div class="tw3-grid tw3-grid-cols-1 tw3-gap-4">
			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-name`">
					{{ fieldLabels.name }}
				</label>
				<InputText
					:id="`${cid}-name`"
					ref="nameInput"
					v-model="name"
					v-tooltip.top="{
						value: errors.name,
						pt: {
							text: {
								style: { backgroundColor: 'rgb(248 113 113)' }
							},
							arrow: {
								style: { borderTopColor: 'rgb(248 113 113)' }
							}
						}
					}"
					:invalid="!!errors.name"
					type="text"
				/>
			</div>
			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-subtype-id`">
					{{ fieldLabels.subtypeId }}
				</label>
				<Select
					:id="`${cid}-subtype-id`"
					v-model="subtypeId"
					class="w-full md:w-56"
					:disabled="editMode"
					optionGroupChildren="items"
					optionGroupLabel="name"
					optionLabel="name"
					:options="groupedSubtypes"
					optionValue="id"
					placeholder="Account Type"
					:pt="{
						optionGroup: {
							style: {
								color: 'black'
							}
						},
						option: {
							style: {
								paddingLeft: '24px'
							}
						}
					}"
				>
					<template #value="{ placeholder, value }">
						<template v-if="value">
							{{ getSubtypeFullName(value) }}
						</template>
						<template v-else>
							{{ placeholder }}
						</template>
					</template>
				</Select>
			</div>

			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-parent-template-id`">
					{{ fieldLabels.parentTemplateId }}
				</label>
				<AutoCompleteSelect
					:id="`${cid}-parent-template-id`"
					v-model="parentTemplateId"
					class="w-full md:w-56"
					:options="parentTemplates"
					optionValue="id"
					placeholder="No parent account"
				/>
			</div>

			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-description`">
					{{ fieldLabels.description }}
				</label>
				<Textarea :id="`${cid}-description`" v-model="description" />
			</div>
			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-code`">
					{{ fieldLabels.code }}
				</label>
				<InputText :id="`${cid}-code`" v-model="code" type="text" />
			</div>
			<div class="tw3-flex tw3-flex-col tw3-gap-1">
				<label class="tw3-pl-1" :for="`${cid}-system`">
					{{ fieldLabels.system }}
				</label>
				<ToggleSwitch :id="`${cid}-system`" v-model="system" />
			</div>
		</div>
		<div class="tw3-flex tw3-justify-end tw3-mt-5">
			<Button
				class="tw3-ml-5"
				:disabled="saving"
				:label="'Cancel'"
				severity="secondary"
				@click="close()"
			/>
			<Button
				class="tw3-ml-5"
				:disabled="saving || !meta.valid"
				:label="'Save'"
				@click="save()"
			/>
		</div>
	</Dialog>
</template>

<style scoped></style>
