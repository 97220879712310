import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import InputNumber from 'primevue/inputnumber';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputMoney',
  props: {
    align: { default: 'right' },
    pt: { default: () => ({}) }
  },
  setup(__props: any) {

const props = __props;

const passThrough = computed(() => {
	return {
		...props.pt,
		pcInput: {
			...(props.pt.pcInput || {}),
			root: {
				...(props.pt.pcInput?.root || {}),
				class: `tw3-text-${props.align} ${props.pt.pcInput?.root?.class || ''}`
			}
		}
	};
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(InputNumber), {
    currency: "USD",
    fluid: "",
    minFractionDigits: 2,
    pt: passThrough.value
  }, null, 8, ["pt"]))
}
}

})