import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 1 }

import Dialog from 'primevue/dialog';
import OverlayBadge from 'primevue/overlaybadge';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import Tabs from 'primevue/tabs';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useFaxStore from '../model/store';
import { FaxTabPanels } from '../model/types';

import FaxTable from './FaxTable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FaxDialog',
  props: {
    "show": { type: Boolean },
    "showModifiers": {},
  },
  emits: ["update:show"],
  setup(__props) {

const faxStore = useFaxStore();
const { t } = useI18n();
const showModal = _useModel<boolean>(__props, 'show');

const FaxTabs = computed((): FaxTabPanels[] => {
	return [
		{
			count: faxStore.faxInbox.state.totalCount,
			key: 'inbox',
			title: t('faxDialog.inbox.title')
		},
		{
			count: faxStore.faxOutbox.state.totalCount,
			key: 'outbox',
			title: t('faxDialog.outbox.title')
		},
		{
			count: faxStore.burstCount.state,
			key: 'burst',
			title: t('faxDialog.status.burst')
		}
	];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3FaxDialog",
    visible: showModal.value,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-h-full tw3-max-w-[1400px]",
    draggable: false,
    header: _unref(t)('faxDialog.fax'),
    modal: "",
    "pt:content": 'tw3-h-full tw3-flex tw3-flex-col',
    "pt:root:class": '!tw3-border-0 tw3-overflow-hidden'
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Tabs), {
        pt: { panelcontainer: { style: { padding: '0' } } },
        value: _unref(faxStore).currentTab,
        "onUpdate:value": _cache[0] || (_cache[0] = tabName => _unref(faxStore).changeCurrentTab(tabName))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(TabList), null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(FaxTabs.value, (item) => {
                return (_openBlock(), _createBlock(_unref(Tab), {
                  key: item.key,
                  "pt:root:class": '!tw3-pt-[16px]',
                  value: item.key
                }, {
                  default: _withCtx(() => [
                    (item?.count && item?.count > 0)
                      ? (_openBlock(), _createBlock(_unref(OverlayBadge), {
                          key: 0,
                          "pt:pcbadge:root": {
							style: {
								top: '-6px',
								right: '2px'
							},
							class: '!tw3-text-xs'
						},
                          size: "small",
                          value: item.count
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.title), 1))
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(FaxTable)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})