import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import AutoComplete from 'primevue/autocomplete';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { ref, toRef, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

// This Autocomplete accepts an array of objects as "options" prop
// Options are filtered by "optionLabel" property of passed options objects
// If "optionValue" is provided it must be the name of options property that will be used as model value
// In this case, the model is a scalar value
// If "optionValue" is undefined, the model must be the object (one of options object)

// TODO:
// - Add a button (cross icon on the right side of the field) to clear the selected value
// - mark matched fragment in the options list


export default /*@__PURE__*/_defineComponent({
  __name: 'AutoCompleteSelect',
  props: {
    modelValue: {},
    optionValue: { default: undefined },
    optionLabel: { default: 'name' },
    optionGroup: { default: undefined },
    filterBy: { default: () => [] },
    options: {},
    clearable: { type: Boolean, default: false }
  },
  emits: ["update:modelValue", "search"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const prop = __props;

const emit = __emit;

const options = toRef(prop, 'options');
const inputValue = ref('');
const searchQuery = ref('');
const preparedOptions = ref([] as any[]);

const filterOptions = (options: any[], searchQuery: string): any[] => {
	if (!searchQuery) {
		return options;
	}
	const filterBy = prop.filterBy.length ? prop.filterBy : [prop.optionLabel];
	// Filter by all properties
	return options.filter(option =>
		filterBy.some(prop =>
			option[prop].toLowerCase().includes(searchQuery.toLowerCase())
		)
	);
};

const groupOptions = (options: any[], optionGroup: string): any[] => {
	options = options.reduce(
		(acc, option) => {
			const group = option[optionGroup];
			if (!acc[group]) {
				acc[group] = [];
			}
			acc[group].push(option);
			return acc;
		},
		{} as Record<string, any[]>
	);
	return Object.entries(options)
		.map(([group, options]) => ({
			label: group,
			items: options
		}))
		.sort((a, b) => a.label.localeCompare(b.label));
};

const prepareOptions = () => {
	let prepared = filterOptions(options.value, searchQuery.value);
	if (prop.optionGroup !== undefined) {
		prepared = groupOptions(prepared, prop.optionGroup);
	}
	// options array need to be changed to trigger the dropdown
	// this is a weird primevue AutoComplete behavior
	preparedOptions.value = [...prepared];
};

watchEffect(() => {
	prepareOptions();
});

watchEffect(() => {
	if (prop.modelValue) {
		if (prop.optionValue) {
			// Using scalar model
			const option = options.value.find(
				option => option[prop.optionValue!] === prop.modelValue
			);
			if (option) {
				inputValue.value = option[prop.optionLabel];
			}
		} else {
			// Using object model
			inputValue.value = prop.modelValue[prop.optionLabel];
		}
	} else {
		inputValue.value = '';
	}
});

const onSearch = (event: { query: string }) => {
	emit('search', event.query);
	searchQuery.value = event.query;
	// Wierdlly, this is needed to show the dropdown, watchEffect doesn't work in some cases
	prepareOptions();
};

const onSelect = (event: any) => {
	if (event.originalEvent.type === 'change') {
		// For some reason AutoComplete emits "option-select" twice when option selected with keyboard
		// One event is with type "keydown" and another is with type "change"
		// As a workaround, we ignore the "change" event
		return;
	}
	let value: any = event.value;
	if (prop.optionValue) {
		// Using scalar model
		value = event.value[prop.optionValue];
	}
	emit('update:modelValue', value);
};

const clear = () => {
	emit('update:modelValue', null);
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(IconField), null, {
    default: _withCtx(() => [
      _createVNode(_unref(AutoComplete), {
        modelValue: inputValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
        dropdown: "",
        fluid: "",
        forceSelection: "",
        optionGroupChildren: _ctx.optionGroup ? 'items' : undefined,
        optionGroupLabel: _ctx.optionGroup ? 'label' : undefined,
        optionLabel: _ctx.optionLabel,
        pt: { optionGroup: { style: 'color: inherit' } },
        suggestions: preparedOptions.value,
        onComplete: onSearch,
        onOptionSelect: onSelect
      }, {
        option: _withCtx((slotProps) => [
          _renderSlot(_ctx.$slots, "option", {
            option: slotProps.option
          }, () => [
            _createElementVNode("span", null, _toDisplayString(slotProps.option[_ctx.optionLabel]), 1)
          ])
        ]),
        optiongroup: _withCtx((slotProps) => [
          (_ctx.optionGroup !== undefined)
            ? _renderSlot(_ctx.$slots, "optiongroup", {
                key: 0,
                option: slotProps.option
              }, () => [
                _createTextVNode(_toDisplayString(slotProps.option[_ctx.optionGroup]), 1)
              ])
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["modelValue", "optionGroupChildren", "optionGroupLabel", "optionLabel", "suggestions"]),
      (_ctx.clearable && prop.modelValue)
        ? _withDirectives((_openBlock(), _createBlock(_unref(InputIcon), {
            key: 0,
            class: "pi pi-times",
            style: {"right":"52px","cursor":"pointer"},
            onClick: clear
          }, null, 512)), [
            [
              _directive_tooltip,
              _unref(t)('clear'),
              void 0,
              { top: true }
            ]
          ])
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})