<template>
	<div ref="dropdownRef" class="dropdown-container">
		<Button
			v-tooltip.top="t('addNewTag')"
			class="w-full md:w-14rem"
			icon="pi pi-plus-circle"
			:loading="isLoading"
			text
			@click="openModal"
		/>
		<Dialog
			id="vue3JobTagsAdding"
			v-model:visible="showModal"
			appendTo="#vue3app"
			class="tw3-max-h-[600px] tw3-max-w-[600px]"
			:draggable="false"
			:header="`${t('rejectedInvoices.addTagsTitle')}`"
			modal
			:pt:content="'tw3-h-full tw3-flex tw3-flex-col'"
			:pt:root:class="'!tw3-border-0 tw3-overflow-hidden'"
		>
			<div class="tw3-flex tw3-flex-col">
				<Listbox
					v-model="selectedtags"
					checkmark
					class="w-full md:w-56"
					filter
					listStyle="max-height:250px"
					multiple
					optionLabel="text"
					:options="preparedTagForOptions"
					pt:root:class="!tw3-border-0"
				>
					<template #option="slotProps">
						<div class="flex items-center">
							<div
								class="tw3-flex tw3-items-center tw3-capitalize tw3-px-2 tw3-py-0.5 tw3-gap-2 tw3-text-sm"
							>
								<Badge
									class="!tw3-w-2.5 !tw3-h-2.5 !tw3-min-w-2.5"
									:pt:root:style="`background-color: ${slotProps.option.color}`"
									size="small"
								></Badge>
								{{ slotProps.option.text.toLowerCase() }}
							</div>
						</div>
					</template>
				</Listbox>
				<Button
					class="w-full md:w-14rem"
					:label="t('rejectedInvoices.applyTags')"
					pt:root:class="tw3-rounded-t-none"
					size="small"
					@click="handleAddingTags"
				/>
			</div>
		</Dialog>
	</div>
</template>

<script lang="ts" setup>
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Listbox from 'primevue/listbox';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import type {
	JobTag,
	newTag
} from '@/widgets/rejectedInvoicesDialog/model/types';

const showModal = defineModel<boolean>('visible');

const { t } = useI18n();

const props = withDefaults(
	defineProps<{
		jobID: number;
		tagsList: JobTag[];
		currentTagsList: JobTag[];
		isLoading: boolean;
	}>(),
	{
		jobID: 0,
		tagsList: () => [],
		currentTagsList: () => [],
		isLoading: false
	}
);
const emit = defineEmits<{
	(e: 'addNewTag', tags: newTag[]): void;
}>();

const preparedTagForOptions = computed(() => {
	return props.tagsList.filter(
		item1 => !props.currentTagsList.some(item2 => item1.id === item2.id)
	);
});

const selectedtags = ref<newTag[]>([]);
const handleAddingTags = () => {
	if (selectedtags.value && selectedtags.value.length) {
		const preparedTags: newTag[] = selectedtags.value.map(
			(element: newTag) => ({
				created: -1,
				modified: -1,
				id: -1,
				job_id: props.jobID,
				shop_tag_id: element.id
			})
		);
		emit('addNewTag', preparedTags);
		selectedtags.value = [];
		showModal.value = false;
	}
};

const openModal = () => {
	showModal.value = true;
};
</script>

<style scoped>
.dropdown-container {
	position: relative;
}

.dropdown-container :deep(.arrow-hidder) {
	width: 220px !important;
	top: 100% !important;
	left: 0 !important;
	margin-top: 0 !important;
}

.dropdown-container :deep(.arrow-hidder:before),
.dropdown-container :deep(.arrow-hidder:after) {
	display: none !important;
}
</style>
