import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import { listExpense } from '@/entities/accounting/expense/api';
import {
	ExpenseShort,
	ListExpenseRequest
} from '@/entities/accounting/expense/model/types';
import useAccountingStore from '@/features/accounting/lib/store';
import { useMessages } from '@/shared/composables';
import { NumberRange, OrderBy, Period } from '@/shared/types';

const useExpenseListStore = defineStore('pagesAccountingExpenseList', () => {
	const { showError } = useMessages();

	const loadingCounter = ref(0);
	const loading = computed(() => {
		return loadingCounter.value > 0;
	});

	const { selectedProfileId } = storeToRefs(useAccountingStore());

	const expenses = ref<ExpenseShort[]>([]);

	const expensesParams = ref<{
		transactionDt?: Period;
		orderBy: OrderBy;
		typeId?: number;
		shopId?: number;
		accountId?: number;
		vendorId?: number;
		amount?: NumberRange;
	}>({
		orderBy: {
			field: 'transactionDt',
			desc: true
		}
	});

	const expensesLoadLimit = ref(20);
	const expensesCanLoadMore = ref(true);

	const loadExpenses = async (reset = false) => {
		if (!selectedProfileId.value) {
			return;
		}
		if (reset) {
			expenses.value = [];
		}
		loadingCounter.value++;
		try {
			const offset = expenses.value.length;
			const limit = expensesLoadLimit.value;
			const params: ListExpenseRequest = {
				...expensesParams.value,
				offset: offset,
				limit: limit,
				organizationId: selectedProfileId.value
			};
			const result = await listExpense(params);
			expenses.value.splice(offset, limit, ...result);
			expensesCanLoadMore.value = result.length >= limit;
		} catch (error) {
			showError(error);
		} finally {
			loadingCounter.value--;
		}
	};

	watch(expensesParams, () => {
		expenses.value = [];
		loadExpenses(true);
	});

	watch(
		selectedProfileId,
		() => {
			loadExpenses(true);
		},
		{ immediate: false }
	);

	return {
		loading,
		expenses: expenses,
		expensesParams,
		loadExpenses,
		expensesCanLoadMore,

		selectedProfileId
	};
});

export default useExpenseListStore;
