import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BalanceSheet from '@/widgets/accounting/reports/BalanceSheet.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'BalanceSheetPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BalanceSheet))
}
}

})