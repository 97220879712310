import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { storeToRefs } from 'pinia';

import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useAccountingStore from './lib/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopSelect',
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

// Shop selector that uses fearures/accounting/lib/store store
const value = _useModel<number | undefined>(__props, "modelValue");

const { shops } = storeToRefs(useAccountingStore());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AutoCompleteSelect, {
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    fluid: "",
    options: _unref(shops),
    optionValue: "id"
  }, null, 8, ["modelValue", "options"]))
}
}

})