<script setup lang="ts">
import InputNumber from 'primevue/inputnumber';
import { computed } from 'vue';

const props = withDefaults(
	defineProps<{
		align?: string;
		pt?: Record<string, any>;
	}>(),
	{
		align: 'right',
		pt: () => ({})
	}
);

const passThrough = computed(() => {
	return {
		...props.pt,
		pcInput: {
			...(props.pt.pcInput || {}),
			root: {
				...(props.pt.pcInput?.root || {}),
				class: `tw3-text-${props.align} ${props.pt.pcInput?.root?.class || ''}`
			}
		}
	};
});
</script>

<template>
	<InputNumber currency="USD" fluid :minFractionDigits="2" :pt="passThrough" />
</template>
