import { useAsyncState } from '@vueuse/core';
import { Settings } from 'luxon';
import { defineStore } from 'pinia';
import { computed, watchEffect } from 'vue';

import { OrganizationShortInfo } from '@/entities/organizations/lib/types';

import { getOne } from './api';

export const useUserStore = defineStore('entitiesUser', () => {
	const userState = useAsyncState(
		() => {
			return getOne();
		},
		{
			user: {
				admin: 0,
				id: 0,
				shop_id: 0,
				timezone: 'local'
			},
			shops: [],
			organizations: [],
			roles: []
		},
		{
			immediate: true,
			shallow: false
		}
	);

	watchEffect(() => {
		// Use the user's timezone as the default timezone
		Settings.defaultZone = userState.state.value.user.timezone || 'local';
	});

	const defaultOrganization = computed(() => {
		const defaultShop = userState.state.value.shops.find(
			(shop: any) => shop.id === userState.state.value.user.shop_id
		);
		const res = userState.state.value.organizations?.find(
			(organization: OrganizationShortInfo) =>
				organization.id == defaultShop?.organization_id
		);
		return res;
	});

	const getDefaultOrganizationShop = (organizationId: number) => {
		// Return default users shop if it belongs to the selected organization
		let shop = userState.state.value.shops.find(
			shop =>
				shop.id === userState.state.value.user.shop_id &&
				shop.organization_id === organizationId
		);

		// If not, return the first shop of the organization
		if (!shop) {
			shop = userState.state.value.shops.find(
				shop => shop.organization_id === organizationId
			)!;
		}

		return shop;
	};

	return {
		user: userState.state,
		loadUser: userState.execute,
		loading: userState.isLoading,
		defaultOrganization,
		getDefaultOrganizationShop
	};
});
