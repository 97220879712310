import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-justify-between" }
const _hoisted_2 = { class: "tw3-flex tw3-gap-2 tw3-items-center" }
const _hoisted_3 = { class: "!tw3-text-xl !tw3-font-bold tw3-text-black" }
const _hoisted_4 = { class: "tw3-flex tw3-gap-2 tw3-items-center" }
const _hoisted_5 = {
  class: "tw3-flex tw3-justify-between tw3-gap-2",
  style: {"padding":"12px 16px"}
}
const _hoisted_6 = { class: "tw3-flex tw3-gap-4" }
const _hoisted_7 = { class: "tw3-flex tw3-flex-col tw3-gap-1 tw3-grow" }
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "tw3-flex tw3-flex-col tw3-gap-1 tw3-grow" }
const _hoisted_10 = ["for"]
const _hoisted_11 = { class: "tw3-flex tw3-gap-4 tw3-items-end" }
const _hoisted_12 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-font-bold" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 1,
  class: "tw3-text-right"
}
const _hoisted_15 = { class: "tw3-flex tw3-flex-col tw3-gap-0" }
const _hoisted_16 = { for: "gte" }
const _hoisted_17 = {
  key: 1,
  class: "tw3-flex tw3-items-center tw3-justify-center"
}
const _hoisted_18 = {
  key: 1,
  class: "action-buttons tw3-flex tw3-flex-row tw3-items-center tw3-justify-end tw3-gap-3"
}

import { FilterMatchMode, FilterOperator } from '@primevue/core/api';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import { computed, ref } from 'vue';

import router from '@/app/router';
import {
	Account,
	AccountStatus,
	BalanceType
} from '@/entities/accounting/accounts/lib/types';
import CheatSheet from '@/features/accounting/CheatSheet.vue';
import useAccountingStore from '@/features/accounting/lib/store';
import ProfileSelect from '@/features/accounting/ProfileSelect.vue';
import { formatMoney } from '@/shared/helpers/formatters';
import { buildTree, flattenTree } from '@/shared/helpers/tree';
import PageCard from '@/shared/ui/container/PageCard.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';

import AccountEdit from './AccountEdit.vue';

const cid = 'accounting-account-list';


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountList',
  setup(__props) {

const skeletonArray = Array(30).fill({});

const accountingStore = useAccountingStore();
const {
	loading,
	statuses,
	accounts,
	typesById,
	subtypesById,
	selectedProfileId
} = storeToRefs(accountingStore);

const statusOptions = [{ name: 'All statuses', id: null }, ...statuses.value];

const defaultAccount: Account = {
	id: 0,
	name: '',
	code: '',
	typeId: 0,
	subtypeId: 0,
	description: '',
	parentAccountId: undefined,
	status: AccountStatus.ACCOUNT_STATUS_ACTIVE,
	balance: 0,
	system: false,
	organizationId: 0
};

const title = ref([{ label: 'Chart of Accounts', disabled: true }]);

const accountsTree = computed(() => {
	if (!accounts.value || !typesById.value || !subtypesById.value) {
		return [];
	}
	const sorted = accounts.value
		.toSorted((a, b) => {
			return (
				a.typeId - b.typeId ||
				a.subtypeId - b.subtypeId ||
				a.name.localeCompare(b.name)
			);
		})
		.map(account => {
			return {
				...account,
				type: typesById.value[account.typeId].name,
				subtype: subtypesById.value[account.subtypeId].name,
				balanceType: typesById.value[account.typeId].balanceType
			};
		});

	return flattenTree(buildTree(sorted));
});

const filters = ref();

const filtersWrapper = computed({
	get: () => {
		return loading.value ? {} : filters.value;
	},
	set: value => {
		filters.value = value;
	}
});

const initFilters = () => {
	filters.value = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		status: {
			value: AccountStatus.ACCOUNT_STATUS_ACTIVE,
			matchMode: FilterMatchMode.EQUALS
		},
		balance: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		}
	};
};

initFilters();

const isEmptyFilterStatus = computed(() => {
	return filters.value.status.value == null;
});

const isEmptyFilterBalance = computed(() => {
	return (
		filters.value.balance.constraints[0].value == null &&
		filters.value.balance.constraints[1].value == null
	);
});

const clearFilterBalance = () => {
	filters.value.balance.constraints[0].value = null;
	filters.value.balance.constraints[1].value = null;
};

const showEditAccount = ref(false);
const selectedAccount = ref({ ...defaultAccount });

const create = () => {
	showEditAccount.value = true;
	selectedAccount.value = {
		...defaultAccount,
		organizationId: selectedProfileId.value!
	};
};

const edit = (id: number) => {
	selectedAccount.value = {
		...accounts.value.find(account => account.id == id)!
	};
	showEditAccount.value = true;
};

const showTransactions = (id: number) => {
	router.push({
		name: 'accounting-account-transactions',
		params: { id: id.toString() }
	});
};

const refresh = () => {
	accountingStore.loadAccounts();
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageCard, null, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(Breadcrumb), {
              model: title.value,
              "pt:root:class": "!tw3-p-0 !tw3-ml-3"
            }, {
              item: _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(item.label), 1)
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(CheatSheet)
          ])
        ])
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", {
                class: "tw3-pl-1",
                for: `${cid}-organization`
              }, "Organization ", 8, _hoisted_8),
              _createVNode(ProfileSelect, {
                id: `${cid}-organization`
              }, null, 8, ["id"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", {
                class: "tw3-pl-1",
                for: `${cid}-search`
              }, "Search", 8, _hoisted_10),
              _createVNode(_unref(IconField), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(InputIcon), { class: "pi pi-search" }),
                  _withDirectives(_createVNode(_unref(InputText), {
                    id: `${cid}-search`,
                    modelValue: filters.value['global'].value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value['global'].value) = $event)),
                    class: "tw3-w-full",
                    placeholder: "",
                    style: {"width":"250px","padding-left":"calc(\n\t\t\t\t\t\t\t\t\t\t(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)\n\t\t\t\t\t\t\t\t\t)","padding-right":"calc(\n\t\t\t\t\t\t\t\t\t\t(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)\n\t\t\t\t\t\t\t\t\t)"},
                    type: "search"
                  }, null, 8, ["id", "modelValue"]), [
                    [_directive_tooltip, 
									'Search by Account Name, Number, Type or Description'
								]
                  ]),
                  _withDirectives(_createVNode(_unref(InputIcon), {
                    class: "pi pi-times",
                    style: {"cursor":"pointer"},
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (filters.value['global'].value = ''))
                  }, null, 512), [
                    [_vShow, filters.value['global'].value]
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _withDirectives(_createVNode(_unref(Button), {
              icon: "pi pi-plus",
              label: "New account",
              onClick: create
            }, null, 512), [
              [
                _directive_tooltip,
                'Create new account',
                void 0,
                { top: true }
              ]
            ]),
            _withDirectives(_createVNode(_unref(Button), {
              icon: "pi pi-refresh",
              severity: "secondary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (refresh()))
            }, null, 512), [
              [
                _directive_tooltip,
                'Refresh',
                void 0,
                { top: true }
              ]
            ])
          ])
        ]),
        _createVNode(_unref(DataTable), {
          filters: filtersWrapper.value,
          "onUpdate:filters": _cache[4] || (_cache[4] = ($event: any) => ((filtersWrapper).value = $event)),
          dataKey: "id",
          filterDisplay: "menu",
          globalFilterFields: ['name', 'code', 'type', 'subtype', 'description'],
          groupRowsBy: "type",
          pt: {
					rowgroupheadercell: { colspan: '99' },
					bodyRow: { style: 'cursor: pointer' }
				},
          rowHover: !_unref(loading),
          rowGroupMode: "subheader",
          scrollable: "",
          scrollHeight: "flex",
          size: "small",
          value: _unref(loading) ? _unref(skeletonArray) : accountsTree.value,
          onRowClick: _cache[5] || (_cache[5] = event => showTransactions(event.data.id))
        }, _createSlots({
          groupheader: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_12, _toDisplayString(data.type), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_unref(Column), {
              field: "name",
              header: "Account name"
            }, {
              body: _withCtx(({ data }) => [
                (_unref(loading))
                  ? (_openBlock(), _createBlock(_unref(Skeleton), {
                      key: 0,
                      height: "2rem"
                    }))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "tw3-flex tw3-flex-row tw3-items-center tw3-gap-1",
                      style: _normalizeStyle({
									'padding-left': `${0.875 * (data.depth + 1)}rem`
								})
                    }, [
                      (data.parentAccountId)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _cache[8] || (_cache[8] = _createElementVNode("i", { class: "material-icons md-arrow_right tw3-opacity-40" }, null, -1)),
                            _createElementVNode("div", null, _toDisplayString(data.name), 1)
                          ], 64))
                        : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(data.name), 1)),
                      (data.system)
                        ? (_openBlock(), _createBlock(_unref(Tag), {
                            key: 2,
                            pt: {
										label: { class: 'tw3-font-normal' },
										root: { class: 'tw3-ml-3' }
									},
                            severity: "info",
                            value: "System"
                          }))
                        : _createCommentVNode("", true)
                    ], 4))
              ]),
              _: 1
            }),
            _createVNode(_unref(Column), {
              field: "code",
              header: "Account number"
            }, {
              body: _withCtx(({ data }) => [
                (_unref(loading))
                  ? (_openBlock(), _createBlock(_unref(Skeleton), {
                      key: 0,
                      height: "2rem"
                    }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(data.code), 1)
                    ], 64))
              ]),
              _: 1
            }),
            _createVNode(_unref(Column), {
              field: "subtype",
              header: "Subtype"
            }, {
              body: _withCtx(({ data }) => [
                (_unref(loading))
                  ? (_openBlock(), _createBlock(_unref(Skeleton), {
                      key: 0,
                      height: "2rem"
                    }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(data.subtype), 1)
                    ], 64))
              ]),
              _: 1
            }),
            _createVNode(_unref(Column), {
              field: "balance",
              header: "Balance",
              pt: {
						filterRemove: { style: 'display: none' }
					},
              showAddButton: false,
              showFilterMatchModes: false,
              showFilterOperator: false
            }, {
              body: _withCtx(({ data }) => [
                (_unref(loading))
                  ? (_openBlock(), _createBlock(_unref(Skeleton), {
                      key: 0,
                      height: "2rem"
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createTextVNode(_toDisplayString(data.balance ? _unref(formatMoney)(data.balance) : '') + " ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(
									data.balance &&
									data.balanceType == _unref(BalanceType).BALANCE_TYPE_YTD
										? 'tw3-opacity-40'
										: 'tw3-opacity-0'
								)
                      }, " YTD ", 2)
                    ]))
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, _toDisplayString(filterModel.matchMode == 'gte'
										? 'Greater or equal than'
										: 'Less or equal than'), 1),
                  _createVNode(InputMoney, {
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    name: "gte"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ])
              ]),
              filterclear: _withCtx(() => [
                _createVNode(_unref(Button), {
                  outlined: "",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (clearFilterBalance()))
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" Clear ")
                  ])),
                  _: 1
                })
              ]),
              filtericon: _withCtx(() => [
                _createElementVNode("i", {
                  class: _normalizeClass(
								isEmptyFilterBalance.value ? 'pi pi-filter' : 'pi pi-filter-fill'
							)
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_unref(Column), {
              field: "status",
              header: "Status",
              showAddButton: false,
              showClearButton: false,
              showFilterMatchModes: false,
              showFilterOperator: false
            }, {
              body: _withCtx(({ data }) => [
                (_unref(loading))
                  ? (_openBlock(), _createBlock(_unref(Skeleton), {
                      key: 0,
                      height: "2rem"
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createVNode(_unref(Tag), {
                        "pt:label:class": "tw3-font-normal",
                        severity: 
									data.status == _unref(AccountStatus).ACCOUNT_STATUS_ACTIVE
										? 'success'
										: 'danger'
								,
                        value: 
									data.status == _unref(AccountStatus).ACCOUNT_STATUS_ACTIVE
										? 'Active'
										: 'Inactive'
								
                      }, null, 8, ["severity", "value"])
                    ]))
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_unref(Select), {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  optionLabel: "name",
                  options: statusOptions,
                  optionValue: "id",
                  placeholder: "All statuses"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              filtericon: _withCtx(() => [
                _createElementVNode("i", {
                  class: _normalizeClass(
								isEmptyFilterStatus.value ? 'pi pi-filter' : 'pi pi-filter-fill'
							)
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_unref(Column), {
              field: "description",
              header: "Description"
            }, {
              body: _withCtx(({ data }) => [
                (_unref(loading))
                  ? (_openBlock(), _createBlock(_unref(Skeleton), {
                      key: 0,
                      height: "2rem"
                    }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(data.description), 1)
                    ], 64))
              ]),
              _: 1
            }),
            _createVNode(_unref(Column), { pt: { bodyCell: { class: '!tw3-py-0' } } }, {
              body: _withCtx(({ data }) => [
                (_unref(loading))
                  ? (_openBlock(), _createBlock(_unref(Skeleton), {
                      key: 0,
                      height: "2rem"
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _withDirectives((_openBlock(), _createBlock(_unref(Button), {
                        "aria-label": "Edit",
                        rounded: "",
                        severity: "secondary",
                        text: "",
                        onClick: ($event: any) => (edit(data.id))
                      }, {
                        icon: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createElementVNode("i", { class: "material-icons md-edit" }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["onClick"])), [
                        [
                          _directive_tooltip,
                          { value: 'Edit account', showDelay: 400 },
                          void 0,
                          { top: true }
                        ]
                      ])
                    ]))
              ]),
              _: 1
            })
          ]),
          _: 2
        }, [
          (!_unref(loading))
            ? {
                name: "empty",
                fn: _withCtx(() => [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20" }, [
                    _createElementVNode("p", { class: "tw3-text-center tw3-text-3xl" }, "Accounts not found")
                  ], -1))
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["filters", "rowHover", "value"])
      ]),
      _: 1
    }),
    _createVNode(AccountEdit, {
      account: selectedAccount.value,
      "onUpdate:account": _cache[6] || (_cache[6] = ($event: any) => ((selectedAccount).value = $event)),
      visible: showEditAccount.value,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((showEditAccount).value = $event))
    }, null, 8, ["account", "visible"])
  ], 64))
}
}

})