export enum PaymentCategoryId {
	CATEGORY_CASH = 'CATEGORY_CASH',
	CATEGORY_CHECK = 'CATEGORY_CHECK',
	CATEGORY_BANK_TRANSFER = 'CATEGORY_BANK_TRANSFER',
	CATEGORY_MANUAL_CARD = 'CATEGORY_MANUAL_CARD',
	CATEGORY_OTHER = 'CATEGORY_OTHER',
	CATEGORY_CARD = 'CATEGORY_CARD'
}

export type PaymentCategoryData = {
	categoryId: PaymentCategoryId;
	accountId: number;
};

export type PaymentSettings = {
	accountReceivableId?: number;
	unearnedRevenueId?: number;
	unappliedCustomerPaymentsId?: number;
	category: PaymentCategoryData[];
};

export type GetPaymentSettingsResponse = {
	settings: PaymentSettings;
};

export type UpdatePaymentSettingsRequest = PaymentSettings & {
	organizationId: number;
};
