interface CustomerDetails {
	Name: string;
	CompanyName: string;
	Status: string;
	Guest: number;
	isInsurance: number;
	isCommercial: number;
	id: number;
}

export interface NewestInvoices {
	jobId: number;
	shopId: number;
	total: number;
	modified: string;
	customer: CustomerDetails;
	billTo: CustomerDetails;
}

export interface DashboardLifetimeData {
	amount: number;
	date: string;
}

export interface DashboardAvgWeightData {
	amount: number;
	weight: number;
	date: string;
}

export enum TimeRange {
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	YEAR = 'YEAR'
}

export interface DashboardLifetimeObjectNumber {
	data: DashboardLifetimeData[];
	count: number;
	difference: number;
}

export interface DashboardLifetimeObjectString {
	data: DashboardLifetimeData[];
	count: string;
	difference: number;
}

export interface DashboardMainGraph {
	revenue: DashboardLifetimeData[];
	invoices: DashboardLifetimeData[];
}

export interface FilteredRequestParams {
	time_range: TimeRange;
	shop_ids?: number[];
}

export type CustomerFilteredRequestParams = FilteredRequestParams & {
	organization_ids?: number[];
};

export interface DashboardHeaderTooltip {
	data: string;
	title: string;
	desc: string;
}
