import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tw3-grid tw3-grid-cols-1 tw3-gap-4" }
const _hoisted_2 = { class: "tw3-flex tw3-flex-row tw3-gap-4" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1" }
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "tw3-flex tw3-flex-col tw3-gap-0" }
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "tw3-flex tw3-flex-col tw3-grow" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "tw3-flex tw3-flex-col tw3-grow" }
const _hoisted_12 = { class: "tw3-flex tw3-flex-col tw3-grow" }
const _hoisted_13 = { class: "tw3-flex tw3-justify-between tw3-mt-5" }
const _hoisted_14 = { class: "tw3-flex tw3-justify-end" }

import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Fieldset from 'primevue/fieldset';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Textarea from 'primevue/textarea';
import { computed, nextTick, ref, toRefs, watch } from 'vue';

import { Account } from '@/entities/accounting/accounts/lib/types';
import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import useTransactionTypesStore from '@/entities/accounting/transactions/lib/typeStore';
import AccountSelect from '@/features/accounting/AccountSelect.vue';
import ShopSelect from '@/features/accounting/ShopSelect.vue';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';

import { LedgerEntryAction, useLedgerEntryForm } from './useLedgerEntryForm';

const cid = 'accounting-ledger-entry-edit';


export default /*@__PURE__*/_defineComponent({
  __name: 'LedgerEntryEdit',
  props: /*@__PURE__*/_mergeModels({
    account: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["saved"], ["update:visible"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

const emit = __emit;

const transactionStore = useTransactionStore();
const { selectedTransaction } = storeToRefs(transactionStore);
const { typesById } = storeToRefs(useTransactionTypesStore());

const transaction = computed(() => selectedTransaction.value!);
const editMode = computed(() => transaction.value?.id !== 0);
const visible = _useModel<boolean>(__props, 'visible');
const props = __props;

const { account } = toRefs(props);

const {
	init,
	valid,
	mainAccountId,
	transactionDt,
	description,
	mainItemDescription,
	shopId,
	items,
	itemsErrors,
	addItem,
	removeItem,
	totalAmount,
	action,
	save: doSave,
	reset,
	fieldLabels
} = useLedgerEntryForm(account);

const shopSelect = ref<typeof InputText>();

watch(
	visible,
	() => {
		if (visible.value) {
			init();
			nextTick(() => {
				// Focus the first input
				shopSelect.value?.$el.focus();
			});
		}
	},
	{ immediate: true }
);
__expose({ init });

const close = () => {
	visible.value = false;
};

const toggleAction = () => {
	action.value =
		action.value === LedgerEntryAction.INCREASE
			? LedgerEntryAction.DECREASE
			: LedgerEntryAction.INCREASE;
};

const errorTooltipPT = {
	text: {
		style: { backgroundColor: 'rgb(248 113 113)' }
	},
	arrow: {
		style: { borderTopColor: 'rgb(248 113 113)' }
	}
};

const save = () => {
	doSave(async () => {
		close();
		emit('saved');
	});
};

const saveAndNew = () => {
	doSave(async () => {
		reset();
		shopSelect.value?.$el.focus();
		emit('saved');
	});
};

const loading = computed(() => !selectedTransaction.value || !typesById.value);

const title = computed(() => {
	if (loading.value) {
		return 'Loading...';
	}
	if (editMode.value) {
		return `Ledger entry #${transaction.value?.id}`;
	}
	return 'New Ledger Entry';
});

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(Dialog), {
    visible: visible.value,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((visible).value = $event)),
    appendTo: "#vue3app",
    header: title.value,
    modal: "",
    style: { width: '60rem' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", {
              class: "tw3-pl-1",
              for: `${cid}-shop-id`
            }, _toDisplayString(_unref(fieldLabels).shopId), 9, _hoisted_4),
            (loading.value)
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2.2rem"
                }))
              : (_openBlock(), _createBlock(ShopSelect, {
                  key: 1,
                  id: `${cid}-shop-id`,
                  ref_key: "shopSelect",
                  ref: shopSelect,
                  modelValue: _unref(shopId),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(shopId) ? (shopId).value = $event : null)),
                  loading: loading.value
                }, null, 8, ["id", "modelValue", "loading"]))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", {
              class: "tw3-pl-1",
              for: `${cid}-transaction-dt`
            }, _toDisplayString(_unref(fieldLabels).transactionDt), 9, _hoisted_6),
            (loading.value)
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2.2rem"
                }))
              : (_openBlock(), _createBlock(DatePicker, {
                  key: 1,
                  id: `${cid}-transaction-dt`,
                  modelValue: _unref(transactionDt),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(transactionDt) ? (transactionDt).value = $event : null))
                }, null, 8, ["id", "modelValue"]))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-description`
          }, _toDisplayString(_unref(fieldLabels).description), 9, _hoisted_8),
          (loading.value)
            ? (_openBlock(), _createBlock(_unref(Skeleton), {
                key: 0,
                height: "3.4rem"
              }))
            : (_openBlock(), _createBlock(_unref(Textarea), {
                key: 1,
                id: `${cid}-description`,
                modelValue: _unref(description),
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(description) ? (description).value = $event : null))
              }, null, 8, ["id", "modelValue"]))
        ]),
        _createVNode(_unref(Fieldset), {
          class: "relative",
          legend: _unref(fieldLabels).items,
          pt: {
					legendLabel: { class: 'gb-label ' },
					legend: { class: '!tw3-pb-1' },
					contentContainer: { class: 'tw3-pt-3' },
					root: { class: '!tw3-pb-2' }
				}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DataTable), {
              pt: {
						column: {
							headerCell: {
								style: 'padding: 0px 0.8rem 0.4rem 0px;'
							},
							bodyCell: {
								style: 'padding: 0.2rem 0.8rem 0rem 0px; border: none'
							},
							footerCell: {
								style: 'padding: 0px 0.8rem 0px 0px; border: none'
							}
						}
					},
              scrollable: "",
              scrollHeight: "flex",
              size: "small",
              tableClass: "tw3-max-w-full",
              value: loading.value ? new Array(1) : _unref(items)
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(Column), {
                  field: "account",
                  style: {"width":"27%"}
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, [
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "gb-label tw3-pl-1" }, "Account", -1)),
                      (loading.value)
                        ? (_openBlock(), _createBlock(_unref(Skeleton), {
                            key: 0,
                            height: "2.2rem"
                          }))
                        : (_openBlock(), _createBlock(AutoCompleteSelect, {
                            key: 1,
                            modelValue: _unref(mainAccountId),
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(mainAccountId) ? (mainAccountId).value = $event : null)),
                            disabled: "",
                            fluid: "",
                            options: [_unref(account)],
                            optionValue: "id"
                          }, null, 8, ["modelValue", "options"]))
                    ])
                  ]),
                  body: _withCtx(({ data, index }) => [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_unref(Skeleton), {
                          key: 0,
                          height: "2.2rem"
                        }))
                      : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(AccountSelect, {
                            modelValue: data.value.accountId,
                            "onUpdate:modelValue": ($event: any) => ((data.value.accountId) = $event),
                            invalid: !!_unref(itemsErrors)[index].accountId
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "invalid"])
                        ])), [
                          [
                            _directive_tooltip,
                            {
									value: _unref(itemsErrors)[index].accountId,
									pt: errorTooltipPT
								},
                            void 0,
                            { top: true }
                          ]
                        ])
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "amount",
                  style: {"width":"18%"}
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, [
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "gb-label tw3-pl-1" }, "Amount $", -1)),
                      (loading.value)
                        ? (_openBlock(), _createBlock(_unref(Skeleton), {
                            key: 0,
                            height: "2.2rem"
                          }))
                        : (_openBlock(), _createBlock(_unref(InputGroup), { key: 1 }, {
                            default: _withCtx(() => [
                              _withDirectives(_createVNode(_unref(Button), {
                                icon: 
											_unref(action) == _unref(LedgerEntryAction).INCREASE
												? 'pi pi-arrow-circle-up'
												: 'pi pi-arrow-circle-down'
										,
                                severity: "primary",
                                onClick: toggleAction
                              }, null, 8, ["icon"]), [
                                [
                                  _directive_tooltip,
                                  
											_unref(action) == _unref(LedgerEntryAction).INCREASE
												? 'Increase'
												: 'Decrease'
										,
                                  void 0,
                                  { top: true }
                                ]
                              ]),
                              _createVNode(InputMoney, {
                                modelValue: _unref(totalAmount),
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(totalAmount) ? (totalAmount).value = $event : null)),
                                disabled: "",
                                pt: {
											pcInput: {
												root: {
													class: 'tw3-font-bold'
												}
											}
										}
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }))
                    ])
                  ]),
                  body: _withCtx(({ data, index }) => [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_unref(Skeleton), {
                          key: 0,
                          height: "2.2rem"
                        }))
                      : (_openBlock(), _createBlock(_unref(InputGroup), { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(InputGroupAddon), null, {
                              default: _withCtx(() => [
                                _withDirectives(_createElementVNode("i", {
                                  class: _normalizeClass(
											_unref(action) == _unref(LedgerEntryAction).INCREASE
												? 'pi pi-arrow-circle-down'
												: 'pi pi-arrow-circle-up'
										)
                                }, null, 2), [
                                  [
                                    _directive_tooltip,
                                    
											_unref(action) == _unref(LedgerEntryAction).INCREASE
												? 'Decrease'
												: 'Increase'
										,
                                    void 0,
                                    { top: true }
                                  ]
                                ])
                              ]),
                              _: 1
                            }),
                            _withDirectives(_createVNode(InputMoney, {
                              modelValue: data.value.amount,
                              "onUpdate:modelValue": ($event: any) => ((data.value.amount) = $event),
                              invalid: !!_unref(itemsErrors)[index].amount
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "invalid"]), [
                              [
                                _directive_tooltip,
                                {
										value: _unref(itemsErrors)[index].amount,
										pt: errorTooltipPT
									},
                                void 0,
                                { top: true }
                              ]
                            ])
                          ]),
                          _: 2
                        }, 1024))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "description",
                  style: {"width":"32%"}
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "gb-label tw3-pl-1" }, "Item Description", -1)),
                      (loading.value)
                        ? (_openBlock(), _createBlock(_unref(Skeleton), {
                            key: 0,
                            height: "2.2rem"
                          }))
                        : (_openBlock(), _createBlock(_unref(InputText), {
                            key: 1,
                            modelValue: _unref(mainItemDescription),
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(mainItemDescription) ? (mainItemDescription).value = $event : null)),
                            fluid: "",
                            type: "text"
                          }, null, 8, ["modelValue"]))
                    ])
                  ]),
                  body: _withCtx(({ data }) => [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_unref(Skeleton), {
                          key: 0,
                          height: "2.2rem"
                        }))
                      : (_openBlock(), _createBlock(_unref(InputText), {
                          key: 1,
                          modelValue: data.value.description,
                          "onUpdate:modelValue": ($event: any) => ((data.value.description) = $event),
                          fluid: "",
                          type: "text"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "actions",
                  pt: {
							headerCell: { class: '!tw3-py-0' }
						},
                  style: {"width":"4%","max-width":"48px"}
                }, {
                  footer: _withCtx(() => [
                    _withDirectives(_createVNode(_unref(Button), {
                      class: "tw3-mr-2",
                      icon: "pi pi-plus",
                      rounded: "",
                      text: "",
                      onClick: _unref(addItem)
                    }, null, 8, ["onClick"]), [
                      [
                        _directive_tooltip,
                        'Add item',
                        void 0,
                        { top: true }
                      ]
                    ])
                  ]),
                  body: _withCtx(({ index }) => [
                    (!loading.value)
                      ? (_openBlock(), _createBlock(_unref(Button), {
                          key: 0,
                          disabled: _unref(items).length <= 1,
                          icon: "pi pi-trash",
                          rounded: "",
                          text: "",
                          onClick: ($event: any) => (_unref(removeItem)(index))
                        }, null, 8, ["disabled", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 8, ["legend"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "tw3-flex tw3-justify-start" }, null, -1)),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_unref(Button), {
            class: "tw3-ml-5",
            label: 'Close',
            severity: "secondary",
            onClick: close
          }),
          _createVNode(_unref(Button), {
            class: "tw3-ml-5",
            disabled: loading.value || !_unref(valid),
            label: 'Save',
            onClick: save
          }, null, 8, ["disabled"]),
          _createVNode(_unref(Button), {
            class: "tw3-ml-5",
            disabled: loading.value || !_unref(valid),
            label: 'Save and New',
            onClick: saveAndNew
          }, null, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})