import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col" }
const _hoisted_2 = { class: "tw3-flex tw3-items-end" }
const _hoisted_3 = {
  key: 0,
  class: "tw3-ml-1 gb-label",
  style: {"color":"var(--p-surface-600)","background-color":"var(--p-surface-100)","padding-left":"0.3rem","padding-right":"0.3rem","border-radius":"0.2rem"}
}
const _hoisted_4 = { class: "gb-label" }

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';

import useAccountingStore from './lib/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountSelect',
  props: /*@__PURE__*/_mergeModels({
    invalid: { type: Boolean, default: false },
    placeholder: { default: 'Select Account' },
    types: { default: () => [] }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

// Account selector that uses fearures/accounting/lib/store store
const value = _useModel<number | undefined>(__props, "modelValue");

const props = __props;

const accountingStore = useAccountingStore();
const { typesById, subtypesById, accounts } = storeToRefs(accountingStore);

const filteredAccounts = computed(() => {
	if (props.types.length) {
		return accounts.value.filter(account =>
			props.types.includes(account.typeId)
		);
	}
	return accounts.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AutoCompleteSelect, {
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    filterBy: ['name', 'code'],
    fluid: "",
    invalid: props.invalid,
    optionGroup: "typeId",
    optionPrefix: "code",
    options: filteredAccounts.value,
    optionValue: "id",
    placeholder: _ctx.placeholder,
    pt: {
			option: {
				style: 'padding-top: 0.2rem; padding-bottom: 0.2rem'
			},
			optionGroup: {
				style: 'color: inherit'
			}
		}
  }, {
    option: _withCtx((slotProps) => [
      _renderSlot(_ctx.$slots, "option", {
        option: slotProps.option
      }, () => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(slotProps.option.name), 1),
            (slotProps.option.code)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(slotProps.option.code), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(subtypesById)[slotProps.option.subtypeId].name), 1)
        ])
      ])
    ]),
    optiongroup: _withCtx((slotProps) => [
      _createTextVNode(_toDisplayString(_unref(typesById)[slotProps.option.label].name), 1)
    ]),
    _: 3
  }, 8, ["modelValue", "invalid", "options", "placeholder"]))
}
}

})