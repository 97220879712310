import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "dropdownRef",
  class: "dropdown-container"
}
const _hoisted_2 = { class: "tw3-flex tw3-flex-col" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "tw3-flex tw3-items-center tw3-capitalize tw3-px-2 tw3-py-0.5 tw3-gap-2 tw3-text-sm" }

import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Listbox from 'primevue/listbox';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import type {
	JobTag,
	newTag
} from '@/widgets/rejectedInvoicesDialog/model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'TagAddingDialog',
  props: /*@__PURE__*/_mergeModels({
    jobID: { default: 0 },
    tagsList: { default: () => [] },
    currentTagsList: { default: () => [] },
    isLoading: { type: Boolean, default: false }
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["addNewTag"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

const showModal = _useModel<boolean>(__props, 'visible');

const { t } = useI18n();

const props = __props;
const emit = __emit;

const preparedTagForOptions = computed(() => {
	return props.tagsList.filter(
		item1 => !props.currentTagsList.some(item2 => item1.id === item2.id)
	);
});

const selectedtags = ref<newTag[]>([]);
const handleAddingTags = () => {
	if (selectedtags.value && selectedtags.value.length) {
		const preparedTags: newTag[] = selectedtags.value.map(
			(element: newTag) => ({
				created: -1,
				modified: -1,
				id: -1,
				job_id: props.jobID,
				shop_tag_id: element.id
			})
		);
		emit('addNewTag', preparedTags);
		selectedtags.value = [];
		showModal.value = false;
	}
};

const openModal = () => {
	showModal.value = true;
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_unref(Button), {
      class: "w-full md:w-14rem",
      icon: "pi pi-plus-circle",
      loading: _ctx.isLoading,
      text: "",
      onClick: openModal
    }, null, 8, ["loading"]), [
      [
        _directive_tooltip,
        _unref(t)('addNewTag'),
        void 0,
        { top: true }
      ]
    ]),
    _createVNode(_unref(Dialog), {
      id: "vue3JobTagsAdding",
      visible: showModal.value,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((showModal).value = $event)),
      appendTo: "#vue3app",
      class: "tw3-max-h-[600px] tw3-max-w-[600px]",
      draggable: false,
      header: `${_unref(t)('rejectedInvoices.addTagsTitle')}`,
      modal: "",
      "pt:content": 'tw3-h-full tw3-flex tw3-flex-col',
      "pt:root:class": '!tw3-border-0 tw3-overflow-hidden'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(Listbox), {
            modelValue: selectedtags.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedtags).value = $event)),
            checkmark: "",
            class: "w-full md:w-56",
            filter: "",
            listStyle: "max-height:250px",
            multiple: "",
            optionLabel: "text",
            options: preparedTagForOptions.value,
            "pt:root:class": "!tw3-border-0"
          }, {
            option: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_unref(Badge), {
                    class: "!tw3-w-2.5 !tw3-h-2.5 !tw3-min-w-2.5",
                    "pt:root:style": `background-color: ${slotProps.option.color}`,
                    size: "small"
                  }, null, 8, ["pt:root:style"]),
                  _createTextVNode(" " + _toDisplayString(slotProps.option.text.toLowerCase()), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options"]),
          _createVNode(_unref(Button), {
            class: "w-full md:w-14rem",
            label: _unref(t)('rejectedInvoices.applyTags'),
            "pt:root:class": "tw3-rounded-t-none",
            size: "small",
            onClick: handleAddingTags
          }, null, 8, ["label"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 512))
}
}

})