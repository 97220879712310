<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Card from 'primevue/card';
import ProgressSpinner from 'primevue/progressspinner';

import useAccountingStore from '@/features/accounting/lib/store';

const accountingStore = useAccountingStore();

const { profiles, profilesLoaded } = storeToRefs(accountingStore);
</script>

<template>
	<div class="gb-flex-container tw3-grow">
		<template v-if="profilesLoaded">
			<router-view v-if="profiles.length" />
			<Card v-else>
				<template #title>Accounting</template>
				<template #content>
					<p class="m-0">Accounting disabled</p>
				</template>
			</Card>
		</template>
		<div v-else class="tw3-flex tw3-flex-grow tw3-items-center">
			<ProgressSpinner />
		</div>
	</div>
</template>
