import { useAsyncState } from '@vueuse/core';
import { defineStore, storeToRefs } from 'pinia';
import { onMounted, reactive, ref, watchEffect } from 'vue';

import { getMany, patch } from '@/entities/claim/lib/api';
import { useUserStore } from '@/entities/user/lib/store';
import { filterEmptyFields } from '@/shared/helpers';
import { useInsuranceStore } from '@/widgets/insurance/lib/store';

import { filtersInit } from './constants';
import { FetchParams, Filters, FiltersWithMetadata } from './types';

export const useClaimsStore = defineStore('claims', () => {
	const { user } = storeToRefs(useUserStore());
	const insuranceStore = useInsuranceStore();

	const shops = reactive(user.value.shops || []);
	const selectedShop = ref(
		shops.length
			? shops.find((v: any) => v.id === user.value.user.shop_id)
			: null
	);
	const selectedChipNumber = ref(0);
	const sort = reactive({ desc: true, field: 'submittedDt' });
	const filters = ref<FiltersWithMetadata<Filters>>({
		...filtersInit
	});
	const pagination = reactive({
		page: 1,
		pageSize: 10
	});

	const claims = useAsyncState(
		(args: FetchParams) => {
			return getMany({
				'orderBy.desc': args.sort?.desc,
				'orderBy.field': args.sort?.field,
				page: args.pagination?.page,
				pageSize: pagination?.pageSize,
				shopId: args?.shopId,
				...filterEmptyFields(filters?.value as Record<string, any>, 'value')
			});
		},
		{ claim: [], totalCount: 0 },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const handleSort = (sortData: { field: string | null; desc: boolean }) => {
		if (sortData.field === null || sortData.field.trim() === '') return;

		sort.field = sortData.field;
		sort.desc = sortData.desc;
	};

	const updateClaimAndRefetchClaims = async (
		id: number,
		archived: boolean,
		fetchClaimsParams: FetchParams
	) => {
		try {
			await patch(id, archived);
			await claims.execute(0, fetchClaimsParams);
		} catch (error) {
			console.error(error);
		}
	};

	onMounted(async () => {
		// Ensure this part runs in a proper context during tests
		await insuranceStore.insurances.execute(0, { providerName: 'glaxis' });
	});

	const composeGetClaimsArgs = async () => {
		const params: any = {
			filters: filters.value,
			pagination: {
				page: pagination.page
			},
			selectedChip: selectedChipNumber.value,
			sort: {
				desc: sort.desc,
				field: sort.field
			}
		};

		if (selectedShop.value) {
			params.shopId = selectedShop.value.id;
		}

		return params;
	};

	watchEffect(async () => {
		await claims.execute(0, await composeGetClaimsArgs());
	});

	return {
		claims,
		composeGetClaimsArgs,
		filters,
		handleSort,
		pagination,
		selectedChipNumber,
		selectedShop,
		shops,
		sort,
		updateClaimAndRefetchClaims
	};
});
