<script setup lang="ts">
import Dialog from 'primevue/dialog';
import OverlayBadge from 'primevue/overlaybadge';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import Tabs from 'primevue/tabs';
import { defineModel } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useRejectedInvoicesStore from '../model/store';

import RejectedInvoicesTable from './table/RejectedInvoicesTable.vue';

const rejectedInvoicesStore = useRejectedInvoicesStore();

const tabs = computed(() => {
	const invoicesCount = rejectedInvoicesStore.ediQueueStatusCounts;
	const tabs: {
		label: string;
		key: string;
		count?: number;
		customColor?: string;
	}[] = [
		{
			customColor: '#dc3545',
			key: 'rejected',
			label: t('rejectedInvoices.tabs.rejected')
		},
		{
			customColor: '#26af5d',
			key: 'queued',
			label: t('rejectedInvoices.tabs.queued')
		},
		{
			customColor: '#4d26af',
			key: 'unsubmitted',
			label: t('rejectedInvoices.tabs.unsubmitted')
		}
	];
	if (rejectedInvoicesStore.isUserAdmin) {
		tabs.push(
			{
				count: invoicesCount?.PreparingCount,
				key: 'preparing',
				label: t('rejectedInvoices.tabs.preparing')
			},
			{
				count: invoicesCount?.PendingCount,
				key: 'pending',
				label: t('rejectedInvoices.tabs.pending')
			},
			{
				count: invoicesCount?.FailedCount,
				customColor: '#e66926',
				key: 'failed',
				label: t('rejectedInvoices.tabs.failed')
			}
		);
	}

	return tabs;
});

function onTabChange(tabName: string) {
	rejectedInvoicesStore.changeCurrentTab(tabName);
}

const { t } = useI18n();

const showModal = defineModel<boolean>('show');
</script>

<template>
	<Dialog
		id="vue3RejectedInvoicesDialog"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-w-full tw3-h-full tw3-max-w-[1400px]"
		:draggable="false"
		:header="`${t('rejectedInvoices.title')}`"
		modal
		:pt:content="'tw3-h-full tw3-flex tw3-flex-col'"
		:pt:root:class="'!tw3-border-0 tw3-overflow-hidden'"
	>
		<Tabs
			:pt="{
				panelcontainer: { style: { padding: '0' } }
			}"
			:value="rejectedInvoicesStore.currentTab"
			@update:value="onTabChange"
		>
			<TabList>
				<Tab
					v-for="tab in tabs"
					:key="tab.key"
					:pt:root:class="'!tw3-pt-[16px] !tw3-overflow-visible'"
					:value="tab.key"
				>
					<OverlayBadge
						v-if="tab?.count && tab?.count > 0"
						:pt:pcbadge:root="{
							style: {
								'background-color': tab?.customColor || '#e66926',
								top: '-6px'
							},
							class: '!tw3-text-xs'
						}"
						size="small"
						:value="tab.count"
					>
						{{ tab.label }}
					</OverlayBadge>
					<span v-else>{{ tab.label }}</span>
				</Tab>
			</TabList>
		</Tabs>
		<RejectedInvoicesTable />
	</Dialog>

	<Dialog
		id="vue3RejectedInvoiceDetailsDialog"
		v-model:visible="rejectedInvoicesStore.showInvoiceDetail"
		appendTo="#vue3app"
		class="tw3-max-w-[600px]"
		:header="rejectedInvoicesStore.invoiceDetailsData.status"
		modal
	>
		<template #default>
			<p v-html="rejectedInvoicesStore.invoiceDetailsData.message"></p>
		</template>
	</Dialog>
</template>
