/* eslint-disable sort-keys */
import { FilterMatchMode } from '@primevue/core/api';

import { i18n } from '@/shared/i18n';

import {
	BurstFilters,
	FaxFiltersWithMetaData,
	FaxTableHeaders,
	InboxFilters,
	OutboxFilters
} from './types';

const { t } = i18n.global;

// Sent to is not important cause it is the same
export const faxInboxHeaders: FaxTableHeaders[] = [
	{ key: 'dateTime', title: t('faxDialog.inbox.dateTime') },
	{ key: 'sentFrom', title: t('faxDialog.inbox.sentFrom') },
	{ key: 'sentTo', title: t('faxDialog.inbox.sentTo') },
	{ key: 'pages', title: t('faxDialog.inbox.pages') },
	{ key: 'referral', title: t('faxDialog.inbox.referral') },
	{ key: 'status', title: t('faxDialog.inbox.status') }
];

export const faxOutboxHeaders: FaxTableHeaders[] = [
	{ key: 'dateTime', title: t('faxDialog.outbox.dateTime') },
	{ key: 'fileName', title: t('faxDialog.outbox.file') },
	{ key: 'sentFrom', title: t('faxDialog.outbox.sentFrom') },
	{ key: 'sentTo', title: t('faxDialog.outbox.sentTo') },
	{ key: 'pages', title: t('faxDialog.outbox.pages') },
	{ key: 'status', title: t('faxDialog.outbox.status') }
];

export const faxBurstHeaders: FaxTableHeaders[] = [
	{ key: 'fileName', title: t('faxDialog.outbox.file') },
	{ key: 'dateTime', title: t('faxDialog.outbox.dateTime') }
];

export const faxInboxFilters: FaxFiltersWithMetaData<InboxFilters> = {
	dateTime: { matchMode: FilterMatchMode.EQUALS, value: '' },
	sentFrom: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	sentTo: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	pages: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	referral: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	status: { matchMode: FilterMatchMode.EQUALS, value: '' }
};

export const faxOutboxFilters: FaxFiltersWithMetaData<OutboxFilters> = {
	dateTime: { matchMode: FilterMatchMode.EQUALS, value: '' },
	fileName: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	sentFrom: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	sentTo: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	pages: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	status: { matchMode: FilterMatchMode.EQUALS, value: '' }
};

export const faxBurstFilters: FaxFiltersWithMetaData<BurstFilters> = {
	fileName: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	dateTime: { matchMode: FilterMatchMode.CONTAINS, value: '' }
};

export const faxStatusOptions = [
	{
		label: t('faxDialog.status.unread', { key: 'UNREAD' }),
		value: 'UNREAD'
	},
	{
		label: t('faxDialog.status.read', { key: 'READ' }),
		value: 'READ'
	},
	{
		label: t('faxDialog.status.sent', { key: 'SENT' }),
		value: 'SENT'
	},
	{
		label: t('faxDialog.status.error', { key: 'ERROR' }),
		value: 'ERROR'
	},
	{
		label: t('faxDialog.status.sending', { key: 'SENDING' }),
		value: 'SENDING'
	},
	{
		label: t('faxDialog.status.queued', { key: 'QUEUED' }),
		value: 'QUEUED'
	}
];
