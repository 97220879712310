<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Popover from 'primevue/popover';
import { computed, ref } from 'vue';

import useAccountingStore from '@/features/accounting/lib/store';

defineProps<{
	rounded?: boolean;
}>();

const accountingStore = useAccountingStore();
const { types } = storeToRefs(accountingStore);
const values = computed(() => {
	return types.value.map(type => {
		return {
			name: type.name,
			type: type.creditAccount ? 'credit' : 'debit',
			increase: type.creditAccount ? 'Credit' : 'Debit',
			decrease: type.creditAccount ? 'Debit' : 'Credit'
		};
	});
});

const op = ref();
const toggle = (event: Event) => {
	op.value.toggle(event);
};
</script>

<template>
	<Button
		v-tooltip.top="'Debits And Credits Cheat Sheet'"
		icon="pi pi-question-circle"
		:rounded="rounded"
		text
		@click="toggle"
	/>
	<Popover ref="op">
		<div class="tw3-flex tw3-flex-col tw3-gap-4 tw3-w-[25rem]">
			<div class="tw3-font-bold tw3-pl-2">Debits And Credits Cheat Sheet</div>
			<DataTable showGridlines :value="values">
				<Column field="name" header="Name">
					<template #body="{ data }">
						<span :class="`${data.type}-type`">
							{{ data.name }}
						</span>
					</template>
				</Column>
				<Column field="increase" header="Increase">
					<template #body="{ data }">
						<span :class="`tw3-italic ${data.type}-type`">
							{{ data.increase }}
						</span>
					</template>
				</Column>
				<Column field="decrease" header="Decrease">
					<template #body="{ data }">
						<span :class="`tw3-italic ${data.type}-type`">
							{{ data.decrease }}
						</span>
					</template>
				</Column>
			</DataTable>
		</div>
	</Popover>
</template>

<style scoped>
:deep(.debit-type) {
	color: #1e3a8a;
}
:deep(.credit-type) {
	color: #881337;
}
</style>
