import { formatNumber, formatToOneDigit } from '@/widgets/dashboard/lib';

export const getPieOptions = (withLabels?: boolean) => {
	return {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: (ctx: any) => {
						const data = ctx.dataset.data;
						const value = ctx.parsed;

						const total = data.reduce(
							(acc: number, val: number) => acc + val,
							0
						);
						const percentage =
							formatToOneDigit((value / total) * 100).toFixed(1) + '%';
						return `${ctx.label}: ${formatNumber(value)} (${percentage})`;
					}
				}
			},
			datalabels: {
				display: withLabels,
				formatter: (value: number, context: any) => {
					const total = context.chart.data.datasets[0].data.reduce(
						(a: number, b: number) => a + b,
						0
					);
					const percentage = (value / total) * 100;
					return percentage < 0.1
						? ''
						: formatToOneDigit(percentage).toFixed(1) + '%';
				},
				color: 'white',
				font: {
					weight: 'bold',
					size: 18
				},
				anchor: 'center',
				align: 'center'
			}
		}
	};
};
