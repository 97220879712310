import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref } from "vue"

const _hoisted_1 = { class: "tw3-w-full" }
const _hoisted_2 = { class: "tw3-m-0 tw3-p-0 tw3-border-0 tw3-outline-0 tw3-list-none" }
const _hoisted_3 = { class: "tw3-flex tw3-justify-between tw3-items-center" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { formatNumber } from '@/widgets/dashboard/lib';

import { FollowUpJobs } from '../../model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnicianFollowUpInfo',
  props: {
    data: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const items = computed(() =>
	[
		{
			value: props.data.callBack,
			label: t('callBack'),
			background: '52, 205, 235'
		},
		{
			value: props.data.dropOff,
			label: t('dropOff'),
			background: '58, 0, 70'
		},
		{
			value: props.data.pickUp,
			label: t('pickUp'),
			background: '0, 70, 70'
		},
		{
			value: props.data.rAndI,
			label: t('rAndI'),
			background: '4, 204, 147'
		},
		{
			value: props.data.recal,
			label: t('recal'),
			background: '153, 52, 235'
		},
		{
			value: props.data.tint,
			label: t('tint'),
			background: '115, 82, 48'
		}
	].sort((a, b) => b.value - a.value)
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.label,
          class: "tw3-flex tw3-justify-between tw3-items-center tw3-py-2 tw3-border-b tw3-border-surface-200 dark:tw3-border-surface-700"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "tw3-w-8 tw3-h-8 tw3-rounded tw3-mr-2",
              style: _normalizeStyle(`
							background-color: rgba(${item.background}, 0.75);
							box-shadow: 0px 0px 10px rgba(${item.background}, 0.3);
						`)
            }, null, 4),
            _createElementVNode("span", null, _toDisplayString(item.label), 1)
          ]),
          _createElementVNode("span", null, _toDisplayString(_unref(formatNumber)(item.value)), 1)
        ]))
      }), 128))
    ])
  ]))
}
}

})