<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { formatNumber } from '@/widgets/dashboard/lib';

import { FollowUpJobs } from '../../model/types';

const { t } = useI18n();

const props = defineProps<{ data: FollowUpJobs }>();

const items = computed(() =>
	[
		{
			value: props.data.callBack,
			label: t('callBack'),
			background: '52, 205, 235'
		},
		{
			value: props.data.dropOff,
			label: t('dropOff'),
			background: '58, 0, 70'
		},
		{
			value: props.data.pickUp,
			label: t('pickUp'),
			background: '0, 70, 70'
		},
		{
			value: props.data.rAndI,
			label: t('rAndI'),
			background: '4, 204, 147'
		},
		{
			value: props.data.recal,
			label: t('recal'),
			background: '153, 52, 235'
		},
		{
			value: props.data.tint,
			label: t('tint'),
			background: '115, 82, 48'
		}
	].sort((a, b) => b.value - a.value)
);
</script>

<template>
	<div class="tw3-w-full">
		<ul class="tw3-m-0 tw3-p-0 tw3-border-0 tw3-outline-0 tw3-list-none">
			<li
				v-for="item in items"
				:key="item.label"
				class="tw3-flex tw3-justify-between tw3-items-center tw3-py-2 tw3-border-b tw3-border-surface-200 dark:tw3-border-surface-700"
			>
				<div class="tw3-flex tw3-justify-between tw3-items-center">
					<div
						class="tw3-w-8 tw3-h-8 tw3-rounded tw3-mr-2"
						:style="`
							background-color: rgba(${item.background}, 0.75);
							box-shadow: 0px 0px 10px rgba(${item.background}, 0.3);
						`"
					></div>
					<span>{{ item.label }}</span>
				</div>
				<span>{{ formatNumber(item.value) }}</span>
			</li>
		</ul>
	</div>
</template>
