import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Dialog from 'primevue/dialog';
import { GoogleMap, Marker } from 'vue3-google-map';

import { googleMapsConfig } from '@/shared/constants/google-maps';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressDialog',
  props: /*@__PURE__*/_mergeModels({
    lat: {},
    lng: {},
    fullAddress: {}
  }, {
    "show": { type: Boolean },
    "showModifiers": {},
  }),
  emits: ["update:show"],
  setup(__props: any) {


const showModal = _useModel<boolean>(__props, 'show');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3GoogleMapDialog",
    visible: showModal.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-max-w-[750px]",
    header: _ctx.fullAddress,
    modal: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(GoogleMap), {
        apiKey: _unref(googleMapsConfig).apiKey,
        center: { lat: _ctx.lat, lng: _ctx.lng },
        mapId: "DEMO_MAP_ID",
        style: {"width":"100%","height":"400px"},
        zoom: 15
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Marker), {
            options: { position: { lat: _ctx.lat, lng: _ctx.lng }, title: _ctx.fullAddress }
          }, null, 8, ["options"])
        ]),
        _: 1
      }, 8, ["apiKey", "center"])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})