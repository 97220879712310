import { DashboardLifetimeData } from '../model/types';

export const getChartLifetimeData = (
	data: DashboardLifetimeData[],
	color: string
) => {
	const documentStyle = getComputedStyle(document.documentElement);

	let borderColor: CanvasGradient | string =
		documentStyle.getPropertyValue('--p-blue-500');

	const canvas = document.createElement('canvas') as HTMLCanvasElement;
	if (canvas) {
		const ctx = canvas.getContext('2d');

		if (ctx) {
			const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
			gradient.addColorStop(0, `rgba(${color}, 0)`);
			gradient.addColorStop(1, `rgba(${color}, 1)`);
			borderColor = gradient;
		}
	}

	return {
		labels: data.map((_, index) => data[data.length - 1 - index].date),
		datasets: [
			{
				label: '',
				data: data.map((_, index) => data[data.length - 1 - index].amount),
				borderColor,
				pointBorderColor: 'transparent',
				pointBackgroundColor: 'transparent',
				fill: false,
				barPercentage: 0.5
			}
		]
	};
};
