import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-items-center tw3-gap-4" }
const _hoisted_2 = { class: "tw3-text-xl tw3-font-bold" }
const _hoisted_3 = {
  key: 0,
  class: "tw3-flex tw3-flex-1 tw3-justify-center tw3-items-center"
}
const _hoisted_4 = { class: "tw3-flex tw3-flex-col tw3-flex-1 tw3-border-l tw3-border-gray-200" }
const _hoisted_5 = { class: "tw3-mt-auto tw3-border-t tw3-border-gray-200 tw3-flex tw3-items-center tw3-justify-end tw3-gap-4 tw3-p-4" }

import { isEqual } from 'lodash-es';
import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import { useConfirm } from 'primevue/useconfirm';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import ContactInfo from '@/features/customer/editTabs/contactInfo/ContactInfo.vue';
import CustomerPreviousJobs from '@/features/customer/editTabs/previousJobs/CustomerPreviousJobs.vue';
import CustomerSettings from '@/features/customer/editTabs/settings/CustomerSettings.vue';
import CustomerShopSettings from '@/features/customer/editTabs/shopSettings/CustomerShopSettings.vue';
import CustomerVehicles from '@/features/customer/editTabs/vehicles/CustomerVehicles.vue';

import CustomerSidebar from './CustomerSidebar.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'editCustomerDialog',
  props: {
    "show": { type: Boolean },
    "showModifiers": {},
  },
  emits: ["update:show"],
  setup(__props) {

const tabs = {
	contactInfo: 'Contacts',
	previousJobs: 'Transactions',
	settings: 'Settings',
	shopSettings: 'Pricing',
	vehicles: 'Vehicles'
};

const confirm = useConfirm();
const store = useCustomerEditStore();
const { t } = useI18n();

const activeTab = ref(tabs.contactInfo);
const showModal = _useModel<boolean>(__props, 'show');

const close = () => {
	if (
		(store.customerStateBeforeChanges &&
			!isEqual(store.customerStateBeforeChanges, store.customer.state)) ||
		(!store.customerStateBeforeChanges && !store.customer.state.id)
	) {
		confirm.require({
			accept: () => {
				store.onClose();
				showModal.value = false;
			},
			acceptLabel: t('yes'),
			header: 'Confirmation',
			message: 'Close modal with unsaved changes?',
			rejectLabel: t('no'),
			rejectProps: {
				severity: 'secondary'
			}
		});
	} else {
		store.onClose();
		showModal.value = false;
	}
};

watch(
	() => showModal.value,
	() => {
		activeTab.value = tabs.contactInfo;
	}
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3EditCustomerDialog",
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-max-w-[1000px] tw3-h-[860px]",
    contentClass: "tw3-flex tw3-flex-1 tw3-border-t tw3-border-gray-200",
    contentStyle: "padding: 0; flex-direction: row",
    modal: "",
    "pt:mask:class": "custom-dialog-z-index",
    visible: showModal.value,
    "onUpdate:visible": close
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(store).customer.state.id
							? _unref(store).customer.state.is_insurance
								? 'Insurance Profile'
								: `${_unref(t)('customer')} Profile`
							: _unref(store).customer.state.is_insurance
								? 'New Insurance'
								: `New ${_unref(t)('customer')}`), 1),
        (_unref(store).customer.state.is_insurance && !_unref(store).customer.state.id)
          ? (_openBlock(), _createBlock(_unref(ButtonGroup), { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_unref(Button), {
                  label: "LYNX",
                  severity: "secondary",
                  onClick: _unref(store).setLynx
                }, null, 8, ["onClick"]),
                _createVNode(_unref(Button), {
                  label: "SGC",
                  severity: "secondary",
                  onClick: _unref(store).setSgc
                }, null, 8, ["onClick"]),
                _createVNode(_unref(Button), {
                  label: "NONE",
                  severity: "secondary",
                  onClick: _unref(store).setNone
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      (_unref(store).customer.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(ProgressSpinner), {
              "aria-label": "Loading",
              class: "tw3-fill-sky-500",
              fill: "transparent",
              style: {"width":"50px","height":"50px"}
            })
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(CustomerSidebar, { class: "tw3-w-72" }),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(Tabs), {
                value: activeTab.value,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((activeTab).value = $event)),
                class: "tw3-overflow-y-auto"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(TabList), { "pt:tabs:style": "background-color: transparent" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Tab), {
                        value: tabs.contactInfo
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tabs.contactInfo), 1)
                        ]),
                        _: 1
                      }, 8, ["value"]),
                      _createVNode(_unref(Tab), {
                        value: tabs.settings
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tabs.settings), 1)
                        ]),
                        _: 1
                      }, 8, ["value"]),
                      _createVNode(_unref(Tab), {
                        value: tabs.shopSettings
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tabs.shopSettings), 1)
                        ]),
                        _: 1
                      }, 8, ["value"]),
                      (!_unref(store).customer.state.is_insurance)
                        ? (_openBlock(), _createBlock(_unref(Tab), {
                            key: 0,
                            value: tabs.vehicles
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(tabs.vehicles), 1)
                            ]),
                            _: 1
                          }, 8, ["value"]))
                        : _createCommentVNode("", true),
                      (!_unref(store).customer.state.is_insurance)
                        ? (_openBlock(), _createBlock(_unref(Tab), {
                            key: 1,
                            value: tabs.previousJobs
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(tabs.previousJobs), 1)
                            ]),
                            _: 1
                          }, 8, ["value"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(TabPanels), {
                    class: "tw3-overflow-y-auto",
                    style: {"box-shadow":"inset 0 7px 10px -10px rgba(0, 0, 0, 0.3)"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(TabPanel), {
                        value: tabs.contactInfo
                      }, {
                        default: _withCtx(() => [
                          (activeTab.value === tabs.contactInfo)
                            ? (_openBlock(), _createBlock(ContactInfo, { key: 0 }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["value"]),
                      _createVNode(_unref(TabPanel), {
                        value: tabs.settings
                      }, {
                        default: _withCtx(() => [
                          (activeTab.value === tabs.settings)
                            ? (_openBlock(), _createBlock(CustomerSettings, { key: 0 }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["value"]),
                      _createVNode(_unref(TabPanel), {
                        value: tabs.shopSettings
                      }, {
                        default: _withCtx(() => [
                          (activeTab.value === tabs.shopSettings)
                            ? (_openBlock(), _createBlock(CustomerShopSettings, { key: 0 }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["value"]),
                      (!_unref(store).customer.state.is_insurance)
                        ? (_openBlock(), _createBlock(_unref(TabPanel), {
                            key: 0,
                            value: tabs.vehicles
                          }, {
                            default: _withCtx(() => [
                              (activeTab.value === tabs.vehicles)
                                ? (_openBlock(), _createBlock(CustomerVehicles, { key: 0 }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["value"]))
                        : _createCommentVNode("", true),
                      (!_unref(store).customer.state.is_insurance)
                        ? (_openBlock(), _createBlock(_unref(TabPanel), {
                            key: 1,
                            value: tabs.previousJobs
                          }, {
                            default: _withCtx(() => [
                              (activeTab.value === tabs.previousJobs)
                                ? (_openBlock(), _createBlock(CustomerPreviousJobs, { key: 0 }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["value"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(Button), {
                  label: "Cancel",
                  severity: "secondary",
                  size: "small",
                  type: "button",
                  onClick: close
                }),
                _createVNode(_unref(Button), {
                  label: "Save",
                  loading: _unref(store).hydrateLoading,
                  severity: "info",
                  size: "small",
                  type: "button",
                  onClick: _unref(store).hydrateCustomer
                }, null, 8, ["loading", "onClick"])
              ])
            ])
          ], 64))
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})