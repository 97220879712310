import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createSlots as _createSlots, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-gap-2 tw3-items-center" }
const _hoisted_2 = { class: "!tw3-text-xl !tw3-font-bold tw3-text-black" }
const _hoisted_3 = {
  class: "tw3-flex tw3-justify-between tw3-gap-2",
  style: {"padding-right":"2.5px"}
}
const _hoisted_4 = { class: "tw3-flex tw3-flex-col tw3-gap-1" }
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "tw3-flex tw3-items-end tw3-gap-2" }
const _hoisted_7 = {
  key: 0,
  class: "tw3-text-right"
}
const _hoisted_8 = { class: "tw3-pr-3" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = { key: 4 }
const _hoisted_13 = {
  key: 0,
  class: "tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20"
}

import { FilterMatchMode, FilterOperator } from '@primevue/core/api';
import { useInfiniteScroll } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { computed, reactive, ref } from 'vue';

import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import { TRANSACTION_TYPE_ID_JOURNAL_ENTRY } from '@/entities/accounting/transactions/lib/types';
import useTransactionTypesStore from '@/entities/accounting/transactions/lib/typeStore';
import useAccountingStore from '@/features/accounting/lib/store';
import ProfileSelect from '@/features/accounting/ProfileSelect.vue';
import ShopSelect from '@/features/accounting/ShopSelect.vue';
import { formatDate, formatMoney } from '@/shared/helpers/formatters';
import PageCard from '@/shared/ui/container/PageCard.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';
import LoadingIndicator from '@/shared/ui/overlay/LoadingIndicator.vue';

import JournalEntryEdit from './JournalEntryEdit.vue';
import useStore from './lib/store';
import TransactionDetails from './TransactionDetails.vue';

const cid = 'accounting-journal-entries-list';


export default /*@__PURE__*/_defineComponent({
  __name: 'JournalEntryList',
  setup(__props) {

const { shopsById } = storeToRefs(useAccountingStore());

const { types, typesById } = storeToRefs(useTransactionTypesStore());
const transactionStore = useTransactionStore();
const store = useStore();
const {
	loading,
	transactions,
	transactionsCanLoadMore,
	transactionsParams,
	selectedProfileId
} = storeToRefs(store);

const refresh = () => {
	store.loadTransactions(true);
};

const headers = [
	{ width: '10%', key: 'transactionDt', title: 'Date', filter: true },
	{ width: '10%', key: 'amount', title: 'Amount', filter: true },
	{ width: '28%', key: 'description', title: 'Description', filter: false },
	{ width: '26%', key: 'shop', title: 'Shop', filter: true }
];

const title = ref([{ label: 'Journal Entries', disabled: true }]);

const details = async (id: number) => {
	transactionStore.loadSelectedTransaction(id, true);
	showTransactionDetails.value = true;
};

// TODO: move to TransactionEdit.vue ?
const edit = async (id: number) => {
	transactionStore.loadSelectedTransaction(id);
	showTransactionDetails.value = false;
	showJournalEntryEdit.value = true;
};

const showJournalEntryEdit = ref(false);
const showTransactionDetails = ref(false);

const create = () => {
	transactionStore.resetSelectedTransaction(selectedProfileId.value!, 2);
	showJournalEntryEdit.value = true;
};

const table = ref<typeof DataTable | null>(null);
useInfiniteScroll(
	() => table.value?.$el.querySelector('.p-datatable-table-container'),
	async () => {
		await store.loadTransactions();
	},
	{
		distance: 10,
		interval: 1000,
		canLoadMore: () => transactionsCanLoadMore.value
	}
);

const filters = ref();

const initFilters = () => {
	filters.value = reactive({
		shop: {
			value: null,
			matchMode: FilterMatchMode.EQUALS
		},
		amount: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		},
		transactionDt: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		}
	});
};

initFilters();

const activeFilters = computed<Record<string, boolean>>(() => ({
	shop: filters.value.shop.value,
	amount:
		filters.value.amount.constraints[0].value ||
		filters.value.amount.constraints[1].value,
	transactionDt:
		filters.value.transactionDt.constraints[0].value ||
		filters.value.transactionDt.constraints[1].value
}));

const clearFilter: Record<string, CallableFunction> = {
	shop: () => {
		filters.value.shop.value = null;
		applyFilters();
	},
	amount: () => {
		filters.value.amount.constraints[0].value = null;
		filters.value.amount.constraints[1].value = null;
		applyFilters();
	},
	transactionDt: () => {
		filters.value.transactionDt.constraints[0].value = null;
		filters.value.transactionDt.constraints[1].value = null;
		applyFilters();
	}
};

const applyFilters = () => {
	transactionsParams.value = {
		...transactionsParams.value,
		typeId: TRANSACTION_TYPE_ID_JOURNAL_ENTRY,
		shopId: filters.value.shop.value,
		amount: {
			min: filters.value.amount.constraints[0].value,
			max: filters.value.amount.constraints[1].value
		},
		transactionDt: {
			start: filters.value.transactionDt.constraints[0].value,
			end: filters.value.transactionDt.constraints[1].value
		}
	};
};

applyFilters();

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageCard, { pt: {
			content: { class: 'tw3-flex tw3-flex-col tw3-grow' },
			root: { class: 'tw3-grow' },
			body: { class: 'tw3-grow' }
		} }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(Breadcrumb), {
            model: title.value,
            "pt:root:class": "!tw3-p-0 !tw3-ml-3"
          }, {
            item: _withCtx(({ item }) => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(item.label), 1)
            ]),
            _: 1
          }, 8, ["model"])
        ])
      ]),
      content: _withCtx(() => [
        (!!_unref(types).length)
          ? (_openBlock(), _createBlock(_unref(DataTable), {
              key: 0,
              ref_key: "table",
              ref: table,
              filters: filters.value,
              "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((filters).value = $event)),
              dataKey: "id",
              filterDisplay: "menu",
              lazy: "",
              pt: { bodyRow: { style: 'cursor: pointer' } },
              rowHover: "",
              scrollable: "",
              scrollHeight: "flex",
              size: "small",
              tableClass: "tw3-max-w-full",
              value: _unref(transactions),
              onFilter: _cache[3] || (_cache[3] = event => applyFilters()),
              onRowClick: _cache[4] || (_cache[4] = event => details(event.data.id))
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("label", {
                      class: "tw3-pl-1",
                      for: `${cid}-organization-id`
                    }, "Organization", 8, _hoisted_5),
                    _createVNode(ProfileSelect, {
                      id: `${cid}-organization-id`
                    }, null, 8, ["id"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _withDirectives(_createVNode(_unref(Button), {
                      icon: "pi pi-plus",
                      label: "New Journal Entry",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (create()))
                    }, null, 512), [
                      [
                        _directive_tooltip,
                        'Create Journal Entry',
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _withDirectives(_createVNode(_unref(Button), {
                      icon: "pi pi-refresh",
                      severity: "secondary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (refresh()))
                    }, null, 512), [
                      [
                        _directive_tooltip,
                        'Refresh',
                        void 0,
                        { top: true }
                      ]
                    ])
                  ])
                ])
              ]),
              empty: _withCtx(() => [
                (!_unref(loading))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[8] || (_cache[8] = [
                      _createElementVNode("p", { class: "tw3-text-center tw3-text-3xl" }, " Journal entries not found ", -1)
                    ])))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (column) => {
                  return _createVNode(_unref(Column), {
                    key: column.key,
                    field: column.key,
                    filterField: column.key,
                    header: column.title,
                    pt: {
						filterRemove: { style: 'display: none' }
					},
                    showAddButton: false,
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    style: _normalizeStyle({ width: column.width })
                  }, _createSlots({
                    body: _withCtx(({ data }) => [
                      (column.key == 'amount')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(formatMoney)(data[column.key])), 1)
                          ]))
                        : (column.key == 'transactionDt')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(formatDate)(data[column.key])), 1))
                          : (column.key == 'type')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(typesById)[data['typeId']].name), 1))
                            : (column.key == 'shop')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(shopsById)[data['shopId']].name), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(data[column.key]), 1))
                    ]),
                    filtericon: _withCtx(() => [
                      _createElementVNode("i", {
                        class: _normalizeClass(
								activeFilters.value[column.key] == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							)
                      }, null, 2)
                    ]),
                    filterclear: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        outlined: "",
                        onClick: ($event: any) => (clearFilter[column.key]())
                      }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" Clear ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, [
                    (column.filter)
                      ? {
                          name: "filter",
                          fn: _withCtx(({ filterModel }) => [
                            (column.key == 'shop')
                              ? (_openBlock(), _createBlock(ShopSelect, {
                                  key: 0,
                                  modelValue: filterModel.value,
                                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                                  placeholder: "All Shops"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                              : (column.key == 'amount')
                                ? (_openBlock(), _createBlock(InputMoney, {
                                    key: 1,
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                                    align: "left",
                                    class: "p-column-filter",
                                    placeholder: 
									filterModel.matchMode == 'gte'
										? `Greater or equal than`
										: `Less or equal than`
								
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
                                : (column.key == 'transactionDt')
                                  ? (_openBlock(), _createBlock(DatePicker, {
                                      key: 2,
                                      modelValue: filterModel.value,
                                      "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                                      class: "p-column-filter",
                                      manualInput: false,
                                      placeholder: 
									filterModel.matchMode == 'gte'
										? `Greater or equal than`
										: `Less or equal than`
								,
                                      showIcon: ""
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
                                  : _createCommentVNode("", true)
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["field", "filterField", "header", "style"])
                }), 64))
              ]),
              _: 1
            }, 8, ["filters", "value"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(LoadingIndicator, null, null, 512), [
          [_vShow, _unref(loading)]
        ])
      ]),
      _: 1
    }),
    _createVNode(TransactionDetails, {
      visible: showTransactionDetails.value,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((showTransactionDetails).value = $event)),
      allowEdit: [_unref(TRANSACTION_TYPE_ID_JOURNAL_ENTRY)],
      onDeleted: refresh,
      onEdit: edit
    }, null, 8, ["visible", "allowEdit"]),
    _createVNode(JournalEntryEdit, {
      visible: showJournalEntryEdit.value,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((showJournalEntryEdit).value = $event))
    }, null, 8, ["visible"])
  ], 64))
}
}

})