<script setup lang="ts">
import AutoComplete from 'primevue/autocomplete';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { ref, toRef, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

// This Autocomplete accepts an array of objects as "options" prop
// Options are filtered by "optionLabel" property of passed options objects
// If "optionValue" is provided it must be the name of options property that will be used as model value
// In this case, the model is a scalar value
// If "optionValue" is undefined, the model must be the object (one of options object)

// TODO:
// - Add a button (cross icon on the right side of the field) to clear the selected value
// - mark matched fragment in the options list

const { t } = useI18n();

const prop = withDefaults(
	defineProps<{
		modelValue: any;
		optionValue?: string; // Property of options object that will be used as value
		optionLabel?: string; // Property of options object that will be used as label
		optionGroup?: string; // If provided, options will be grouped by this property
		filterBy?: string[]; // Properties to filter by when searching
		options: any[];
		clearable?: boolean;
	}>(),
	{
		optionValue: undefined,
		optionLabel: 'name',
		optionGroup: undefined,
		filterBy: () => [],
		clearable: false
	}
);

const emit = defineEmits<{
	'update:modelValue': any;
	search: [query: string];
}>();

const options = toRef(prop, 'options');
const inputValue = ref('');
const searchQuery = ref('');
const preparedOptions = ref([] as any[]);

const filterOptions = (options: any[], searchQuery: string): any[] => {
	if (!searchQuery) {
		return options;
	}
	const filterBy = prop.filterBy.length ? prop.filterBy : [prop.optionLabel];
	// Filter by all properties
	return options.filter(option =>
		filterBy.some(prop =>
			option[prop].toLowerCase().includes(searchQuery.toLowerCase())
		)
	);
};

const groupOptions = (options: any[], optionGroup: string): any[] => {
	options = options.reduce(
		(acc, option) => {
			const group = option[optionGroup];
			if (!acc[group]) {
				acc[group] = [];
			}
			acc[group].push(option);
			return acc;
		},
		{} as Record<string, any[]>
	);
	return Object.entries(options)
		.map(([group, options]) => ({
			label: group,
			items: options
		}))
		.sort((a, b) => a.label.localeCompare(b.label));
};

const prepareOptions = () => {
	let prepared = filterOptions(options.value, searchQuery.value);
	if (prop.optionGroup !== undefined) {
		prepared = groupOptions(prepared, prop.optionGroup);
	}
	// options array need to be changed to trigger the dropdown
	// this is a weird primevue AutoComplete behavior
	preparedOptions.value = [...prepared];
};

watchEffect(() => {
	prepareOptions();
});

watchEffect(() => {
	if (prop.modelValue) {
		if (prop.optionValue) {
			// Using scalar model
			const option = options.value.find(
				option => option[prop.optionValue!] === prop.modelValue
			);
			if (option) {
				inputValue.value = option[prop.optionLabel];
			}
		} else {
			// Using object model
			inputValue.value = prop.modelValue[prop.optionLabel];
		}
	} else {
		inputValue.value = '';
	}
});

const onSearch = (event: { query: string }) => {
	emit('search', event.query);
	searchQuery.value = event.query;
	// Wierdlly, this is needed to show the dropdown, watchEffect doesn't work in some cases
	prepareOptions();
};

const onSelect = (event: any) => {
	if (event.originalEvent.type === 'change') {
		// For some reason AutoComplete emits "option-select" twice when option selected with keyboard
		// One event is with type "keydown" and another is with type "change"
		// As a workaround, we ignore the "change" event
		return;
	}
	let value: any = event.value;
	if (prop.optionValue) {
		// Using scalar model
		value = event.value[prop.optionValue];
	}
	emit('update:modelValue', value);
};

const clear = () => {
	emit('update:modelValue', null);
};
</script>

<template>
	<IconField>
		<AutoComplete
			v-model="inputValue"
			dropdown
			fluid
			forceSelection
			:optionGroupChildren="optionGroup ? 'items' : undefined"
			:optionGroupLabel="optionGroup ? 'label' : undefined"
			:optionLabel="optionLabel"
			:pt="{ optionGroup: { style: 'color: inherit' } }"
			:suggestions="preparedOptions"
			@complete="onSearch"
			@option-select="onSelect"
		>
			<template #option="slotProps">
				<slot name="option" :option="slotProps.option">
					<span> {{ slotProps.option[optionLabel] }} </span>
				</slot>
			</template>
			<template #optiongroup="slotProps">
				<slot
					v-if="optionGroup !== undefined"
					name="optiongroup"
					:option="slotProps.option"
				>
					{{ slotProps.option[optionGroup] }}
				</slot>
			</template>
		</AutoComplete>
		<InputIcon
			v-if="clearable && prop.modelValue"
			v-tooltip.top="t('clear')"
			class="pi pi-times"
			style="right: 52px; cursor: pointer"
			@click="clear"
		/>
	</IconField>
</template>
