import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "cell-container" }
const _hoisted_2 = {
  key: 2,
  class: "tw3-max-w-[250px] tw3-truncate"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = {
  key: 1,
  class: "tw3-flex tw3-items-center tw3-gap-1"
}
const _hoisted_9 = {
  key: 1,
  class: "tw3-flex tw3-gap-1"
}
const _hoisted_10 = { class: "tw3-flex tw3-flex-col tw3-gap-2" }
const _hoisted_11 = { class: "tw3-flex tw3-justify-start tw3-gap-3" }
const _hoisted_12 = {
  key: 0,
  class: "tw3-flex tw3-gap-3 tw3-items-center tw3-justify-start"
}
const _hoisted_13 = { class: "tw3-flex tw3-gap-3 tw3-items-center tw3-justify-start" }
const _hoisted_14 = { class: "tw3-w-full tw3-h-full tw3-flex tw3-justify-center tw3-items-center" }
const _hoisted_15 = { class: "tw3-text-center tw3-text-3xl" }

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import DatePicker from 'primevue/datepicker';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
	downloadFile,
	getFileObjectFromBase64,
	isEmptyValue
} from '@/shared/helpers';
import { ActionPopover, DownloadButton, RefreshButton } from '@/shared/ui';

import {
	faxBurstHeaders,
	faxInboxHeaders,
	faxOutboxHeaders,
	faxStatusOptions
} from '../model/constants';
import useFaxStore from '../model/store';

import AttachJobToFax from './AttachJobToFax.vue';
import FaxStatus from './FaxStatus.vue';
import FaxUpload from './FaxUpload.vue';

import type {
	Burst,
	BurstFilters,
	FaxFiltersWithMetaData,
	FaxInbox,
	FaxOutbox,
	InboxFilters,
	OutboxFilters
} from '../model/types';
import type {
	DataTablePageEvent,
	DataTableSortEvent
} from 'primevue/datatable';


export default /*@__PURE__*/_defineComponent({
  __name: 'FaxTable',
  setup(__props) {

const { t } = useI18n();
const toast = useToast();
const confirm = useConfirm();
const faxStore = useFaxStore();

const handlePage = (state: DataTablePageEvent) => {
	faxStore.pagination.page = state.page + 1;
};
const handleSort = (state: DataTableSortEvent) => {
	faxStore.pagination.page = 1;

	faxStore.handleSort({
		desc: state.sortOrder === -1,
		field: state.sortField as string
	});
};

const getFilterIconClass = (field: string) => {
	const filter =
		faxStore[currentFilters.value][
			field as keyof FaxFiltersWithMetaData<
				InboxFilters | OutboxFilters | BurstFilters
			>
		];
	return isEmptyValue(filter.value) || filter.value === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const currentFilters = computed(() => {
	if (faxStore.currentTab == 'inbox') {
		return 'inboxFilters';
	} else if (faxStore.currentTab == 'outbox') {
		return 'outboxFilters';
	} else {
		return 'burstFilters';
	}
});

const currentData = computed(() => {
	if (faxStore.currentTab == 'inbox') {
		return 'faxInbox';
	} else if (faxStore.currentTab == 'outbox') {
		return 'faxOutbox';
	} else {
		return 'burstTable';
	}
});

const currentHeaders = computed(() => {
	if (faxStore.currentTab == 'inbox') {
		return faxInboxHeaders;
	} else if (faxStore.currentTab == 'outbox') {
		return faxOutboxHeaders;
	} else {
		return faxBurstHeaders;
	}
});

const burstButtonIsLoading = ref({
	faxIds: [0],
	loading: false
});
const burstFaxesConfirm = (faxIds: number[]) => {
	confirm.require({
		accept: () => {
			burstFaxesHandler(faxIds);
		},
		acceptProps: {
			label: t('faxDialog.actions.burst.burst'),
			severity: 'warn'
		},
		header: t('confirmation'),
		icon: 'pi pi-info-circle',
		message:
			faxIds.length > 1
				? t('faxDialog.actions.burst.plural')
				: t('faxDialog.actions.burst.single'),
		rejectLabel: t('faxDialog.actions.cancel'),
		rejectProps: {
			label: t('faxDialog.actions.cancel'),
			outlined: true,
			severity: 'secondary'
		}
	});
};
const burstFaxesHandler = async (faxIds: number[]) => {
	burstButtonIsLoading.value = {
		faxIds: faxIds,
		loading: true
	};

	await faxStore.burstChosenFaxes(faxIds);

	selectedFaxes.value = null;
	burstButtonIsLoading.value = {
		faxIds: [0],
		loading: false
	};
};

const deleteButtonIsLoading = ref({
	faxIds: [0],
	loading: false
});
const deleteFaxesConfirm = (faxIds: number[]) => {
	confirm.require({
		accept: () => {
			deleteFaxesHandler(faxIds);
		},
		acceptProps: {
			label: t('faxDialog.actions.delete.delete'),
			severity: 'danger'
		},
		header: t('confirmation'),
		icon: 'pi pi-info-circle',
		message:
			faxIds.length > 1
				? t('faxDialog.actions.delete.plural')
				: t('faxDialog.actions.delete.single'),
		rejectLabel: t('faxDialog.actions.cancel'),
		rejectProps: {
			label: t('faxDialog.actions.cancel'),
			outlined: true,
			severity: 'secondary'
		}
	});
};
const deleteFaxesHandler = async (faxIds: number[]) => {
	deleteButtonIsLoading.value = {
		faxIds: faxIds,
		loading: true
	};

	const response = await faxStore.deleteChosenFaxes(faxIds);
	if (response.status === 'Error') {
		toast.add({
			life: 3000,
			severity: 'error',
			detail: t('faxDialog.actions.delete.error'),
			summary: t('defaultToast.errorSummary')
		});
	} else {
		toast.add({
			life: 3000,
			severity: 'success',
			detail: t('faxDialog.actions.delete.success'),
			summary: t('defaultToast.successSummary')
		});
	}

	selectedFaxes.value = null;

	deleteButtonIsLoading.value = {
		faxIds: [0],
		loading: false
	};
};

const attachJobDetails = ref<FaxInbox | FaxOutbox | null>(null);
const showJobAttachDialog = ref<boolean>(false);
const openJobAttachDialog = (item: FaxInbox | FaxOutbox) => {
	attachJobDetails.value = item;
	showJobAttachDialog.value = true;
};

const selectedFaxes = ref<FaxInbox[] | FaxOutbox[] | Burst[] | null>();
const statusList = [
	{
		title: t('faxDialog.status.unread'),
		value: 'unread'
	},
	{
		title: t('faxDialog.status.read'),
		value: 'read'
	},
	{
		title: t('faxDialog.status.burst'),
		value: 'burst'
	}
];
const statusModel = ref('');

const prepareSelectedFaxes = computed(() => {
	if (selectedFaxes.value) {
		return selectedFaxes.value.map(item => item.faxId);
	} else {
		return [];
	}
});

const changeStatusIsLoading = ref<boolean>(false);
const changeSelectedFaxesStatus = async (faxId: number[], status: string) => {
	changeStatusIsLoading.value = true;

	await faxStore.updateFaxStatusHandler({
		faxIds: faxId,
		status: status
	});

	changeStatusIsLoading.value = false;
	statusModel.value = '';
	selectedFaxes.value = null;
};
const showUploadFaxDialog = ref<boolean>(false);

const updateReferral = async (slotProps: any, status: string) => {
	if (status === 'cancel') {
		slotProps.editorCancelCallback();
	} else {
		const response = await faxStore.updateFaxReferralHandler({
			faxId: slotProps.data.faxId,
			referral: slotProps.data[slotProps.field]
		});
		if (response === 'Error' || response === 'failed') {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: t('faxDialog.actions.changeReferral.error'),
				summary: t('defaultToast.errorSummary')
			});
		} else {
			toast.add({
				life: 3000,
				severity: 'success',
				detail: t('faxDialog.actions.changeReferral.success'),
				summary: t('defaultToast.successSummary')
			});
		}
		slotProps.editorSaveCallback();
	}
};

const dataTableRef = ref();
const skeletonArray = Array(10).fill({});

const confirmationIsLoading = ref({
	faxId: 0,
	loading: false
});
const downloadOutboxTransmittal = async (jobid: number, faxId: number) => {
	confirmationIsLoading.value.faxId = faxId;
	confirmationIsLoading.value.loading = true;
	const response = await faxStore.downloadOutboxTransmittalHandler(jobid);
	if (!response) {
		return;
	} else {
		const fileData = getFileObjectFromBase64(
			response,
			`fax-confirmation-${jobid}.pdf`
		);
		downloadFile(fileData.url, fileData.name);
	}
	confirmationIsLoading.value.faxId = 0;
	confirmationIsLoading.value.loading = false;
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(DataTable), {
      ref_key: "dataTableRef",
      ref: dataTableRef,
      filters: _unref(faxStore)[currentFilters.value],
      "onUpdate:filters": _cache[6] || (_cache[6] = ($event: any) => ((_unref(faxStore)[currentFilters.value]) = $event)),
      selection: selectedFaxes.value,
      "onUpdate:selection": _cache[7] || (_cache[7] = ($event: any) => ((selectedFaxes).value = $event)),
      dataKey: "faxId",
      editMode: "cell",
      filterDisplay: "menu",
      lazy: "",
      paginator: "",
      rows: _unref(faxStore).pagination.limit,
      scrollable: "",
      scrollHeight: "flex",
      sortField: _unref(faxStore).sort.sortBy,
      sortOrder: _unref(faxStore).sort.sortDesc === false ? 1 : -1,
      style: {"height":"calc(100% - 57px)"},
      tableClass: "tw3-max-w-full tw3-h-full",
      totalRecords: _unref(faxStore)[currentData.value].state.totalCount,
      value: 
			_unref(faxStore)[currentData.value].isLoading
				? _unref(skeletonArray)
				: _unref(faxStore)[currentData.value].state.data
		,
      onPage: handlePage,
      onSort: handleSort
    }, _createSlots({
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_unref(Button), {
            label: _unref(t)('faxDialog.actions.upload.upload'),
            severity: "info",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showUploadFaxDialog.value = true))
          }, null, 8, ["label"]),
          (_unref(faxStore).currentTab !== 'outbox')
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_unref(ActionPopover), {
                  dropdownPosition: "left-bottom",
                  icon: "pi pi-pen-to-square"
                }, {
                  button: _withCtx(() => [
                    _withDirectives(_createVNode(_unref(Button), {
                      icon: "pi pi-angle-down",
                      iconPos: "right",
                      label: _unref(t)('faxDialog.actions.actions'),
                      severity: "info"
                    }, null, 8, ["label"]), [
                      [
                        _directive_tooltip,
                        {
									value: _unref(t)('faxDialog.actions.pluralActionsTooltip'),
									pt: {
										text: '!tw3-text-center'
									}
								},
                        void 0,
                        { top: true }
                      ]
                    ])
                  ]),
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_13, [
                      (_unref(faxStore).currentTab === 'inbox')
                        ? (_openBlock(), _createBlock(_unref(Select), {
                            key: 0,
                            modelValue: statusModel.value,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((statusModel).value = $event)),
                            disabled: prepareSelectedFaxes.value.length === 0,
                            loading: changeStatusIsLoading.value,
                            optionLabel: "title",
                            options: statusList,
                            optionValue: "value",
                            placeholder: _unref(t)('faxDialog.selectStatus'),
                            onChange: _cache[2] || (_cache[2] = ($event: any) => (
										changeSelectedFaxesStatus(prepareSelectedFaxes.value, statusModel.value)
									))
                          }, null, 8, ["modelValue", "disabled", "loading", "placeholder"]))
                        : _createCommentVNode("", true),
                      (_unref(faxStore).currentTab === 'inbox')
                        ? _withDirectives((_openBlock(), _createBlock(_unref(Button), {
                            key: 1,
                            disabled: prepareSelectedFaxes.value.length === 0,
                            icon: "pi pi-eraser",
                            loading: burstButtonIsLoading.value.loading,
                            severity: "warn",
                            size: "small",
                            text: "",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (burstFaxesConfirm(prepareSelectedFaxes.value)))
                          }, null, 8, ["disabled", "loading"])), [
                            [
                              _directive_tooltip,
                              _unref(t)('faxDialog.actions.burst.pluralTooltip'),
                              void 0,
                              { top: true }
                            ]
                          ])
                        : _createCommentVNode("", true),
                      (_unref(faxStore).currentTab !== 'outbox')
                        ? _withDirectives((_openBlock(), _createBlock(_unref(Button), {
                            key: 2,
                            disabled: prepareSelectedFaxes.value.length === 0,
                            icon: "pi pi-trash",
                            loading: deleteButtonIsLoading.value.loading,
                            severity: "danger",
                            size: "small",
                            text: "",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (deleteFaxesConfirm(prepareSelectedFaxes.value)))
                          }, null, 8, ["disabled", "loading"])), [
                            [
                              _directive_tooltip,
                              _unref(t)('faxDialog.actions.delete.pluralTooltip'),
                              void 0,
                              { top: true }
                            ]
                          ])
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_unref(RefreshButton), {
            class: "tw3-mr-0 tw3-ml-auto",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(faxStore).refreshData()))
          })
        ])
      ]),
      default: _withCtx(() => [
        (_unref(faxStore).currentTab !== 'outbox')
          ? (_openBlock(), _createBlock(_unref(Column), {
              key: 0,
              headerStyle: "width: 3rem",
              selectionMode: "multiple"
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentHeaders.value, (column) => {
          return (_openBlock(), _createBlock(_unref(Column), {
            key: column.key,
            editor: column.key === 'referral' ? 'InputText' : false,
            field: column.key,
            filterField: column.key,
            header: column.title,
            showFilterMatchModes: false,
            sortable: "",
            style: _normalizeStyle(
				column.key === 'referral'
					? {
							width: 'auto'
						}
					: column.key === 'dateTime'
						? {
								width: '16%',
								'min-width': '16%',
								'text-align': 'center'
							}
						: column.key === 'sentFrom' || column.key === 'sentTo'
							? {
									width: '14%',
									'min-width': '14%',
									'text-align': 'center'
								}
							: column.key === 'pages'
								? {
										width: '5%',
										'min-width': '5%',
										'text-align': 'right'
									}
								: column.key === 'status'
									? {
											width: '5%',
											'min-width': '5%',
											'text-align': 'center'
										}
									: {}
			)
          }, _createSlots({
            filtericon: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(getFilterIconClass(column.key))
              }, null, 2)
            ]),
            filter: _withCtx(({ filterModel }) => [
              (column.key === 'date' || column.key === 'dateTime')
                ? (_openBlock(), _createBlock(_unref(DatePicker), {
                    key: 0,
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    appendTo: "#vue3app",
                    dateFormat: "mm/dd/yy",
                    mask: "99/99/9999",
                    placeholder: "mm/dd/yyyy"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (column.key === 'status')
                  ? (_openBlock(), _createBlock(_unref(Select), {
                      key: 1,
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                      optionLabel: "label",
                      options: _unref(faxStatusOptions),
                      optionValue: "value",
                      placeholder: "Select status"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                  : (_openBlock(), _createBlock(_unref(InputText), {
                      key: 2,
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                      class: "p-column-filter",
                      placeholder: `${_unref(t)('searchBy')} ${column.title}`,
                      type: "text"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
            ]),
            body: _withCtx(({ data, editorInitCallback }) => [
              _createElementVNode("div", _hoisted_1, [
                (_unref(faxStore)[currentData.value].isLoading)
                  ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                  : (column.key === 'status')
                    ? (_openBlock(), _createBlock(FaxStatus, {
                        key: 1,
                        status: data[column.key]
                      }, null, 8, ["status"]))
                    : (column.key === 'fileName' && data.url)
                      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createElementVNode("a", {
                            href: data.url,
                            target: "_blank"
                          }, _toDisplayString(data[column.key]), 9, _hoisted_3)
                        ])), [
                          [_directive_tooltip, data[column.key]]
                        ])
                      : (column.key === 'referral')
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 3,
                            onClick: editorInitCallback
                          }, [
                            (data[column.key])
                              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(data[column.key]), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(t)('faxDialog.actions.changeReferral.placeholder')), 1))
                          ], 8, _hoisted_4))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(data[column.key]), 1))
              ])
            ]),
            _: 2
          }, [
            (column.key === 'referral' ? 'InputText' : false)
              ? {
                  name: "editor",
                  fn: _withCtx((slotProps) => [
                    (_unref(faxStore)[currentData.value].isLoading)
                      ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_unref(InputText), {
                            modelValue: slotProps.data[slotProps.field],
                            "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.field]) = $event),
                            placeholder: _unref(t)('faxDialog.actions.changeReferral.placeholder')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]),
                          _createVNode(_unref(Button), {
                            icon: "pi pi-check",
                            size: "small",
                            text: "",
                            onClickOnce: 
							() => {
								updateReferral(slotProps, 'update');
							}
						
                          }, null, 8, ["onClickOnce"]),
                          _createVNode(_unref(Button), {
                            icon: "pi pi-times",
                            severity: "danger",
                            size: "small",
                            text: "",
                            onClick: 
							event => {
								updateReferral(slotProps, 'cancel');
							}
						
                          }, null, 8, ["onClick"])
                        ]))
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["editor", "field", "filterField", "header", "style"]))
        }), 128)),
        _createVNode(_unref(Column), {
          bodyClass: "text-center",
          dataType: "boolean",
          field: "actions",
          header: _unref(t)('faxDialog.actions.actions'),
          showClearButton: false,
          style: {"width":"100px"}
        }, {
          body: _withCtx(({ data }) => [
            (_unref(faxStore)[currentData.value].isLoading)
              ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_unref(faxStore).currentTab !== 'outbox')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _withDirectives(_createVNode(_unref(Button), {
                          icon: "pi pi-trash",
                          loading: 
								deleteButtonIsLoading.value.loading &&
								deleteButtonIsLoading.value.faxIds.includes(data.faxId)
							,
                          severity: "danger",
                          size: "small",
                          text: "",
                          onClickOnce: ($event: any) => (deleteFaxesConfirm([data.faxId]))
                        }, null, 8, ["loading", "onClickOnce"]), [
                          [
                            _directive_tooltip,
                            _unref(t)('faxDialog.actions.delete.delete'),
                            void 0,
                            { top: true }
                          ]
                        ]),
                        _withDirectives((_openBlock(), _createBlock(_unref(ActionPopover), null, {
                          content: _withCtx(() => [
                            _createElementVNode("div", _hoisted_10, [
                              (_unref(faxStore).currentTab === 'inbox')
                                ? _withDirectives((_openBlock(), _createBlock(_unref(Button), {
                                    key: 0,
                                    icon: "pi pi-eraser",
                                    loading: 
											burstButtonIsLoading.value.loading &&
											burstButtonIsLoading.value.faxIds.includes(data.faxId)
										,
                                    severity: "warn",
                                    size: "small",
                                    text: "",
                                    onClickOnce: ($event: any) => (burstFaxesConfirm([data.faxId]))
                                  }, null, 8, ["loading", "onClickOnce"])), [
                                    [
                                      _directive_tooltip,
                                      _unref(t)('faxDialog.actions.burst.burst'),
                                      void 0,
                                      { top: true }
                                    ]
                                  ])
                                : _createCommentVNode("", true),
                              _withDirectives(_createVNode(_unref(DownloadButton), {
                                fileName: data.fileName,
                                link: data.url,
                                size: "small",
                                text: "",
                                onDownloading: 
											() => {
												if (_unref(faxStore).currentTab == 'inbox')
													changeSelectedFaxesStatus([data.faxId], 'read');
											}
										
                              }, null, 8, ["fileName", "link", "onDownloading"]), [
                                [
                                  _directive_tooltip,
                                  _unref(t)('faxDialog.actions.download'),
                                  void 0,
                                  { top: true }
                                ]
                              ]),
                              _withDirectives(_createVNode(_unref(Button), {
                                icon: "pi pi-paperclip",
                                severity: "secondary",
                                size: "small",
                                text: "",
                                onClick: ($event: any) => (openJobAttachDialog(data))
                              }, null, 8, ["onClick"]), [
                                [
                                  _directive_tooltip,
                                  _unref(t)('faxDialog.actions.attachFax.attach'),
                                  void 0,
                                  { top: true }
                                ]
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024)), [
                          [
                            _directive_tooltip,
                            'Additional actions',
                            void 0,
                            { top: true }
                          ]
                        ])
                      ], 64))
                    : _withDirectives((_openBlock(), _createBlock(_unref(Button), {
                        key: 1,
                        disabled: data.status !== 'SENT',
                        icon: "pi pi-download",
                        loading: 
							confirmationIsLoading.value.loading &&
							confirmationIsLoading.value.faxId == data.faxId
						,
                        severity: data.status == 'SENT' ? 'success' : 'secondary',
                        size: "small",
                        text: "",
                        onClick: ($event: any) => (downloadOutboxTransmittal(data.jobid, data.faxId))
                      }, null, 8, ["disabled", "loading", "severity", "onClick"])), [
                        [
                          _directive_tooltip,
                          {
							value:
								data.status == 'SENT'
									? _unref(t)('faxDialog.actions.downloadConfirmation')
									: _unref(t)('faxDialog.actions.noConfirmationAvailable'),
							pt: {
								text: 'text-center'
							}
						},
                          void 0,
                          { top: true }
                        ]
                      ])
                ]))
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 2
    }, [
      (!_unref(faxStore)[currentData.value].isLoading)
        ? {
            name: "empty",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("p", _hoisted_15, _toDisplayString(_unref(t)('noData')), 1)
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["filters", "selection", "rows", "sortField", "sortOrder", "totalRecords", "value"]),
    (showJobAttachDialog.value)
      ? (_openBlock(), _createBlock(AttachJobToFax, {
          key: 0,
          show: showJobAttachDialog.value,
          "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => ((showJobAttachDialog).value = $event)),
          data: attachJobDetails.value
        }, null, 8, ["show", "data"]))
      : _createCommentVNode("", true),
    (showUploadFaxDialog.value)
      ? (_openBlock(), _createBlock(FaxUpload, {
          key: 1,
          show: showUploadFaxDialog.value,
          "onUpdate:show": _cache[9] || (_cache[9] = ($event: any) => ((showUploadFaxDialog).value = $event))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})