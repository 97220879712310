import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "tw3-col-span-12 md:tw3-col-span-6 xl:tw3-col-span-3" }
const _hoisted_2 = { class: "tw3-text-sm tw3-font-medium tw3-leading-none tw3-uppercase" }
const _hoisted_3 = { class: "tw3-font-bold" }
const _hoisted_4 = {
  key: 0,
  class: "tw3-text-sm tw3-text-gray-600 tw3-whitespace-pre-line"
}
const _hoisted_5 = { class: "tw3-relative tw3-flex tw3-justify-between tw3-h-full" }
const _hoisted_6 = { class: "tw3-flex tw3-justify-between tw3-items-center" }
const _hoisted_7 = { class: "tw3-leading-tight" }
const _hoisted_8 = { class: "tw3-leading-loose tw3-text-3xl" }
const _hoisted_9 = { class: "tw3-absolute tw3-bottom-0 tw3-left-0 tw3-text-xs tw3-font-semibold tw3-leading-none tw3-text-gray-500" }

import Chart from 'primevue/chart';
import Popover from 'primevue/popover';
import { computed, ref, StyleValue } from 'vue';

import { formatNumber, formatPrice, formatToOneDigit } from '../lib';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardLifetime',
  props: {
    title: {},
    differenceNumber: {},
    value: {},
    chart: {},
    valueStyle: { type: [Boolean, null, String, Object, Array] },
    cardStyle: { type: [Boolean, null, String, Object, Array] },
    isRevenue: { type: Boolean },
    isPercent: { type: Boolean },
    last90: { type: Boolean },
    showTooltip: {},
    showTooltipExplaining: { type: Boolean }
  },
  setup(__props: any) {



const popup = ref<any>();

const toggle = (event: MouseEvent) => {
	popup.value.toggle(event);
};

const chartLifetimeOptions = computed(() => {
	return {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: false
			}
		},
		responsive: true,
		maintainAspectRatio: false,
		hover: {
			mode: null
		},
		scales: {
			y: {
				display: false,
				grid: {
					display: false
				}
			},
			x: {
				display: false,
				grid: {
					display: false
				}
			}
		},
		elements: {
			line: {
				borderWidth: 2,
				tension: 0.8
			},
			point: {
				radius: 0
			}
		}
	};
});

return (_ctx: any,_cache: any) => {
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "card tw3-cursor-pointer !tw3-border-0 !tw3-shadow-transparent tw3-relative tw3-h-28 tw3-rounded-xl tw3-p-4",
      style: _normalizeStyle(_ctx.cardStyle)
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", {
        class: "tw3-flex tw3-justify-center tw3-z-10 tw3-absolute tw3-top-2 tw3-right-2",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, [
        (_ctx.showTooltip)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              "aria-controls": "overlay_popover",
              class: "tw3-w-9 tw3-h-9 tw3-flex tw3-items-center tw3-justify-center tw3-opacity-60 tw3-transition-all hover:tw3-opacity-100 hover:tw3-bg-gray-100 tw3-rounded-full",
              onClick: toggle
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("i", { class: "pi pi-info-circle tw3-text-lg" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.showTooltip)
          ? (_openBlock(), _createBlock(_unref(Popover), {
              key: 1,
              id: "overlay_popover",
              ref_key: "popup",
              ref: popup,
              class: "tw3-max-w-96 !tw3-mt-0 !tw3-ml-[-5px]"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                  class: "tw3-text-lg tw3-mb-2",
                  keypath: _ctx.showTooltip.tooltipDescI18,
                  tag: "p"
                }, {
                  name: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.showTooltip.tooltipHeaderI18)), 1)
                  ]),
                  _: 1
                }, 8, ["keypath"]),
                (_ctx.showTooltipExplaining)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('dashboardTooltipDescription')), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 512))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "tw3-flex tw3-justify-center tw3-items-center tw3-gap-2 tw3-h-8 tw3-rounded tw3-p-2 tw3-mr-4",
            style: _normalizeStyle(_ctx.valueStyle)
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["pi", _ctx.differenceNumber >= 0 ? 'pi-plus' : 'pi-minus'])
            }, null, 2),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.isPercent
									? _unref(formatToOneDigit)(_ctx.differenceNumber).toFixed(1) + '%'
									: _ctx.isRevenue
										? _unref(formatPrice)(_unref(formatNumber)(_ctx.differenceNumber))
										: _unref(formatNumber)(_ctx.differenceNumber)), 1)
          ], 4),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.isPercent
								? _unref(formatToOneDigit)(_ctx.value).toFixed(1) + '%'
								: _ctx.isRevenue
									? _unref(formatPrice)(_unref(formatNumber)(_ctx.value))
									: _unref(formatNumber)(_ctx.value)), 1)
        ]),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.last90 ? _ctx.$t('time.last90days') : _ctx.$t('time.thisMonth')), 1)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["tw3-w-44 tw3-h-20 tw3-bottom-4 tw3-right-4 tw3-absolute tw3-inline-block", { '!tw3-h-14': _ctx.showTooltip }])
      }, [
        (_ctx.chart)
          ? (_openBlock(), _createBlock(_unref(Chart), {
              key: 0,
              class: "tw3-h-full",
              data: _ctx.chart,
              options: chartLifetimeOptions.value,
              type: "line"
            }, null, 8, ["data", "options"]))
          : _createCommentVNode("", true)
      ], 2)
    ], 4)
  ]))
}
}

})