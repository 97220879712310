import { CORE_API } from '@/shared/api';

import type {
	addJobTagRequest,
	deleteJobTagRequest,
	deleteJobTagResponse,
	JobTag,
	RejectedEdisRequest,
	RejectedInvoice,
	RejectedInvoiceAPI,
	RejectedInvoicesData,
	RejectedInvoicesRequest,
	RejectedInvoicesResponse
} from '../model/types';

const queEdis_URL = '/api/status/queuedEdis';

const getEdiQueueStatusCounts = async () => {
	const response = await CORE_API.get(`/api/ediQueueStatusCounts`);

	return response.data;
};

const getInvoicesByStatus = async (
	params: RejectedInvoicesRequest
): Promise<RejectedInvoicesData<RejectedInvoice>> => {
	const response = await CORE_API.get<
		RejectedInvoicesResponse<RejectedInvoiceAPI>
	>(queEdis_URL, {
		params: params
	});

	return {
		data: response.data.rows,
		totalCount: response.data.numberOfRowsFound
	};
};

const getRejectedInvoices = async (
	params: RejectedEdisRequest
): Promise<RejectedInvoicesData<RejectedInvoice>> => {
	const response = await CORE_API.post<
		RejectedInvoicesResponse<RejectedInvoiceAPI>
	>(`unum/job-details/jobslist/rejected-dialog/rejected`, params);

	return {
		data: response.data.rows,
		totalCount: response.data.numberOfRowsFound
	};
};

const getQueuedInvoices = async (
	params: RejectedEdisRequest
): Promise<RejectedInvoicesData<RejectedInvoice>> => {
	const response = await CORE_API.post<
		RejectedInvoicesResponse<RejectedInvoiceAPI>
	>(`unum/job-details/jobslist/rejected-dialog/queued`, params);

	return {
		data: response.data.rows,
		totalCount: response.data.numberOfRowsFound
	};
};

const getUnsubmittedInvoices = async (
	params: RejectedEdisRequest
): Promise<RejectedInvoicesData<RejectedInvoice>> => {
	const response = await CORE_API.post<
		RejectedInvoicesResponse<RejectedInvoiceAPI>
	>(`unum/job-details/jobslist/rejected-dialog-unsubmitted`, params);

	return {
		data: response.data.rows,
		totalCount: response.data.numberOfRowsFound
	};
};

const getShopTags = async (shopId: number): Promise<JobTag[] | []> => {
	const response = await CORE_API.get<JobTag[] | []>(`/api/shop/${shopId}/tag`);

	return response.data;
};

const deleteJobTag = async (
	params: deleteJobTagRequest
): Promise<deleteJobTagResponse | 'error'> => {
	try {
		const response = await CORE_API.delete<deleteJobTagResponse | 'error'>(
			`/api/shop/${params.shopID}/job/${params.jobID}/jobtag/${params.jobTagID}`
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return 'error';
	}
};

const addJobTag = async (
	params: addJobTagRequest
): Promise<JobTag[] | 'error'> => {
	try {
		const response = await CORE_API.post<JobTag[]>(
			`/api/shop/${params.shopID}/job/${params.jobID}/jobtag`,
			params.tags
		);

		return response.data;
	} catch (error) {
		console.log(error);
		return 'error';
	}
};

export {
	getEdiQueueStatusCounts,
	getInvoicesByStatus,
	getRejectedInvoices,
	getQueuedInvoices,
	getUnsubmittedInvoices,
	deleteJobTag,
	getShopTags,
	addJobTag
};
