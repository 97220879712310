import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { DateTime, Settings } from 'luxon';
import DatePicker from 'primevue/datepicker';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DatePicker',
  props: {
    "modelValue": { required: true },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

/* 
Wrapper for PrimeVue DatePicker component to use with Luxon DateTime.
PrimeVue DatePicker component uses Date object which is always in local time zone.
*/

const dateTime = _useModel<DateTime | null | undefined>(__props, "modelValue");

const date = computed({
	get: () => {
		if (dateTime.value == null) {
			return null;
		}
		// Replace time zone in Luxon DateTime with local time zone and convert to JS Date
		return dateTime.value.setZone('local', { keepLocalTime: true }).toJSDate();
	},
	set: (value: Date | null) => {
		if (value == null) {
			dateTime.value = null;
			return;
		}
		// Convert JS Date to Luxon DateTime and replace time zone to time zone from
		// original DateTime or to Luxon default time zone if original value is null
		const zone = dateTime.value?.zone || Settings.defaultZone;
		dateTime.value = DateTime.fromJSDate(value).setZone(zone, {
			keepLocalTime: true
		});
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DatePicker), {
    modelValue: date.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((date).value = $event))
  }, null, 8, ["modelValue"]))
}
}

})