import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-dialog-title" }
const _hoisted_2 = { class: "tw3-grid tw3-grid-cols-1 tw3-gap-4" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-row tw3-gap-4" }
const _hoisted_4 = { class: "tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1" }
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1" }
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "tw3-flex tw3-flex-col tw3-gap-0" }
const _hoisted_9 = ["for"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "tw3-flex tw3-justify-between tw3-mt-5" }
const _hoisted_12 = { class: "tw3-flex tw3-justify-start" }
const _hoisted_13 = { class: "tw3-flex tw3-justify-end" }

import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Fieldset from 'primevue/fieldset';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Textarea from 'primevue/textarea';
import { computed, nextTick, ref, watch } from 'vue';

import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import useTransactionTypesStore from '@/entities/accounting/transactions/lib/typeStore';
import AccountSelect from '@/features/accounting/AccountSelect.vue';
import CheatSheet from '@/features/accounting/CheatSheet.vue';
import ShopSelect from '@/features/accounting/ShopSelect.vue';
import { formatMoney } from '@/shared/helpers/formatters';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';

import { useJournalEntryForm } from './useJournalEntryForm';

const cid = 'accounting-journal-entry-edit';


export default /*@__PURE__*/_defineComponent({
  __name: 'JournalEntryEdit',
  props: {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["saved"], ["update:visible"]),
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { typesById } = storeToRefs(useTransactionTypesStore());

const transactionStore = useTransactionStore();
const { selectedTransaction } = storeToRefs(transactionStore);

const transaction = computed(() => selectedTransaction.value!);
const editMode = computed(() => transaction.value?.id !== 0);
const visible = _useModel<boolean>(__props, 'visible');

const {
	init,
	valid,
	transactionDt,
	description,
	shopId,
	items,
	itemsErrors,
	addItem,
	removeItem,
	swapDebitCredit,
	creditTotal,
	debitTotal,
	amountMatch,
	save: doSave,
	reset,
	fieldLabels,
	onItemAmountChange
} = useJournalEntryForm();

const shopSelect = ref<typeof InputText>();

watch(
	visible,
	() => {
		if (visible.value) {
			init();
			nextTick(() => {
				// Focus the first input
				shopSelect.value?.$el.focus();
			});
		}
	},
	{ immediate: true }
);

const close = () => {
	visible.value = false;
};

const errorTooltipPT = {
	text: {
		style: { backgroundColor: 'rgb(248 113 113)' }
	},
	arrow: {
		style: { borderTopColor: 'rgb(248 113 113)' }
	}
};

const save = () => {
	doSave(async () => {
		emit('saved');
		close();
	});
};

const saveAndNew = () => {
	doSave(async () => {
		reset();
		shopSelect.value?.$el.focus();
		emit('saved');
	});
};

const loading = computed(() => !selectedTransaction.value || !typesById.value);

const title = computed(() => {
	if (loading.value) {
		return 'Loading...';
	}
	if (editMode.value) {
		return `${typesById.value[transaction.value!.typeId!]?.name} #${transaction.value?.id}`;
	}
	return 'New Journal Entry';
});

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(Dialog), {
    visible: visible.value,
    "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((visible).value = $event)),
    appendTo: "#vue3app",
    modal: "",
    style: { width: '60rem' }
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(title.value), 1),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "tw3-flex-grow" }, null, -1)),
      _createVNode(CheatSheet, { rounded: "" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", {
              class: "tw3-pl-1",
              for: `${cid}-shop-id`
            }, _toDisplayString(_unref(fieldLabels).shopId), 9, _hoisted_5),
            (loading.value)
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2.2rem"
                }))
              : (_openBlock(), _createBlock(ShopSelect, {
                  key: 1,
                  id: `${cid}-shop-id`,
                  ref_key: "shopSelect",
                  ref: shopSelect,
                  modelValue: _unref(shopId),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(shopId) ? (shopId).value = $event : null)),
                  loading: loading.value
                }, null, 8, ["id", "modelValue", "loading"]))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", {
              class: "tw3-pl-1",
              for: `${cid}-transaction-dt`
            }, _toDisplayString(_unref(fieldLabels).transactionDt), 9, _hoisted_7),
            (loading.value)
              ? (_openBlock(), _createBlock(_unref(Skeleton), {
                  key: 0,
                  height: "2.2rem"
                }))
              : (_openBlock(), _createBlock(DatePicker, {
                  key: 1,
                  id: `${cid}-transaction-dt`,
                  modelValue: _unref(transactionDt),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(transactionDt) ? (transactionDt).value = $event : null))
                }, null, 8, ["id", "modelValue"]))
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", {
            class: "tw3-pl-1",
            for: `${cid}-description`
          }, _toDisplayString(_unref(fieldLabels).description), 9, _hoisted_9),
          (loading.value)
            ? (_openBlock(), _createBlock(_unref(Skeleton), {
                key: 0,
                height: "3.4rem"
              }))
            : (_openBlock(), _createBlock(_unref(Textarea), {
                key: 1,
                id: `${cid}-description`,
                modelValue: _unref(description),
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(description) ? (description).value = $event : null))
              }, null, 8, ["id", "modelValue"]))
        ]),
        _createVNode(_unref(Fieldset), {
          class: "relative",
          legend: _unref(fieldLabels).items,
          pt: {
					legendLabel: { class: 'gb-label ' },
					legend: { class: '!tw3-pb-1' },
					contentContainer: { class: 'tw3-pt-3' },
					root: { class: '!tw3-pb-2' }
				}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DataTable), {
              pt: {
						column: {
							headerCell: {
								style: 'padding: 0px 0.8rem 0px 0px; border: none'
							},
							bodyCell: {
								style: 'padding: 0px 0.8rem 0.2rem 0px; border: none'
							},
							footerCell: {
								style: 'padding: 0px 0.8rem 0px 0px; border: none'
							}
						}
					},
              scrollable: "",
              scrollHeight: "flex",
              size: "small",
              tableClass: "tw3-max-w-full",
              value: loading.value ? new Array(2) : _unref(items)
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(Column), {
                  field: "account",
                  style: {"width":"27%"}
                }, {
                  header: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createElementVNode("div", { class: "gb-label tw3-pl-1" }, "Account", -1)
                  ])),
                  body: _withCtx(({ data, index }) => [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_unref(Skeleton), {
                          key: 0,
                          height: "2.2rem"
                        }))
                      : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(AccountSelect, {
                            modelValue: data.value.accountId,
                            "onUpdate:modelValue": ($event: any) => ((data.value.accountId) = $event),
                            invalid: !!_unref(itemsErrors)[index].accountId
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "invalid"])
                        ])), [
                          [
                            _directive_tooltip,
                            {
									value: _unref(itemsErrors)[index].accountId,
									pt: errorTooltipPT
								},
                            void 0,
                            { top: true }
                          ]
                        ])
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "debit",
                  style: {"width":"18%"}
                }, {
                  header: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createElementVNode("div", { class: "gb-label tw3-pl-1" }, "Debit $", -1)
                  ])),
                  body: _withCtx(({ data, index }) => [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_unref(Skeleton), {
                          key: 0,
                          height: "2.2rem"
                        }))
                      : _withDirectives((_openBlock(), _createBlock(InputMoney, {
                          key: 1,
                          modelValue: data.value.debit,
                          "onUpdate:modelValue": [($event: any) => ((data.value.debit) = $event), 
									(value) =>
										_unref(onItemAmountChange)(data.value, 'debit', value)
								],
                          invalid: !!_unref(itemsErrors)[index].debit
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "invalid"])), [
                          [
                            _directive_tooltip,
                            {
									value: _unref(itemsErrors)[index].debit,
									pt: errorTooltipPT
								},
                            void 0,
                            { top: true }
                          ]
                        ])
                  ]),
                  footer: _withCtx(() => [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_unref(Skeleton), {
                          key: 0,
                          height: "2.2rem"
                        }))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(["tw3-font-bold tw3-text-right", { 'tw3-text-red-600': !_unref(amountMatch) }]),
                          style: {"padding-right":"var(--p-inputtext-padding-x)"}
                        }, _toDisplayString(_unref(formatMoney)(_unref(debitTotal))), 3))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "credit",
                  style: {"width":"18%"}
                }, {
                  header: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createElementVNode("div", { class: "gb-label tw3-pl-1" }, "Credit $", -1)
                  ])),
                  body: _withCtx(({ data, index }) => [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_unref(Skeleton), {
                          key: 0,
                          height: "2.2rem"
                        }))
                      : _withDirectives((_openBlock(), _createBlock(InputMoney, {
                          key: 1,
                          modelValue: data.value.credit,
                          "onUpdate:modelValue": [($event: any) => ((data.value.credit) = $event), 
									(value) =>
										_unref(onItemAmountChange)(data.value, 'credit', value)
								],
                          invalid: !!_unref(itemsErrors)[index].credit
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "invalid"])), [
                          [
                            _directive_tooltip,
                            {
									value: _unref(itemsErrors)[index].credit,
									pt: errorTooltipPT
								},
                            void 0,
                            { top: true }
                          ]
                        ])
                  ]),
                  footer: _withCtx(() => [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_unref(Skeleton), {
                          key: 0,
                          height: "2.2rem"
                        }))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(["tw3-font-bold tw3-text-right", { 'tw3-text-red-600': !_unref(amountMatch) }]),
                          style: {"padding-right":"var(--p-inputtext-padding-x)"}
                        }, _toDisplayString(_unref(formatMoney)(_unref(creditTotal))), 3))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "description",
                  style: {"width":"32%"}
                }, {
                  header: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createElementVNode("div", { class: "gb-label tw3-pl-1" }, "Item Description", -1)
                  ])),
                  body: _withCtx(({ data }) => [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_unref(Skeleton), {
                          key: 0,
                          height: "2.2rem"
                        }))
                      : (_openBlock(), _createBlock(_unref(InputText), {
                          key: 1,
                          modelValue: data.value.description,
                          "onUpdate:modelValue": ($event: any) => ((data.value.description) = $event),
                          fluid: "",
                          type: "text"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Column), {
                  field: "actions",
                  pt: {
							headerCell: { class: '!tw3-p-0' },
							bodyCell: { class: '!tw3-p-0' }
						},
                  style: {"max-width":"48px"}
                }, {
                  body: _withCtx(({ index }) => [
                    _createVNode(_unref(Button), {
                      disabled: loading.value || _unref(items).length <= 2,
                      icon: "pi pi-trash",
                      rounded: "",
                      text: "",
                      onClick: ($event: any) => (_unref(removeItem)(index))
                    }, null, 8, ["disabled", "onClick"])
                  ]),
                  footer: _withCtx(() => [
                    _withDirectives(_createVNode(_unref(Button), {
                      class: "tw3-mr-2",
                      icon: "pi pi-plus",
                      rounded: "",
                      text: "",
                      onClick: _unref(addItem)
                    }, null, 8, ["onClick"]), [
                      [
                        _directive_tooltip,
                        'Add item',
                        void 0,
                        { top: true }
                      ]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 8, ["legend"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _withDirectives((_openBlock(), _createBlock(_unref(Button), {
            disabled: loading.value,
            label: 'Swap Debit/Creditb',
            severity: "secondary",
            onClick: _unref(swapDebitCredit)
          }, {
            icon: _withCtx(() => _cache[10] || (_cache[10] = [
              _createElementVNode("i", { class: "material-icons md-swap_horiz" }, null, -1)
            ])),
            _: 1
          }, 8, ["disabled", "onClick"])), [
            [
              _directive_tooltip,
              'Swap Debit and Credit valuesa',
              void 0,
              { top: true }
            ]
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_unref(Button), {
            class: "tw3-ml-5",
            label: 'Cancel',
            severity: "secondary",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (close()))
          }),
          _createVNode(_unref(Button), {
            class: "tw3-ml-5",
            disabled: loading.value || !_unref(valid),
            label: 'Save',
            onClick: save
          }, null, 8, ["disabled"]),
          (!editMode.value)
            ? (_openBlock(), _createBlock(_unref(Button), {
                key: 0,
                class: "tw3-ml-5",
                disabled: loading.value || !_unref(valid),
                label: 'Save and New',
                onClick: saveAndNew
              }, null, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})