<script setup lang="ts">
import { FilterMatchMode, FilterOperator } from '@primevue/core/api';
import { useInfiniteScroll } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { computed, reactive, ref } from 'vue';

import useAccountingStore from '@/features/accounting/lib/store';
import ProfileSelect from '@/features/accounting/ProfileSelect.vue';
import ShopSelect from '@/features/accounting/ShopSelect.vue';
import { formatDate, formatMoney } from '@/shared/helpers/formatters';
import PageCard from '@/shared/ui/container/PageCard.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';
import LoadingIndicator from '@/shared/ui/overlay/LoadingIndicator.vue';

import useStore from './model/expenseListStore';
import ExpenseEdit from './ui/ExpenseEdit.vue';

const cid = 'accounting-expenses-list';

const { shopsById, accounts } = storeToRefs(useAccountingStore());

const store = useStore();
const { loading, expenses, expensesCanLoadMore, expensesParams } =
	storeToRefs(store);

const refresh = () => {
	store.loadExpenses(true);
};

const headers = [
	{ width: '10%', key: 'transactionDt', title: 'Date', filter: true },
	{
		width: '10%',
		key: 'expenseAccountIds',
		title: 'Account',
		filter: false
	},
	{ width: '10%', key: 'vendorName', title: 'Paid to', filter: false },
	{ width: '10%', key: 'paidFromAccountId', title: 'Paid from', filter: false },
	{ width: '26%', key: 'shop', title: 'Shop', filter: true },
	{ width: '28%', key: 'description', title: 'Description', filter: false },
	{ width: '10%', key: 'amount', title: 'Amount', filter: true }
];

const title = ref([{ label: 'Expenses', disabled: true }]);

const selectedExpenseId = ref<number | null>(null);
const showExpenseEdit = ref(false);

// TODO: move to ExpenseEdit.vue ?
const edit = async (id: number) => {
	selectedExpenseId.value = id;
	showExpenseEdit.value = true;
};

const create = () => {
	selectedExpenseId.value = null;
	showExpenseEdit.value = true;
};

const table = ref<typeof DataTable | null>(null);
useInfiniteScroll(
	() => table.value?.$el.querySelector('.p-datatable-table-container'),
	async () => {
		await store.loadExpenses();
	},
	{
		distance: 10,
		interval: 1000,
		canLoadMore: () => expensesCanLoadMore.value
	}
);

const filters = ref();

const initFilters = () => {
	// TODO: add filters
	filters.value = reactive({
		shop: {
			value: null,
			matchMode: FilterMatchMode.EQUALS
		},
		amount: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		},
		transactionDt: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
				{ value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
			]
		}
	});
};

initFilters();

const activeFilters = computed<Record<string, boolean>>(() => ({
	shop: filters.value.shop.value,
	amount:
		filters.value.amount.constraints[0].value ||
		filters.value.amount.constraints[1].value,
	transactionDt:
		filters.value.transactionDt.constraints[0].value ||
		filters.value.transactionDt.constraints[1].value
}));

const clearFilter: Record<string, CallableFunction> = {
	shop: () => {
		filters.value.shop.value = null;
		applyFilters();
	},
	amount: () => {
		filters.value.amount.constraints[0].value = null;
		filters.value.amount.constraints[1].value = null;
		applyFilters();
	},
	transactionDt: () => {
		filters.value.transactionDt.constraints[0].value = null;
		filters.value.transactionDt.constraints[1].value = null;
		applyFilters();
	}
};

const applyFilters = () => {
	expensesParams.value = {
		...expensesParams.value,
		shopId: filters.value.shop.value,
		amount: {
			min: filters.value.amount.constraints[0].value,
			max: filters.value.amount.constraints[1].value
		},
		transactionDt: {
			start: filters.value.transactionDt.constraints[0].value,
			end: filters.value.transactionDt.constraints[1].value
		}
	};
};

applyFilters();
</script>

<template>
	<PageCard
		:pt="{
			content: { class: 'tw3-flex tw3-flex-col tw3-grow' },
			root: { class: 'tw3-grow' },
			body: { class: 'tw3-grow' }
		}"
	>
		<template #title>
			<div class="tw3-flex tw3-gap-2 tw3-items-center">
				<Breadcrumb :model="title" pt:root:class="!tw3-p-0 !tw3-ml-3">
					<template #item="{ item }">
						<span class="!tw3-text-xl !tw3-font-bold tw3-text-black">{{
							item.label
						}}</span>
					</template>
				</Breadcrumb>
			</div>
		</template>
		<template #content>
			<DataTable
				ref="table"
				v-model:filters="filters"
				dataKey="id"
				filterDisplay="menu"
				lazy
				:pt="{ bodyRow: { style: 'cursor: pointer' } }"
				rowHover
				scrollable
				scrollHeight="flex"
				size="small"
				tableClass="tw3-max-w-full"
				:value="expenses"
				@filter="event => applyFilters()"
				@row-click="event => edit(event.data.id)"
			>
				<template #header>
					<div
						class="tw3-flex tw3-justify-between tw3-gap-2"
						style="padding-right: 2.5px"
					>
						<div class="tw3-flex tw3-flex-col tw3-gap-1">
							<label class="tw3-pl-1" :for="`${cid}-organization-id`"
								>Organization</label
							>
							<ProfileSelect :id="`${cid}-organization-id`" />
						</div>
						<div class="tw3-flex tw3-items-end tw3-gap-2">
							<Button
								v-tooltip.top="'Create Expense'"
								icon="pi pi-plus"
								label="New Expense"
								@click="create()"
							/>
							<Button
								v-tooltip.top="'Refresh'"
								icon="pi pi-refresh"
								severity="secondary"
								@click="refresh()"
							/>
						</div>
					</div>
				</template>

				<Column
					v-for="column of headers"
					:key="column.key"
					:field="column.key"
					:filterField="column.key"
					:header="column.title"
					:pt="{
						filterRemove: { style: 'display: none' }
					}"
					:showAddButton="false"
					:showFilterMatchModes="false"
					:showFilterOperator="false"
					:style="{ width: column.width }"
				>
					<template #body="{ data }">
						<div v-if="column.key == 'amount'" class="tw3-text-right">
							<span class="tw3-pr-3">{{ formatMoney(data[column.key]) }}</span>
						</div>
						<span v-else-if="column.key == 'transactionDt'">
							{{ formatDate(data[column.key]) }}
						</span>
						<span v-else-if="column.key == 'paidFromAccountId'">
							{{
								accounts.find(account => account.id === data.paidFromAccountId)
									?.name
							}}
						</span>
						<span v-else-if="column.key == 'expenseAccountIds'">
							<div v-for="accountId in data.expenseAccountIds" :key="accountId">
								{{ accounts.find(account => account.id === accountId)?.name }}
							</div>
						</span>
						<span v-else-if="column.key == 'shop'">
							{{ shopsById[data['shopId']].name }}
						</span>
						<span v-else>{{ data[column.key] }}</span>
					</template>

					<template v-if="column.filter" #filter="{ filterModel }">
						<template v-if="column.key == 'shop'">
							<ShopSelect v-model="filterModel.value" placeholder="All Shops" />
						</template>
						<template v-else-if="column.key == 'amount'">
							<InputMoney
								v-model="filterModel.value"
								align="left"
								class="p-column-filter"
								:placeholder="
									filterModel.matchMode == 'gte'
										? `Greater or equal than`
										: `Less or equal than`
								"
							/>
						</template>
						<template v-else-if="column.key == 'transactionDt'">
							<DatePicker
								v-model="filterModel.value"
								class="p-column-filter"
								:manualInput="false"
								:placeholder="
									filterModel.matchMode == 'gte'
										? `Greater or equal than`
										: `Less or equal than`
								"
								showIcon
							/>
						</template>
					</template>
					<template #filtericon>
						<i
							:class="
								activeFilters[column.key] == null
									? 'pi pi-filter'
									: 'pi pi-filter-fill'
							"
						></i>
					</template>
					<template #filterclear>
						<Button outlined @click="clearFilter[column.key]()"> Clear </Button>
					</template>
				</Column>

				<template #empty>
					<div
						v-if="!loading"
						class="tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20"
					>
						<p class="tw3-text-center tw3-text-3xl">Expenses not found</p>
					</div>
				</template>
			</DataTable>
			<LoadingIndicator v-show="loading" />
		</template>
	</PageCard>
	<ExpenseEdit
		v-model:visible="showExpenseEdit"
		:expenseId="selectedExpenseId"
		@changed="refresh"
	/>
</template>
