import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';

import {
	CustomerFilteredRequestParams,
	DashboardLifetimeObjectNumber,
	FilteredRequestParams
} from '@/widgets/dashboard/model/types';

import {
	getAverageAndCostRatio,
	getAverageAndWarrantyRate,
	getFilteredCommission,
	getFilteredCompleted,
	getFilteredCostRatio,
	getFilteredWarrantyRate,
	getFollowUpJobs,
	getPurchaseMethodJobs,
	getTotalAndCommissionAmount,
	getTotalAndCompletedNumber
} from '../../api';

export const useTechnicianDashboardStore = defineStore(
	'technician-dashboard',
	() => {
		const filteredCompleted = useAsyncState(
			async (params: FilteredRequestParams) => {
				try {
					const result = await getFilteredCompleted(params);
					return result;
				} catch (e) {
					return [];
				}
			},
			[],
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		const filteredWarranty = useAsyncState(
			async (params: FilteredRequestParams) => {
				try {
					const result = await getFilteredWarrantyRate(params);
					return result;
				} catch (e) {
					return [];
				}
			},
			[],
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		const filteredCommission = useAsyncState(
			async (params: CustomerFilteredRequestParams) => {
				try {
					const result = await getFilteredCommission(params);
					return result;
				} catch (e) {
					return [];
				}
			},
			[],
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		const filteredCostRatio = useAsyncState(
			async (params: CustomerFilteredRequestParams) => {
				try {
					const result = await getFilteredCostRatio(params);
					return result;
				} catch (e) {
					return [];
				}
			},
			[],
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		const totalAndCompletedNumber =
			useAsyncState<DashboardLifetimeObjectNumber>(
				async () => {
					try {
						const result = await getTotalAndCompletedNumber();
						return result;
					} catch (e) {
						return { data: [], count: 0, difference: 0 };
					}
				},
				{ data: [], count: 0, difference: 0 },
				{
					immediate: false,
					resetOnExecute: false
				}
			);

		const averageAndWarrantyRate = useAsyncState<DashboardLifetimeObjectNumber>(
			async () => {
				try {
					const result = await getAverageAndWarrantyRate();
					return { ...result, count: Number(result.count) };
				} catch (e) {
					return { data: [], count: 0, difference: 0 };
				}
			},
			{ data: [], count: 0, difference: 0 },
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		const totalAndCommissionAmount =
			useAsyncState<DashboardLifetimeObjectNumber>(
				async () => {
					try {
						const result = await getTotalAndCommissionAmount();
						return { ...result, count: Number(result.count) };
					} catch (e) {
						return { data: [], count: 0, difference: 0 };
					}
				},
				{ data: [], count: 0, difference: 0 },
				{
					immediate: false,
					resetOnExecute: false
				}
			);

		const averageAndCostRatio = useAsyncState<DashboardLifetimeObjectNumber>(
			async () => {
				try {
					const result = await getAverageAndCostRatio();
					return { ...result, count: Number(result.count) };
				} catch (e) {
					return { data: [], count: 0, difference: 0 };
				}
			},
			{ data: [], count: 0, difference: 0 },
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		const followUpJobs = useAsyncState(
			async () => {
				try {
					const result = await getFollowUpJobs();
					return {
						pickUp: Number(result.pickUp),
						dropOff: Number(result.dropOff),
						rAndI: Number(result.rAndI),
						tint: Number(result.tint),
						recal: Number(result.recal),
						callBack: Number(result.callBack)
					};
				} catch (e) {
					return {};
				}
			},
			{},
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		const purchaseMethodJobs = useAsyncState(
			async () => {
				try {
					const result = await getPurchaseMethodJobs();
					return {
						mobile:
							Math.round(
								((Number(result.delivery) + Number(result.mobile)) /
									Number(result.totalJobs)) *
									1000
							) / 10,
						techside:
							Math.round(
								(Number(result.techsideSent) / Number(result.completedJobs)) *
									1000
							) / 10,
						todayJob:
							Math.round(
								(Number(result.completedJobs) / Number(result.totalJobs)) * 1000
							) / 10
					};
				} catch (e) {
					return {};
				}
			},
			{},
			{
				immediate: false,
				resetOnExecute: false
			}
		);

		return {
			filteredCompleted,
			filteredWarranty,
			filteredCommission,
			filteredCostRatio,
			totalAndCompletedNumber,
			averageAndWarrantyRate,
			totalAndCommissionAmount,
			averageAndCostRatio,
			followUpJobs,
			purchaseMethodJobs
		};
	}
);
