import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-0" }
const _hoisted_2 = { class: "timeline-header tw3-p-4 tw3-flex tw3-justify-between tw3-items-center" }
const _hoisted_3 = { class: "tw3-m-0" }
const _hoisted_4 = { class: "timeline-content tw3-pb-4" }
const _hoisted_5 = {
  key: 0,
  class: "tw3-h-96 tw3-flex tw3-items-center tw3-justify-center"
}
const _hoisted_6 = {
  key: 1,
  class: "tw3-flex tw3-flex-col tw3-items-center tw3-gap-4"
}
const _hoisted_7 = { class: "tw3-font-semibold tw3-text-xl" }
const _hoisted_8 = { class: "tw3-flex tw3-items-center tw3-justify-between" }
const _hoisted_9 = { class: "tw3-flex tw3-flex-col tw3-gap-1 tw3-overflow-hidden tw3-w-full" }
const _hoisted_10 = { class: "tw3-flex tw3-w-full tw3-justify-between tw3-gap-4" }
const _hoisted_11 = { class: "tw3-font-semibold tw3-max-w-56" }
const _hoisted_12 = { class: "tw3-m-0 tw3-text-black" }
const _hoisted_13 = { class: "tw3-flex tw3-w-full tw3-justify-between tw3-gap-4" }
const _hoisted_14 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-m-0 tw3-text-base" }
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "tw3-text-surface-500 dark:tw3-text-surface-400" }
const _hoisted_17 = {
  key: 0,
  class: "tw3-px-2 t-py-0.5 tw3-rounded tw3-text-white tw3-text-xs tw3-shadow-md tw3-bg-indigo-500"
}
const _hoisted_18 = { class: "tw3-text-sm tw3-text-surface-500 dark:tw3-text-surface-400" }

import ProgressSpinner from 'primevue/progressspinner';
import Timeline from 'primevue/timeline';

import { formatDate, formatNumber, formatPrice } from '../lib';
import { NewestInvoices, TimeRange } from '../model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewestInvoices',
  props: {
    items: {},
    loading: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('newestInvoices')), 1),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "header-icons" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.items.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_unref(ProgressSpinner), { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[1] || (_cache[1] = _createElementVNode("i", {
                    class: "pi pi-chart-bar",
                    style: {"font-size":"3rem"}
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('noInvoices')), 1)
                ]))
          ]))
        : (_openBlock(), _createBlock(_unref(Timeline), {
            key: 1,
            class: "customized-timeline tw3-gap-2 tw3-py-0 tw3-px-4",
            pt: {
					eventContent: { style: 'overflow: hidden !important' }
				},
            value: _ctx.items
          }, {
            marker: _withCtx((slotProps) => [
              _createElementVNode("span", {
                class: _normalizeClass(["tw3-rounded-full tw3-p-1 tw3-w-8 tw3-h-8 tw3-flex tw3-justify-center tw3-items-center tw3-bg-cyan-500", {
							'tw3-bg-indigo-500': slotProps.item.customer.isInsurance === 1,
							'tw3-bg-yellow-500': slotProps.item.customer.isCommercial === 1
						}])
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(
								!slotProps.item.customer.isInsurance ||
								slotProps.item.customer.isCommercial
									? 'pi pi-briefcase'
									: 'pi pi-building'
							)
                }, null, 2)
              ], 2)
            ]),
            content: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", _hoisted_11, _toDisplayString(slotProps.item.customer.companyName
											? slotProps.item.customer.companyName
											: slotProps.item.customer.name), 1),
                    _createElementVNode("h6", _hoisted_12, _toDisplayString(_unref(formatPrice)(_unref(formatNumber)(slotProps.item.total))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("a", {
                        class: "tw3-text-gray-600",
                        href: `/jobs/${slotProps.item.jobId}`,
                        target: "_blank"
                      }, _toDisplayString(' #' + slotProps.item.jobId), 9, _hoisted_15),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('billTo')), 1),
                      (slotProps.item.billTo.isInsurance)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('insurance')), 1))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(["tw3-px-2 t-py-0.5 tw3-rounded tw3-text-white tw3-text-xs tw3-shadow-md", 
											slotProps.item.billTo.isCommercial
												? 'tw3-bg-yellow-500'
												: 'tw3-bg-cyan-500'
										])
                          }, _toDisplayString(slotProps.item.billTo.isCommercial
												? _ctx.$t('comm')
												: _ctx.$t('retail')), 3))
                    ]),
                    _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(formatDate)(slotProps.item.modified, _unref(TimeRange).DAY)), 1)
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["value"]))
    ])
  ]))
}
}

})