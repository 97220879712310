<script setup lang="ts">
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import ToggleSwitch from 'primevue/toggleswitch';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';

import { isEmptyValue } from '@/shared/helpers';
import { TableFiltersWithMetadata } from '@/shared/types/tables';
import { RefreshButton } from '@/shared/ui';

import { jobStatusConfig, rejectedInvoicesHeader } from '../../model/constants';
import useRejectedInvoicesStore from '../../model/store';

import TagAddingDialog from './TagAddingDialog.vue';

import type {
	newTag,
	RejectedInvoice,
	RejectedInvoicesFilters
} from '@/widgets/rejectedInvoicesDialog/model/types';
import type {
	DataTablePageEvent,
	DataTableSortEvent
} from 'primevue/datatable';

const toast = useToast();

const rejectedInvoicesStore = useRejectedInvoicesStore();
const { t } = useI18n();

const handlePage = (state: DataTablePageEvent) => {
	rejectedInvoicesStore.pagination.page = state.page + 1;
};

const handleSort = (state: DataTableSortEvent) => {
	rejectedInvoicesStore.pagination.page = 1;

	rejectedInvoicesStore.handleSort({
		desc: state.sortOrder === -1 ? true : false,
		field: state.sortField as string
	});
};

const getFilterIconClass = (field: string) => {
	const filter =
		rejectedInvoicesStore.filters[
			field as keyof TableFiltersWithMetadata<RejectedInvoicesFilters>
		];

	return isEmptyValue(filter.value) || filter.value === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const openInvoiceDetail = (id: number) => {
	const invoice = rejectedInvoicesStore.reactiveInvoicesData.find(
		item => item.id === id
	);
	rejectedInvoicesStore.setInvoiceDetailsData({
		message: invoice?.edi_message,
		status: invoice?.edi_status
	});
};

const newTagsIsLoading = ref({
	jobID: 0,
	loading: false
});
async function addNewTags(tags: newTag[], data: RejectedInvoice) {
	newTagsIsLoading.value = {
		jobID: data.id,
		loading: true
	};

	const response = await rejectedInvoicesStore.addJobTagHandler({
		jobID: data?.id,
		newTags: tags,
		oldTags: data.tags
	});

	if (response !== 'error') {
		toast.add({
			detail: t('rejectedInvoices.tagAddedSuccess'),
			life: 3000,
			severity: 'success',
			summary: t('defaultToast.successSummary')
		});
	} else {
		toast.add({
			detail: t('defaultToast.errorDetails'),
			life: 3000,
			severity: 'error',
			summary: t('defaultToast.errorSummary')
		});
	}
	newTagsIsLoading.value = {
		jobID: 0,
		loading: false
	};
	return response;
}

const deleteTagIsLoading = ref({
	tagID: 0,
	jobID: 0,
	loading: false
});
async function deleteTag(params: { jobID: number; jobTagID: number }) {
	deleteTagIsLoading.value = {
		tagID: params.jobTagID,
		jobID: params.jobID,
		loading: true
	};

	const response = await rejectedInvoicesStore.deleteJobTagHandler({
		jobID: params.jobID,
		jobTagID: params.jobTagID
	});

	if (response !== 'error') {
		toast.add({
			severity: 'success',
			summary: t('defaultToast.successSummary'),
			life: 3000,
			detail: t('rejectedInvoices.tagDeletedSuccess')
		});
	} else {
		toast.add({
			severity: 'error',
			summary: t('defaultToast.errorSummary'),
			life: 3000,
			detail: t('rejectedInvoices.tagDeletedFailed')
		});
	}
	deleteTagIsLoading.value = {
		tagID: 0,
		jobID: 0,
		loading: false
	};
	return response;
}

const toggleSwitch = ref();
function toggleSwitchHandler() {
	if (toggleSwitch.value) {
		toggleSwitch.value.checked = !rejectedInvoicesStore.includeVoided;
		rejectedInvoicesStore.includeVoided = !rejectedInvoicesStore.includeVoided;
	}
}

const statusObject = (status: keyof typeof jobStatusConfig) => {
	return jobStatusConfig[status] || '';
};

const skeletonArray = Array(10).fill({});
</script>

<template>
	<DataTable
		v-model:filters="rejectedInvoicesStore.filters"
		dataKey="id"
		filterDisplay="menu"
		:first="
			(rejectedInvoicesStore.pagination.page - 1) *
			rejectedInvoicesStore.pagination.limit
		"
		lazy
		paginator
		:rows="rejectedInvoicesStore.pagination.limit"
		scrollable
		scrollHeight="flex"
		:sortField="rejectedInvoicesStore.sort.sortBy"
		:sortOrder="rejectedInvoicesStore.sort.sortDesc === false ? 1 : -1"
		style="height: calc(100% - 42px)"
		tableClass="tw3-max-w-full tw3-max-h-full"
		:totalRecords="rejectedInvoicesStore.rejectedInvoicesData.totalCount"
		:value="
			rejectedInvoicesStore.rejectedInvoicesDataLoading
				? skeletonArray
				: rejectedInvoicesStore.reactiveInvoicesData
		"
		@page="handlePage"
		@sort="handleSort"
	>
		<Column
			v-for="column of rejectedInvoicesHeader"
			:key="column.key"
			bodyClass="tw3-align-top"
			:field="column.key"
			:filterField="column.key"
			:header="column.title"
			headerClass="tw3-text-right tw3-justify-end"
			:showFilterMatchModes="false"
			showFilterMenu
			sortable
			:style="{
				width:
					column.key === 'customer_name'
						? 'auto'
						: column.key === 'amount' || column.key === 'referral'
							? '12%'
							: column.key === 'id' || column.key === 'trading_partner'
								? '16%'
								: 'auto'
			}"
		>
			<template #body="{ data }">
				<span
					:class="[
						'cell-container',
						{
							'tw3-justify-end':
								column.key === 'amount' ||
								column.key === 'referral' ||
								column.key === 'id',
							'tw3-justify-center': column.key === 'trading_partner'
						}
					]"
				>
					<Skeleton v-if="rejectedInvoicesStore.rejectedInvoicesDataLoading" />
					<template v-else-if="column.key === 'id'">
						<div class="tw3-flex tw3-gap-4 tw3-items-center">
							<RouterLink target="_blank" :to="`/jobs/${data[column.key]}`">
								{{ data[column.key] }}
							</RouterLink>
							<Tag
								v-if="statusObject(data.job_status)"
								:style="{
									display: 'inline-block',
									background: statusObject(data.job_status).color,
									color: '#fff'
								}"
							>
								{{ statusObject(data.job_status).label }}
							</Tag>
						</div>
					</template>
					<span v-else>{{ data[column.key] }}</span>
				</span>
			</template>
			<template #filtericon>
				<i :class="getFilterIconClass(column.key)"></i>
			</template>
			<template #filter="{ filterModel }">
				<InputText
					v-model="filterModel.value"
					class="p-column-filter"
					:placeholder="`${t('searchBy')} ${column.title}`"
					type="text"
				/>
			</template>
		</Column>

		<Column
			v-if="rejectedInvoicesStore.shopTagsData.length"
			bodyClass="tw3-align-top text-center"
			dataType="array"
			field="tags"
			:header="t('rejectedInvoices.tags')"
			:style="{
				width: '20%',
				'min-width': '200px'
			}"
		>
			<template #body="{ data }">
				<Skeleton v-if="rejectedInvoicesStore.rejectedInvoicesDataLoading" />
				<div v-else class="tw3-flex tw3-items-start tw3-gap-4">
					<TagAddingDialog
						v-if="data"
						:currentTagsList="data.tags"
						:isLoading="
							newTagsIsLoading.jobID === data.id
								? newTagsIsLoading.loading
								: false
						"
						:jobID="data.id"
						:tagsList="rejectedInvoicesStore.shopTagsData"
						@add-new-tag="tags => addNewTags(tags, data)"
					/>
					<div class="tw3-flex tw3-flex-wrap tw3-items-center tw3-gap-2">
						<template v-for="(tag, index) in data.tags" :key="tag?.id || index">
							<Tag
								severity="secondary"
								:style="{
									'border-left': '10px solid black',
									'border-color': tag.color
								}"
							>
								<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize">
									<span style="font-size: 0.7rem">{{
										tag.text.toLowerCase()
									}}</span>
									<i
										v-if="
											deleteTagIsLoading.jobID === data.id &&
											deleteTagIsLoading.tagID === tag.jobtag.id &&
											deleteTagIsLoading.loading
										"
										class="pi pi-spin pi-spinner text-sm"
										style="cursor: pointer; font-size: 0.7rem"
									></i>
									<i
										v-else
										class="pi pi-times-circle text-sm"
										style="cursor: pointer; font-size: 0.7rem"
										@click.once="
											deleteTag({ jobID: data.id, jobTagID: tag.jobtag.id })
										"
									></i>
								</div>
							</Tag>
						</template>
					</div>
				</div>
			</template>
		</Column>

		<Column
			bodyClass="tw3-align-top"
			:style="{
				width: '80px'
			}"
		>
			<template #body="{ data }">
				<Skeleton v-if="rejectedInvoicesStore.rejectedInvoicesDataLoading" />
				<Button
					v-else-if="data?.edi_message || data?.edi_status"
					v-tooltip.top="t('rejectedInvoices.showDetails')"
					icon="pi pi-info-circle"
					severity="info"
					text
					@click="openInvoiceDetail(data.id)"
				/>
			</template>
		</Column>

		<template v-if="!rejectedInvoicesStore.rejectedInvoicesDataLoading" #empty>
			<div
				class="tw3-w-full tw3-h-[476px] tw3-flex tw3-justify-center tw3-items-center"
			>
				<p class="tw3-text-center tw3-text-3xl">{{ t('noData') }}</p>
			</div>
		</template>
		<template #header>
			<div class="tw3-flex tw3-items-center tw3-justify-between tw3-gap-3">
				<template v-if="rejectedInvoicesStore.isFilteredByShop">
					<Select
						v-model="rejectedInvoicesStore.selectedShop"
						optionLabel="name"
						:options="rejectedInvoicesStore.shops"
						:placeholder="`${t('selectShop')}`"
					/>

					<div class="tw3-flex tw3-items-center tw3-gap-0.5 tw3-gap-x-2">
						<ToggleSwitch
							ref="toggleSwitch"
							v-model="rejectedInvoicesStore.includeVoided"
						/>
						<span
							class="tw3-cursor-pointer"
							style="line-height: 100%"
							@click="toggleSwitchHandler"
						>
							{{ t('rejectedInvoices.voidedJobs') }}
						</span>
					</div>
				</template>

				<RefreshButton
					class="tw3-ml-auto tw3-mr-0"
					@click="rejectedInvoicesStore.refreshData()"
				/>
			</div>
		</template>
	</DataTable>
</template>

<style scoped>
.p-tag {
	margin: 0;
}

.tag-inner {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
}
</style>
