import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import {
	getPaymentSettingsTemplate,
	updatePaymentSettingsTemplate
} from '@/entities/accounting/settingsTemplate/lib/api';
import { PaymentSettingsTemplate } from '@/entities/accounting/settingsTemplate/lib/types';
import { useMessages } from '@/shared/composables';

const useSettingsTemplateStore = defineStore(
	'widgetsAccountingSettingsTemplate',
	() => {
		const { showError, showInfo } = useMessages();

		const loadingCounter = ref(0);
		const loading = computed(() => {
			return loadingCounter.value > 0;
		});

		const paymentSettingsTemplateState = useAsyncState(
			() => {
				loadingCounter.value++;
				return getPaymentSettingsTemplate()
					.catch(error => {
						showError(error);
						return {
							category: []
						} as PaymentSettingsTemplate;
					})
					.finally(() => {
						loadingCounter.value--;
					});
			},
			{ category: [] } as PaymentSettingsTemplate,
			{
				immediate: true,
				resetOnExecute: false
			}
		);

		const loadPaymentSettingsTemplate = async () => {
			await paymentSettingsTemplateState.execute(0);
		};

		const savePaymentSettingsTemplate = async (
			params: PaymentSettingsTemplate
		) => {
			loadingCounter.value++;
			try {
				const request: PaymentSettingsTemplate = {
					...params
				};
				await updatePaymentSettingsTemplate(request);
				showInfo('Settings saved');
				loadPaymentSettingsTemplate();
			} catch (error) {
				showError(error);
			} finally {
				loadingCounter.value--;
			}
		};

		return {
			loading,
			paymentSettingsTemplate: paymentSettingsTemplateState.state,
			loadPaymentSettingsTemplate,
			savePaymentSettingsTemplate
		};
	}
);

export default useSettingsTemplateStore;
