import { CORE_API } from '@/shared/api';

import {
	normalizeBurst,
	normalizeInbox,
	normalizeOutbox
} from '../model/utils';

import type {
	AttachFaxRequest,
	AttachFaxResponse,
	Burst,
	BurstAPI,
	BurstFaxesResponse,
	BurstLocalFaxRequest,
	BurstTableRequestParams,
	FaxInbox,
	FaxInboxAPI,
	FaxInboxRequestParams,
	FaxOutbox,
	FaxOutboxAPI,
	FaxOutboxRequestParams,
	FaxTableResponse,
	FaxTableResponseAPI,
	QuickJobSearchResponse
} from '../model/types';

export const getUnreadFaxCount = async (): Promise<number> => {
	const response = await CORE_API.get(`/api/unreadFaxCount`);

	return response.data.numberOfUnreadFaxes;
};

export const getFaxInbox = async (
	params: FaxInboxRequestParams
): Promise<FaxTableResponse<FaxInbox>> => {
	const response = await CORE_API.get<FaxTableResponseAPI<FaxInboxAPI>>(
		`/api/fax/inboxTable`,
		{
			params: params
		}
	);

	return {
		data: normalizeInbox(response.data.rows),
		totalCount: response.data.numberOfRowsFound
	};
};

export const getFaxOutbox = async (
	params: FaxOutboxRequestParams
): Promise<FaxTableResponse<FaxOutbox>> => {
	const response = await CORE_API.get<FaxTableResponseAPI<FaxOutboxAPI>>(
		`/api/fax/outboxTable`,
		{
			params: params
		}
	);

	return {
		data: normalizeOutbox(response.data.rows),
		totalCount: response.data.numberOfRowsFound
	};
};

export const getBurstTable = async (
	params: BurstTableRequestParams
): Promise<FaxTableResponse<Burst>> => {
	const response = await CORE_API.get<FaxTableResponseAPI<BurstAPI>>(
		`/api/fax/burstTable`,
		{
			params: params
		}
	);

	return {
		data: normalizeBurst(response.data.rows),
		totalCount: response.data.numberOfRowsFound
	};
};

export const getOutgoingFaxNumber = async (): Promise<number> => {
	const response = await CORE_API.get(`/api/fax/outgoingFaxNumber`);

	return response.data;
};

export const getBurstCount = async (): Promise<number> => {
	const response = await CORE_API.get(`/api/fax/burstCount`);

	return response.data.burst_count;
};

export const deleteFaxes = async (
	faxIds: string
): Promise<{ status: string }> => {
	try {
		await CORE_API.post(`/api/fax/deleteFaxes`, null, {
			params: {
				faxIds: faxIds
			}
		});

		return {
			status: 'Success'
		};
	} catch (error) {
		console.error(error);
		return {
			status: 'Error'
		};
	}
};

export const deleteBurstsFaxes = async (
	burstIds: string
): Promise<{ status: string }> => {
	try {
		await CORE_API.post(`/api/fax/deleteBursts`, null, {
			params: {
				burstIds: burstIds
			}
		});

		return {
			status: 'Success'
		};
	} catch (error) {
		console.error(error);
		return {
			status: 'Error'
		};
	}
};

export const burstFaxes = async (
	faxID: string
): Promise<BurstFaxesResponse> => {
	try {
		const response = await CORE_API.post<BurstFaxesResponse>(
			`/api/fax/burstFaxes`,
			{
				burstName: null,
				burstUrl: null,
				faxId: faxID
			}
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return {
			status: 'Error'
		};
	}
};

export const quickJobSearch = async (
	searchVal: string
): Promise<QuickJobSearchResponse[] | string> => {
	try {
		const encodedSearchVal = encodeURIComponent(searchVal);
		const response = await CORE_API.get<QuickJobSearchResponse[]>(
			`/api/jobs/search/${encodedSearchVal}/tab/faxsearch`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return 'Error';
	}
};

export const attachFax = async (
	params: AttachFaxRequest
): Promise<AttachFaxResponse | string> => {
	try {
		const response = await CORE_API.post<AttachFaxResponse>(
			`/api/shop/${params.shopId}/job/${params.jobId}/document/${params.id}`,
			params
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return 'Error';
	}
};

export const updateFaxStatus = async (params: {
	faxIds: string;
	status: string;
}): Promise<{ [key: string]: string } | string> => {
	try {
		const response = await CORE_API.post(`/api/fax/updateStatus`, null, {
			params: params
		});

		return response.data;
	} catch (error) {
		console.error(error);
		return 'Error';
	}
};

export const updateFaxReferral = async (params: {
	faxId: number;
	referral: string | null;
}): Promise<string> => {
	try {
		const response = await CORE_API.post(`/api/fax/${params.faxId}/referral`, {
			referral: params.referral
		});

		return response.data.response;
	} catch (error) {
		console.error(error);
		return 'Error';
	}
};

export const sendNewFax = async (params: FormData): Promise<string> => {
	try {
		const response = await CORE_API.post(`/legacy/api/v1/fax/send`, params);

		return response.data;
	} catch (error) {
		console.error(error);
		return 'Error';
	}
};

export const burstLocalFax = async (
	params: BurstLocalFaxRequest
): Promise<string> => {
	const response = await CORE_API.post<{ status: string }>(
		`/api/fax/burstFaxes`,
		{
			burstName: params.burstName,
			burstUrl: params.burstUrl,
			faxId: null
		}
	);

	return response.data.status;
};

export const getFaxSentPdf = async (jobid: number) => {
	const response = await CORE_API.get<string>(`/api/fax/pdf/${jobid}`, {
		headers: { 'Content-Type': 'multipart/form-data' }
	});

	return response;
};

// getFaxSentPdf: async function (faxId) {
// 	return await Api.get(`/api/fax/pdf/${faxId}`, {headers: {'Content-Type': 'multipart/form-data'}})
// },
// getFaxTransmittalPdf: async function (faxId) {
// 	return await Api.get(`/api/fax/transmittal/${faxId}`, {headers: {'Content-Type': 'multipart/form-data'}})
// },
