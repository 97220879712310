<script setup lang="ts">
import { FilterMatchMode } from '@primevue/core/api';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import { computed, ref } from 'vue';

import useOrganizationsStore from '@/entities/admin/organizations/lib/store';
import PageCard from '@/shared/ui/container/PageCard.vue';

import useProfilesStore from './lib/store';
import ProfileCreateDialog from './ProfileCreateDialog.vue';

const cid = 'accounting-profiles-list';

const skeletonArray = Array(30).fill({});

const profilesStore = useProfilesStore();
const { loading, profiles } = storeToRefs(profilesStore);

const organizationsStore = useOrganizationsStore();
const { organizations } = storeToRefs(organizationsStore);

const tableData = computed(() => {
	return profiles.value.map(profile => {
		return {
			...profile,
			organizationName: organizations.value[profile.organizationId].name
		};
	});
});

const filters = ref();

const filtersWrapper = computed({
	get: () => {
		return loading.value ? {} : filters.value;
	},
	set: value => {
		filters.value = value;
	}
});

const initFilters = () => {
	filters.value = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS }
	};
};

initFilters();

const showEditProfile = ref(false);
// const selectedProfile = ref({ ...defaultProfile });

const create = () => {
	showEditProfile.value = true;
	// selectedProfile.value = { ...defaultProfile };
};

const refresh = () => {
	profilesStore.loadProfiles();
};

const title = ref([{ label: 'Profiles', disabled: true }]);
</script>

<template>
	<PageCard>
		<template #title>
			<div class="tw3-flex tw3-gap-2 tw3-items-center">
				<Breadcrumb :model="title" pt:root:class="!tw3-p-0 !tw3-ml-3">
					<template #item="{ item }">
						<span class="!tw3-text-xl !tw3-font-bold tw3-text-black">{{
							item.label
						}}</span>
					</template>
				</Breadcrumb>
			</div>
		</template>
		<template #content>
			<div
				class="tw3-flex tw3-justify-between tw3-gap-2"
				style="padding: 12px 16px"
			>
				<div class="tw3-flex tw3-gap-4">
					<div class="tw3-flex tw3-flex-col tw3-gap-1 tw3-grow">
						<label class="tw3-pl-1" :for="`${cid}-search`">Search</label>
						<IconField>
							<InputIcon class="pi pi-search" />
							<InputText
								:id="`${cid}-search`"
								v-model="filters['global'].value"
								v-tooltip="'Search by Name, Number, Type or Description'"
								class="tw3-w-full"
								placeholder=""
								style="
									width: 250px;
									padding-left: calc(
										(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)
									);
									padding-right: calc(
										(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)
									);
								"
								type="search"
							/>
							<InputIcon
								v-show="filters['global'].value"
								class="pi pi-times"
								style="cursor: pointer"
								@click="filters['global'].value = ''"
							/>
						</IconField>
					</div>
				</div>
				<div class="tw3-flex tw3-gap-4 tw3-items-end">
					<Button
						v-tooltip.top="'Create new profile'"
						icon="pi pi-plus"
						label="New profile"
						@click="create"
					/>
					<Button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						severity="secondary"
						@click="refresh()"
					/>
				</div>
			</div>

			<DataTable
				v-model:filters="filtersWrapper"
				dataKey="id"
				filterDisplay="menu"
				:globalFilterFields="['name', 'code', 'type', 'subtype', 'description']"
				groupRowsBy="type"
				:pt="{
					rowgroupheadercell: { colspan: '99' }
				}"
				scrollable
				scrollHeight="flex"
				size="small"
				:value="loading ? skeletonArray : tableData"
			>
				<Column field="name" header="Company Name">
					<template #body="{ data }">
						<Skeleton v-if="loading" height="2rem" />
						<template v-else>
							<span> {{ data.organizationName }} </span>
						</template>
					</template>
				</Column>

				<Column field="status" header="Status">
					<template #body="{ data }">
						<Skeleton v-if="loading" height="2rem" />
						<div v-else class="tw3-flex tw3-items-center tw3-justify-left">
							<Tag
								pt:label:class="tw3-font-normal"
								:severity="data.active ? 'success' : 'danger'"
								:value="data.active ? 'Active' : 'Inactive'"
							></Tag>
						</div>
					</template>
				</Column>

				<!-- <Column :pt="{ bodyCell: { class: '!tw3-py-0' } }">
			<template #body="{ data }">
				<Skeleton v-if="loading" height="2rem" />
				<div
					v-else
					class="action-buttons tw3-flex tw3-flex-row tw3-items-center tw3-justify-end tw3-gap-3"
				>
					<Button
						v-tooltip.top="{ value: 'Edit account', showDelay: 400 }"
						aria-label="Edit"
						rounded
						severity="secondary"
						text
						@click="edit(data.id)"
					>
						<template #icon>
							<i class="material-icons md-edit" />
						</template>
					</Button>
				</div>
			</template>
		</Column> -->

				<template v-if="!loading" #empty>
					<div
						class="tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20"
					>
						<p class="tw3-text-center tw3-text-3xl">Profiles not found</p>
					</div>
				</template>
			</DataTable>
			<ProfileCreateDialog v-model="showEditProfile" />
		</template>
	</PageCard>
</template>
