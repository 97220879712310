import { CORE_API } from '@/shared/api';
import { convertToDateTime } from '@/shared/helpers/converters';
import { Exact } from '@/shared/types';

import {
	CreateTemplateRequest,
	CreateTemplateResponse,
	ListTemplateResponse,
	Template,
	UpdateTemplateRequest
} from './types';

export const URL = '/unum/accounting/account/template';

export const listTemplate = async (): Promise<Template[]> => {
	const response = await CORE_API.get<ListTemplateResponse>(URL);
	return convertToDateTime(response.data.template);
};

export const createTemplate = async <T>(
	request: Exact<T, CreateTemplateRequest>
): Promise<number> => {
	const response = await CORE_API.post<CreateTemplateResponse>(URL, request);
	return response.data.id;
};

export const updateTemplate = async <T>(
	request: Exact<T, UpdateTemplateRequest>
): Promise<void> => {
	await CORE_API.patch(URL, request);
};

export const deleteTemplate = async (id: number): Promise<void> => {
	await CORE_API.delete(`${URL}/${id}`);
};
