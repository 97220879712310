import { CORE_API } from '@/shared/api';
import { Exact } from '@/shared/types';

import { CreateProfileRequest, ListProfileResponse, Profile } from './types';

export const URL = '/unum/accounting/profile';

export const createProfile = async <T>(
	request: Exact<T, CreateProfileRequest>
): Promise<void> => {
	await CORE_API.post(URL, request);
};

export const listProfile = async (
	admin: boolean = false
): Promise<Profile[]> => {
	const response = await CORE_API.get<ListProfileResponse>(URL, {
		params: {
			admin
		}
	});
	return response.data.profile;
};
