import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-justify-between" }
const _hoisted_2 = { class: "tw3-flex tw3-gap-2 tw3-items-center" }
const _hoisted_3 = { class: "!tw3-text-xl !tw3-font-bold tw3-text-black" }
const _hoisted_4 = { class: "tw3-flex tw3-gap-2 tw3-items-center" }
const _hoisted_5 = { class: "tw3-flex tw3-justify-between tw3-gap-2" }
const _hoisted_6 = { class: "tw3-flex tw3-gap-3" }
const _hoisted_7 = { class: "tw3-flex tw3-flex-col tw3-gap-0 tw3-grow" }
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "tw3-flex tw3-flex-col tw3-gap-0 tw3-grow" }
const _hoisted_10 = { class: "tw3-flex tw3-gap-2 tw3-items-end" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "tw3-flex tw3-items-center tw3-gap-2 tw3-font-bold" }
const _hoisted_14 = { class: "tw3-flex tw3-justify-end tw3-font-bold tw3-w-full" }
const _hoisted_15 = { class: "tw3-mr-3" }

import { round } from 'lodash';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import { computed, ref, watchEffect } from 'vue';

import router from '@/app/router';
import useAccountingStore from '@/features/accounting/lib/store';
import ProfileSelect from '@/features/accounting/ProfileSelect.vue';
import { formatMoney } from '@/shared/helpers/formatters';
import { buildTree, flattenTree } from '@/shared/helpers/tree';
import PageCard from '@/shared/ui/container/PageCard.vue';
import DatePicker from '@/shared/ui/input/DatePicker.vue';

import useAccountingReportsStore from './lib/store';

const cid = 'accounting-balance-sheet';


export default /*@__PURE__*/_defineComponent({
  __name: 'BalanceSheet',
  setup(__props) {

const skeletonArray = Array(30).fill({});

const { typesById, selectedProfileId } = storeToRefs(useAccountingStore());

const reportsStore = useAccountingReportsStore();
const { balanceSheetLoading: loading, balanceSheet: records } =
	storeToRefs(reportsStore);

const accountsTree = computed(() => {
	if (!records.value) {
		return [];
	}
	const sorted = records.value.toSorted((a, b) => {
		return (
			a.typeId - b.typeId ||
			a.subtypeId - b.subtypeId ||
			a.name.localeCompare(b.name)
		);
	});

	return flattenTree(buildTree(sorted));
});

// start of the current day
const selectedTransactionDtTo = ref(DateTime.now().startOf('day'));

const refresh = () => {
	reportsStore.loadBalanceSheet({
		organizationId: selectedProfileId.value!,
		transactionDtTo: selectedTransactionDtTo.value.plus({ days: 1 })
	});
};

watchEffect(() => {
	refresh();
});

const title = ref([{ label: 'Balance Sheet', disabled: true }]);

const calculateTotal = (typeId: number) => {
	return records.value
		.filter(account => account.typeId === typeId)
		.reduce((acc, account) => acc + account.balance, 0);
};

const showTransactions = (id: number) => {
	router.push({
		name: 'accounting-transactions-by-account',
		params: { id: id.toString() }
	});
};

const balanced = computed(() => {
	if (!records.value) {
		return false;
	}
	const totalAssets = round(calculateTotal(1));
	const totalLiabilities = round(calculateTotal(2));
	const totalEquity = round(calculateTotal(3));
	return totalAssets === totalLiabilities + totalEquity;
});

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(PageCard, null, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(Breadcrumb), {
            model: title.value,
            "pt:root:class": "!tw3-p-0 !tw3-ml-3"
          }, {
            item: _withCtx(({ item }) => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(item.label), 1)
            ]),
            _: 1
          }, 8, ["model"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(Tag), {
            severity: balanced.value ? 'success' : 'danger',
            value: balanced.value ? 'Balanced' : 'Not in balance'
          }, null, 8, ["severity", "value"])
        ])
      ])
    ]),
    content: _withCtx(() => [
      _createVNode(_unref(DataTable), {
        dataKey: "id",
        filterDisplay: "menu",
        groupRowsBy: "typeId",
        pt: {
					root: { class: 'tw3-mb-3' },
					tableContainer: {
						class: 'tw3-self-center',
						style: 'width: 60rem; max-width: 100%;'
					},
					thead: { style: 'display: none' },
					rowgroupheadercell: { colspan: '99' },
					rowgroupfootercell: { colspan: '99' },
					bodyRow: { style: 'cursor: pointer' }
				},
        rowHover: !_unref(loading),
        rowGroupMode: "subheader",
        scrollable: "",
        scrollHeight: "flex",
        size: "small",
        tableClass: "tw3-max-w-full",
        value: _unref(loading) ? _unref(skeletonArray) : accountsTree.value,
        onRowClick: _cache[2] || (_cache[2] = event => showTransactions(event.data.id))
      }, _createSlots({
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", {
                  for: `${cid}-profile-id`
                }, "Organization", 8, _hoisted_8),
                _createVNode(ProfileSelect, {
                  id: `${cid}-profile-id`
                }, null, 8, ["id"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[3] || (_cache[3] = _createElementVNode("label", { for: "name" }, "As of", -1)),
                _createVNode(DatePicker, {
                  modelValue: selectedTransactionDtTo.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedTransactionDtTo).value = $event)),
                  class: "tw3-w-[250px]",
                  manualInput: false,
                  showIcon: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createVNode(_unref(Button), {
                icon: "pi pi-refresh",
                severity: "secondary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (refresh()))
              }, null, 512), [
                [
                  _directive_tooltip,
                  'Refresh',
                  void 0,
                  { top: true }
                ]
              ])
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(Column), {
            key: "code",
            field: "code",
            header: "Account number",
            pt: { bodyCell: { class: 'tw3-opacity-40' } },
            style: {"width":"7%"}
          }, {
            body: _withCtx(({ data }) => [
              (_unref(loading))
                ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(data.code), 1)
                  ], 64))
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            key: "name",
            field: "name",
            header: "Account name"
          }, {
            body: _withCtx(({ data }) => [
              (_unref(loading))
                ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "tw3-flex tw3-flex-row tw3-items-center tw3-gap-1",
                    style: _normalizeStyle({
								'padding-left': `${0.875 * (data.depth + 1)}rem`
							})
                  }, [
                    (data.parentAccountId)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _cache[4] || (_cache[4] = _createElementVNode("i", { class: "material-icons md-arrow_right tw3-opacity-40" }, null, -1)),
                          _createElementVNode("div", null, _toDisplayString(data.name), 1)
                        ], 64))
                      : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(data.name), 1))
                  ], 4))
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            key: "balance",
            bodyStyle: "text-align: right",
            field: "balance"
          }, {
            header: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("span", { class: "tw3-flex-1 tw3-text-right tw3-font-bold" }, " Balance ", -1)
            ])),
            body: _withCtx(({ data }) => [
              (_unref(loading))
                ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
                : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(formatMoney)(data.balance)), 1))
            ]),
            _: 1
          })
        ]),
        _: 2
      }, [
        (!_unref(loading))
          ? {
              name: "groupheader",
              fn: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(typesById)[data.typeId].name), 1)
              ]),
              key: "0"
            }
          : undefined,
        (!_unref(loading))
          ? {
              name: "groupfooter",
              fn: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(typesById)[data.typeId].name) + " Total: ", 1),
                  _createElementVNode("span", null, _toDisplayString(_unref(formatMoney)(calculateTotal(data.typeId))), 1)
                ])
              ]),
              key: "1"
            }
          : undefined,
        (!_unref(loading))
          ? {
              name: "empty",
              fn: _withCtx(() => [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20" }, [
                  _createElementVNode("p", { class: "tw3-text-center tw3-text-3xl" }, "Balance sheet is empty")
                ], -1))
              ]),
              key: "2"
            }
          : undefined
      ]), 1032, ["rowHover", "value"])
    ]),
    _: 1
  }))
}
}

})