import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-gap-4" }
const _hoisted_2 = { class: "tw3-flex tw3-gap-4 tw3-w-full" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-w-full tw3-bg-ye" }
const _hoisted_4 = { class: "tw3-w-full" }
const _hoisted_5 = { class: "tw3-m-0 tw3-flex tw3-flex-col tw3-gap-4" }
const _hoisted_6 = { class: "tw3-flex tw3-items-center tw3-gap-4" }
const _hoisted_7 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_8 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_9 = {
  key: 0,
  class: "tw3-w-full tw3-max-w-[462px]"
}
const _hoisted_10 = { class: "tw3-flex tw3-gap-4" }
const _hoisted_11 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_12 = {
  key: 0,
  class: "tw3-w-full tw3-max-w-64"
}
const _hoisted_13 = {
  key: 1,
  class: "tw3-flex tw3-gap-4"
}
const _hoisted_14 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_15 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_16 = { class: "tw3-m-0 tw3-flex tw3-flex-col tw3-gap-4" }
const _hoisted_17 = { class: "tw3-flex tw3-items-center tw3-gap-4" }
const _hoisted_18 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_19 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_20 = { class: "tw3-flex tw3-items-center tw3-gap-4" }
const _hoisted_21 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_22 = { class: "tw3-truncate tw3-w-full tw3-text-left tw3-text-[1rem]" }
const _hoisted_23 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_24 = { class: "tw3-m-0 tw3-flex tw3-items-center tw3-gap-4" }
const _hoisted_25 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_26 = { class: "tw3-w-full" }
const _hoisted_27 = { class: "tw3-w-full tw3-max-w-64" }
const _hoisted_28 = { class: "tw3-flex tw3-items-end tw3-gap-2" }
const _hoisted_29 = { class: "tw3-text-xs tw3-text-inherit" }
const _hoisted_30 = { class: "tw3-w-full" }

import Button from 'primevue/button';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Divider from 'primevue/divider';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Popover from 'primevue/popover';
import Select from 'primevue/select';
import Textarea from 'primevue/textarea';
import ToggleButton from 'primevue/togglebutton';
import { computed, onMounted, ref } from 'vue';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import {
	Customer,
	EdiPartners,
	ShopSettings
} from '@/entities/customer/lib/types';
import InputAddress from '@/features/address/InputAddress.vue';

import { edis, invoiceTerms } from '../../lib/constants';

import UpdateBillTo from './UpdateBillTo.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerSettings',
  setup(__props) {

const regex1 = /\b\d{4} \d{4} \d{4} \d{4}\b/;
const regex2 = /\b\d{16}\b/;

const store = useCustomerEditStore();
const isNotesWarning = computed(() =>
	store.customer.state.notes
		? regex1.test(store.customer.state.notes) ||
			regex2.test(store.customer.state.notes)
		: undefined
);
const isAutopopupWarning = computed(() =>
	store.customer.state.auto_popup_message
		? regex1.test(store.customer.state.auto_popup_message) ||
			regex2.test(store.customer.state.auto_popup_message)
		: undefined
);

const popWarning = ref<any>();
const popAutopopupWarning = ref<any>();

const showUpdateBill = ref(false);

const tradingPartner = computed(
	() => store.customer.state.edi?.trading_partner
);
const partnerProgramId = computed(
	() => store.customer.state.edi?.partner_program_id
);

const shopsToAppearIn = computed(() => {
	const shopsToAppearIn = [];
	for (let i = 0; i < store.customer.state.consumer_shop_settings.length; i++) {
		if (store.customer.state.consumer_shop_settings[i].override_allowed_shop) {
			shopsToAppearIn.push(
				store.customer.state.consumer_shop_settings[i].shop_id
			);
		}
	}
	return shopsToAppearIn;
});

const updateShopsToAppearIn = (shopsToAppearIn: number[]) => {
	const consumerSettings = [...store.customer.state.consumer_shop_settings];
	for (let i = 0; i < consumerSettings.length; i++) {
		consumerSettings[i].override_allowed_shop = 0;
	}

	for (let j = 0; j < shopsToAppearIn.length; j++) {
		let foundShopSetting = false;
		for (let i = 0; i < consumerSettings.length; i++) {
			if (consumerSettings[i].shop_id === shopsToAppearIn[j]) {
				consumerSettings[i].override_allowed_shop = 1;
				foundShopSetting = true;
			}
		}
		if (!foundShopSetting) {
			const newConsumerShopSetting = {
				override_allowed_shop: 1,
				pricelevel_id: null,
				rates: null,
				shop_id: shopsToAppearIn[j]
			};
			consumerSettings.push(newConsumerShopSetting as ShopSettings);
		}
	}

	updateValue('consumer_shop_settings', consumerSettings);
};

const toggleWarning = (event: MouseEvent, show: boolean) => {
	if (show) popWarning.value.show(event);
	else popWarning.value.hide(event);
};

const toggleAutopopupWarning = (event: MouseEvent, show: boolean) => {
	if (show) popAutopopupWarning.value.show(event);
	else popAutopopupWarning.value.hide(event);
};

const updateValue = (field: keyof Customer, value: any) => {
	store.customer.state = { ...store.customer.state, [field]: value };
};

const updateTradingPartner = async (v: string) => {
	if (v === 'none') {
		updateValue('edi', null);
	} else {
		await store.ediPartners.execute(0, { id: v });
		updateValue('edi', {
			capable: 1,
			partner_program_id: null,
			program_name: null,
			trading_partner: v
		});
	}
};

const updateEdiPartners = (value: string) => {
	const partner = store.ediPartners.state.find(
		(v: EdiPartners) => v.program_id === value
	) as unknown as EdiPartners;
	if (!partner) {
		updateValue('edi', {
			...store.customer.state.edi,
			partner_program_id: null,
			program_name: null
		});
	} else {
		updateValue('edi', {
			...store.customer.state.edi,
			partner_program_id: partner.program_id,
			program_name: partner.name
		});
	}
};

onMounted(() => {
	if (!store.salesrepOptions.state.length) {
		store.salesrepOptions.execute(0);
	}
	if (!store.techOptions.state.length) {
		store.techOptions.execute(0);
	}
	if (store.customer.state.edi && tradingPartner.value) {
		store.ediPartners.execute(0, { id: tradingPartner.value });
	}
	if (!store.shopsByOrganization.state.length) {
		store.shopsByOrganization.execute(0);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Card), {
      "pt:body:style": "padding: 0",
      style: {"box-shadow":"none"}
    }, {
      title: _withCtx(() => _cache[22] || (_cache[22] = [
        _createTextVNode("General Settings")
      ])),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Notes ", -1)),
            _createVNode(_unref(Textarea), {
              class: "tw3-w-full",
              field: "",
              modelValue: _unref(store).customer.state.notes,
              placeholder: "Note",
              rows: "3",
              style: _normalizeStyle(isNotesWarning.value ? 'border-color:  #fbbf24' : undefined),
              onMouseleave: _cache[0] || (_cache[0] = v => toggleWarning(v, false)),
              onMouseover: _cache[1] || (_cache[1] = v => toggleWarning(v, true)),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = v => updateValue('notes', v))
            }, null, 8, ["modelValue", "style"]),
            (isNotesWarning.value)
              ? (_openBlock(), _createBlock(_unref(Popover), {
                  key: 0,
                  ref_key: "popWarning",
                  ref: popWarning,
                  class: "tw3-max-w-96 customer-settings-notes-warning-popup"
                }, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createElementVNode("div", { class: "tw3-flex tw3-items-center tw3-gap-4" }, [
                      _createElementVNode("i", { class: "pi pi-exclamation-triangle" }),
                      _createElementVNode("div", null, " Entering credit card info in the notes field is prohibited due to security risks. ")
                    ], -1)
                  ])),
                  _: 1
                }, 512))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[26] || (_cache[26] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Auto Popup Note ", -1)),
            _createVNode(_unref(InputGroup), {
              onMouseleave: _cache[5] || (_cache[5] = v => toggleAutopopupWarning(v, false)),
              onMouseover: _cache[6] || (_cache[6] = v => toggleAutopopupWarning(v, true))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(InputGroupAddon), { style: {"padding":"0"} }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(ToggleButton), {
                      class: "tw3-w-[71px] tw3-h-full",
                      modelValue: !!_unref(store).customer.state.auto_popup,
                      offLabel: "Enable",
                      onLabel: "Disable",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = v => updateValue('auto_popup', +v))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Textarea), {
                  class: "tw3-w-full",
                  disabled: !_unref(store).customer.state.auto_popup,
                  field: "",
                  modelValue: _unref(store).customer.state.auto_popup_message,
                  placeholder: "Auto Popup Note",
                  rows: "3",
                  style: _normalizeStyle(
									isAutopopupWarning.value ? 'border-color:  #fbbf24' : undefined
								),
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = v => updateValue('auto_popup_message', v))
                }, null, 8, ["disabled", "modelValue", "style"]),
                (isAutopopupWarning.value)
                  ? (_openBlock(), _createBlock(_unref(Popover), {
                      key: 0,
                      ref_key: "popAutopopupWarning",
                      ref: popAutopopupWarning,
                      class: "tw3-max-w-96 customer-settings-notes-warning-popup"
                    }, {
                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                        _createElementVNode("div", { class: "tw3-flex tw3-items-center tw3-gap-4" }, [
                          _createElementVNode("i", { class: "pi pi-exclamation-triangle" }),
                          _createElementVNode("div", null, " Entering credit card info in the notes field is prohibited due to security risks. ")
                        ], -1)
                      ])),
                      _: 1
                    }, 512))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_unref(Divider), { style: {"margin":"0"} }),
    _createVNode(_unref(Card), {
      "pt:body:style": "padding: 0",
      style: {"box-shadow":"none"}
    }, {
      title: _withCtx(() => _cache[27] || (_cache[27] = [
        _createTextVNode("Job Defaults")
      ])),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[28] || (_cache[28] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Default Tech ", -1)),
              _createVNode(_unref(Select), {
                class: "tw3-w-full",
                loading: _unref(store).techOptions.isLoading,
                modelValue: _unref(store).customer.state.tech?.id,
                optionLabel: "name",
                options: _unref(store).techOptions.state,
                optionValue: "id",
                placeholder: "Select item",
                "onUpdate:modelValue": _cache[7] || (_cache[7] = 
									v => updateValue('tech', v == null ? v : { id: v })
								)
              }, null, 8, ["loading", "modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Default Salesrep ", -1)),
              _createVNode(_unref(Select), {
                class: "tw3-w-full",
                loading: _unref(store).salesrepOptions.isLoading,
                modelValue: _unref(store).customer.state.salesrep?.id,
                optionLabel: "name",
                options: _unref(store).salesrepOptions.state,
                optionValue: "id",
                placeholder: "Select item",
                "onUpdate:modelValue": _cache[8] || (_cache[8] = 
									v => updateValue('salesrep', v == null ? v : { id: v })
								)
              }, null, 8, ["loading", "modelValue", "options"])
            ])
          ]),
          (!_unref(store).customer.state.is_insurance)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Default Install Location ", -1)),
                _createVNode(InputAddress, {
                  class: "tw3-w-full",
                  modelValue: _unref(store).customer.state.install_location?.address,
                  withExtra: "",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = 
								v =>
									updateValue('install_location', {
										..._unref(store).customer.state.install_location,
										address: v.full_address ? v : null
									})
							)
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[31] || (_cache[31] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Auto add adhesive ", -1)),
              _createVNode(_unref(ToggleButton), {
                class: "p-togglebutton-boolean",
                modelValue: !!_unref(store).customer.state.auto_add_adhesive,
                offLabel: "No",
                onLabel: "Yes",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = v => updateValue('auto_add_adhesive', +v))
              }, null, 8, ["modelValue"])
            ]),
            (!_unref(store).customer.state.is_insurance)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _cache[32] || (_cache[32] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Auto add vehicles to fleet ", -1)),
                  _createVNode(_unref(ToggleButton), {
                    class: "p-togglebutton-boolean",
                    modelValue: !!_unref(store).customer.state.auto_add_to_fleet,
                    offLabel: "No",
                    onLabel: "Yes",
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = v => updateValue('auto_add_to_fleet', +v))
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true)
          ]),
          (!_unref(store).customer.state.is_insurance)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _cache[33] || (_cache[33] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Auto set install address ", -1)),
                  _createVNode(_unref(ToggleButton), {
                    class: "p-togglebutton-boolean",
                    modelValue: !!_unref(store).customer.state.auto_set_install_address,
                    offLabel: "No",
                    onLabel: "Yes",
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = 
									v => updateValue('auto_set_install_address', +v)
								)
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _cache[34] || (_cache[34] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Auto send confirmation texts ", -1)),
                  _createVNode(_unref(ToggleButton), {
                    class: "p-togglebutton-boolean",
                    modelValue: 
									!!_unref(store).customer.state.consumer_confirmation_settings?.send
								,
                    offLabel: "No",
                    onLabel: "Yes",
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = 
									v =>
										updateValue(
											'consumer_confirmation_settings',
											v ? { send: 1 } : { send: 0 }
										)
								)
                  }, null, 8, ["modelValue"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createVNode(_unref(Divider), { style: {"margin":"0"} }),
    _createVNode(_unref(Card), {
      "pt:body:style": "padding: 0",
      style: {"box-shadow":"none"}
    }, {
      title: _withCtx(() => _cache[35] || (_cache[35] = [
        _createTextVNode("Billing Defaults")
      ])),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _cache[36] || (_cache[36] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Default Invoice Tier ", -1)),
              _createVNode(_unref(Select), {
                class: "tw3-w-full",
                modelValue: _unref(store).customer.state.default_invoice_tier,
                optionLabel: "label",
                options: _unref(invoiceTerms),
                optionValue: "value",
                placeholder: "Select item",
                showClear: "",
                "onUpdate:modelValue": _cache[14] || (_cache[14] = 
									v => updateValue('default_invoice_tier', v)
								)
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _cache[37] || (_cache[37] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Standing PO ", -1)),
              _createVNode(_unref(InputText), {
                class: "tw3-w-full",
                modelValue: _unref(store).customer.state.standing_po,
                placeholder: "Standing po",
                "onUpdate:modelValue": _cache[15] || (_cache[15] = v => updateValue('standing_po', v))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _cache[39] || (_cache[39] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Default Bill To ", -1)),
              _createVNode(_unref(Button), {
                class: "tw3-w-full tw3-gap-1 tw3-leading-[21px]",
                iconPos: "right",
                outlined: "",
                severity: "secondary",
                style: {"padding":"0.5rem 0.75rem"},
                onClick: _cache[17] || (_cache[17] = ($event: any) => (showUpdateBill.value = true))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(store).customer.state.bill_to?.full_name ?? 'Select item'), 1),
                  (_unref(store).customer.state.bill_to)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[16] || (_cache[16] = _withModifiers(() => updateValue('bill_to', null), ["stop"]))
                      }, _cache[38] || (_cache[38] = [
                        _createElementVNode("i", { class: "pi pi-times" }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(UpdateBillTo, {
                show: showUpdateBill.value,
                "onUpdate:show": _cache[18] || (_cache[18] = ($event: any) => ((showUpdateBill).value = $event)),
                onUpdateBill: _cache[19] || (_cache[19] = v => updateValue('bill_to', v))
              }, null, 8, ["show"])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _cache[40] || (_cache[40] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Customer Resale ID ", -1)),
              _createVNode(_unref(InputText), {
                class: "tw3-w-full",
                modelValue: _unref(store).customer.state.resale_id,
                placeholder: "Customer Resale ID",
                "onUpdate:modelValue": _cache[20] || (_cache[20] = v => updateValue('resale_id', v))
              }, null, 8, ["modelValue"])
            ])
          ])
        ])
      ]),
      _: 1
    }),
    (!!_unref(store).customer.state.is_insurance)
      ? (_openBlock(), _createBlock(_unref(Divider), {
          key: 0,
          style: {"margin":"0"}
        }))
      : _createCommentVNode("", true),
    (!!_unref(store).customer.state.is_insurance)
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 1,
          "pt:body:style": "padding: 0",
          style: {"box-shadow":"none"}
        }, {
          title: _withCtx(() => _cache[41] || (_cache[41] = [
            _createTextVNode("EDI")
          ])),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _cache[42] || (_cache[42] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Trading Partner ", -1)),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_unref(Select), {
                    class: "tw3-w-full",
                    field: "",
                    modelValue: tradingPartner.value,
                    optionLabel: "name",
                    options: _unref(edis),
                    optionValue: "id",
                    placeholder: "Select item",
                    "onUpdate:modelValue": updateTradingPartner
                  }, null, 8, ["modelValue", "options"])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _cache[43] || (_cache[43] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Trading Partner Program ID ", -1)),
                _createVNode(_unref(Select), {
                  class: "tw3-w-full",
                  disabled: 
								!_unref(store).customer.state.edi || !_unref(store).ediPartners.state.length
							,
                  filter: "",
                  filterFields: ['program_id', 'name'],
                  loading: _unref(store).ediPartners.isLoading,
                  modelValue: partnerProgramId.value,
                  optionLabel: "name",
                  options: _unref(store).ediPartners.state,
                  optionValue: "program_id",
                  placeholder: "Select item",
                  showClear: "",
                  "onUpdate:modelValue": updateEdiPartners
                }, {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("div", null, _toDisplayString(option.name), 1),
                      _createElementVNode("div", _hoisted_29, " ID: " + _toDisplayString(option.program_id), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["disabled", "loading", "modelValue", "options"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_unref(Divider), { style: {"margin":"0"} }),
    _createVNode(_unref(Card), {
      "pt:body:style": "padding: 0",
      style: {"box-shadow":"none"}
    }, {
      title: _withCtx(() => _cache[44] || (_cache[44] = [
        _createTextVNode("Shop Settings")
      ])),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_30, [
          _cache[45] || (_cache[45] = _createElementVNode("div", { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }, " Override which shops included on ", -1)),
          _createVNode(_unref(InputGroup), null, {
            default: _withCtx(() => [
              _createVNode(_unref(InputGroupAddon), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Checkbox), {
                    binary: "",
                    modelValue: !!_unref(store).customer.state.override_allowed_shops,
                    "onUpdate:modelValue": _cache[21] || (_cache[21] = 
									v => updateValue('override_allowed_shops', +v)
								)
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_unref(MultiSelect), {
                class: "tw3-w-full tw3-max-w-64 tw3-items-center",
                disabled: !_unref(store).customer.state.override_allowed_shops,
                display: "chip",
                loading: _unref(store).shopsByOrganization.isLoading,
                modelValue: shopsToAppearIn.value,
                optionLabel: "name",
                options: _unref(store).shopsByOrganization.state,
                optionValue: "id",
                placeholder: "Select item",
                showToggleAll: false,
                "onUpdate:modelValue": updateShopsToAppearIn
              }, null, 8, ["disabled", "loading", "modelValue", "options"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}
}

})