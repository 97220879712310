import { DateTime } from 'luxon';

import { NumberRange, OrderBy, Period } from '@/shared/types';

export const TRANSACTION_TYPE_ID_JOURNAL_ENTRY = 12;
export const TRANSACTION_TYPE_ID_LEDGER_ENTRY = 19;

export type TransactionType = {
	id: number;
	name: string;
};

export type ListTransactionTypeResponse = {
	transactionType: TransactionType[];
};

export type TransactionItem = {
	accountId?: number;
	customerId?: number;
	vendorId?: number;
	debit: number;
	credit: number;
	partId?: number;
	quantity: number;
	description: string;
};

export type Transaction = {
	id?: number;
	organizationId: number;
	shopId: number;
	typeId: number;
	transactionDt: DateTime;
	amount?: number;
	description: string;
	jobId?: number;
	items: TransactionItem[];
};

export type TransactionShort = {
	id: number;
	organizationId: number;
	shopId: number;
	typeId: number;
	transactionDt: DateTime;
	amount: number;
	description: string;
	jobId: number;
};

export type ListTransactionRequest = {
	organizationId?: number;
	limit: number;
	offset: number;
	orderBy: OrderBy;
	typeId?: number;
	shopId?: number;
	amount?: NumberRange;
	transactionDt?: Period;
};

export type ListTransactionResponse = {
	transaction: TransactionShort[];
};

export type GetTransactionResponse = {
	transaction: Transaction;
};

export type CreateTransactionRequest = {
	organizationId: number;
	shopId: number;
	typeId: number;
	transactionDt: DateTime;
	description: string;
	jobId?: number;
	items: TransactionItem[];
};

export type CreateTransactionResponse = {
	id: number;
};

export type UpdateTransactionRequest = {
	id: number;
	transactionDt: DateTime;
	description: string;
	items: TransactionItem[];
};

export type DeleteTransactionRequest = {
	id: number;
};
