export const getPieBackgroundColors = () => {
	return {
		backgroundColor: [
			'#34cdebbf', // callback
			'#3a0046bf', // dropOff
			'#004646bf', // pickUp
			'#04cc93bf', // rAndI
			'#9934ebbf', // recal
			'#735230bf' // tint
		],
		hoverBackgroundColor: [
			'#66d7efff', // callback - lighter
			'#5a246aff', // dropOff - lighter
			'#236767ff', // pickUp - lighter
			'#36d4a9ff', // rAndI - lighter
			'#b95cf5ff', // recal - lighter
			'#977650ff' // tint - lighter
		]
	};
};
