import { CORE_API } from '@/shared/api';
import { convertToDateTime } from '@/shared/helpers/converters';

import {
	GetOrganizationResponse,
	ListOrganizationResponse,
	OrganizationShortInfo
} from './types';

export const URL = '/api';

export const searchOrganizations = async (
	term: string
): Promise<OrganizationShortInfo[]> => {
	const response = await CORE_API.get<ListOrganizationResponse>(
		URL + '/searchOrganizations',
		{
			params: { searchTerm: term, adminShowAll: true }
		}
	);
	return convertToDateTime(response.data);
};

export const getOrganization = async (
	id: number
): Promise<OrganizationShortInfo> => {
	const response = await CORE_API.get<GetOrganizationResponse>(
		`${URL}/organization/${id}`
	);
	return convertToDateTime(response.data);
};
