<script setup lang="ts">
import { FilterMatchMode } from '@primevue/core/api';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import { useConfirm } from 'primevue/useconfirm';
import { computed, ref } from 'vue';

import { Template } from '@/entities/accounting/accountTemplates/lib/types';
import useAccountingStore from '@/features/accounting/lib/store';
import { buildTree, flattenTree } from '@/shared/helpers/tree';

import useTemplatesStore from './lib/store';
import TemplateEdit from './TemplateEdit.vue';

const cid = 'accounting-account-templates-list';

const skeletonArray = Array(30).fill({});

const accountingStore = useAccountingStore();
const { typesById, subtypesById } = storeToRefs(accountingStore);

const templatesStore = useTemplatesStore();
const { loading, templates } = storeToRefs(templatesStore);

const defaultTemplate: Template = {
	id: 0,
	name: '',
	code: '',
	typeId: 0,
	subtypeId: 0,
	description: '',
	parentTemplateId: undefined,
	system: false
};

const accountsTree = computed(() => {
	if (!templates.value) {
		return [];
	}
	const sorted = templates.value
		.toSorted((a, b) => {
			return (
				a.typeId - b.typeId ||
				a.subtypeId - b.subtypeId ||
				a.name.localeCompare(b.name)
			);
		})
		.map(item => {
			const subtype = subtypesById.value[item.subtypeId] || { name: '' };
			const type = typesById.value[subtype.accountTypeId] || { name: '' };
			return {
				...item,
				type: type.name,
				subtype: subtype.name,
				balanceType: type.balanceType
			};
		});

	return flattenTree(buildTree(sorted, 'parentTemplateId'));
});

const filters = ref();

const filtersWrapper = computed({
	get: () => {
		return loading.value ? {} : filters.value;
	},
	set: value => {
		filters.value = value;
	}
});

const initFilters = () => {
	filters.value = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS }
	};
};

initFilters();

const showEditTemplate = ref(false);
const selectedTemplate = ref({ ...defaultTemplate });

const create = () => {
	showEditTemplate.value = true;
	selectedTemplate.value = { ...defaultTemplate };
};

const edit = (id: number) => {
	selectedTemplate.value = {
		...templates.value.find(item => item.id == id)!
	};
	showEditTemplate.value = true;
};

const refresh = () => {
	templatesStore.loadTemplates();
};

const confirm = useConfirm();

const remove = (id: number) => {
	const name = templates.value.find(item => item.id == id)!.name;
	confirm.require({
		message: `Do you want to delete Account "${name}"?`,
		header: 'Delete Account',
		icon: 'pi pi-trash',
		rejectLabel: 'Cancel',
		rejectProps: {
			label: 'Cancel',
			severity: 'secondary',
			outlined: true
		},
		acceptProps: {
			label: 'Delete',
			severity: 'danger'
		},
		accept: () => {
			templatesStore.deleteTemplate(id);
		},
		reject: () => {}
	});
};
</script>

<template>
	<div
		class="tw3-flex tw3-justify-between tw3-gap-2"
		style="padding: 12px 16px"
	>
		<div class="tw3-flex tw3-gap-4">
			<div class="tw3-flex tw3-flex-col tw3-gap-1 tw3-grow">
				<label class="tw3-pl-1" :for="`${cid}-search`">Search</label>
				<IconField>
					<InputIcon class="pi pi-search" />
					<InputText
						:id="`${cid}-search`"
						v-model="filters['global'].value"
						v-tooltip="'Search by Name, Number, Type or Description'"
						class="tw3-w-full"
						placeholder=""
						style="
							width: 250px;
							padding-left: calc(
								(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)
							);
							padding-right: calc(
								(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)
							);
						"
						type="search"
					/>
					<InputIcon
						v-show="filters['global'].value"
						class="pi pi-times"
						style="cursor: pointer"
						@click="filters['global'].value = ''"
					/>
				</IconField>
			</div>
		</div>
		<div class="tw3-flex tw3-gap-4 tw3-items-end">
			<Button
				v-tooltip.top="'Create new account'"
				icon="pi pi-plus"
				label="New account"
				@click="create"
			/>
			<Button
				v-tooltip.top="'Refresh'"
				icon="pi pi-refresh"
				severity="secondary"
				@click="refresh()"
			/>
		</div>
	</div>

	<DataTable
		v-model:filters="filtersWrapper"
		dataKey="id"
		filterDisplay="menu"
		:globalFilterFields="['name', 'code', 'type', 'subtype', 'description']"
		groupRowsBy="type"
		:pt="{
			rowgroupheadercell: { colspan: '99' }
		}"
		:rowHover="!loading"
		rowGroupMode="subheader"
		scrollable
		scrollHeight="flex"
		size="small"
		:value="loading ? skeletonArray : accountsTree"
	>
		<template #groupheader="{ data }">
			<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-font-bold">
				{{ data.type }}
			</div>
		</template>

		<Column field="name" header="Account name">
			<template #body="{ data }">
				<Skeleton v-if="loading" height="2rem" />
				<template v-else>
					<div
						class="tw3-flex tw3-flex-row tw3-items-center tw3-gap-1"
						:style="{
							'padding-left': `${0.875 * (data.depth + 1)}rem`
						}"
					>
						<template v-if="data.parentTemplateId">
							<i class="material-icons md-arrow_right tw3-opacity-40" />
							<div>{{ data.name }}</div>
						</template>
						<template v-else>
							<div>
								{{ data.name }}
							</div>
						</template>
						<Tag
							v-if="data.system"
							:pt="{
								label: { class: 'tw3-font-normal' },
								root: { class: 'tw3-ml-3' }
							}"
							severity="info"
							value="System"
						></Tag>
					</div>
				</template>
			</template>
		</Column>

		<Column field="code" header="Account number">
			<template #body="{ data }">
				<Skeleton v-if="loading" height="2rem" />
				<template v-else>{{ data.code }}</template>
			</template>
		</Column>

		<Column field="subtype" header="Subtype">
			<template #body="{ data }">
				<Skeleton v-if="loading" height="2rem" />
				<template v-else>{{ data.subtype }}</template>
			</template>
		</Column>

		<Column field="description" header="Description">
			<template #body="{ data }">
				<Skeleton v-if="loading" height="2rem" />
				<template v-else>{{ data.description }}</template>
			</template>
		</Column>

		<Column :pt="{ bodyCell: { class: '!tw3-py-0' } }">
			<template #body="{ data }">
				<Skeleton v-if="loading" height="2rem" />
				<div
					v-else
					class="action-buttons tw3-flex tw3-flex-row tw3-items-center tw3-justify-end tw3-gap-3"
				>
					<Button
						v-tooltip.top="{ value: 'Edit account', showDelay: 400 }"
						aria-label="Edit"
						rounded
						severity="secondary"
						text
						@click="edit(data.id)"
					>
						<template #icon>
							<i class="material-icons md-edit" />
						</template>
					</Button>
					<Button
						v-tooltip.top="{ value: 'Delete account', showDelay: 400 }"
						aria-label="Delete"
						rounded
						severity="secondary"
						text
						@click="remove(data.id)"
					>
						<template #icon>
							<i class="material-icons md-delete" />
						</template>
					</Button>
				</div>
			</template>
		</Column>

		<template v-if="!loading" #empty>
			<div
				class="tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20"
			>
				<p class="tw3-text-center tw3-text-3xl">Accounts not found</p>
			</div>
		</template>
	</DataTable>
	<TemplateEdit
		v-model:account="selectedTemplate"
		v-model:visible="showEditTemplate"
	/>
</template>
