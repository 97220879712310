import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-w-[2.25rem] tw3-mt-[0.375rem]" }
const _hoisted_2 = { class: "tw3-flex" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-items-center tw3-justify-center" }

import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import ToggleSwitch from 'primevue/toggleswitch';
import { useFieldArray, useForm } from 'vee-validate';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { Address } from '@/entities/address/lib/types';
import { useCustomerEditStore } from '@/entities/customer/lib/store';
import InputAddress from '@/features/address/InputAddress.vue';

const ZOD_ADDRESS = 'address';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactInfoAddresses',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { t } = useI18n();

const store = useCustomerEditStore();

const validationSchema = toTypedSchema(
	zod.object({
		[ZOD_ADDRESS]: zod.array(
			zod.object({
				address: zod.string(),
				city: zod.string().nullish(),
				extra: zod.string().nullish(),
				full_address: zod.string({ message: t('required') }),
				google_place_id: zod.string(),
				lat: zod.number(),
				lng: zod.number(),
				po_box: zod.number().nullish(),
				state: zod.string().nullish(),
				unit: zod.string().nullish(),
				zip: zod.string().nullish()
			})
		)
	})
);

const { errors, setValues } = useForm({
	initialValues: { [ZOD_ADDRESS]: props.modelValue },
	validationSchema
});

const { fields } = useFieldArray<Omit<Address, 'id'>>(ZOD_ADDRESS);

const add = () => {
	const val = {
		address: null,
		city: null,
		extra: null,
		full_address: null,
		google_place_id: null,
		lat: 0,
		lng: 0,
		po_box: null,
		state: null,
		unit: null,
		zip: null
	};
	emit('update:modelValue', [...props.modelValue, val as any]);
	if (props.modelValue) return;
	store.primaryAddressIndex = 0;
};

const updateVal = (index: number, val: Partial<Address>) => {
	emit(
		'update:modelValue',
		props.modelValue.map((v, vIndex) => {
			if (vIndex === index) return val as Address;
			return v;
		})
	);
};

const removeVal = (index: number) => {
	emit(
		'update:modelValue',
		props.modelValue.filter((_, i) => i !== index)
	);
	if (store.primaryAddressIndex < index) return;
	if (store.primaryAddressIndex === index) {
		store.primaryAddressIndex = 0;
	} else if (store.primaryAddressIndex > index) {
		store.primaryAddressIndex -= 1;
	}
};

watch(
	() => props.modelValue,
	() => {
		setValues({ [ZOD_ADDRESS]: props.modelValue });
	}
);

watch(
	() => errors.value,
	() => store.updateErrors('addresses', errors.value)
);

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_unref(DataTable), {
    class: "ui-data-table",
    size: "small",
    stripedRows: "",
    tableStyle: "tw3-w-full",
    value: _unref(fields)
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Column), {
        field: "address",
        header: "Address",
        pt: {
				headerCell: {
					style: {
						width: '66.6%'
					}
				}
			}
      }, {
        body: _withCtx(({ index }) => [
          _createVNode(InputAddress, {
            class: "tw3-w-full",
            errMsg: _unref(errors)[`address[${index}].full_address` as any],
            modelValue: _unref(fields)[index].value,
            withExtra: "",
            withPoBox: "",
            "onUpdate:modelValue": v => updateVal(index, v)
          }, null, 8, ["errMsg", "modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), {
        field: "primary",
        header: "Primary",
        style: {"display":"flex"}
      }, {
        body: _withCtx(({ index }) => [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createVNode(_unref(ToggleSwitch), {
              modelValue: _unref(store).primaryAddressIndex === index,
              name: "dynamic",
              style: _normalizeStyle(_unref(fields).length === 1 && 'opacity: 0.6'),
              "onUpdate:modelValue": 
							val =>
								val &&
								index !== _unref(store).primaryAddressIndex &&
								(_unref(store).primaryAddressIndex = index)
						
            }, null, 8, ["modelValue", "style", "onUpdate:modelValue"]), [
              [
                _directive_tooltip,
                'Set to primary',
                void 0,
                { top: true }
              ]
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(Column), {
        field: "actions",
        header: "",
        style: {"width":"5%"}
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(Button), {
              "aria-label": "Settings",
              icon: "pi pi-plus",
              rounded: "",
              size: "small",
              text: "",
              type: "button",
              onClick: add
            })
          ])
        ]),
        body: _withCtx(({ index }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(Button), {
              "aria-label": "Delete",
              class: "tw3-w-8 tw3-h-8",
              icon: "pi pi-trash",
              rounded: "",
              severity: "danger",
              text: "",
              onClick: ($event: any) => (removeVal(index))
            }, null, 8, ["onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value"]))
}
}

})