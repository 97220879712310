import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "tw3-font-medium" }
const _hoisted_2 = {
  key: 0,
  class: "tw3-flex tw3-items-center tw3-gap-2"
}
const _hoisted_3 = { class: "tw3-text-lg tw3-font-medium" }
const _hoisted_4 = { class: "tw3-w-8 tw3-h-8 tw3-rounded-full tw3-overflow-hidden tw3-flex tw3-items-center tw3-justify-center tw3-bg-gray-200" }
const _hoisted_5 = {
  key: 1,
  class: "tw3-text-base tw3-text-gray-600"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "tw3-w-full tw3-h-[600px] tw3-flex tw3-justify-center tw3-items-center" }
const _hoisted_8 = { class: "tw3-text-center tw3-text-3xl" }

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Image from 'primevue/image';
import InputText from 'primevue/inputtext';
import { useI18n } from 'vue-i18n';

import { isEmptyValue } from '@/shared/helpers';
import {
	formatNumber,
	formatPrice,
	formatToOneDigit
} from '@/widgets/dashboard/lib';

import { techHeader } from '../../config/constants';
import { formatTableDate } from '../../lib';
import { useTechnicianStore } from '../../model/store';

import type {
	DataTablePageEvent,
	DataTableSortEvent
} from 'primevue/datatable';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnicianTable',
  setup(__props) {

const { t } = useI18n();

const store = useTechnicianStore();

const handlePage = (state: DataTablePageEvent) => {
	store.pagination.page = state.page + 1;
};
const handleSort = (state: DataTableSortEvent) => {
	store.pagination.page = 1;

	store.handleSort({
		desc: state.sortOrder === -1,
		field: state.sortField as string
	});
};
const openTech = (id: number) => {
	const newEvent = new CustomEvent('openVendor', { detail: { id } });
	window.dispatchEvent(newEvent);
};

const getFilterIconClass = (field: string) => {
	const filter = store.filters[field];

	return isEmptyValue(filter.value) || filter.value === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DataTable), {
    filters: _unref(store).filters,
    "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_unref(store).filters) = $event)),
    dataKey: "id",
    filterDisplay: "menu",
    lazy: "",
    loading: _unref(store).techs.isLoading,
    paginator: "",
    rows: _unref(store).pagination.pageSize,
    rowsPerPageOptions: [10, 20, 50, 100],
    scrollable: "",
    sortField: _unref(store).sort.field,
    sortOrder: _unref(store).sort.desc === false ? 1 : -1,
    tableClass: "tw3-max-w-full",
    totalRecords: _unref(store).techs.state.totalCount,
    value: _unref(store).techs.state.items,
    onPage: handlePage,
    onSort: handleSort,
    "onUpdate:rows": _unref(store).handleRows
  }, _createSlots({
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('technicians')), 1)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(techHeader), (column) => {
        return (_openBlock(), _createBlock(_unref(Column), {
          key: column.key,
          field: column.key,
          filterField: column.key,
          header: column.title,
          headerClass: "tw3-relative tw3-whitespace-nowrap",
          showFilterMatchModes: false,
          showFilterMenu: column.key === 'name',
          sortable: column.key !== 'avatar'
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", {
              class: _normalizeClass(["cell-container", {
						'tw3-justify-end':
							column.key === 'lifetimeRevenue' ||
							column.key === 'lifetimeJobs' ||
							column.key === 'warrantyJobs' ||
							column.key === 'totalCommissions' ||
							column.key === 'averageCommissionPerJob' ||
							column.key === 'costRatio' ||
							column.key === 'warrantyRate' ||
							column.key === 'jobCompletionPercentage',
						'tw3-justify-center':
							column.key === 'isDisabled' ||
							column.key === 'avatar' ||
							column.key === 'averageRating'
					}])
            }, [
              (column.key === 'averageRating')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _cache[1] || (_cache[1] = _createElementVNode("i", { class: "pi pi-star-fill tw3-text-green-500" }, null, -1)),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(formatToOneDigit)(data[column.key])), 1)
                  ]))
                : (column.key === 'avatar')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(["tw3-w-10 tw3-h-10 tw3-rounded-full tw3-flex tw3-items-center tw3-justify-center", { 'tw3-bg-gray-100': !data.color }]),
                      style: _normalizeStyle({ background: data.color })
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        (data.avatar)
                          ? (_openBlock(), _createBlock(_unref(Image), {
                              key: 0,
                              alt: "Image",
                              src: data.avatar
                            }, null, 8, ["src"]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(!data.name ? '' : data.name.slice(0, 2).toUpperCase()), 1))
                      ])
                    ], 6))
                  : (column.key === 'isDisabled')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass(["tw3-rounded tw3-text-white tw3-text-sm tw3-leading-3 tw3-capitalize tw3-shadow-md tw3-p-2", data[column.key] ? 'tw3-bg-gray-500' : 'tw3-bg-green-500'])
                      }, _toDisplayString(data[column.key] ? _ctx.$t('inactive') : _ctx.$t('active')), 3))
                    : (column.key === 'name')
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 3,
                          href: "#",
                          onClick: ($event: any) => (openTech(data.id))
                        }, _toDisplayString(data[column.key]), 9, _hoisted_6))
                      : (
							column.key === 'warrantyRate' ||
							column.key === 'costRatio' ||
							column.key === 'jobCompletionPercentage'
						)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                            _createTextVNode(_toDisplayString(_unref(formatToOneDigit)(data[column.key] * 100).toFixed(1) + '%'), 1)
                          ], 64))
                        : (column.key === 'firstJobDate')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                              _createTextVNode(_toDisplayString(data[column.key] ? _unref(formatTableDate)(data[column.key]) : '-'), 1)
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                              _createTextVNode(_toDisplayString(column.key === 'lifetimeRevenue' ||
							column.key === 'totalCommissions' ||
							column.key === 'averageCommissionPerJob'
								? _unref(formatPrice)(_unref(formatNumber)(data[column.key]))
								: _unref(formatNumber)(data[column.key])), 1)
                            ], 64))
            ], 2)
          ]),
          filtericon: _withCtx(() => [
            _createElementVNode("i", {
              class: _normalizeClass(getFilterIconClass(column.key))
            }, null, 2)
          ]),
          filter: _withCtx(({ filterModel }) => [
            (column.key === 'name')
              ? (_openBlock(), _createBlock(_unref(InputText), {
                  key: 0,
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  allowEmpty: "",
                  class: "p-column-filter",
                  placeholder: `Search by ${column.title}`,
                  type: "text"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["field", "filterField", "header", "showFilterMenu", "sortable"]))
      }), 128))
    ]),
    _: 2
  }, [
    (!_unref(store).techs.isLoading)
      ? {
          name: "empty",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(t)('noData')), 1)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["filters", "loading", "rows", "sortField", "sortOrder", "totalRecords", "value", "onUpdate:rows"]))
}
}

})