import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Button from 'primevue/button';

import { downloadFile } from '../../helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadButton',
  props: {
	fileName: {
		default: '',
		type: String
	},
	link: {
		default: '#',
		type: String
	},
	tooltip: {
		default: 'Download',
		type: String
	}
},
  emits: ["downloading"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const downloadFileHandler = () => {
	emit('downloading');
	downloadFile(props.link, props.fileName);
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_unref(Button), {
    icon: "pi pi-download",
    severity: "contrast",
    size: "small",
    onClick: downloadFileHandler
  }, null, 512)), [
    [
      _directive_tooltip,
      __props.tooltip,
      void 0,
      { top: true }
    ]
  ])
}
}

})