import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import {
	createProfile as createProfileApi,
	listProfile
} from '@/entities/accounting/profiles/lib/api';
import {
	CreateProfileRequest,
	Profile
} from '@/entities/accounting/profiles/lib/types';
import { searchOrganizations as searchOrganizationsAPI } from '@/entities/admin/organizations/lib/api';
import { OrganizationShortInfo } from '@/entities/admin/organizations/lib/types';
import { useMessages } from '@/shared/composables';

const useProfileStore = defineStore('widgetsAccountingProfiles', () => {
	const { showError, showInfo } = useMessages();

	const loadingCounter = ref(0);
	const loading = computed(() => {
		return loadingCounter.value > 0;
	});

	const profilesState = useAsyncState(
		() => {
			loadingCounter.value++;
			return listProfile(true)
				.catch(error => {
					showError(error);
					return [] as Profile[];
				})
				.finally(() => {
					loadingCounter.value--;
				});
		},
		[] as Profile[],
		{
			immediate: true,
			resetOnExecute: false
		}
	);

	const loadProfiles = async () => {
		await profilesState.execute(0);
	};

	const createProfile = async (params: CreateProfileRequest) => {
		loadingCounter.value++;
		try {
			await createProfileApi(params);
			showInfo('Profile created');
			loadProfiles();
		} catch (error) {
			showError(error);
		} finally {
			loadingCounter.value--;
		}
	};

	const organizationsState = useAsyncState(
		(term: string) => {
			return searchOrganizationsAPI(term).catch(error => {
				showError(error);
				return [] as OrganizationShortInfo[];
			});
		},
		[] as OrganizationShortInfo[],
		{
			immediate: true,
			resetOnExecute: false
		}
	);

	const searchOrganizations = async (term: string) => {
		await organizationsState.execute(0, term);
	};

	const organizations = computed(() => {
		return organizationsState.state.value.filter(organization => {
			return !profilesState.state.value.some(
				profile => profile.organizationId === organization.id
			);
		});
	});

	return {
		loading,
		profiles: profilesState.state,
		loadProfiles,
		createProfile,
		organizations,
		searchOrganizations
	};
});

export default useProfileStore;
