import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-justify-between tw3-gap-3 tw3-w-full tw3-items-end tw3-pb-5" }
const _hoisted_2 = { class: "tw3-flex tw3-items-end tw3-gap-3" }
const _hoisted_3 = { class: "tw3-flex tw3-flex-col tw3-gap-1 tw3-min-w-96" }
const _hoisted_4 = { class: "tw3-leading-tight" }
const _hoisted_5 = { class: "tw3-flex tw3-flex-col tw3-gap-1 tw3-min-w-96" }
const _hoisted_6 = { class: "tw3-leading-tight" }
const _hoisted_7 = { class: "tw3-col" }
const _hoisted_8 = ["src"]

import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Select from 'primevue/select';
import { useToast } from 'primevue/usetoast';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useFaxStore from '../model/store';

import type { FaxInbox, FaxOutbox } from '../model/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'AttachJobToFax',
  props: /*@__PURE__*/_mergeModels({
    data: {}
  }, {
    "show": { type: Boolean },
    "showModifiers": {},
  }),
  emits: ["update:show"],
  setup(__props: any) {

const { t } = useI18n();
const toast = useToast();
const selectedType = ref<string>('');
const jobSearchValue = ref<
	| string
	| {
			id: number;
			shop_id: number;
	  }
>('');
const showModal = _useModel<boolean>(__props, 'show');
const props = __props;

const faxStore = useFaxStore();
const documentTypes = [
	{
		code: 'swo',
		name: 'SWO'
	},
	{
		code: 'dis',
		name: 'DIS'
	},
	{
		code: 'pop',
		name: 'PoP'
	},
	{
		code: 'fiv',
		name: 'FIV'
	},
	{
		code: 'quo',
		name: 'QUO'
	},
	{
		code: 'oth',
		name: 'OTH'
	}
];

const jobList = computed(() => faxStore.faxJobsList.state);
const search = (params: { event: Event; query: string }) => {
	faxStore.faxJobsList.execute(0, params.query);
};

const attachIsLoading = ref<boolean>(false);
const attachFaxHandler = async () => {
	attachIsLoading.value = true;

	if (typeof jobSearchValue.value !== 'string' && props.data?.fileName) {
		const response = await faxStore.attachFaxHandler({
			created: -1,
			document_name: props.data?.fileName,
			document_type: selectedType.value,
			id: -1,
			jobId: jobSearchValue.value.id,
			modified: -1,
			shopId: jobSearchValue.value.shop_id,
			url: props.data?.url
		});

		if (response === 'Error') {
			toast.add({
				life: 3000,
				severity: 'error',
				summary: t('faxDialog.actions.attachFax.error')
			});
		} else {
			toast.add({
				life: 3000,
				severity: 'success',
				summary: t('faxDialog.actions.attachFax.success')
			});
		}
		showModal.value = false;
		attachIsLoading.value = false;
	}
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "FaxAttachJobDialog",
    visible: showModal.value,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-max-w-[1400px]",
    draggable: false,
    header: _unref(t)('faxDialog.attachFaxJob'),
    modal: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_unref(t)('job')), 1),
            _createVNode(_unref(AutoComplete), {
              modelValue: jobSearchValue.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((jobSearchValue).value = $event)),
              class: "tw3-w-full",
              inputStyle: {
							'padding-right': '40px'
						},
              inputClass: "tw3-w-full",
              loading: _unref(faxStore).faxJobsList.isLoading,
              optionLabel: "name",
              placeholder: _unref(t)('faxDialog.searchForJob'),
              suggestions: jobList.value,
              onComplete: search
            }, null, 8, ["modelValue", "loading", "placeholder", "suggestions"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_unref(t)('faxDialog.type')), 1),
            _createVNode(_unref(Select), {
              modelValue: selectedType.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedType).value = $event)),
              class: "w-full md:w-56",
              optionLabel: "name",
              options: documentTypes,
              optionValue: "code",
              placeholder: _unref(t)('faxDialog.selectType')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _createVNode(_unref(Button), {
            class: "tw3-w-full",
            disabled: selectedType.value === '' || typeof jobSearchValue.value === 'string',
            icon: "pi pi-plus-circle",
            label: _unref(t)('faxDialog.attachJob'),
            loading: attachIsLoading.value,
            size: "small",
            onClickOnce: _cache[2] || (_cache[2] = ($event: any) => (attachFaxHandler()))
          }, null, 8, ["disabled", "label", "loading"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, _toDisplayString(_unref(t)('faxDialog.uploadFax.fileName')), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.data?.fileName), 1)
          ]),
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, _toDisplayString(_unref(t)('faxDialog.uploadFax.dateTime')), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.data?.dateTime), 1)
          ])
        ])
      ]),
      _createElementVNode("iframe", {
        src: _ctx.data !== null ? _ctx.data.url : '',
        style: {"width":"100%","height":"100%","min-height":"550px"},
        type: "application/pdf"
      }, null, 8, _hoisted_8)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})