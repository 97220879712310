import { formatToOneDigit } from '@/widgets/dashboard/lib';
import { DashboardAvgWeightData } from '@/widgets/dashboard/model/types';

export const calculateAvgWeightWithoutLast = (
	arr: DashboardAvgWeightData[]
) => {
	if (!arr.length) return '0.0%';

	const totalWeight = arr.reduce((acc, v) => acc + v.weight, 0);
	const totalAmount = arr.reduce((acc, v) => acc + v.amount, 0);

	const totalWeightWithoutLast = totalWeight - arr[0].weight;
	const totalAmountWithoutLast = totalAmount - arr[0].amount;

	const res =
		totalWeightWithoutLast !== 0
			? (totalAmountWithoutLast / totalWeightWithoutLast) * 100
			: 0;

	return formatToOneDigit(res).toFixed(1) + '%';
};
