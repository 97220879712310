import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import { listTransaction } from '@/entities/accounting/transactions/lib/api';
import {
	ListTransactionRequest,
	TransactionShort
} from '@/entities/accounting/transactions/lib/types';
import useAccountingStore from '@/features/accounting/lib/store';
import { useMessages } from '@/shared/composables';
import { NumberRange, OrderBy, Period } from '@/shared/types';

const useTransactionsStore = defineStore(
	'widgetsAccountingTransactions',
	() => {
		const { showError } = useMessages();

		const loadingCounter = ref(0);
		const loading = computed(() => {
			return loadingCounter.value > 0;
		});

		const { selectedProfileId } = storeToRefs(useAccountingStore());

		const transactions = ref<TransactionShort[]>([]);

		const transactionsParams = ref<{
			transactionDt?: Period;
			orderBy: OrderBy;
			typeId?: number;
			shopId?: number;
			amount?: NumberRange;
		}>({
			orderBy: {
				field: 'transactionDt',
				desc: true
			}
		});

		const transactionsLoadLimit = ref(20);
		const transactionsCanLoadMore = ref(true);

		const loadTransactions = async (reset = false) => {
			if (!selectedProfileId.value) {
				return;
			}
			if (reset) {
				transactions.value = [];
			}
			loadingCounter.value++;
			try {
				const offset = transactions.value.length;
				const limit = transactionsLoadLimit.value;
				const params: ListTransactionRequest = {
					...transactionsParams.value,
					offset: offset,
					limit: limit,
					organizationId: selectedProfileId.value
				};
				const result = await listTransaction(params);
				transactions.value.splice(offset, limit, ...result);
				transactionsCanLoadMore.value = result.length >= limit;
			} catch (error) {
				showError(error);
			} finally {
				loadingCounter.value--;
			}
		};

		watch(transactionsParams, () => {
			transactions.value = [];
			loadTransactions(true);
		});

		watch(
			selectedProfileId,
			() => {
				loadTransactions(true);
			},
			{ immediate: false }
		);

		return {
			loading,
			transactions: transactions,
			transactionsParams,
			loadTransactions,
			transactionsCanLoadMore,

			selectedProfileId
		};
	}
);

export default useTransactionsStore;
