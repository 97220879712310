import { i18n } from '@/shared/i18n';

export const getChartTextByModeRevenue = (mode: number) => {
	let mainBlockTitle = '';
	let chartTitle = '';

	if (mode === 1) {
		mainBlockTitle = i18n.global.t('dashboard.titles.compareRevenue');
		chartTitle = i18n.global.t('dashboard.titles.revenueAndNumberOfInvoices');
	} else if (mode === 4) {
		mainBlockTitle = i18n.global.t('dashboard.titles.customerGrow');
		chartTitle = i18n.global.t('dashboard.titles.numberOfCustomers');
	} else if (mode === 3) {
		mainBlockTitle = i18n.global.t('dashboard.titles.invoiceGrow');
		chartTitle = i18n.global.t('dashboard.titles.numberOfInvoices');
	} else {
		mainBlockTitle = i18n.global.t('dashboard.titles.averageInvoice');
		chartTitle = i18n.global.t('dashboard.titles.singleInvoiceAmount');
	}

	return { mainBlockTitle, chartTitle };
};
