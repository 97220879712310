import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw3-grid tw3-grid-cols-2 tw3-gap-2" }
const _hoisted_2 = { class: "tw3-h-10 tw3-content-center tw3-font-bold" }
const _hoisted_3 = { class: "tw3-h-10 tw3-content-center" }
const _hoisted_4 = { class: "tw3-col-span-2" }
const _hoisted_5 = { class: "tw3-mb-0" }

import Fieldset from 'primevue/fieldset';
import Tag from 'primevue/tag';


export default /*@__PURE__*/_defineComponent({
  __name: 'CoverageResponse',
  props: {
    deductible: {},
    errorMessage: {},
    referenceNumber: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Fieldset), {
    legend: "Result",
    pt: { content: 'tw3-p-1' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "tw3-h-8 w3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end" }, " Reference Number ", -1)),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.referenceNumber), 1)
        ]),
        _createElementVNode("div", null, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end" }, " Deductable ", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(Tag), {
              severity: "success",
              value: _ctx.deductible !== 0 ? _ctx.deductible : 'Covered'
            }, null, 8, ["value"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end" }, " Details ", -1)),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})