import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Dialog from 'primevue/dialog';


import QuotesTable from '@/widgets/quoteTable/QuotesTable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'QuoteDialog',
  props: {
    "show": { type: Boolean },
    "showModifiers": {},
  },
  emits: ["update:show"],
  setup(__props) {

const showModal = _useModel<boolean>(__props, 'show');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    id: "vue3QuoteDialog",
    visible: showModal.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
    appendTo: "#vue3app",
    class: "tw3-w-full tw3-max-w-[1440px]",
    header: "Quotes",
    modal: ""
  }, {
    default: _withCtx(() => [
      _createVNode(QuotesTable)
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})