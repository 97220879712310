import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "tw3-w-64 tw3-p-5 tw3-pb-6 tw3-pt-12 tw3-flex-shrink-0 tw3-rounded-l-xl tw3-relative" }
const _hoisted_2 = { class: "tw3-flex tw3-items-center tw3-justify-center" }
const _hoisted_3 = { class: "tw3-w-32 tw3-h-32 tw3-rounded-full tw3-flex tw3-items-center tw3-justify-center tw3-bg-white" }
const _hoisted_4 = { class: "tw3-text-6xl tw3-text-gray-600" }
const _hoisted_5 = { class: "tw3-text-xl tw3-font-medium tw3-mt-5 tw3-text-slate-500 tw3-text-center tw3-break-normal tw3-truncate" }
const _hoisted_6 = { class: "tw3-space-y-6 tw3-text-base tw3-text-slate-500 tw3-font-medium" }
const _hoisted_7 = { class: "tw3-flex tw3-space-x-6 tw3-items-center" }
const _hoisted_8 = { class: "tw3-flex tw3-space-x-6 tw3-items-center" }
const _hoisted_9 = {
  key: 0,
  class: "tw3-px-2 t-py-0.5 tw3-rounded tw3-text-white tw3-text-xs tw3-mr-3 tw3-cursor-pointer tw3-shadow-md tw3-bg-indigo-500"
}
const _hoisted_10 = { class: "tw3-flex tw3-space-x-6 tw3-items-center" }
const _hoisted_11 = { class: "tw3-flex tw3-space-x-6 tw3-items-center" }
const _hoisted_12 = ["href"]
const _hoisted_13 = {
  key: 1,
  class: "tw3-text-gray-300"
}
const _hoisted_14 = { class: "tw3-flex tw3-space-x-6 tw3-items-center" }
const _hoisted_15 = ["href"]
const _hoisted_16 = {
  key: 1,
  class: "tw3-text-gray-300"
}
const _hoisted_17 = { class: "tw3-flex tw3-space-x-6 tw3-items-center" }
const _hoisted_18 = {
  key: 1,
  class: "tw3-text-gray-300"
}
const _hoisted_19 = { class: "tw3-flex tw3-space-x-6 tw3-items-center" }
const _hoisted_20 = ["href"]
const _hoisted_21 = {
  key: 1,
  class: "tw3-text-gray-300"
}
const _hoisted_22 = { class: "tw3-flex tw3-space-x-6 tw3-items-center" }
const _hoisted_23 = {
  key: 0,
  class: "tw3-break-normal"
}
const _hoisted_24 = {
  key: 1,
  class: "tw3-text-gray-300"
}
const _hoisted_25 = { class: "tw3-flex tw3-space-x-6 tw3-items-center" }
const _hoisted_26 = { class: "tw3-flex tw3-space-x-6 tw3-items-center" }

import { computed, ref } from 'vue';

import {
	getAvatarBg,
	getConsumerName,
	getInitials
} from '@/entities/customer/lib/helpers';
import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { Customer } from '@/entities/customer/lib/types';
import AddressDialog from '@/widgets/addressDialog/AddressDialog.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerSidebar',
  setup(__props) {

const showAddress = ref(false);

const store = useCustomerEditStore();

const name = computed(() =>
	getConsumerName({
		company_name: store.customer.state.company_name,
		name: store.name
	})
);
const avatarBg = computed(() => getAvatarBg(name.value));
const initials = computed(() =>
	getInitials({ ...store.customer.state, name: store.name })
);

const toggleBadge = (field: keyof Customer, value: number | string) => {
	store.customer.state = { ...store.customer.state, [field]: value };
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(store).address && _unref(store).address.full_address)
      ? (_openBlock(), _createBlock(AddressDialog, {
          key: 0,
          show: showAddress.value,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showAddress).value = $event)),
          fullAddress: _unref(store).address.full_address,
          lat: _unref(store).address.lat,
          lng: _unref(store).address.lng
        }, null, 8, ["show", "fullAddress", "lat", "lng"]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "tw3-absolute tw3-top-4 tw3-right-4 tw3-cursor-pointer hover:tw3-bg-gray-200 tw3-p-4 tw3-rounded-full",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleBadge('flagged', _unref(store).customer.state.flagged ? 0 : 1)))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(_unref(store).customer.state.flagged ? 'pi pi-flag-fill' : 'pi pi-flag')
      }, null, 2)
    ])), [
      [
        _directive_tooltip,
        
				_unref(store).customer.state.flagged ? 'Remove Flagged' : 'Make Flagged'
			,
        void 0,
        { top: true }
      ]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["tw3-w-36 tw3-h-36 tw3-rounded-full tw3-flex tw3-items-center tw3-justify-center", avatarBg.value])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(initials.value), 1)
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(name.value ?? '-'), 1),
    _cache[16] || (_cache[16] = _createElementVNode("hr", { class: "tw3-my-6" }, null, -1)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _cache[7] || (_cache[7] = _createElementVNode("i", { class: "pi pi-id-card" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass({ 'tw3-text-gray-300': !_unref(store).customer.state.id })
        }, _toDisplayString(_unref(store).customer.state.id || 'ID'), 3)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("i", {
          class: _normalizeClass(
						!_unref(store).customer.state.is_commercial ||
						_unref(store).customer.state.is_insurance
							? 'pi pi-briefcase'
							: 'pi pi-building'
					)
        }, null, 2),
        _createElementVNode("div", null, [
          (_unref(store).customer.state.is_insurance)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Insurance "))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["tw3-px-2 t-py-0.5 tw3-rounded tw3-text-white tw3-text-xs tw3-mr-3 tw3-cursor-pointer tw3-shadow-md", 
							_unref(store).customer.state.is_commercial
								? 'tw3-bg-yellow-500'
								: 'tw3-bg-cyan-500'
						]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (
							toggleBadge(
								'is_commercial',
								_unref(store).customer.state.is_commercial ? 0 : 1
							)
						))
              }, _toDisplayString(_unref(store).customer.state.is_commercial ? 'Comm' : 'Retail'), 3))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("i", {
          class: _normalizeClass(
						!_unref(store).customer.state.status ||
						_unref(store).customer.state.status === 'active'
							? 'pi pi-check-circle'
							: 'pi pi-times-circle'
					)
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(["tw3-px-2 tw3-py-0.5 tw3-rounded tw3-text-white tw3-text-xs tw3-mr-3 tw3-cursor-pointer tw3-shadow-md", 
						!_unref(store).customer.state.status ||
						_unref(store).customer.state.status === 'active'
							? 'tw3-bg-green-500'
							: 'tw3-bg-gray-500'
					]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (
						toggleBadge(
							'status',
							!_unref(store).customer.state.status ||
								_unref(store).customer.state.status === 'active'
								? 'disabled'
								: 'active'
						)
					))
        }, _toDisplayString(!_unref(store).customer.state.status ||
						_unref(store).customer.state.status === 'active'
							? 'Active'
							: 'Inactive'), 3)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[8] || (_cache[8] = _createElementVNode("i", { class: "pi pi-phone" }, null, -1)),
        (_unref(store).phone && _unref(store).phone.number)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: 'tel:' + _unref(store).phone.number
            }, _toDisplayString(_unref(store).phone.number), 9, _hoisted_12))
          : (_openBlock(), _createElementBlock("div", _hoisted_13, "Telephone"))
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[9] || (_cache[9] = _createElementVNode("i", { class: "pi pi-envelope" }, null, -1)),
        (_unref(store).email && _unref(store).email.email)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: 'mailto:' + _unref(store).email.email
            }, _toDisplayString(_unref(store).email.email), 9, _hoisted_15))
          : (_openBlock(), _createElementBlock("div", _hoisted_16, "Email"))
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[10] || (_cache[10] = _createElementVNode("i", { class: "pi pi-map-marker" }, null, -1)),
        (_unref(store).address && _unref(store).address.full_address)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "tw3-break-normal",
              href: "#",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (showAddress.value = true))
            }, _toDisplayString(_unref(store).address.full_address), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_18, "Address"))
      ]),
      _createElementVNode("div", _hoisted_19, [
        _cache[11] || (_cache[11] = _createElementVNode("i", { class: "pi pi-globe" }, null, -1)),
        (_unref(store).customer.state.website)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "tw3-break-normal",
              href: _unref(store).customer.state.website,
              target: "_blank"
            }, _toDisplayString(_unref(store).customer.state.website), 9, _hoisted_20))
          : (_openBlock(), _createElementBlock("div", _hoisted_21, "Website"))
      ]),
      _createElementVNode("div", _hoisted_22, [
        _cache[12] || (_cache[12] = _createElementVNode("i", { class: "pi pi-inbox" }, null, -1)),
        (_unref(store).customer.state.fax)
          ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_unref(store).customer.state.fax), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_24, "Fax"))
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("i", {
          class: _normalizeClass(
						_unref(store).customer.state.tax_exempt ? 'pi pi-star-fill' : 'pi pi-star'
					)
        }, null, 2),
        _withDirectives((_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["tw3-px-2 tw3-py-0.5 tw3-rounded tw3-text-xs tw3-mr-3 tw3-cursor-pointer tw3-shadow-md tw3-border", 
						_unref(store).customer.state.tax_exempt
							? 'tw3-border-transparent tw3-bg-gray-400 tw3-text-white'
							: ' tw3-border-gray-400 tw3-text-gray-400'
					]),
          onClick: _cache[5] || (_cache[5] = ($event: any) => (
						toggleBadge('tax_exempt', _unref(store).customer.state.tax_exempt ? 0 : 1)
					))
        }, _cache[13] || (_cache[13] = [
          _createTextVNode(" Exempt ")
        ]), 2)), [
          [
            _directive_tooltip,
            'Sales tax exempt',
            void 0,
            { top: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _cache[15] || (_cache[15] = _createElementVNode("i", { class: "pi pi-inbox" }, null, -1)),
        _withDirectives((_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["tw3-px-2 tw3-py-0.5 tw3-rounded tw3-text-xs tw3-mr-3 tw3-cursor-pointer tw3-shadow-md tw3-border", 
						_unref(store).customer.state.po_required
							? 'tw3-border-transparent tw3-bg-sky-700 tw3-text-white'
							: ' tw3-border-sky-700 tw3-text-sky-700'
					]),
          onClick: _cache[6] || (_cache[6] = ($event: any) => (
						toggleBadge('po_required', _unref(store).customer.state.po_required ? 0 : 1)
					))
        }, _cache[14] || (_cache[14] = [
          _createTextVNode(" PO Required ")
        ]), 2)), [
          [
            _directive_tooltip,
            'PO number required to invoice customer',
            void 0,
            { top: true }
          ]
        ])
      ])
    ])
  ]))
}
}

})