import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tw3-h-10 tw3-w-10 tw3-flex tw3-absolute tw3--top-7 tw3-right-6 tw3-bg-surface-50 tw3-border-surface-200 tw3-rounded-md tw3-border" }
const _hoisted_2 = { class: "tw3-flex tw3-flex-col tw3-gap-2 tw3-w-full" }
const _hoisted_3 = { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_4 = { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_5 = { class: "tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm" }
const _hoisted_6 = { class: "tw3-flex tw3-flex-col tw3-gap-2" }
const _hoisted_7 = { class: "tw3-mx-auto" }
const _hoisted_8 = { class: "tw3-font-bold" }
const _hoisted_9 = { class: "tw3-flex tw3-h-6 tw3-text-sm tw3-content-end tw3-gap-3" }
const _hoisted_10 = { class: "tw3-text-slate-500 tw3-w-6 tw3-font-medium tw3-mb-1" }
const _hoisted_11 = { class: "tw3-flex tw3-ml-1" }
const _hoisted_12 = { class: "tw3-tracking-widest" }
const _hoisted_13 = {
  key: 0,
  class: "tw3-flex tw3-items-center tw3-h-6 tw3-text-sm tw3-gap-3"
}
const _hoisted_14 = { class: "tw3-text-slate-500 tw3-w-6 tw3-font-medium tw3-mb-1" }
const _hoisted_15 = { class: "tw3-flex tw3-ml-1" }
const _hoisted_16 = { class: "tw3-tracking-widest" }
const _hoisted_17 = {
  key: 1,
  class: "tw3-flex tw3-items-center tw3-h-6 tw3-text-sm tw3-gap-3"
}
const _hoisted_18 = { class: "tw3-text-slate-500 tw3-w-6 tw3-font-medium" }
const _hoisted_19 = { class: "tw3-flex tw3-ml-1" }
const _hoisted_20 = { class: "tw3-tracking-widest" }

import { useClipboard } from '@vueuse/core';
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import Image from 'primevue/image';
import InputNumber from 'primevue/inputnumber';
import Popover from 'primevue/popover';
import Select from 'primevue/select';
import Tag from 'primevue/tag';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import noImg from './lib/assets/noImg.svg';
import { vehicleOwnerships, vehicleTypes } from './lib/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'VehicleInfo',
  props: /*@__PURE__*/_mergeModels({
    status: { type: Boolean }
  }, {
    "vehicle": { required: true },
    "vehicleModifiers": {},
  }),
  emits: ["update:vehicle"],
  setup(__props: any) {

const { t } = useI18n();

const vehicle = _useModel<{
	id: number;
	imageUrl: string;
	make: string;
	makeId: string;
	model: string;
	nagsId: string;
	number: number;
	ownership: string;
	plateNumber: string;
	thumbUrl: string;
	type: string;
	vin: string;
	year: number;
	style: string;
}>(__props, 'vehicle');



const { copy } = useClipboard();

const popoverRef = ref();
const imgUrl = ref(
	`https://storage.googleapis.com/nags-part-images/${vehicle.value.nagsId}.gif`
);

const toggleAdditionalInfoSettings = (event: any) => {
	popoverRef.value.toggle(event);
};

const handleError = () => {
	imgUrl.value = noImg;
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(Fieldset), {
      class: "tw3-relative tw3-h-[100%]",
      legend: `${_unref(t)('vehicle')}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(Button), {
            "aria-label": "Settings",
            class: "tw3-border-surface-200",
            icon: "pi pi-cog",
            outlined: "",
            severity: "secondary",
            text: "",
            type: "button",
            onClick: toggleAdditionalInfoSettings
          }),
          _createVNode(_unref(Popover), {
            ref_key: "popoverRef",
            ref: popoverRef,
            class: "tw3-w-64"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('vehicleOwnership')), 1),
                  _createVNode(_unref(Select), {
                    modelValue: vehicle.value.ownership,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vehicle.value.ownership) = $event)),
                    class: "tw3-w-full",
                    disabled: _ctx.status,
                    optionLabel: "label",
                    options: _unref(vehicleOwnerships),
                    optionValue: "value"
                  }, null, 8, ["modelValue", "disabled", "options"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('vehicleType')), 1),
                  _createVNode(_unref(Select), {
                    modelValue: vehicle.value.type,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((vehicle.value.type) = $event)),
                    class: "tw3-w-full",
                    disabled: _ctx.status,
                    optionLabel: "label",
                    options: _unref(vehicleTypes),
                    optionValue: "value"
                  }, null, 8, ["modelValue", "disabled", "options"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('vehicleNumber')), 1),
                  _createVNode(_unref(InputNumber), {
                    modelValue: vehicle.value.number,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((vehicle.value.number) = $event)),
                    disabled: _ctx.status,
                    max: 9,
                    min: 1,
                    mode: "decimal",
                    pt: {
									input: {
										style: { widht: '100px' }
									}
								},
                    showButtons: ""
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ])
            ]),
            _: 1
          }, 512)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(Image), {
              alt: "Car Image",
              height: "96px",
              src: imgUrl.value,
              width: "160px",
              onError: handleError
            }, null, 8, ["src"])
          ]),
          _createElementVNode("div", _hoisted_8, _toDisplayString(vehicle.value.year) + " " + _toDisplayString(vehicle.value.make) + " " + _toDisplayString(vehicle.value.model), 1),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(t)('type')) + ": ", 1),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(vehicle.value.style), 1)
              ]),
              _withDirectives((_openBlock(), _createBlock(_unref(Tag), {
                class: "tw3-ml-auto tw3-uppercase",
                icon: "pi pi-check",
                severity: "info"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("nags")
                ])),
                _: 1
              })), [
                [
                  _directive_tooltip,
                  '#2204',
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            (vehicle.value.plateNumber)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(t)('plate')) + ": ", 1),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(vehicle.value.plateNumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_unref(Button), {
                      "aria-label": "Copy",
                      icon: "pi pi-copy",
                      rounded: "",
                      style: { height: '21px', width: '21px' },
                      text: "",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(copy)(vehicle.value.plateNumber)))
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            (vehicle.value.vin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _withDirectives((_openBlock(), _createElementBlock("span", null, [
                      _createTextVNode(_toDisplayString(_unref(t)('vin')) + ":", 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _unref(t)('vinTooltip'),
                        void 0,
                        { top: true }
                      ]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("span", _hoisted_20, _toDisplayString(vehicle.value.vin), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_unref(Button), {
                      "aria-label": "Copy",
                      icon: "pi pi-copy",
                      rounded: "",
                      style: { height: '21px', width: '21px' },
                      text: "",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(copy)(vehicle.value.vin)))
                    })
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["legend"])
  ]))
}
}

})