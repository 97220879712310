<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Divider from 'primevue/divider';
import Fieldset from 'primevue/fieldset';
import Skeleton from 'primevue/skeleton';
import { ref, watchEffect } from 'vue';

import { PaymentCategoryId } from '@/entities/accounting/settings/lib/types';
import AutoCompleteSelect from '@/shared/ui/input/AutoCompleteSelect.vue';
import useAccountTemplatesStore from '@/widgets/accounting/accountTemplates/lib/store';

import useSettingsTemplateStore from './lib/store';

const { templates: accounts } = storeToRefs(useAccountTemplatesStore());

const settingsStore = useSettingsTemplateStore();
const { paymentSettingsTemplate: paymentSettings } = storeToRefs(settingsStore);

const loading = ref(false);

const paymentCategories = [
	{ categoryId: PaymentCategoryId.CATEGORY_CASH, name: 'Cash' },
	{ categoryId: PaymentCategoryId.CATEGORY_CHECK, name: 'Check' },
	{
		categoryId: PaymentCategoryId.CATEGORY_BANK_TRANSFER,
		name: 'Bank Transfer'
	},
	{ categoryId: PaymentCategoryId.CATEGORY_MANUAL_CARD, name: 'Manual Card' },
	{ categoryId: PaymentCategoryId.CATEGORY_OTHER, name: 'Other' },
	{ categoryId: PaymentCategoryId.CATEGORY_CARD, name: 'Card' }
];

type PaymentCategoryAccount = {
	categoryId: PaymentCategoryId;
	name: string;
	accountId: number | undefined;
};

const paymentCategoryAccount = ref<PaymentCategoryAccount[]>(
	paymentCategories.map(paymentCategory => ({
		categoryId: paymentCategory.categoryId,
		name: paymentCategory.name,
		accountId: undefined
	}))
);

const unearnedRevenueId = ref<number | undefined>();
const accountReceivableId = ref<number | undefined>();
const unappliedCustomerPaymentsId = ref<number | undefined>();

// Update form values from store
watchEffect(() => {
	unearnedRevenueId.value = paymentSettings.value.unearnedRevenueId;
	accountReceivableId.value = paymentSettings.value.accountReceivableId;
	unappliedCustomerPaymentsId.value =
		paymentSettings.value.unappliedCustomerPaymentsId;
	paymentCategoryAccount.value = paymentCategories.map(paymentCategory => {
		const settingsCategory = paymentSettings.value.category.find(
			category => category.categoryId === paymentCategory.categoryId
		);
		return {
			categoryId: paymentCategory.categoryId,
			name: paymentCategory.name,
			accountId: settingsCategory?.accountId
		};
	});
});

const saveCategoryAccount = async (
	categoryId: PaymentCategoryId,
	accountId: number
) => {
	await settingsStore.savePaymentSettingsTemplate({
		category: [
			{
				categoryId: categoryId,
				accountId: accountId
			}
		]
	});
};

const saveUnearnedRevenueId = async (accountId: number) => {
	await settingsStore.savePaymentSettingsTemplate({
		unearnedRevenueId: accountId,
		category: []
	});
};

const saveAccountReceivableId = async (accountId: number) => {
	await settingsStore.savePaymentSettingsTemplate({
		accountReceivableId: accountId,
		category: []
	});
};

const saveUnappliedCustomerPaymentsId = async (accountId: number) => {
	await settingsStore.savePaymentSettingsTemplate({
		unappliedCustomerPaymentsId: accountId,
		category: []
	});
};
</script>

<template>
	<div class="tw3-grid tw3-grid-cols-2 tw3-gap-4">
		<Fieldset legend="Default Accounts" :pt="{ root: { class: '!tw3-mt-3' } }">
			<div class="tw3-grid tw3-grid-cols-3 tw3-gap-2 tw3-items-center tw3-p-2">
				<div class="tw3-text-right">Unearned revenue</div>
				<div class="tw3-col-span-2">
					<Skeleton v-if="loading" height="2.2rem" />
					<AutoCompleteSelect
						v-else
						v-model="unearnedRevenueId"
						fluid
						:options="accounts"
						optionValue="id"
						@update:model-value="saveUnearnedRevenueId"
					/>
				</div>
			</div>
			<div class="tw3-grid tw3-grid-cols-3 tw3-gap-2 tw3-items-center tw3-p-2">
				<div class="tw3-text-right">Unapplied Customer Payments</div>
				<div class="tw3-col-span-2">
					<Skeleton v-if="loading" height="2.2rem" />
					<AutoCompleteSelect
						v-else
						v-model="unappliedCustomerPaymentsId"
						fluid
						:options="accounts"
						optionValue="id"
						@update:model-value="saveUnappliedCustomerPaymentsId"
					/>
				</div>
			</div>
			<div class="tw3-grid tw3-grid-cols-3 tw3-gap-2 tw3-items-center tw3-p-2">
				<div class="tw3-text-right">Account Receivable</div>
				<div class="tw3-col-span-2">
					<Skeleton v-if="loading" height="2.2rem" />
					<AutoCompleteSelect
						v-else
						v-model="accountReceivableId"
						fluid
						:options="accounts"
						optionValue="id"
						@update:model-value="saveAccountReceivableId"
					/>
				</div>
			</div>
			<Divider />
			<div
				v-for="item in paymentCategoryAccount"
				:key="item.categoryId"
				class="tw3-grid tw3-grid-cols-3 tw3-gap-2 tw3-items-center tw3-p-2"
			>
				<div class="tw3-text-right">{{ item.name }}</div>
				<div class="tw3-col-span-2">
					<Skeleton v-if="loading" height="2.2rem" />
					<AutoCompleteSelect
						v-else
						v-model="item.accountId"
						fluid
						:options="accounts"
						optionValue="id"
						@update:model-value="saveCategoryAccount(item.categoryId, $event)"
					/>
				</div>
			</div>
		</Fieldset>
	</div>
</template>
