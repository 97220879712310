<script setup lang="ts">
import Dialog from 'primevue/dialog';
import OverlayBadge from 'primevue/overlaybadge';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import Tabs from 'primevue/tabs';
import { computed, defineModel } from 'vue';
import { useI18n } from 'vue-i18n';

import useFaxStore from '../model/store';
import { FaxTabPanels } from '../model/types';

import FaxTable from './FaxTable.vue';

const faxStore = useFaxStore();
const { t } = useI18n();
const showModal = defineModel<boolean>('show');

const FaxTabs = computed((): FaxTabPanels[] => {
	return [
		{
			count: faxStore.faxInbox.state.totalCount,
			key: 'inbox',
			title: t('faxDialog.inbox.title')
		},
		{
			count: faxStore.faxOutbox.state.totalCount,
			key: 'outbox',
			title: t('faxDialog.outbox.title')
		},
		{
			count: faxStore.burstCount.state,
			key: 'burst',
			title: t('faxDialog.status.burst')
		}
	];
});
</script>

<template>
	<Dialog
		id="vue3FaxDialog"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-w-full tw3-h-full tw3-max-w-[1400px]"
		:draggable="false"
		:header="t('faxDialog.fax')"
		modal
		:pt:content="'tw3-h-full tw3-flex tw3-flex-col'"
		:pt:root:class="'!tw3-border-0 tw3-overflow-hidden'"
	>
		<Tabs
			:pt="{ panelcontainer: { style: { padding: '0' } } }"
			:value="faxStore.currentTab"
			@update:value="tabName => faxStore.changeCurrentTab(tabName)"
		>
			<TabList>
				<Tab
					v-for="item in FaxTabs"
					:key="item.key"
					:pt:root:class="'!tw3-pt-[16px]'"
					:value="item.key"
				>
					<OverlayBadge
						v-if="item?.count && item?.count > 0"
						:pt:pcbadge:root="{
							style: {
								top: '-6px',
								right: '2px'
							},
							class: '!tw3-text-xs'
						}"
						size="small"
						:value="item.count"
					>
						{{ item.title }}
					</OverlayBadge>
					<span v-else>{{ item.title }}</span>
				</Tab>
			</TabList>
		</Tabs>
		<FaxTable />
	</Dialog>
</template>
