import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw3-text-center tw3-py-20"
}
const _hoisted_2 = {
  key: 1,
  class: "tw3-max-w-[1440px] tw3-m-auto tw3-grid tw3-grid-cols-12 tw3-gap-4 md:tw3-gap-8"
}
const _hoisted_3 = { class: "tw3-col-span-12 lg:tw3-col-span-8" }
const _hoisted_4 = { class: "card widget-visitor-graph !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-4" }
const _hoisted_5 = { class: "tw3-font-medium tw3-flex tw3-flex-col sm:tw3-flex-row sm:tw3-justify-between sm:tw3-items-center tw3-leading-loose" }
const _hoisted_6 = { class: "tw3-flex tw3-flex-col sm:tw3-flex-row tw3-items-center tw3-gap-2" }
const _hoisted_7 = { class: "graph-content tw3-grid tw3-grid-cols-12 tw3-gap-4 tw3-mt-6" }
const _hoisted_8 = {
  key: 0,
  class: "tw3-col-span-12 md:tw3-col-span-6"
}
const _hoisted_9 = { class: "tw3-text-3xl tw3-font-semibold" }
const _hoisted_10 = { class: "tw3-font-semibold tw3-my-4" }
const _hoisted_11 = { class: "tw3-text-surface-500" }
const _hoisted_12 = { class: "graph" }
const _hoisted_13 = { class: "tw3-text-xl tw3-font-semibold tw3-my-6" }
const _hoisted_14 = {
  key: 0,
  class: "tw3-h-[590px] tw3-w-full tw3-flex tw3-justify-center tw3-items-center"
}
const _hoisted_15 = { class: "tw3-self-end tw3-mt-2 tw3-text-sm tw3-text-gray-300" }
const _hoisted_16 = { class: "tw3-col-span-12 lg:tw3-col-span-4" }
const _hoisted_17 = { class: "card !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-6 tw3-flex tw3-flex-col tw3-items-center" }
const _hoisted_18 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-gap-2" }
const _hoisted_19 = { class: "tw3-font-medium tw3-text-lg" }
const _hoisted_20 = { class: "tw3-text-base tw3-text-gray-500" }
const _hoisted_21 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-gap-2 tw3-mt-4" }
const _hoisted_22 = { class: "tw3-font-medium tw3-text-lg" }
const _hoisted_23 = { class: "tw3-text-base tw3-text-gray-500" }
const _hoisted_24 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-gap-2 tw3-mt-4" }
const _hoisted_25 = { class: "tw3-font-medium tw3-text-lg" }
const _hoisted_26 = { class: "tw3-text-base tw3-text-gray-500" }
const _hoisted_27 = { class: "card !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-6 tw3-flex tw3-flex-col tw3-items-center tw3-mt-6" }
const _hoisted_28 = { class: "tw3-font-semibold tw3-text-xl tw3-mb-6" }
const _hoisted_29 = {
  key: 1,
  class: "tw3-w-60 tw3-h-60 tw3-flex tw3-justify-center tw3-items-center"
}
const _hoisted_30 = { class: "tw3-col-span-12 tw3-mb-[1.25rem]" }
const _hoisted_31 = { class: "card !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-4" }

import { storeToRefs } from 'pinia';
import Chart from 'primevue/chart';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import TreeSelect from 'primevue/treeselect';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUserStore } from '@/entities/user/lib/store';
import { timeRangeModes } from '@/widgets/dashboard/config/constants';
import {
	calculateTooltipTotals,
	formatNumber,
	formatPrice,
	getChartLifetimeData,
	getChartOptions,
	getLastUpdatedDate,
	getMainChartData,
	getTimeRangePrefix,
	getTimeRangeSuffix,
	toLabelsAndData
} from '@/widgets/dashboard/lib';
import { DashboardHeaderTooltip } from '@/widgets/dashboard/model/types';
import DashboardLifetime from '@/widgets/dashboard/ui/DashboardLifetime.vue';

import {
	calculateAvgWeightWithoutLast,
	getChartTextByModeTech,
	getPieBackgroundColors,
	getPieOptions
} from '../lib';
import { useTechnicianDashboardStore } from '../model/store';
import { FollowUpJobs } from '../model/types';

import TechnicianFollowUpInfo from './technician/TechnicianFollowUpInfo.vue';
import TechnicianTable from './technician/TechnicianTable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechDashboard',
  setup(__props) {

const { t } = useI18n();

const store = useTechnicianDashboardStore();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const shopOptions = computed(() => [
	{
		key: 'allShops',
		label: 'All shops'
	},
	...user.value.organizations.map(v => ({
		key: 'organizations_' + v.id,
		label: v.name,
		children: user.value.shops
			.filter(s => s.organization_id === v.id)
			.map(s => ({ key: 'shops_' + s.id, label: s.name }))
	}))
]);

const selectedShop = ref({ allShops: true });
const apiSelectedShop = computed(() => {
	let shopIds: number[] = [];
	Object.keys(selectedShop.value).forEach(v => {
		if (v === 'allShops' || v === 'organizations' || v === 'shops') return;
		else {
			const data = v.split('_');
			if (data[0] === 'shops') {
				shopIds.push(Number(data[1]));
			}
			if (data[0] === 'organizations') {
				shopIds = user.value.shops
					.filter(v => v.organization_id === Number(data[1]))
					.map(v => v.id);
			}
		}
	});
	return shopIds.length ? shopIds : undefined;
});

const mainBlockTitle = ref(t('tech.titles.commissionGrow'));
const chartTitle = ref(t('commissionAmount'));
const chartHeaderTooltips = ref<DashboardHeaderTooltip[]>([]);

const selectedMainChart = ref(1);
const dataActualDate = ref<string>();
const mainChart = ref();
const chartOptions = ref();
const numberOfCompletedChart = ref();
const rateOfWarrantyChart = ref();
const amountOfCommissionChart = ref();
const averageCostRatioChart = ref();

const pieFollowUpJob = ref();
const pieFollowUpJobOptions = ref();

const timeMode = ref(timeRangeModes[2]);

const mainChartDescSuffix = computed(() =>
	getTimeRangeSuffix(timeMode.value.code)
);

const onUpdate = async () => {
	await nextTick();
	await getAndSetMainChartData();
};

const updateChartMode = async (v: number) => {
	selectedMainChart.value = v;
	selectedShop.value = { allShops: true };
	timeMode.value = timeRangeModes[2];
	await onUpdate();
};

const getAndSetMainChartData = async () => {
	const params = {
		time_range: timeMode.value.code,
		shop_ids: apiSelectedShop.value
	};
	const headPrefix = getTimeRangePrefix(timeMode.value.code);
	let currentLabels;

	if (selectedMainChart.value === 1) {
		await store.filteredCommission.execute(0, params);

		const totalWithoutLast = calculateTooltipTotals(
			store.filteredCommission.state
		);

		chartHeaderTooltips.value = [
			{
				data: formatPrice(formatNumber(totalWithoutLast)),
				title: headPrefix + ' ' + t('commissionAmount'),
				desc: t('tech.titles.amountOfCommission')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredCommission.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(
			data,
			labels,
			t('commission'),
			'#86efac'
		);
	} else if (selectedMainChart.value === 2) {
		await store.filteredCostRatio.execute(0, params);

		const tooltipData = calculateAvgWeightWithoutLast(
			store.filteredCostRatio.state
		);

		chartHeaderTooltips.value = [
			{
				data: tooltipData,
				title: t('averageCostRatio'),
				desc: t('averageCostRatio')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredCostRatio.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(data, labels, t('costRatio'), '#34d399');
	} else if (selectedMainChart.value === 3) {
		await store.filteredWarranty.execute(0, params);

		const tooltipData = calculateAvgWeightWithoutLast(
			store.filteredWarranty.state
		);

		chartHeaderTooltips.value = [
			{
				data: tooltipData,
				title: headPrefix + ' ' + t('averageWarrantyRate'),
				desc: t('averageWarrantyRate')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredWarranty.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(
			data,
			labels,
			t('warrantyRate'),
			'#5eead4'
		);
	} else {
		await store.filteredCompleted.execute(0, params);

		const totalWithoutLast = calculateTooltipTotals(
			store.filteredCompleted.state
		);

		chartHeaderTooltips.value = [
			{
				data: formatNumber(totalWithoutLast),
				title: headPrefix + ' ' + t('completedJobsCount'),
				desc: t('tech.titles.totalNumberOfCJobs')
			}
		];
		const { labels, data } = toLabelsAndData(
			store.filteredCompleted.state,
			timeMode.value.code
		);
		currentLabels = labels;
		mainChart.value = getMainChartData(
			data,
			labels,
			t('completedJobs'),
			'#22d3ee'
		);
	}

	const { chartTitle: chart, mainBlockTitle: main } = getChartTextByModeTech(
		selectedMainChart.value
	);
	mainBlockTitle.value = main;
	chartTitle.value = chart;

	chartOptions.value = getChartOptions(
		true,
		timeMode.value.code,
		currentLabels,
		selectedMainChart.value === 3 || selectedMainChart.value === 2
	);
};

onMounted(async () => {
	const promises: Promise<any>[] = [
		getAndSetMainChartData(),
		store.followUpJobs.execute(0),
		store.purchaseMethodJobs.execute(0)
	];

	if (store.totalAndCommissionAmount.state.data.length === 0) {
		promises.push(store.totalAndCommissionAmount.execute(0));
	}
	if (store.averageAndCostRatio.state.data.length === 0) {
		promises.push(store.averageAndCostRatio.execute(0));
	}
	if (store.averageAndWarrantyRate.state.data.length === 0) {
		promises.push(store.averageAndWarrantyRate.execute(0));
	}
	if (store.totalAndCompletedNumber.state.data.length === 0) {
		promises.push(store.totalAndCompletedNumber.execute(0));
	}

	await Promise.all(promises);

	dataActualDate.value = getLastUpdatedDate();

	amountOfCommissionChart.value = getChartLifetimeData(
		store.totalAndCommissionAmount.state.data,
		'134, 239, 172'
	);
	averageCostRatioChart.value = getChartLifetimeData(
		store.averageAndCostRatio.state.data,
		'52, 211, 153'
	);
	rateOfWarrantyChart.value = getChartLifetimeData(
		store.averageAndWarrantyRate.state.data,
		'94, 234, 212'
	);
	numberOfCompletedChart.value = getChartLifetimeData(
		store.totalAndCompletedNumber.state.data,
		'34, 211, 238'
	);

	pieFollowUpJobOptions.value = getPieOptions();
	const { backgroundColor, hoverBackgroundColor } = getPieBackgroundColors();
	const { callBack, dropOff, pickUp, rAndI, recal, tint } =
		store.followUpJobs.state;
	pieFollowUpJob.value = {
		labels: [
			t('callBack'),
			t('dropOff'),
			t('pickUp'),
			t('rAndI'),
			t('recal'),
			t('tint')
		],
		datasets: [
			{
				data: [callBack, dropOff, pickUp, rAndI, recal, tint],
				backgroundColor,
				hoverBackgroundColor
			}
		]
	};
});

return (_ctx: any,_cache: any) => {
  return (
			_unref(store).totalAndCommissionAmount.isLoading ||
			_unref(store).averageAndCostRatio.isLoading ||
			_unref(store).averageAndWarrantyRate.isLoading ||
			_unref(store).totalAndCompletedNumber.isLoading
		)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(ProgressSpinner))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(DashboardLifetime, {
          cardStyle: 
				selectedMainChart.value === 1
					? {
							boxShadow: '0px 6px 20px rgba(134, 239, 172, 0.5) !important'
						}
					: undefined
			,
          chart: amountOfCommissionChart.value,
          differenceNumber: _unref(store).totalAndCommissionAmount.state.difference,
          isRevenue: "",
          title: _ctx.$t('lifetimeCommission'),
          value: _unref(store).totalAndCommissionAmount.state.count,
          valueStyle: {
				backgroundColor: '#86efac',
				boxShadow: '0px 6px 20px rgba(134, 239, 172, 0.3)'
			},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (updateChartMode(1)))
        }, null, 8, ["cardStyle", "chart", "differenceNumber", "title", "value"]),
        _createVNode(DashboardLifetime, {
          cardStyle: 
				selectedMainChart.value === 4
					? {
							boxShadow: '0px 6px 20px rgba(34, 211, 238, 0.5) !important'
						}
					: undefined
			,
          chart: numberOfCompletedChart.value,
          differenceNumber: _unref(store).totalAndCompletedNumber.state.difference,
          title: _ctx.$t('lifetimeCompletedJobs'),
          value: _unref(store).totalAndCompletedNumber.state.count,
          valueStyle: {
				backgroundColor: '#22d3ee',
				boxShadow: '0px 6px 20px rgba(34, 211, 238, 0.3)'
			},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (updateChartMode(4)))
        }, null, 8, ["cardStyle", "chart", "differenceNumber", "title", "value"]),
        _createVNode(DashboardLifetime, {
          cardStyle: 
				selectedMainChart.value === 2
					? {
							boxShadow: '0px 6px 20px rgba(52, 211, 153, 0.5) !important'
						}
					: undefined
			,
          chart: averageCostRatioChart.value,
          differenceNumber: _unref(store).averageAndCostRatio.state.difference,
          isPercent: "",
          showTooltip: {
				tooltipHeaderI18: 'tech.costRatioLifetime',
				tooltipDescI18: 'costRatioExplaining'
			},
          title: _ctx.$t('lifetimeCostRatio'),
          value: _unref(store).averageAndCostRatio.state.count,
          valueStyle: {
				backgroundColor: '#34d399',
				boxShadow: '0px 6px 20px rgba(52, 211, 153, 0.3)'
			},
          onClick: _cache[2] || (_cache[2] = ($event: any) => (updateChartMode(2)))
        }, null, 8, ["cardStyle", "chart", "differenceNumber", "title", "value"]),
        _createVNode(DashboardLifetime, {
          cardStyle: 
				selectedMainChart.value === 3
					? {
							boxShadow: '0px 6px 20px rgba(94, 234, 212, 0.5) !important'
						}
					: undefined
			,
          chart: rateOfWarrantyChart.value,
          differenceNumber: _unref(store).averageAndWarrantyRate.state.difference,
          isPercent: "",
          last90: "",
          title: _ctx.$t('lifetimeWarrantyRate'),
          value: _unref(store).averageAndWarrantyRate.state.count,
          valueStyle: {
				backgroundColor: '#5eead4',
				boxShadow: '0px 6px 20px rgba(94, 234, 212, 0.3)'
			},
          onClick: _cache[3] || (_cache[3] = ($event: any) => (updateChartMode(3)))
        }, null, 8, ["cardStyle", "chart", "differenceNumber", "title", "value"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(mainBlockTitle.value), 1),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(TreeSelect), {
                  modelValue: selectedShop.value,
                  "onUpdate:modelValue": [
                    _cache[4] || (_cache[4] = ($event: any) => ((selectedShop).value = $event)),
                    onUpdate
                  ],
                  class: "tw3-w-full sm:tw3-w-auto",
                  loading: 
								_unref(store).filteredCommission.isLoading ||
								_unref(store).filteredWarranty.isLoading ||
								_unref(store).filteredCompleted.isLoading ||
								_unref(store).filteredCostRatio.isLoading
							,
                  options: shopOptions.value,
                  placeholder: "Select an Item"
                }, null, 8, ["modelValue", "loading", "options"]),
                _createVNode(_unref(Select), {
                  modelValue: timeMode.value,
                  "onUpdate:modelValue": [
                    _cache[5] || (_cache[5] = ($event: any) => ((timeMode).value = $event)),
                    onUpdate
                  ],
                  class: "tw3-w-full sm:tw3-w-auto",
                  loading: 
								_unref(store).filteredCommission.isLoading ||
								_unref(store).filteredWarranty.isLoading ||
								_unref(store).filteredCompleted.isLoading ||
								_unref(store).filteredCostRatio.isLoading
							,
                  optionLabel: "name",
                  options: _unref(timeRangeModes)
                }, null, 8, ["modelValue", "loading", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (
							!mainChart.value ||
							_unref(store).filteredCommission.isLoading ||
							_unref(store).filteredWarranty.isLoading ||
							_unref(store).filteredCompleted.isLoading ||
							_unref(store).filteredCostRatio.isLoading
						)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_unref(Skeleton), {
                      height: "31px",
                      width: "5.5rem"
                    }),
                    _createVNode(_unref(Skeleton), {
                      class: "tw3-my-4",
                      height: "18px",
                      width: "7rem"
                    }),
                    _createVNode(_unref(Skeleton), {
                      height: "18px",
                      width: "12rem"
                    })
                  ]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(chartHeaderTooltips.value, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "tw3-col-span-12 md:tw3-col-span-6"
                    }, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(item.data), 1),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(item.title), 1),
                      _createElementVNode("p", _hoisted_11, _toDisplayString(item.desc + mainChartDescSuffix.value), 1)
                    ]))
                  }), 128))
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, _toDisplayString(chartTitle.value), 1),
              (
							!mainChart.value ||
							_unref(store).filteredCommission.isLoading ||
							_unref(store).filteredWarranty.isLoading ||
							_unref(store).filteredCompleted.isLoading ||
							_unref(store).filteredCostRatio.isLoading
						)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_unref(ProgressSpinner))
                  ]))
                : (_openBlock(), _createBlock(_unref(Chart), {
                    key: 1,
                    data: mainChart.value,
                    height: 590,
                    options: chartOptions.value,
                    type: "bar"
                  }, null, 8, ["data", "options"]))
            ]),
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('dataUpdatedOn', { date: dataActualDate.value })), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('mobileVsInShop')), 1),
              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('mobileAbout', {
								n: _unref(store).purchaseMethodJobs.state.mobile
							})), 1),
              _createVNode(_unref(ProgressBar), {
                mode: 
							_unref(store).purchaseMethodJobs.isLoading
								? 'indeterminate'
								: 'determinate'
						,
                value: _unref(store).purchaseMethodJobs.state.mobile
              }, null, 8, ["mode", "value"])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('techside')), 1),
              _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('techsideJobsCompleted', {
								n: _unref(store).purchaseMethodJobs.state.techside
							})), 1),
              _createVNode(_unref(ProgressBar), {
                mode: 
							_unref(store).purchaseMethodJobs.isLoading
								? 'indeterminate'
								: 'determinate'
						,
                "pt:value:style": { backgroundColor: 'rgba(52, 205, 235, 0.75)' },
                value: _unref(store).purchaseMethodJobs.state.techside
              }, null, 8, ["mode", "value"])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('lifetimeCompletedJobs')), 1),
              _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('lifetimeJobsCompleted', {
								n: _unref(store).purchaseMethodJobs.state.todayJob
							})), 1),
              _createVNode(_unref(ProgressBar), {
                mode: 
							_unref(store).purchaseMethodJobs.isLoading
								? 'indeterminate'
								: 'determinate'
						,
                "pt:value:style": { backgroundColor: '#fde047' },
                value: _unref(store).purchaseMethodJobs.state.todayJob
              }, null, 8, ["mode", "value"])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('followUpJobs')), 1),
            (pieFollowUpJob.value)
              ? (_openBlock(), _createBlock(_unref(Chart), {
                  key: 0,
                  class: "tw3-w-60 tw3-h-60",
                  data: pieFollowUpJob.value,
                  options: pieFollowUpJobOptions.value,
                  type: "doughnut"
                }, null, 8, ["data", "options"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_29, [
                  _createVNode(_unref(ProgressSpinner))
                ])),
            _createVNode(TechnicianFollowUpInfo, {
              data: _unref(store).followUpJobs.state as FollowUpJobs
            }, null, 8, ["data"])
          ])
        ]),
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _createVNode(TechnicianTable)
          ])
        ])
      ]))
}
}

})