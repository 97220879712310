export interface FollowUpJobs {
	pickUp: number;
	dropOff: number;
	rAndI: number;
	tint: number;
	recal: number;
	callBack: number;
}

export interface PurchaseMethodJobs {
	mobile: number;
	inShop: number;
	delivery: number;
	willCall: number;
	totalJobs: number;
	techsideSent: number;
	completedJobs: number;
}

export interface TechEntry {
	id: number;
	name: string;
	lifetimeRevenue: number;
	lifetimeJobs: number;
	warrantyJobs: number;
	averageRating: number;
	totalCommissions: number;
	averageCommissionPerJob: number;
	warrantyRate: number;
	costRatio: number;
	jobCompletionPercentage: number;
	firstJobDate: Date | null;
}

export type TableHeader = {
	key: string;
	title: string;
	sortable?: boolean;
};

export enum TechSortBy {
	id = 'id',
	name = 'name',
	isDisabled = 'is_disabled',
	lifetimeRevenue = 'lifetime_revenue',
	lifetimeJobs = 'lifetime_jobs',
	warrantyJobs = 'warranty_jobs',
	averageRating = 'average_rating',
	totalCommissions = 'total_commissions',
	averageCommissionPerJob = 'average_commission_per_job',
	costRatio = 'cost_ratio',
	warrantyRate = 'warranty_rate',
	jobCompletionPercentage = 'job_completion_percentage',
	firstJobDate = 'first_job_date'
}

export type FiltersWithMetadata<T> = {
	[K in keyof T]: {
		value: string | boolean | null;
		matchMode: string;
	};
};

export type TechRequestParams = {
	take: number;
	skip: number;
	sortField: string;
	sortOrder: string;
	filters?: any;
};

export type TechResponse<T> = {
	items: T[];
	totalCount: number;
};

export type FetchTechsParams = {
	pagination: { page: number };
	selectedChip: number;
	filters: any;
	sort: { field: string; desc: boolean };
};
