import { useAsyncState } from '@vueuse/core';
import { DateTime } from 'luxon';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import {
	createTransaction as createTransactionApi,
	deleteTransaction,
	getTransaction,
	updateTransaction as updateTransactionApi
} from '@/entities/accounting/transactions/lib/api';
import {
	CreateTransactionRequest,
	TRANSACTION_TYPE_ID_JOURNAL_ENTRY,
	UpdateTransactionRequest
} from '@/entities/accounting/transactions/lib/types';
import useShopStore from '@/entities/shop/lib/store';
import { useUserStore } from '@/entities/user/lib/store';
import { useMessages } from '@/shared/composables';

const useTransactionsStore = defineStore(
	'entitiesAccountingTransaction',
	() => {
		const { showError, showInfo } = useMessages();

		const { user } = storeToRefs(useUserStore());
		const { shops } = storeToRefs(useShopStore());
		const loadingCounter = ref(0);
		const loading = computed(() => {
			return loadingCounter.value > 0;
		});

		const selectedTransactionState = useAsyncState(
			(id: number) => {
				return getTransaction(id).catch(error => {
					showError(error);
					return null;
				});
			},
			null,
			{
				immediate: false,
				resetOnExecute: true
			}
		);

		const loadSelectedTransaction = async (id: number, force = false) => {
			if (
				!force &&
				selectedTransactionState.state.value &&
				selectedTransactionState.state.value.id === id
			) {
				return;
			}
			await selectedTransactionState.execute(0, id);
		};

		const resetSelectedTransaction = (
			profileId: number,
			itemsCount: number
		) => {
			const items = [];
			for (let i = 0; i < itemsCount; i++) {
				items.push({
					debit: 0,
					credit: 0,
					description: '',
					quantity: 0
				});
			}

			let shop = shops.value.find(
				shop =>
					shop.id === user.value.user.shop_id &&
					shop.organization_id === profileId
			);

			if (!shop) {
				shop = shops.value.find(shop => shop.organization_id === profileId)!;
			}

			selectedTransactionState.state.value = {
				id: 0,
				transactionDt: DateTime.now().startOf('day'),
				amount: 0,
				typeId: TRANSACTION_TYPE_ID_JOURNAL_ENTRY,
				description: '',
				shopId: shop?.id,
				organizationId: profileId,
				items: items
			};
		};

		const saveSelectedTransaction = async () => {
			if (selectedTransactionState.state.value == null) {
				return;
			}
			loadingCounter.value++;
			try {
				if (selectedTransactionState.state.value.id) {
					const params: UpdateTransactionRequest = {
						id: selectedTransactionState.state.value.id,
						transactionDt: selectedTransactionState.state.value.transactionDt,
						description: selectedTransactionState.state.value.description,
						items: selectedTransactionState.state.value.items
					};
					await updateTransactionApi(params);
				} else {
					const params: CreateTransactionRequest = {
						organizationId:
							selectedTransactionState.state.value.organizationId!,
						shopId: selectedTransactionState.state.value.shopId!,
						typeId: selectedTransactionState.state.value.typeId!,
						transactionDt: selectedTransactionState.state.value.transactionDt,
						description: selectedTransactionState.state.value.description,
						jobId: selectedTransactionState.state.value.jobId!,
						items: selectedTransactionState.state.value.items
					};
					await createTransactionApi(params);
				}
				showInfo('Transaction saved');
			} catch (error) {
				showError(error);
			} finally {
				loadingCounter.value--;
			}
		};

		const deleteSelectedTransaction = async () => {
			if (selectedTransactionState.state.value == null) {
				return;
			}
			loadingCounter.value++;
			try {
				if (selectedTransactionState.state.value.id) {
					await deleteTransaction(selectedTransactionState.state.value.id);
					showInfo('Transaction deleted');
				}
			} catch (error) {
				showError(error);
			} finally {
				loadingCounter.value--;
			}
		};

		return {
			loading,
			selectedTransaction: selectedTransactionState.state,
			saveSelectedTransaction,
			loadSelectedTransaction,
			resetSelectedTransaction,
			deleteSelectedTransaction
		};
	}
);

export default useTransactionsStore;
