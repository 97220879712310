<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Fieldset from 'primevue/fieldset';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Textarea from 'primevue/textarea';
import { computed, nextTick, ref, watch } from 'vue';

import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import useTransactionTypesStore from '@/entities/accounting/transactions/lib/typeStore';
import AccountSelect from '@/features/accounting/AccountSelect.vue';
import CheatSheet from '@/features/accounting/CheatSheet.vue';
import ShopSelect from '@/features/accounting/ShopSelect.vue';
import { formatMoney } from '@/shared/helpers/formatters';
import DatePicker from '@/shared/ui/input/DatePicker.vue';
import InputMoney from '@/shared/ui/input/InputMoney.vue';

import { useJournalEntryForm } from './useJournalEntryForm';

const cid = 'accounting-journal-entry-edit';

const emit = defineEmits<{
	saved: [];
}>();

const { typesById } = storeToRefs(useTransactionTypesStore());

const transactionStore = useTransactionStore();
const { selectedTransaction } = storeToRefs(transactionStore);

const transaction = computed(() => selectedTransaction.value!);
const editMode = computed(() => transaction.value?.id !== 0);
const visible = defineModel<boolean>('visible');

const {
	init,
	valid,
	transactionDt,
	description,
	shopId,
	items,
	itemsErrors,
	addItem,
	removeItem,
	swapDebitCredit,
	creditTotal,
	debitTotal,
	amountMatch,
	save: doSave,
	reset,
	fieldLabels,
	onItemAmountChange
} = useJournalEntryForm();

const shopSelect = ref<typeof InputText>();

watch(
	visible,
	() => {
		if (visible.value) {
			init();
			nextTick(() => {
				// Focus the first input
				shopSelect.value?.$el.focus();
			});
		}
	},
	{ immediate: true }
);

const close = () => {
	visible.value = false;
};

const errorTooltipPT = {
	text: {
		style: { backgroundColor: 'rgb(248 113 113)' }
	},
	arrow: {
		style: { borderTopColor: 'rgb(248 113 113)' }
	}
};

const save = () => {
	doSave(async () => {
		emit('saved');
		close();
	});
};

const saveAndNew = () => {
	doSave(async () => {
		reset();
		shopSelect.value?.$el.focus();
		emit('saved');
	});
};

const loading = computed(() => !selectedTransaction.value || !typesById.value);

const title = computed(() => {
	if (loading.value) {
		return 'Loading...';
	}
	if (editMode.value) {
		return `${typesById.value[transaction.value!.typeId!]?.name} #${transaction.value?.id}`;
	}
	return 'New Journal Entry';
});
</script>

<template>
	<Dialog
		v-model:visible="visible"
		appendTo="#vue3app"
		modal
		:style="{ width: '60rem' }"
	>
		<template #header>
			<span class="p-dialog-title">
				{{ title }}
			</span>
			<div class="tw3-flex-grow"></div>
			<CheatSheet rounded></CheatSheet>
		</template>
		<div class="tw3-grid tw3-grid-cols-1 tw3-gap-4">
			<div class="tw3-flex tw3-flex-row tw3-gap-4">
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-shop-id`">
						{{ fieldLabels.shopId }}
					</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<ShopSelect
						v-else
						:id="`${cid}-shop-id`"
						ref="shopSelect"
						v-model="shopId"
						:loading="loading"
					/>
				</div>
				<div class="tw3-flex tw3-flex-col tw3-gap-0 tw3-grow tw3-basis-1">
					<label class="tw3-pl-1" :for="`${cid}-transaction-dt`">
						{{ fieldLabels.transactionDt }}
					</label>
					<Skeleton v-if="loading" height="2.2rem" />
					<DatePicker
						v-else
						:id="`${cid}-transaction-dt`"
						v-model="transactionDt"
					/>
				</div>
			</div>

			<div class="tw3-flex tw3-flex-col tw3-gap-0">
				<label class="tw3-pl-1" :for="`${cid}-description`">{{
					fieldLabels.description
				}}</label>
				<Skeleton v-if="loading" height="3.4rem" />
				<Textarea v-else :id="`${cid}-description`" v-model="description" />
			</div>

			<Fieldset
				class="relative"
				:legend="fieldLabels.items"
				:pt="{
					legendLabel: { class: 'gb-label ' },
					legend: { class: '!tw3-pb-1' },
					contentContainer: { class: 'tw3-pt-3' },
					root: { class: '!tw3-pb-2' }
				}"
			>
				<DataTable
					:pt="{
						column: {
							headerCell: {
								style: 'padding: 0px 0.8rem 0px 0px; border: none'
							},
							bodyCell: {
								style: 'padding: 0px 0.8rem 0.2rem 0px; border: none'
							},
							footerCell: {
								style: 'padding: 0px 0.8rem 0px 0px; border: none'
							}
						}
					}"
					scrollable
					scrollHeight="flex"
					size="small"
					tableClass="tw3-max-w-full"
					:value="loading ? new Array(2) : items"
				>
					<Column field="account" style="width: 27%">
						<template #header>
							<div class="gb-label tw3-pl-1">Account</div>
						</template>
						<template #body="{ data, index }">
							<Skeleton v-if="loading" height="2.2rem" />
							<div
								v-else
								v-tooltip.top="{
									value: itemsErrors[index].accountId,
									pt: errorTooltipPT
								}"
							>
								<AccountSelect
									v-model="data.value.accountId"
									:invalid="!!itemsErrors[index].accountId"
								/>
							</div>
						</template>
					</Column>
					<Column field="debit" style="width: 18%">
						<template #header>
							<div class="gb-label tw3-pl-1">Debit $</div>
						</template>
						<template #body="{ data, index }">
							<Skeleton v-if="loading" height="2.2rem" />
							<InputMoney
								v-else
								v-model="data.value.debit"
								v-tooltip.top="{
									value: itemsErrors[index].debit,
									pt: errorTooltipPT
								}"
								:invalid="!!itemsErrors[index].debit"
								@update:model-value="
									(value: number) =>
										onItemAmountChange(data.value, 'debit', value)
								"
							/>
						</template>
						<template #footer>
							<Skeleton v-if="loading" height="2.2rem" />
							<div
								v-else
								class="tw3-font-bold tw3-text-right"
								:class="{ 'tw3-text-red-600': !amountMatch }"
								style="padding-right: var(--p-inputtext-padding-x)"
							>
								{{ formatMoney(debitTotal) }}
							</div>
						</template>
					</Column>
					<Column field="credit" style="width: 18%">
						<template #header>
							<div class="gb-label tw3-pl-1">Credit $</div>
						</template>
						<template #body="{ data, index }">
							<Skeleton v-if="loading" height="2.2rem" />
							<InputMoney
								v-else
								v-model="data.value.credit"
								v-tooltip.top="{
									value: itemsErrors[index].credit,
									pt: errorTooltipPT
								}"
								:invalid="!!itemsErrors[index].credit"
								@update:model-value="
									(value: number) =>
										onItemAmountChange(data.value, 'credit', value)
								"
							/>
						</template>
						<template #footer>
							<Skeleton v-if="loading" height="2.2rem" />
							<div
								v-else
								class="tw3-font-bold tw3-text-right"
								:class="{ 'tw3-text-red-600': !amountMatch }"
								style="padding-right: var(--p-inputtext-padding-x)"
							>
								{{ formatMoney(creditTotal) }}
							</div>
						</template>
					</Column>
					<Column field="description" style="width: 32%">
						<template #header>
							<div class="gb-label tw3-pl-1">Item Description</div>
						</template>
						<template #body="{ data }">
							<Skeleton v-if="loading" height="2.2rem" />
							<InputText
								v-else
								v-model="data.value.description"
								fluid
								type="text"
							/>
						</template>
					</Column>
					<Column
						field="actions"
						:pt="{
							headerCell: { class: '!tw3-p-0' },
							bodyCell: { class: '!tw3-p-0' }
						}"
						style="max-width: 48px"
					>
						<template #body="{ index }">
							<Button
								:disabled="loading || items.length <= 2"
								icon="pi pi-trash"
								rounded
								text
								@click="removeItem(index)"
							/>
						</template>
						<template #footer>
							<Button
								v-tooltip.top="'Add item'"
								class="tw3-mr-2"
								icon="pi pi-plus"
								rounded
								text
								@click="addItem"
							/>
						</template>
					</Column>
				</DataTable>
			</Fieldset>
		</div>
		<div class="tw3-flex tw3-justify-between tw3-mt-5">
			<div class="tw3-flex tw3-justify-start">
				<Button
					v-tooltip.top="'Swap Debit and Credit valuesa'"
					:disabled="loading"
					:label="'Swap Debit/Creditb'"
					severity="secondary"
					@click="swapDebitCredit"
				>
					<template #icon>
						<i class="material-icons md-swap_horiz" />
					</template>
				</Button>
			</div>
			<div class="tw3-flex tw3-justify-end">
				<Button
					class="tw3-ml-5"
					:label="'Cancel'"
					severity="secondary"
					@click="close()"
				/>
				<Button
					class="tw3-ml-5"
					:disabled="loading || !valid"
					:label="'Save'"
					@click="save"
				/>
				<Button
					v-if="!editMode"
					class="tw3-ml-5"
					:disabled="loading || !valid"
					:label="'Save and New'"
					@click="saveAndNew"
				/>
			</div>
		</div>
	</Dialog>
</template>

<style scoped></style>
