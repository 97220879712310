import { i18n } from '@/shared/i18n';
import { TimeRange } from '@/widgets/dashboard/model/types';

export const getTimeRangeSuffix = (code: TimeRange) => {
	if (code === TimeRange.MONTH) {
		return ' ' + i18n.global.t('time.monthLong');
	} else if (code === TimeRange.WEEK) {
		return ' ' + i18n.global.t('time.weekLong');
	} else if (code === TimeRange.YEAR) {
		return ' ' + i18n.global.t('time.yearLong');
	} else {
		return ' ' + i18n.global.t('time.dayLong');
	}
};

export const getTimeRangePrefix = (code: TimeRange) => {
	if (code === TimeRange.DAY) {
		return i18n.global.t('time.dayShort');
	} else if (code === TimeRange.MONTH) {
		return i18n.global.t('time.monthShort');
	} else if (code === TimeRange.WEEK) {
		return i18n.global.t('time.weekShort');
	} else if (code === TimeRange.YEAR) {
		return i18n.global.t('time.yearShort');
	}
};
