import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Card from 'primevue/card';

export default /*@__PURE__*/_defineComponent({
  __name: 'PageCard',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Card), { pt: {
			content: { class: 'tw3-flex tw3-flex-col tw3-grow' },
			root: { class: 'tw3-grow' },
			body: { class: 'tw3-grow' }
		} }, {
    title: _withCtx(() => [
      _renderSlot(_ctx.$slots, "title")
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }))
}
}

})