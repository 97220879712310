import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { useEventListener as vueuseEventListener } from '@vueuse/core';
import { ref, watch } from 'vue';

import { useClaimDetailsStore } from '@/entities/claim/lib/store';
import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { useUserStore } from '@/entities/user/lib/store';
import { useEventListener } from '@/shared/composables';
import ClaimDialog from '@/widgets/claimDialog/ClaimDialog.vue';
import { newClaimFromJob } from '@/widgets/claimTable/lib/utils';
import EditCustomerDialog from '@/widgets/editCustomerDialog/editCustomerDialog.vue';
import FaxDialog from '@/widgets/faxDialog';
import { useInsuranceStore } from '@/widgets/insurance/lib/store';
import InsuranceDialog from '@/widgets/insuranceDialog/InsuranceDialog.vue';
import QuoteDialog from '@/widgets/quoteDialog/QuoteDialog.vue';
import RejectedInvoicesDialog from '@/widgets/rejectedInvoicesDialog';


export default /*@__PURE__*/_defineComponent({
  __name: 'DialogContainer',
  setup(__props) {

const showInsuranceDialog = useEventListener<boolean>('openInsuranceDialog');
const showQuotesDialog = useEventListener<boolean>('openQuotesDialog');
const showClaimDetailsDialog = useEventListener<boolean>(
	'openClaimDetailsDialog'
);
const showFaxDialog = useEventListener<boolean>('openFaxDialog');
const showRejectedInvoicesDialog = useEventListener<boolean>(
	'openRejectedInvoicesDialog'
);
const showEditCustomerDialog = ref(false);

const customerEditStore = useCustomerEditStore();

vueuseEventListener(
	window,
	'openHydrateCustomerDialog',
	async (event: CustomEvent) => {
		customerEditStore.customerStateBeforeChanges = undefined;
		if (event.detail.field)
			customerEditStore.vue2FieldName = event.detail.field;
		if (event.detail.id) {
			await customerEditStore.customer.execute(0, { id: event.detail.id });
		} else {
			customerEditStore.customer.state = {
				...event.detail.data,
				status: 'active',
				names: [],
				phones: [],
				emails: [],
				addresses: []
			};
		}
		showEditCustomerDialog.value = true;
	}
);

vueuseEventListener(
	window,
	'showNewClaimDialog',
	async (event: CustomEvent) => {
		// Create new claim from job details
		const insuranceStore = useInsuranceStore();
		await insuranceStore.insurances.execute(0, { providerName: 'glaxis' });
		const claimsStore = useClaimDetailsStore();
		claimsStore.claim.state = newClaimFromJob(event.detail);
		showClaimDetailsDialog.value = true;
	}
);
vueuseEventListener(
	window,
	'showReviewClaimDialog',
	async (event: CustomEvent) => {
		// Show claim dialog for existing claim
		const insuranceStore = useInsuranceStore();
		await insuranceStore.insurances.execute(0, { providerName: 'glaxis' });
		const claimsStore = useClaimDetailsStore();
		claimsStore.claim.state = event.detail;
		showClaimDetailsDialog.value = true;
	}
);

useUserStore();

watch(
	() => customerEditStore.vue2FieldName,
	() => {
		if (!customerEditStore.vue2FieldName) {
			customerEditStore.onClose();
			showEditCustomerDialog.value = false;
		}
	}
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(showInsuranceDialog))
      ? (_openBlock(), _createBlock(InsuranceDialog, {
          key: 0,
          show: _unref(showInsuranceDialog),
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (_isRef(showInsuranceDialog) ? (showInsuranceDialog).value = $event : null))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    (showEditCustomerDialog.value)
      ? (_openBlock(), _createBlock(EditCustomerDialog, {
          key: 1,
          show: showEditCustomerDialog.value,
          "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((showEditCustomerDialog).value = $event))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    (_unref(showQuotesDialog))
      ? (_openBlock(), _createBlock(QuoteDialog, {
          key: 2,
          show: _unref(showQuotesDialog),
          "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (_isRef(showQuotesDialog) ? (showQuotesDialog).value = $event : null))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    (_unref(showClaimDetailsDialog))
      ? (_openBlock(), _createBlock(ClaimDialog, {
          key: 3,
          show: _unref(showClaimDetailsDialog),
          "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => (_isRef(showClaimDetailsDialog) ? (showClaimDetailsDialog).value = $event : null))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    (_unref(showFaxDialog))
      ? (_openBlock(), _createBlock(_unref(FaxDialog), {
          key: 4,
          show: _unref(showFaxDialog),
          "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => (_isRef(showFaxDialog) ? (showFaxDialog).value = $event : null))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    (_unref(showRejectedInvoicesDialog))
      ? (_openBlock(), _createBlock(_unref(RejectedInvoicesDialog), {
          key: 5,
          show: _unref(showRejectedInvoicesDialog),
          "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => (_isRef(showRejectedInvoicesDialog) ? (showRejectedInvoicesDialog).value = $event : null))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})