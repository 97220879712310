<script setup lang="ts">
import Badge from 'primevue/badge';
import Fieldset from 'primevue/fieldset';
import Select from 'primevue/select';
import { defineModel } from 'vue';
import { useI18n } from 'vue-i18n';

import { Insurance } from '@/entities/insurance/lib/types';
import { US_STATES } from '@/shared/constants';
import { isEmptyValue } from '@/shared/helpers';

import { useInsuranceStore } from './lib/store';

const { t } = useI18n();

const insuredCustomer = defineModel<{
	city: string;
	firstName: string;
	id: number;
	lastName: string;
	phone1: string;
	phone2: string;
	postalCode: string;
	state: string;
	streetAddress: string;
}>('insuredCustomer', { required: true });

const insurance = defineModel<Insurance>('insurance', {
	required: true
});

defineProps<{
	policyNumber: string;
	status: boolean;
}>();

const insuranceStore = useInsuranceStore();
</script>

<template>
	<div>
		<Fieldset :legend="`${t('policy')} ${policyNumber}`">
			<div class="tw3-grid tw3-grid-cols-2 tw3-gap-2">
				<div>
					<div
						class="tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end"
					>
						{{ t('customer') }}
					</div>
					<div class="tw3-h-10 tw3-font-bold tw3-content-center">
						{{ insuredCustomer.firstName }} {{ insuredCustomer.lastName }}
					</div>
				</div>
				<div>
					<div
						class="tw3-h-8 tw3-items-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-flex tw3-gap-2"
					>
						<div>{{ t('phone') }}</div>
					</div>
					<div>
						<div class="tw3-h-10 tw3-content-center tw3-font-bold">
							{{ insuredCustomer.phone1 }}
						</div>
					</div>
				</div>
				<div>
					<div
						class="tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end"
					>
						{{ t('street') }}
					</div>
					<div class="tw3-h-10 tw3-content-center">
						{{ insuredCustomer.streetAddress }}
					</div>
				</div>
				<div>
					<div
						class="tw3-h-8 tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-content-end"
					>
						{{ t('city') }}
					</div>
					<div class="tw3-h-10 tw3-content-center">
						{{ insuredCustomer.city }}, {{ insuredCustomer.state }}
						{{ insuredCustomer.postalCode }}
					</div>
				</div>
				<div>
					<div
						class="tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
					>
						<span>
							{{ t('insurance') }}
						</span>
						<Badge
							v-if="isEmptyValue(insurance.insurerId)"
							v-tooltip.top="t('required')"
							class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
							severity="danger"
						/>
					</div>
					<Select
						v-model="insurance.insurerId"
						class="tw3-w-full tw3-max-w-64"
						:disabled="status"
						optionLabel="name"
						:options="insuranceStore.insurances.state"
						optionValue="externalId"
					/>
				</div>
				<div>
					<div
						class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
					>
						{{ t('statePolicty') }}
					</div>
					<Select
						v-model="insurance.policyState"
						class="tw3-w-full tw3-max-w-64"
						:disabled="status"
						optionLabel="title"
						:options="US_STATES"
						optionValue="value"
						placeholder="Select State"
					/>
				</div>
			</div>
		</Fieldset>
	</div>
</template>
