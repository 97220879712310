import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-gap-2 tw3-items-center" }
const _hoisted_2 = { class: "!tw3-text-xl !tw3-font-bold tw3-text-black" }
const _hoisted_3 = {
  class: "tw3-flex tw3-justify-between tw3-gap-2",
  style: {"padding":"12px 16px"}
}
const _hoisted_4 = { class: "tw3-flex tw3-gap-4" }
const _hoisted_5 = { class: "tw3-flex tw3-flex-col tw3-gap-1 tw3-grow" }
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "tw3-flex tw3-gap-4 tw3-items-end" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 1,
  class: "tw3-flex tw3-items-center tw3-justify-left"
}

import { FilterMatchMode } from '@primevue/core/api';
import { storeToRefs } from 'pinia';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import { computed, ref } from 'vue';

import useOrganizationsStore from '@/entities/admin/organizations/lib/store';
import PageCard from '@/shared/ui/container/PageCard.vue';

import useProfilesStore from './lib/store';
import ProfileCreateDialog from './ProfileCreateDialog.vue';

const cid = 'accounting-profiles-list';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileList',
  setup(__props) {

const skeletonArray = Array(30).fill({});

const profilesStore = useProfilesStore();
const { loading, profiles } = storeToRefs(profilesStore);

const organizationsStore = useOrganizationsStore();
const { organizations } = storeToRefs(organizationsStore);

const tableData = computed(() => {
	return profiles.value.map(profile => {
		return {
			...profile,
			organizationName: organizations.value[profile.organizationId].name
		};
	});
});

const filters = ref();

const filtersWrapper = computed({
	get: () => {
		return loading.value ? {} : filters.value;
	},
	set: value => {
		filters.value = value;
	}
});

const initFilters = () => {
	filters.value = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS }
	};
};

initFilters();

const showEditProfile = ref(false);
// const selectedProfile = ref({ ...defaultProfile });

const create = () => {
	showEditProfile.value = true;
	// selectedProfile.value = { ...defaultProfile };
};

const refresh = () => {
	profilesStore.loadProfiles();
};

const title = ref([{ label: 'Profiles', disabled: true }]);

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(PageCard, null, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Breadcrumb), {
          model: title.value,
          "pt:root:class": "!tw3-p-0 !tw3-ml-3"
        }, {
          item: _withCtx(({ item }) => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(item.label), 1)
          ]),
          _: 1
        }, 8, ["model"])
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", {
              class: "tw3-pl-1",
              for: `${cid}-search`
            }, "Search", 8, _hoisted_6),
            _createVNode(_unref(IconField), null, {
              default: _withCtx(() => [
                _createVNode(_unref(InputIcon), { class: "pi pi-search" }),
                _withDirectives(_createVNode(_unref(InputText), {
                  id: `${cid}-search`,
                  modelValue: filters.value['global'].value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value['global'].value) = $event)),
                  class: "tw3-w-full",
                  placeholder: "",
                  style: {"width":"250px","padding-left":"calc(\n\t\t\t\t\t\t\t\t\t\t(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)\n\t\t\t\t\t\t\t\t\t)","padding-right":"calc(\n\t\t\t\t\t\t\t\t\t\t(var(--p-form-field-padding-x) * 2) + var(--p-icon-size)\n\t\t\t\t\t\t\t\t\t)"},
                  type: "search"
                }, null, 8, ["id", "modelValue"]), [
                  [_directive_tooltip, 'Search by Name, Number, Type or Description']
                ]),
                _withDirectives(_createVNode(_unref(InputIcon), {
                  class: "pi pi-times",
                  style: {"cursor":"pointer"},
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (filters.value['global'].value = ''))
                }, null, 512), [
                  [_vShow, filters.value['global'].value]
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _withDirectives(_createVNode(_unref(Button), {
            icon: "pi pi-plus",
            label: "New profile",
            onClick: create
          }, null, 512), [
            [
              _directive_tooltip,
              'Create new profile',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives(_createVNode(_unref(Button), {
            icon: "pi pi-refresh",
            severity: "secondary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (refresh()))
          }, null, 512), [
            [
              _directive_tooltip,
              'Refresh',
              void 0,
              { top: true }
            ]
          ])
        ])
      ]),
      _createVNode(_unref(DataTable), {
        filters: filtersWrapper.value,
        "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => ((filtersWrapper).value = $event)),
        dataKey: "id",
        filterDisplay: "menu",
        globalFilterFields: ['name', 'code', 'type', 'subtype', 'description'],
        groupRowsBy: "type",
        pt: {
					rowgroupheadercell: { colspan: '99' }
				},
        scrollable: "",
        scrollHeight: "flex",
        size: "small",
        value: _unref(loading) ? _unref(skeletonArray) : tableData.value
      }, _createSlots({
        default: _withCtx(() => [
          _createVNode(_unref(Column), {
            field: "name",
            header: "Company Name"
          }, {
            body: _withCtx(({ data }) => [
              (_unref(loading))
                ? (_openBlock(), _createBlock(_unref(Skeleton), {
                    key: 0,
                    height: "2rem"
                  }))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(data.organizationName), 1))
            ]),
            _: 1
          }),
          _createVNode(_unref(Column), {
            field: "status",
            header: "Status"
          }, {
            body: _withCtx(({ data }) => [
              (_unref(loading))
                ? (_openBlock(), _createBlock(_unref(Skeleton), {
                    key: 0,
                    height: "2rem"
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_unref(Tag), {
                      "pt:label:class": "tw3-font-normal",
                      severity: data.active ? 'success' : 'danger',
                      value: data.active ? 'Active' : 'Inactive'
                    }, null, 8, ["severity", "value"])
                  ]))
            ]),
            _: 1
          })
        ]),
        _: 2
      }, [
        (!_unref(loading))
          ? {
              name: "empty",
              fn: _withCtx(() => [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "tw3-w-full tw3-flex tw3-justify-center tw3-items-center tw3-py-20" }, [
                  _createElementVNode("p", { class: "tw3-text-center tw3-text-3xl" }, "Profiles not found")
                ], -1))
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["filters", "value"]),
      _createVNode(ProfileCreateDialog, {
        modelValue: showEditProfile.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showEditProfile).value = $event))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})