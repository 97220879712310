import { useAsyncState } from '@vueuse/core';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import {
	getPaymentSettings,
	updatePaymentSettings
} from '@/entities/accounting/settings/lib/api';
import {
	PaymentSettings,
	UpdatePaymentSettingsRequest
} from '@/entities/accounting/settings/lib/types';
import useAccountingStore from '@/features/accounting/lib/store';
import { useMessages } from '@/shared/composables';

const useSettingsStore = defineStore('widgetsAccountingSettings', () => {
	const { showError, showInfo } = useMessages();

	const loadingCounter = ref(0);
	const loading = computed(() => {
		return loadingCounter.value > 0;
	});

	const { selectedProfileId } = storeToRefs(useAccountingStore());

	const paymentSettingsState = useAsyncState(
		(organizationId: number, background: boolean) => {
			if (!background) {
				loadingCounter.value++;
			}
			return getPaymentSettings(organizationId)
				.catch(error => {
					showError(error);
					return {
						category: []
					} as PaymentSettings;
				})
				.finally(() => {
					if (!background) {
						loadingCounter.value--;
					}
				});
		},
		{ category: [] } as PaymentSettings,
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const loadPaymentSettings = async (background: boolean = false) => {
		if (!selectedProfileId.value) {
			return;
		}
		await paymentSettingsState.execute(0, selectedProfileId.value, background);
	};

	const savePaymentSettings = async (params: PaymentSettings) => {
		if (!selectedProfileId.value) {
			throw new Error('Organization is not selected');
		}
		try {
			const request: UpdatePaymentSettingsRequest = {
				organizationId: selectedProfileId.value,
				...params
			};
			await updatePaymentSettings(request);
			showInfo('Settings saved');
			loadPaymentSettings(true);
		} catch (error) {
			showError(error);
		}
	};

	watch(
		selectedProfileId,
		async () => {
			await loadPaymentSettings();
		},
		{ immediate: true }
	);

	return {
		loading,
		paymentSettings: paymentSettingsState.state,
		loadPaymentSettings,
		savePaymentSettings,
		selectedProfileId
	};
});

export default useSettingsStore;
