import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Chip from 'primevue/chip';
import ProgressSpinner from 'primevue/progressspinner';

export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingIndicator',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Chip), { style: {"position":"absolute","bottom":"40px","padding":"8px 20px","left":"50%","transform":"translateX(-50%)"} }, {
    default: _withCtx(() => [
      _createVNode(_unref(ProgressSpinner), {
        strokeWidth: "8",
        style: {"width":"30px","height":"30px","margin-right":"8px"}
      }),
      _cache[0] || (_cache[0] = _createElementVNode("span", null, "Loading...", -1))
    ]),
    _: 1
  }))
}
}

})