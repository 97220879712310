import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["href"]

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Skeleton from 'primevue/skeleton';
import { onMounted, ref } from 'vue';

import { useCustomerEditStore } from '@/entities/customer/lib/store';

import { previousJobsHeader } from '../../lib/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerPreviousJobs',
  setup(__props) {

const store = useCustomerEditStore();
const baseUrl = ref('');

const formatPrice = (i: number) => {
	return '$' + Math.abs(i).toFixed(2);
};

onMounted(() => {
	baseUrl.value = window.location.origin;
	store.previousJobs.execute(0);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DataTable), {
    class: "tw3-w-full",
    value: 
			_unref(store).previousJobs.isLoading ? new Array(4) : _unref(store).previousJobs.state
		
  }, {
    empty: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "tw3-text-center" }, "No Data", -1)
    ])),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(previousJobsHeader), (column) => {
        return (_openBlock(), _createBlock(_unref(Column), {
          key: column.key,
          field: column.key,
          header: column.title,
          style: {"width":"20%"}
        }, {
          body: _withCtx(({ data }) => [
            (_unref(store).previousJobs.isLoading)
              ? (_openBlock(), _createBlock(_unref(Skeleton), { key: 0 }))
              : (column.key === 'id')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: baseUrl.value + '/jobs/' + data[column.key],
                    target: "_blank"
                  }, _toDisplayString(data[column.key]), 9, _hoisted_1))
                : (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass({
						'tw3-text-right': column.key === 'total_balance_after_payments'
					})
                  }, _toDisplayString(column.key === 'total_balance_after_payments'
							? formatPrice(data[column.key] ? data[column.key] : 0)
							: data[column.key]), 3))
          ]),
          _: 2
        }, 1032, ["field", "header"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["value"]))
}
}

})